import React from "react";

import { utils } from "../config/userConfig";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";

export default function EMComQstFooter({poll, pqst, roundAnswers=[], bold=false, className=''}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const chosen  = utils.getChosenAnswers(pqst, 1, pqst?.qround);
    const nc2s = (pqst ? (pqst.nwanted - utils.countChosenAnswers(pqst, 1, pqst?.qround-1)) : 0);
    const qgoal = (pqst ? utils.completeLocaleMapText(localeMap, "monitor.quest.goal." + pqst.qkind, [ pqst.nwanted, pqst.nanswers ], poll.pkind) : '');
    const rgoal = (pqst ? utils.completeLocaleMapText(localeMap, "monitor.quest.goal." + pqst.qkind, [ nc2s, roundAnswers.length], poll.pkind) : '');
    const [goalFolded, setGoalFolded] = React.useState(true);
    const TEXT_SIZE = 'text-lg';

    if (!poll) {
        return (<h3>poll is null</h3>);
    } else if (!pqst) {
        return (<h3>pqst is null</h3>);
    }

    return (
        <>
            { pqst.qstatus < 'Vx' ?
                <div className={"w-full flex flex-col justify-start items-start " + className}>
                    <div className={"w-full flex flex-row justify-between items-center "}>
                        <div className={"flex flex-row justify-start items-center gap-2 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {localeMap["monitor.quest.goal.label"]} :
                            </label>
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                { rgoal === qgoal ?
                                    <span className={TEXT_SIZE + " font-bold"}>{rgoal}</span>
                                :
                                    <span><span className={TEXT_SIZE + " font-bold"}>{rgoal}</span> &nbsp; ({`Q${poll.qactive+1}`}: {qgoal}) </span>
                                }
                            </label> 
                        </div>
                        <IconChevron width={24} height={24} 
                            className={`cursor-pointer hover:text-blue-500 ${goalFolded ? `` : `rotate-180`}`} 
                            onClick={()=>{ setGoalFolded(!goalFolded); }}/> 
                    </div>
                    { !goalFolded &&
                    <>
                        <div className={"flex flex-row justify-start items-center gap-2 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {localeMap["poll02.wcriteria.label." + pqst.qkind]} :
                            </label>
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {utils.translateCriteria(pqst.qoption.c2win, localeMap)}
                            </label> 
                        </div>
                        { pqst.qroundmax > 1 &&
                        <div className={"flex flex-row justify-start items-center gap-2 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {localeMap["poll02.dcriteria.label"]} :
                            </label>
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {utils.translateCriteria(pqst.qoption.c2drop, localeMap)}
                            </label> 
                        </div>
                        }
                        <div className={"flex flex-row justify-start items-center gap-2 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {localeMap["monitor.quest.goal.roundmax"]} :
                            </label>
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {pqst.qroundmax}
                            </label> 
                        </div>
                    </>
                    }
                </div>
            :
                <div className={"w-full flex flex-col justify-start items-start " + className}>
                    <div className={"w-full flex flex-row justify-between items-center "}>
                        <div className="flex flex-row justify-start items-center gap-2" >
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                    {localeMap["monitor.quest.result.chosen"]} :
                            </label>
                            <label className={TEXT_SIZE + " font-bold"}>
                                {chosen.join(', ')}
                            </label> 
                        </div>
                        <IconChevron width={24} height={24} 
                            className={`cursor-pointer hover:text-blue-500 ${goalFolded ? `` : `rotate-180`}`} 
                            onClick={()=>{ setGoalFolded(!goalFolded); }}/> 
                    </div>
                    <div className="flex flex-row justify-start items-center gap-2" >
                        <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                            {localeMap["monitor.quest.result.todo"]} :
                        </label>
                        { chosen.length >= pqst.nwanted ? 
                            <span className={TEXT_SIZE}>{localeMap["monitor.quest.todo.done"]}</span>
                        : pqst.qround >= pqst.qroundmax && chosen.length >= 1 ?
                            <span className={TEXT_SIZE}>{utils.completeMessageText(localeMap["monitor.quest.todo.fail1"], [pqst.nwanted - chosen.length])}</span>
                        : pqst.qround >= pqst.qroundmax ?
                            <span className={TEXT_SIZE}>{localeMap["monitor.quest.todo.fail0"]}</span>
                        :
                            <span className={TEXT_SIZE}>{utils.completeLocaleMapText(localeMap, "monitor.quest.todo."+pqst.qkind, [(pqst.nwanted - chosen.length)], poll.pkind)}</span>
                        }
                    </div>
                    { !goalFolded &&
                    <>
                        <div className={"flex flex-row justify-start items-center gap-2 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {localeMap["monitor.quest.goal.label"]} :
                            </label>
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                { rgoal === qgoal ?
                                    <span className={"font-bold " + TEXT_SIZE}>{rgoal}</span>
                                :
                                    <span><span className={"font-bold " + TEXT_SIZE}>{rgoal}</span> &nbsp; ({`Q${poll.qactive+1}`}: {qgoal}) </span>
                                }
                            </label> 
                        </div>
                        <div className={"flex flex-row justify-start items-center gap-2 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {localeMap["poll02.wcriteria.label." + pqst.qkind]} :
                            </label>
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {utils.translateCriteria(pqst.qoption.c2win, localeMap)}
                            </label> 
                        </div>
                        { pqst.qroundmax > 1 &&
                        <div className={"flex flex-row justify-start items-center gap-2 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {localeMap["poll02.dcriteria.label"]} :
                            </label>
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {utils.translateCriteria(pqst.qoption.c2drop, localeMap)}
                            </label> 
                        </div>
                        }
                        <div className={"flex flex-row justify-start items-center gap-2 hover:cursor-pointer " + (pqst.qstatus === 'Vt' ? "invisible" : "")} >
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {localeMap["monitor.quest.goal.roundmax"]} :
                            </label>
                            <label className={TEXT_SIZE + (bold ? " font-bold" : " font-normal")}>
                                {pqst.qroundmax}
                            </label> 
                        </div>
                    </>
                    }
                </div>
            }
        </>
    );
}

