import React from "react";
import { Outlet } from "react-router-dom";

export default function EmptyLayout() {
  return (
    <div className="w-[100vw] h-[100vh] ">
      <Outlet />
    </div>
  );
}
