import React from "react";
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal";
import PzInput from "../common/PzInput";
import PzSymbol from "../common/PzSymbol";
import EvtViewerForQstResult from "../eventview/EvtViewerForQstResult";
import PzSetting from "../common/PzSetting";

export default function EvtViewerForQst({ pqst, className='', size='lg', visibility={k:true,t:true,q:true,g:true,x:false}, narrow=false }) {
    const idTitle = React.useId();
    const idType = React.useId();
    const idQuestion = React.useId();
    const idAnswers = React.useId();
    const idQstGoal = React.useId();
    const idWCriteria = React.useId();
    const idDCriteria = React.useId();
    const ulocale = useSelector((state)=>state.ucfg.locale);  // user's current locale selection
    const localeMap = useSelector(utils.selectLocaleMap);
    let [textAnswers, setTextAnswers] = React.useState(convertAnswersForTextArea(pqst?.answers));
    const [selQkind, setSelQkind] = React.useState([ pqst.qkind ]);
    const text_size = 'text-' + size;
    // console.log(visibility);

    React.useEffect(() => {
        setTextAnswers(convertAnswersForTextArea(pqst.answers))
    }, [pqst.answers]);

    function convertAnswersForTextArea(answers) {
        let desc = [];
        for (let i = 1; i < answers?.length; i++) {
            if (typeof answers[i] === 'string') {
                desc.push(answers[i]);          // This is case when it was created on the frontend
            } else if (typeof answers[i] === 'object' && answers[i].aname !== '_WAIVE_') {
                desc.push(answers[i].aname);    // This is case when it was received from the backend
            }
        }
        return desc.join("\n");
    }

    if (!pqst) return (<h4>Undefined 'pqst'</h4>);
    return (
        <div className={" w-full grid grid-cols-[auto_1fr] gap-x-3 gap-y-1 " + text_size + className}>

            {/* Kind */}
            { visibility.k && <>  
                <span htmlFor={idType} className={text_size}>{localeMap["poll02.qkind.label"]} :</span>
                <div className="grid grid-cols-[auto_auto_auto] items-start">
                    <PzInput.Radio2 value='A' selection={selQkind} size={size} disabled={pqst.qkind!=='A'} text={localeMap["poll02.qkind.A"]} />
                    <PzInput.Radio2 value='S' selection={selQkind} size={size} disabled={pqst.qkind!=='S'} text={localeMap["poll02.qkind.S"]} />
                    <PzInput.Radio2 value='E' selection={selQkind} size={size} disabled={pqst.qkind!=='E'} text={localeMap["poll02.qkind.E"]} />
                </div>
            </>}

            {/* Question */}
            { visibility.q && <>  
                <span htmlFor={idQuestion} className={text_size}>{localeMap["poll02.question.label"]} :</span>
                <PzInput.ForText 
                    id={idQuestion} name="question" className="border-[1px] border-gray-300 px-1" disabled={true}
                    value={pqst.question} update={true} />
            </>}

            {/* Answers */}
            { visibility.q && <>  
                <span htmlFor={idAnswers} className={text_size} hidden={pqst.qkind==='C'}>{pqst.qkind==='E' ? localeMap["poll02.answers.elabel"] : localeMap["poll02.answers.clabel"]} :</span>
                <PzInput.ForTextArea 
                    id={idAnswers} name="answers" className="border-[1px] border-gray-300 px-1" disabled={true} 
                    rows={pqst.nanswers >= 5 ? 5 : pqst.nanswers} 
                    value={textAnswers} setValue={setTextAnswers} update={true} />
            </>}

            {/* Goal */}
            { visibility.g && <>  
                <span htmlFor={idQstGoal} className={text_size}>{localeMap["poll02.vgoal.label"]} :</span>
                <div id={idQstGoal} className="flex flex-row justify-between items-center">
                    <div className="text-sm pl-1">
                        { pqst.qkind==='A' ?
                            <p className={text_size}>{utils.getQstGoalSummary(pqst, localeMap)}</p>
                        : pqst.qkind==='S' ?
                            <div className={"flex flex-row justify-start items-center gap-1 " + text_size}>
                                <label>{localeMap["poll02.qkind."+pqst.qkind]}</label> &nbsp;
                                <label>{pqst.nwanted}&nbsp;/&nbsp;{pqst.nanswers}</label>
                            </div>
                        : pqst.qkind==='E' ?
                            <div className={"flex flex-row justify-start items-center gap-1 " + text_size}>
                                <label>{localeMap["poll02.qkind."+pqst.qkind]}</label> &nbsp;
                                <label>{pqst.nwanted}&nbsp;/&nbsp;{pqst.nanswers}</label>
                            </div>
                        : 
                            <label>{localeMap["poll02.qkind."+pqst.qkind]}</label>
                        }
                    </div>
                    <div className={`flex flex-row justify-start items-center gap-2 ${pqst.qkind==='C' ? `invisible` : ``}`}>
                        <label>{localeMap["poll02.vgoal.maxrounds"]} :</label>
                        <label>{pqst.qroundmax}</label>
                    </div>
                </div>
            </>}

            {/* Criteria to Win */}
            { visibility.g && pqst.qkind !== 'C' &&
                <>  
                    <span htmlFor={idWCriteria} className={text_size}>{localeMap["poll02.wcriteria.label." + pqst.qkind]} :</span>
                    <PzSetting.C2Win criteria={pqst.qoption?.c2win} locale={ulocale} size={narrow ? 'md' : size} disabled={true} />
                </>
            }

            {/* Criteria to Drop */}
            { visibility.g && pqst.qkind !== 'A' && pqst.qkind !== 'C' && pqst.qroundmax > 1 &&
                <>  
                    <span htmlFor={idDCriteria} className={text_size}>{localeMap["poll02.dcriteria.label"]} :</span>
                    <PzSetting.C2Drop criteria={pqst.qoption?.c2drop} locale={ulocale} size={narrow ? 'md' : size} disabled={true} />
                </>
            }
            { visibility.x && <>  {/* Results */}
                <span htmlFor={idAnswers} className={text_size}>{localeMap["poll02.xresult.label"]} :</span>
                <EvtViewerForQstResult pqst={pqst} />
            </>}
        </div>
    );
}

export function Modal4EvtViewerForQst({pqst, onClose}) {
    const localeMap = useSelector(utils.selectLocaleMap);

    if (!pqst) return (<p>invalid pqst</p>);

    return (
        <PzBasicModal onClose={onClose} xclose={true} >
            <div className="min-w-[34rem] flex flex-col justify-start items-stretch p-5 gap-5">

                <h2>{localeMap["poller.editevent.quest"]}</h2>

                <EvtViewerForQst pqst={pqst} />

            </div>
        </PzBasicModal>
    );
}
