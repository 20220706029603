import React from "react";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzSymbol from "../common/PzSymbol";
import { locale } from "dayjs";

export default function EMComFinalResults({poll}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const qstlist = get_quest_result_list(poll);
    const [qSelected, setQSelected] = React.useState(-1);

    function get_quest_result_list(poll) {
        let qstlist = [];
        if (poll && poll.pqstlist) {
            for (let i = 0; i < poll.pqstlist.length; i++) {
                let q = poll.pqstlist[i];
                let chosen = [];
                for (let j = 1; j < q.chosen.length; j++) {
                    let a = q.chosen[j];
                    let r = Number(a.astat.substring(0,1));
                    let percent = (a.rvcount[r] * 100 / poll.natt).toFixed(1);
                    chosen.push({ aidx: a.aidx, aname: a.aname, round:r, count: a.rvcount[r], total: poll.natt, percent: percent});
                }
                let qresult = { question: q.question, nchn: chosen.length, nrem: q.nwanted - chosen.length, chosen: chosen, qkind: q.qkind };
                qstlist.push(qresult);
            }
        }
        return qstlist;
    }

    function onClickToQst(qidx) {
        setQSelected(qidx === qSelected ? -1 : qidx);
    }

    return (
        <>
            { qstlist.map((q,idx)=>(
                <div key={idx} className="w-full p-4 border border-slate-800 rounded-2xl flex flex-col gap-4">  
                    <div className="flex flex-row justify-start items-center gap-4">
                        <PzSymbol.Text text={'Q'+(idx+1)} size="xl" className="!bg-purple-800 !text-white hover:cursor-pointer" 
                            onClick={()=>{console.log(poll.pqstlist[idx]);}} />
                        <p className={"w-auto text-2xl font-bold " + (idx === qSelected ? "" : "truncate")} onClick={()=>{onClickToQst(idx);}}>
                            {q.question}
                        </p>
                    </div>
                    <div className="w-full h-[1px] bg-slate-200"></div>
                        { q.chosen.length > 0 ?
                            <div className="w-full pl-12 flex flex-col justify-start items-start gap-2">
                                { q.chosen.map((a)=>{ return (
                                    <div key={a.aidx} className="w-full grid grid-cols-[1fr_auto_4.5rem_2.5rem] justify-end items-center">
                                        <label className="text-xl text-left font-bold">{a.aname}</label>
                                        <label className="text-xl text-right">{a.count} / {a.total}</label>
                                        <label className="text-xl text-right">{a.percent}%</label>
                                        <div className="w-auto flex flex-row justify-end items-center">
                                            <PzSymbol.Text text={'R'+a.round} size="lg" op50={true} className="!bg-purple-800 !text-white" />
                                        </div>
                                    </div>
                                )}) }
                            </div>
                        :
                            <h4 className="text-slate-900">{localeMap["monitor.result.nochosen."+q.qkind]}</h4>
                        }
                </div>
            ))}
        </>
    );
}

