import React from "react";

import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";

export default function EMComQstAnswers({pqst, answers, preview=false, selectable=false, selection=[], setSelection=()=>{}, className=''}) {
    const nc2s = (pqst ? pqst.nwanted - (pqst.chosen?.length - 1) : 0);
    const toomany = (answers.length > 7);
    const clsflex = (toomany ? "w-full flex flex-wrap gap-2" : "w-auto flex flex-col gap-2");
    let selectable2 = (setSelection ? selectable : false);
    // console.log("EMComQstAnswers :  selectable:", selectable, "  bold:", bold);

    if (!answers) {
        return (<h3>answers is null</h3>);
    }

    return (
        <div className={"w-full flex flex-row justify-start items-center " + className}>
            <div className={ clsflex + " justify-start items-start"}>
                { pqst.qstatus === 'Vs' ?
                    answers.map((a)=>{return (
                        <PzInput.Answer key={a.aidx} value={a.aidx} text={a.aname} 
                            checked={selection.includes(a.aidx)} size={toomany ? "xl" : "2xl"}
                            disabled={!preview && !selectable} preview={preview} selectable={selectable2} 
                            selection={selection} setSelection={setSelection} nSelection={nc2s} />
                    )}) 
                :
                    <></>
                }
            </div>
        </div>
    );
}

