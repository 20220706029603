import React from "react";

import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";
import PzProgress from "../common/PzProgress";

export default function EMComQstResult({poll, pqst, roundAnswers, topAnswers=[], folded=true}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const nc2s = (pqst ? pqst.nwanted - (pqst.chosen?.length - 1) : 0);
    const pchosen = get_chosen_in_previous_rounds();

    function get_chosen_in_previous_rounds() {
        let chosen = [];
        for (let i = 1; pqst && i < pqst.chosen.length; i++) {
            let a = pqst.chosen[i]; // { aidx:1, aname:'', adesc:null, astat:'1-', rvcount:[...], rcount:99 }
            if (a.astat[0] < String(pqst.qround) && a.astat[1] === 'O') chosen.push(a.aname);
        }
        return chosen;
    }

    function get_class_for_answer(answer) {
        switch (answer.astat[1]) {
        case 'O': return "text-blue-800 font-extrabold";
        case 'X': return "text-slate-400";
        default : return "";
        }
    }

    if (!poll) {
        return (<h3>poll is null</h3>);
    } else if (!pqst) {
        return (<h3>pqst is null</h3>);
    }
    
    return (
        <>
        { pqst.qstatus === 'Vt' ?
            <div className="w-full px-5 flex flex-col justify-start items-center gap-5">
                <div className="w-full max-h-[16rem] overflow-y-scroll">{
                    roundAnswers.map((a)=>{ return (
                        <div key={a.aidx} className="flex flex-row justify-between items-center">
                            <h3>{a.aname}</h3>
                            <PzProgress className="w-[30%]" showlabels={false} determinate={false} />
                        </div>
                    )})}
                </div>
            </div>
        : pqst.qstatus === 'Vx' ?
            <div className="w-full max-h-[20rem] overflow-y-scroll">
                <div className="w-full px-10 flex flex-col justify-start items-stretch gap-2 ">
                    { folded && pqst.qkind != 'A' ?
                        topAnswers.map((a)=>{ return (
                            <div key={a.aidx} className="flex flex-row justify-between items-center">
                                <label className={"text-xl " + get_class_for_answer(a)}>{a.aname}</label>
                                <PzProgress className="w-[30%]" 
                                    count={a.rcount} total={poll.natt} result={a.astat.substring(1,2)} />
                            </div>
                        ); })
                    :
                        roundAnswers.map((a)=>{ return (
                            <div key={a.aidx} className="flex flex-row justify-between items-center">
                                <label className={"text-xl " + get_class_for_answer(a)}>{a.aname}</label>
                                <PzProgress className="w-[30%]" 
                                    count={a.rcount} total={poll.natt} result={a.astat.substring(1,2)} />
                            </div>
                        ); })
                    }
                </div>
            </div>
                
        :
            <div>&nbsp;</div>
        }
        </>
    );
}

