import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import { ReactComponent as IconPollBox } from "../resource/icons/pz-vote-pollbox.svg";
import PzButton from "../common/PzButton";

export default function NavbarItemMyEvents() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const navigate = useNavigate();

    return (
        <PzButton.Minimal className="px-1" gap="gap-1" onClick={()=>{navigate('/pages/myevents')}}>
            <IconPollBox width="1.2rem" height="1.2rem" />
            <h5>{localeMap["menu.myevents"]}</h5>
        </PzButton.Minimal>
    );
}
