import React from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { motion, AnimatePresence } from "framer-motion"

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal"
import PzButton from "../common/PzButton"

const EntranceForRehearsal = {
    Dialog:      EntranceForRehearsalDialog,
};

export default EntranceForRehearsal;


function EntranceForRehearsalDialog({poll, onClose=()=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const POLL_URL_PATH = '/y/rrun?p=' + poll?.pguid;
    const imgsrc = `${appConfig.getBaseUrlForAPI()}/api/entrance/rehearsal?p=${poll?.pguid}`
    const [cookies, removeCookie] = useCookies(['vauth']);
    // console.log("imgsrc : ", imgsrc);
    const [step, setStep] = React.useState(0);
    const ttext = localeMap['simulate.rehearsal.title'];
    const title = utils.completeMessageText((ttext ? ttext : ""), [poll?.ptitle]);

    const images = [
        appConfig.getBaseUrlForAPI() + '/static/images/concept_poll.png',
    ];
    const variants = {
        init:   { x:     0, opacity: 0, scale: 0 },
        enter:  { x: +1000, opacity: 0, scale: 1 },
        center: { x:     0, opacity: 1, scale: 1 },
        exit:   { x: -1000, opacity: 0, scale: 1 },
    };

    function onClickForButton() {
        if (step < images.length-1) {
            setStep(step+1);
        } else {
            removeCookie("vauth", null);
            const url = appConfig.getBaseUrlForAPI() + '/y/rrun?p=' + poll?.pguid ;
            const win = window.open(url.replace(":80", ":3000"), '_blank');
            if (win != null) win.focus();
            if (typeof onClose === 'function') onClose();
        }
    }

    return (
        <PzBasicModal onClose={onClose}>
            <div className="w-full min-w-[24rem] max-w-[32rem] flex flex-col justify-start items-center p-10 gap-10" >
                <div className="w-full flex flex-col justify-start items-center gap-5">
                    <div className="w-full flex flex-col justify-start items-center gap-1">
                        { title.split("\n").map((part,idx)=>{return (<h2 key={idx}>{part}</h2>);}) }
                    </div>
                </div>
                <div className="w-[30rem] h-[24rem] overflow-clip relative">
                    <AnimatePresence initial="init" >
                        <motion.img
                            key={step}
                            src={images[step % images.length]}
                            variants={variants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            className="absolute object-contain object-center"
                            transition={{
                                x: { type: "spring", stiffness: 300, damping: 30 },
                                opacity: { duration: 0.2 }
                            }}
                            onClick={onClickForButton}
                        />
                    </AnimatePresence>
                </div>
                <div className="w-full flex flex-col justify-start items-center gap-5">
                    <PzButton.Std 
                        text={step < images.length-1 ? localeMap["poll.create.button.next"] : localeMap["simulate.trial.openevent"]} 
                        size='xl' onClick={onClickForButton} />
                </div>
            </div>
        </PzBasicModal>
    )

}

