import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import EMWithPzServer from "./EMWithPzServer";
import EMWithSimulator from "./EMWithSimulator";

export default function EMAreaFooterAsPoller({poll, pqst, setPoll}) {
    const location = useLocation();
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const [quitting, setQuitting] = React.useState(isQuitting());
    const [disabled, setDisabled] = React.useState(isNextDisabled());
    let diff = utils.getDiffSince(poll?.updatedat);
    const [invisible, setInvisible] = React.useState(!diff ? false : diff < 1000);
    let [actionSymbol, actionText] = getActionSymbolText();
    // console.log(poll.qactive, poll.qactive_selected);

    useInterval(() => {
        if (quitting !== isQuitting()) setQuitting(isQuitting());
        if (disabled !== isNextDisabled()) setDisabled(isNextDisabled());
        let diff = utils.getDiffSince(poll.updatedat);
        let new_invisible = (actionText === '' || !diff || diff < 1000);
        if (invisible !== new_invisible) setInvisible(new_invisible);
        // console.log("disabled:", disabled, "  invisible:", invisible);
    }, 500);

    function isQuitting() {
        if (!poll) return false;
        let closed_pqst = 0;
        for (let i = 0; i < poll.pqstlist.length; i++) {
            if (poll.pqstlist[i].qstatus >= 'Vx') closed_pqst++;
        }
        return (closed_pqst > 0 && (closed_pqst === poll.pqstlist.length || poll.qactive_selected === -1));
    }

    function isNextDisabled() {
        if (!poll) return false;
        let diff = utils.getDiffSince(poll.updatedat);
        let tooearly = (!diff || diff < 3000);
        switch (pstatus) {
        case 'Ax': return (tooearly);
        case 'Oo': return (tooearly || poll.natt < poll.nmin);
        case 'Os':
            switch (qstatus) {
            case ' ' : 
                return (tooearly ? true : quitting ? false : !(poll?.qactive_selected >= 0));
            case 'Vs': return (tooearly || poll.natt < poll.nmin);
            case 'Vt': return (tooearly);
            case 'Vx': return (tooearly);
            default:   return false;
            }
        case 'Oz': return (tooearly);
        default:   return false;
        }
    }

    function getActionSymbolText() {
        if (!poll) return ['',''];
        const last_quest = (poll && (poll.qactive === poll.pqstlist.length-1));
        const last_round = (pqst && (pqst.qround  === pqst.qroundmax || (pqst.chosen.length-1) >= pqst.nwanted));
        const qr_symbol = (pqst && pqst.qround > 1 ? `Q${poll.qactive+1} Round${pqst.qround}` : `Q${poll.qactive+1}`);
        let diff = utils.getDiffSince(poll.updatedat);
        let toolate = (diff && diff > 9000);

        switch (pstatus) {
        case 'Ax': 
            return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.open", [], poll.pkind)];
        case 'Oo': 
            if (poll.natt > poll.nmin) {
                return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.start", [], poll.pkind)];
            } else {
                return [``, ``];
            }
        case 'Os':
            switch (qstatus) {
            case ' ' : 
                if (quitting) {
                    return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.final", [], poll.pkind)];
                } else if (poll.qactive_selected >= 0) {
                    return [`Q${poll.qactive_selected+1}`, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstart", [], poll.pkind)];
                } else {
                    return [``, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstart", [], poll.pkind)];
                }
            case 'Vs': 
                return [qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstop", [], poll.pkind)];
            case 'Vt': 
                if (!toolate) return ['', ''];
                return [qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.vstop", [], poll.pkind)];
            case 'Vx':
                // if (last_round && last_quest) {
                //     return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.final", [], poll.pkind)];
                // } else 
                if (last_round) {
                    let new_qr_symbol = `Q${poll.qactive+1}`;
                    return [new_qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.close", [], poll.pkind)];
                } else {
                    let new_qr_symbol = (pqst ? `Q${poll.qactive+1} Round${pqst.qround+1}` : `Q${poll.qactive+1}`);
                    return [new_qr_symbol, utils.completeLocaleMapText(localeMap, "monitor.action.quest.nextr", [], poll.pkind)];
                }
            default: return [``, `invalid qstatus (${qstatus})`];
            }
        case 'Oz': return [``, utils.completeLocaleMapText(localeMap, "monitor.action.event.close", [], poll.pkind)];
        default:   return [``, `invalid pstatus (${pstatus})`];
        }
    }

    function gotoNextStep() {
        const last_quest = (poll && (poll.qactive === poll.pqstlist.length-1));
        const last_round = (pqst && (pqst.qround === pqst.qroundmax || (pqst.chosen.length-1) >= pqst.nwanted));
        const simulate = (location.pathname.startsWith("/y/"));
        let diff = utils.getDiffSince(poll.updatedat);
        let toolate = (diff && diff > 9000);

        switch (pstatus) {
        case 'Ax':
            if (simulate) EMWithSimulator.setPollStatus(poll, setPoll, 'Oo');
            else          EMWithPzServer.setPollStatus(poll, setPoll, 'Oo');
            break;
        case 'Oo':
            if (simulate) EMWithSimulator.setPollStatus(poll, setPoll, 'Os'); // this may start the only question 
            else          EMWithPzServer.setPollStatus(poll, setPoll, 'Os');
            break;
        case 'Os':
            switch (qstatus) {
            case ' ' : 
                if (quitting) {
                    if (simulate) EMWithSimulator.setPollStatusDone(poll, setPoll);
                    else          EMWithPzServer.setPollStatusDone(poll, setPoll);
                } else { // next question
                    let new_qactive = (poll.qactive_selected ? poll.qactive_selected : 0);
                    if (simulate) EMWithSimulator.setPqstStart(poll, setPoll, new_qactive);
                    else          EMWithPzServer.setPqstStart(poll, setPoll, new_qactive);
                }
                break;
            case 'Vs':
                if (simulate) EMWithSimulator.setPqstStatus(poll, setPoll, 'Vt');
                else          EMWithPzServer.setPqstStatus(poll, setPoll, 'Vt');
                break;
            case 'Vt':
                if (toolate) {
                    if (simulate) EMWithSimulator.setPqstStatus(poll, setPoll, 'Vt');
                    else          EMWithPzServer.setPqstStatus(poll, setPoll, 'Vt');
                }
                break;
            case 'Vx':
                if (last_round) { // next question
                    if (quitting) {
                        if (simulate) EMWithSimulator.setPollStatusDone(poll, setPoll);
                        else          EMWithPzServer.setPollStatusDone(poll, setPoll);
                    } else {
                        if (simulate) EMWithSimulator.setPqstStatusDone(poll, setPoll);
                        else          EMWithPzServer.setPqstStatusDone(poll, setPoll);
                    }
                } else { // next round
                    if (simulate) EMWithSimulator.setPqstStart(poll, setPoll, poll.qactive);
                    else          EMWithPzServer.setPqstStart(poll, setPoll, poll.qactive);
                }
                break;
            }
            break;
        case 'Oz':
            if (simulate) EMWithSimulator.setPollStatusClosed(poll, setPoll);
            else          EMWithPzServer.setPollStatusClosed(poll, setPoll);
            break;
        default:
            console.log("pstatus invalid (" + pstatus + ")");
            break;
        }
    }

    return (
        <div className="w-full flex flex-col justify-start items-center mb-5 relative">
            <div className="w-auto flex flex-row justify-between items-center gap-0">

                <PzButton.EMMinimal text=" " className="my-2" onClick={()=>{}} />

                { pstatus >= 'Ax' && pstatus <= 'Oz' ?
                    <PzButton.EMAction symbol={actionSymbol} text={actionText} 
                        disabled={disabled} invisible={invisible || actionText===''} 
                        className="pz-fab-shadow" onClick={gotoNextStep} />
                : 
                    <></>
                }

                <PzButton.EMMinimal text=" " className="my-2" onClick={()=>{}} />

            </div>
        </div>
    );

}

