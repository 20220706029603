import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import EventRunner from "../../eventmonitor/EventRunner";
import UserSplash from "../UserSplash";

export default function Run() {
    const [cookies, removeCookie] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const [poll, setPoll] = React.useState(upoll);  
    const navigate = useNavigate();
    const localeMap = useSelector(utils.selectLocaleMap);
    const location = useLocation();
    const query = utils.useQuery();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!uauth) {
            let pguid = (upoll ? upoll.pguid : query.get("p"))
            navigate("/auth/login", {state:{referer:location.pathname, pguid:pguid}});
        }
    }, [cookies]);

    function setPoll2(new_poll) {
        setPoll(new_poll);                  // update the poll in memory
        dispatch(setUPoll(new_poll));       // update the poll in Redux storage
    }

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    }  

    return (
        <PzArea.Container>
            <PzArea.MwhBoard  className="px-5 pt-10 pb-20 select-none">

                <EventRunner poll={poll} setPoll={setPoll2} />

            </PzArea.MwhBoard >

        </PzArea.Container>
    );
}

