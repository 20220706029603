import React from "react";

import TextField from '@mui/material/TextField';

export default function PzUtcOffsetField({onEnter=(text)=>{}}) {
    const [text, setText] = React.useState('');

    const onChange = (e)=>{
        const is_sign = (c)=>{return (c === '+' || c === '-'); }
        const is_digit = (c)=>{return (c >= '0'&& c <= '9'); }
        let new_text = e.target.value;
        if      (new_text.length > 0 && new_text[0] !== 'U') setText(new_text.substring(0,0));
        else if (new_text.length > 1 && new_text[1] !== 'T') setText(new_text.substring(0,1));
        else if (new_text.length > 2 && new_text[2] !== 'C') setText(new_text.substring(0,2));
        else if (new_text.length > 3 && !is_sign(new_text[3]))  setText(new_text.substring(0,3));
        else if (new_text.length > 4 && !is_digit(new_text[4])) setText(new_text.substring(0,4));
        else if (new_text.length > 5 && !is_digit(new_text[5])) setText(new_text.substring(0,5));
        else if (new_text.length > 6 && new_text[6] !== ':') setText(new_text.substring(0,6));
        else if (new_text.length > 7 && !is_digit(new_text[7])) setText(new_text.substring(0,7));
        else if (new_text.length > 8 && !is_digit(new_text[8])) setText(new_text.substring(0,8));
        else if (new_text.length > 9) setText(new_text.substring(0,9));
        else setText(new_text);
    };

    return (
        <TextField 
            size="small" 
            placeholder="UTC+00:00"     // 
            sx={{ width: 200 }}
            inputProps={{ style: { padding: 0 } }}
            InputProps={{ style: { padding: 5 }, sx: { borderRadius: 1 } }} 
            value={text}
            onChange={onChange}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    if (text.length == 3 || text.length == 6 || text.length == 9) onEnter(text);
                }
            }}
        />
    )
}
