import React from 'react';

import appConfig from "../config/appConfig";
import {utils} from "../config/userConfig";
import PzInput from "./PzInput";
import PzDropdown from "./PzDropdown";

const PzSetting = {
    C2Win:  PzSettingC2Win,
    C2Drop: PzSettingC2Drop,
    NVote:  PzSettingNVote,
    VDist:  PzSettingVDist,
};

export default PzSetting;

export function PzSettingC2Win({criteria, setCriteria=()=>{}, size='sm', className='', disabled=false, op50=false, locale='en'}) {
    if (!criteria) criteria = 'Most';
    const localeMap = utils.selectMapByLocale(locale);
    const [selection, setSelection] = React.useState([criteria]);
    const [olist, vlist] = getOpAndValues();
    const [oidx2, vidx2] = getOpAndValueIndex();
    const [oidx, setOidx] = React.useState(oidx2);
    const [vidx, setVidx] = React.useState(vidx2);
    const text_size = 'text-' + size;
    const ncriteria = (criteria.startsWith('N >') ? criteria    : 'N >= 2/3');
    const ncclasses = (criteria.startsWith('N >') ? 'font-bold' : '') + ' ' + text_size;
    // console.log("PzSettingC2Win : ", criteria, oidx2, vidx2);

    function setSelection2(new_selection) {
        if (disabled) return;
        setSelection(new_selection);
    }

    function getOpAndValues() {
        let crtlist = appConfig.getCriteriaListToWin();
        let values = [];
        for (let i = 0; i < crtlist.length; i++) {
            if (!crtlist[i].startsWith("N >")) continue;
            let split = crtlist[i].split(' ');
            if (split.length === 3 && !values.includes(split[2])) {
                values.push(split[2]);
            }
        }
        return [ [ '>', '>=' ], values ];
    }

    function getOpAndValueIndex() {
        let ncriteria = (!criteria || !criteria.startsWith('N >') ? 'N > 2/3' : criteria);
        // if (!criteria || !criteria.startsWith('N >')) return [ 0, 0 ];
        let split = ncriteria.split(' '), oidx2 = 0, vidx2 = 0;
        if (split.length === 3) {
            if (olist && olist.includes(split[1])) oidx2 = olist.indexOf(split[1]);
            if (vlist && vlist.includes(split[2])) vidx2 = vlist.indexOf(split[2]);
        }
        return [ oidx2, vidx2 ];
    }

    function onChangeToRadio(old_selection, new_selection) {
        if (disabled) return;
        if (new_selection.length > 0) setCriteria(new_selection[0]);
        else setCriteria('');
    }

    function onChangeToOidx(old_oidx, new_oidx) {
        if (disabled) return;
        setCriteria( `N ${olist[new_oidx]} ${vlist[vidx]}` );
    }

    function onChangeToVidx(old_vidx, new_vidx) {
        if (disabled) return;
        setCriteria( `N ${olist[oidx]} ${vlist[new_vidx]}` );
    }

    return (
        <div className={"grid grid-cols-[auto_auto_auto] justify-stretch items-center " + className}>
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={'Majority'} text={locale==='ko' ? utils.translateCriteria('Majority',localeMap) : 'Majority'} disabled={disabled && !selection.includes('Majority')} />
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={'Most'}     text={locale==='ko' ? utils.translateCriteria('Most',localeMap) : 'Most'} disabled={disabled && !selection.includes('Most')} />
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={ncriteria}  disabled={disabled && !selection[0].startsWith('N ')} >
                <span className={ncclasses}>N</span>
                <PzSettingDropdown values={olist} vidx={oidx} setVidx={setOidx} className={ncclasses} size={size} disabled={disabled} onChange={onChangeToOidx} />
                <PzSettingDropdown values={vlist} vidx={vidx} setVidx={setVidx} className={ncclasses} size={size} disabled={disabled} onChange={onChangeToVidx} />
            </PzInput.Radio2>
        </div>
    );
};


export function PzSettingC2Drop({criteria, setCriteria=()=>{}, size='sm', className='', disabled=false, op50=false, locale='en'}) {
    if (!criteria) criteria = 'Zero';
    const localeMap = utils.selectMapByLocale(locale);
    const [selection, setSelection] = React.useState([criteria]);
    const tlist = ['Top2x', 'Top3x', 'Top4x'];
    const [olist, vlist] = getOpAndValues();
    const [oidx2, vidx2] = getOpAndValueIndex();
    const [oidx, setOidx] = React.useState(oidx2);
    const [vidx, setVidx] = React.useState(vidx2);
    const [tidx, setTidx] = React.useState(getTopIndex());
    const text_size = 'text-' + size;
    const tcriteria = (criteria.startsWith('Top') ? criteria    : 'Top2x');
    const tcclasses = (criteria.startsWith('Top') ? 'font-bold' : '');
    const ncriteria = (criteria.startsWith('N <') ? criteria    : 'N < 1/100');
    const ncclasses = (criteria.startsWith('N <') ? 'font-bold' : '') + ' ' + text_size;
    // console.log("PzSettingC2Drop : ", criteria, selection);

    function setSelection2(new_selection) {
        if (disabled) return;
        setSelection(new_selection);
    }

    function getTopIndex() {
        if (!criteria || !criteria.startsWith('Top')) return 0;
        tlist.indexOf(criteria);
    }

    function getOpAndValues() {
        let crtlist = appConfig.getCriteriaListToDrop();
        let values = [];
        for (let i = 0; i < crtlist.length; i++) {
            if (!crtlist[i].startsWith("N <")) continue;
            let split = crtlist[i].split(' ');
            if (split.length === 3 && !values.includes(split[2])) {
                values.push(split[2]);
            }
        }
        return [ [ '<', '<=' ], values ];
    }

    function getOpAndValueIndex() {
        let ncriteria = (!criteria || !criteria.startsWith('N <') ? 'N < 1/100' : criteria);
        // if (!criteria || !criteria.startsWith('N <')) return [ 0, 0 ];
        let split = ncriteria.split(' '), oidx2 = 0, vidx2 = 0;
        if (split.length === 3) {
            if (olist && olist.includes(split[1])) oidx2 = olist.indexOf(split[1]);
            if (vlist && vlist.includes(split[2])) vidx2 = vlist.indexOf(split[2]);
        }
        return [ oidx2, vidx2 ];
    }

    function onChangeToRadio(old_selection, new_selection) {
        if (disabled) return;
        if (new_selection.length > 0) setCriteria(new_selection[0]);
        else setCriteria('');
    }

    function onChangeToTidx(old_tidx, new_tidx) {
        if (disabled) return;
        console.log(selection, tcriteria, new_tidx, tlist[new_tidx]);
        setCriteria( tlist[new_tidx] );
        setSelection( tlist[new_tidx] );
    }

    function onChangeToOidx(old_oidx, new_oidx) {
        if (disabled) return;
        setCriteria( `N ${olist[new_oidx]} ${vlist[vidx]}` );
    }

    function onChangeToVidx(old_vidx, new_vidx) {
        if (disabled) return;
        setCriteria( `N ${olist[oidx]} ${vlist[new_vidx]}` );
    }

    return (
        <div className={"grid grid-cols-[auto_auto_auto_auto] justify-stretch items-center " + className}>
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={'Zero'}  text={locale==='ko' ? utils.translateCriteria('Zero',localeMap) : 'Zero'}  disabled={disabled && !selection.includes('Zero')} />
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={'Least'} text={locale==='ko' ? utils.translateCriteria('Least',localeMap): 'Least'} disabled={disabled && !selection.includes('Least')} />
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={tcriteria} disabled={disabled && !selection[0].startsWith('Top')} >
                <PzSettingDropdown values={tlist} vidx={tidx} setVidx={setTidx} className={tcclasses} size={size} disabled={disabled} onChange={onChangeToTidx} />
            </PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection2} size={size} onChange={onChangeToRadio} value={ncriteria} disabled={disabled && !selection[0].startsWith('N ')} >
                <span className={ncclasses}>N</span>
                <PzSettingDropdown values={olist} vidx={oidx} setVidx={setOidx} className={ncclasses} size={size} disabled={disabled} onChange={onChangeToOidx} />
                <PzSettingDropdown values={vlist} vidx={vidx} setVidx={setVidx} className={ncclasses} size={size} disabled={disabled} onChange={onChangeToVidx} />
            </PzInput.Radio2>
        </div>
    );
};

export function PzSettingNVote({nVote, setNVote, maxNVote, size='sm', className='', disabled=false, op50=false}) {
    const [selection, setSelection] = React.useState([nVote]);

    function onChangeToRadio(old_selection, new_selection) {
        if (disabled) return;
        if (new_selection.length > 0) setNVote(new_selection[0]);
        else setNVote(0);
    }

    return (
        <div className={" flex flex-row justify-between items-center " + className}>
            { maxNVote > 6 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={6}>{6}</PzInput.Radio2>
            }
            { maxNVote > 10 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={10}>{10}</PzInput.Radio2>
            }
            { maxNVote > 12 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={12}>{12}</PzInput.Radio2>
            }
            { maxNVote > 24 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={24}>{24}</PzInput.Radio2>
            }
            { maxNVote > 48 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={48}>{48}</PzInput.Radio2>
            }
            { maxNVote > 100 &&
                <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={100}>{100}</PzInput.Radio2>
            }
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={maxNVote}>{maxNVote}</PzInput.Radio2>
        </div>
    );
};

export function PzSettingVDist({vdist, setVDist, size='sm', className='', disabled=false, op50=false}) {
    const [selection, setSelection] = React.useState([vdist]);

    function onChangeToRadio(old_selection, new_selection) {
        if (disabled) return;
        if (new_selection.length > 0) setVDist(new_selection[0]);
        else setVDist('N');
    }

    return (
        <div className={" flex flex-row justify-between items-center " + className}>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'U'}>{'U'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'N'}>{'N'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'1'}>{'1'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'1r'}>{'1r'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'2'}>{'2'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'2r'}>{'2r'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'3'}>{'3'}</PzInput.Radio2>
            <PzInput.Radio2 selection={selection} setSelection={setSelection} size={size} onChange={onChangeToRadio} value={'3r'}>{'3r'}</PzInput.Radio2>
        </div>
    );
};


function PzSettingDropdown({ values, vidx=0, setVidx=()=>{}, className='', size='sm', disabled=false, op50=false, onChange=()=>{} }) {
    const [open, setOpen] = React.useState(false);
    // console.log("PzSettingsDropdown  vidx:", vidx);

    function onClick(idx) {
        if (idx !== vidx) {
            if (typeof onChange === 'function') onChange(vidx, idx);
            setVidx(idx);
        }
        setOpen(false);
    }

    return (
        <PzDropdown 
            open={open} setOpen={setOpen} ha={'right'}
            button={
                <span className={` text-${size} ` + (op50 ? `opacity-50 ` : ``) + className} onClick={()=>{if (!disabled) setOpen(true);}}>
                    {values[vidx]}
                </span>
            } >
            <ul className="p-2 flex flex-col items-start justify-center text-center gap-0">
                {values.map((value,idx)=>{
                    return (<span key={idx} className={`text-${size} ` + (idx === vidx ? `text-bold` : ``)} onClick={()=>{onClick(idx);}}>{value}</span>);
                })}
            </ul>
        </PzDropdown>
    );
}

