import React from "react";
import { useNavigate } from "react-router-dom";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import PzProgress from "../common/PzProgress";
import EMStatus from "./EMStatus";

export default function EMAreaFooterAsVoter({poll, pqst, setPoll, selection, castVote}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const navigate = useNavigate();
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const [errMsg, setErrMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [invisible, setInvisible] = React.useState(!poll.updatedat || utils.getDiffSince(poll.updatedat) < 3000);
    const nextRound = (pqst && pqst.qround < pqst.qroundmax && (pqst.chosen.length-1) < pqst.nwanted);
    // if (pqst && pqst.qstatus === 'Vx') console.log("Round:", pqst.qround, "  chosen:", (pqst.chosen.length-1), "  nextR:", nextRound);

    useInterval(() => {
        let diff = utils.getDiffSince(poll.updatedat);
        let new_invisible = (diff && diff < 3000);
        if (invisible !== new_invisible) setInvisible(new_invisible);
        if (poll.paused) setPoll( { ...poll, updatedat:dayjs() }); 
    }, 500);

    function onClickToPauseResumeButton() {
        setPoll( { ...poll, paused: (poll.paused ? false : true) });
    }

    return (
        <div className="w-full flex flex-col justify-start items-center mb-5 relative">
            {/* <label className="text-slate-500">&nbsp;{errMsg}&nbsp;</label> */}
            <div className="w-auto flex flex-row justify-between items-center gap-0">

                <PzButton.EMMinimal text=" " className="my-2" onClick={false ? onClickToPauseResumeButton : ()=>{}} />

                { pstatus === 'Oo' ?
                    ( poll.natt > 2 &&
                        <EMStatus.Waiting poll={poll} pqst={pqst} text={localeMap["monitor.waiting.event.start"]} invisible={invisible} />
                    )
                : pstatus === 'Os' ?
                    ( !pqst ?
                        <EMStatus.Waiting symbol={''} text={localeMap["monitor.waiting.quest.vstart"]} invisible={invisible} />
                    : qstatus === 'Vs' && !pqst.voted ?
                        <PzButton.EMAction text={localeMap["monitor.action.castvote"]} onClick={castVote} 
                            className="pz-fab-shadow" disabled={!selection || selection.length==0} loading={loading} />
                    : qstatus === 'Vs' ?
                        <EMStatus.Waiting symbol={''} text={localeMap["monitor.waiting.quest.vstop"]} invisible={false} noprogress={true} />
                    : qstatus === 'Vt' ?
                        <EMStatus.Waiting symbol={''} text={' '} invisible={true} />
                    : qstatus === 'Vx' && nextRound ?
                        <EMStatus.Waiting symbol={`Round ${pqst.qround+1}`} text={localeMap["monitor.waiting.quest.nextr"]} invisible={invisible} />
                    : qstatus === 'Vx'  ?
                        <EMStatus.Waiting symbol={`Q${pqst.qseq}`} text={localeMap["monitor.waiting.quest.close"]} invisible={invisible} />
                    :
                        <></>
                    )
                : pstatus === 'Oz' ?
                    <PzButton.EMAction text={localeMap["monitor.action.checkout"]} invisible={invisible}
                        onClick={()=>{navigate('/y/vote?p=THANKYOU'); setPoll(null); }} />
                : pstatus === 'Xp' ?
                    <PzButton.EMAction text={localeMap["monitor.action.checkout"]} invisible={invisible}
                        onClick={()=>{navigate('/y/vote?p=THANKYOU'); setPoll(null); }} />
                :
                    <></>
                }

                <PzButton.EMMinimal text=" " className="my-2" onClick={()=>{}} />

            </div>
        </div>
    );

}