import React from "react";
import { useSelector, useDispatch } from "react-redux";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";

export default function Mission() {
    const localeMap = useSelector(utils.selectLocaleMap);

    return (
        <PzArea.Container>
            <PzArea.HomeBoard>

                {/* Why for Us */}
                <div className="w-[70%] min-w-[min(max(94vw,360px),500px)] max-w-[min(96vw,700px)] flex flex-col gap-5">

                    <h2>{localeMap["home.intro.whyforus"]}</h2>

                    <div className="flex flex-col gap-4">
                        <h5><em>{localeMap["home.goal.boostdm"]}</em></h5>
                        <p>{localeMap["home.goal.desc.boostdm"]}</p>
                        <h5><em>{localeMap["home.goal.support"]}</em></h5>
                        <p>{localeMap["home.goal.desc.support"]}</p>
                        <h5><em>{localeMap["home.goal.staysml"]}</em></h5>
                        <p>{localeMap["home.goal.desc.staysml"]}</p>
                    </div>
                </div>

                {/* <div className="w-[70%] flex flex-col gap-2">
                    <div className="w-full flex flex-row justify-center items-center gap-4">
                        <PzInput.Radio2 value={1} selection={R1} setSelection={setR1}>Radio 1</PzInput.Radio2>
                        <PzInput.Radio2 value={2} selection={R1} setSelection={setR1}>Radio 2</PzInput.Radio2>
                        <PzButton.Minimal onClick={()=>{console.log("R1:", R1);}}>test</PzButton.Minimal>
                    </div>
                    <div className="w-full flex flex-row justify-center items-center gap-4">
                        <IconHeart width={'2rem'} height={'2rem'} className="heartbeat" onClick={()=>{}} />
                    </div>
                </div> */}

            </PzArea.HomeBoard>
        </PzArea.Container>

    );
}

