import React from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { SHA256, enc } from "crypto-js";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import AzArea from "./azcomp/AzArea";
import AzButton from "./azcomp/AzButton";
import AzInputForPWord from "./azcomp/AzInputForPWord";
import AzModal from "./azcomp/AzModal";

export default function ModalToChangePWord({onClose}) {
    const [ucookies] = useCookies(['uauth']);
    const uauth = (ucookies?.uauth ? jwtDecode(ucookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [pword0, setPword0] = React.useState({value:'', errmsg:''});
    const [pword1, setPword1] = React.useState({value:'', errmsg:''});
    const [pword2, setPword2] = React.useState({value:'', errmsg:''});
    const [errMsg, setErrMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    function postChangePW() {
        setLoading(true);
        const old_pwhash64 = SHA256(pword0.value).toString(enc.Hex);
        const new_pwhash64 = SHA256(pword1.value).toString(enc.Hex);
        appConfig.getAPI().post("/api/auth/chpword", {uemail: uauth?.uemail, oldpassword:old_pwhash64, newpassword:new_pwhash64 })
        .then((response)=>{
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI 'chpword' success : ", pzdata);
            onClose();
        })
        .catch((error)=>{
            // console.log("error:", error);
            if (error.code === 'ERR_NETWORK') {
                setErrMsg(localeMap["auth.errmsg.network"]); 
            } else if (error.response && error.response.status === 400) { // BAD_REQUEST
                const pzdata = error.response.data;     // {respcode:0, respmsg:'success', resource:{...}}
                switch (pzdata.respcode) {
                    case 21 : // "vcode mismatch"
                        setErrMsg(localeMap["auth.chpword.errmsg.notfound"]); 
                        break;   
                    case 22 : // "email not found"
                        setErrMsg(localeMap["auth.chpword.errmsg.oldinvalid"]); 
                        break;   
                    case 23 : // ""
                        setErrMsg(localeMap["auth.chpword.errmsg.newinvalid"]); 
                        break;   
                    default:
                        console.log("PzAPI 'chpword' failure - error:", error);
                        setErrMsg(pzdata.respmsg);
                        break;   
                }
            } else {
                setErrMsg(localeMap["auth.errmsg.unknown"] + ` (${error.code})`); 
                console.log("PzAPI 'chpword' failure - status:", error.response?.status, ", error:", error);
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    };
    
    return (
        <AzModal onClose={onClose} >
            <AzArea.Board>

                <div className="w-full flex flex-col justify-start items-center py-4 gap-4" >
                    <div className="w-full flex flex-col justify-start items-center" >
                        <h1>{localeMap["auth.chpword.title"]}</h1>
                        {/* <h5 className="flex flex-row justify-center text-slate-500">
                            &nbsp;{localeMap["auth.chpword.subtitle"]}&nbsp;
                        </h5> */}
                    </div>
                    <div className="w-[16rem] flex flex-col justify-start items-stretch gap-3">
                        <p>&nbsp;</p>
                        <div className="flex flex-col justify-start items-stretch gap-3">
                            <AzInputForPWord v={pword0} setV={setPword0} placeholder={localeMap["auth.chpword.input.pword0"]} className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} />
                            <AzInputForPWord v={pword1} setV={setPword1} placeholder={localeMap["auth.chpword.input.pword1"]} className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} />
                            <AzInputForPWord v={pword2} setV={setPword2} placeholder={localeMap["auth.chpword.input.pword2"]} className="w-full" onChange={()=>{if (errMsg!=='') setErrMsg('');}} vref={pword1} />
                        </div>
                        <h5 className="text-xs text-center text-slate-500">&nbsp;{errMsg}&nbsp;</h5>
                    </div>
                    <div className="w-full flex flex-col justify-start items-center gap-2">
                        <AzButton type='R' className="w-[16rem] flex justify-center" 
                            disabled={pword0.value === '' || pword0.errmsg!=='' || pword1.value === '' || pword1.errmsg!=='' || pword2.value === '' || pword2.errmsg!=='' || errMsg!=='' || loading} 
                            onClick={postChangePW} loading={loading} >
                            {localeMap["auth.chpword.button"]}
                        </AzButton>
                    </div>
                </div>

            </AzArea.Board>
        </AzModal>
    );
}
