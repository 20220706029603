import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function PzSpin({children, spinning, color}) {
    return (
        <div className="flex items-center w-full">
            <div className="relative z-0">
                <div>
                    {children}
                </div>
                {spinning &&
                <div className="absolute inset-0 flex justify-center items-center z-10">
                    <CircularProgress color={color}/>
                </div>
                }
            </div>
        </div>
    );
}