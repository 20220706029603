import React from "react";
import { useSelector } from "react-redux";
import { useInterval } from "react-use";
import dayjs from 'dayjs';

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from '../common/PzBasicModal';
import PzButton from '../common/PzButton';
import PzInput from '../common/PzInput';
import PzDateTimePicker from '../common/PzDateTimePicker';
import PzTime from '../common/PzTime';

export default function EvtEditorForKTW({ poll, setPoll, size='lg', visibility={u:true, k:false, t:true, w:true, e:true, l:true}, onChange=()=>{} }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const idType = React.useId();
    const idTitle = React.useId();
    const idUName = React.useId();
    const idDate = React.useId();
    const idEarly = React.useId();
    const idLang = React.useId();
    const [selK, setSelK] = React.useState([ poll.pkind[0] ]);
    const [selW, setSelW] = React.useState([ poll.pwhen === '' ? 'N' : 'R' ]);
    const [selE, setSelE] = React.useState( poll.pearlystt && poll.pearlystt !== '' ? ['Yes'] : []);
    const utzone = useSelector((state)=>state.ucfg.timezone);  // timezone from user settings
    const [wConst, setWConst] = React.useState('');
    const text_size = 'text-' + size;
    const pollCreating = (poll?.pguid === undefined);
    
    useInterval(() => {
        if (!poll.createdat) setWConst('');
        else if (poll.pwhen === '') {
            let updatedat = (poll.updatedat ? dayjs(poll.updatedat) : dayjs());
            setWConst(utils.completeTimeText(localeMap["poll01.pwhen.rightnow.expire"], updatedat.add(1, 'hour'), utzone));
        }
    }, 1000);

    function replacePkind(s, idx, c) {
        if (!s || s.length === 0 || idx >= s.length) {
            return s
        } else if (idx === s.length - 1) {
            return s.substring(0,idx) + c 
        } else {
            return s.substring(0,idx) + c + s.substring(idx+1)
        }
    }
    function onChangeDoneToPTitle(text) {
        setPoll({ ...poll, ptitle: text });
        if (typeof onChange === 'function') onChange();
    }
    function onChangeDoneToUName(text) {
        setPoll({ ...poll, uname: text });
        if (typeof onChange === 'function') onChange();
    }
    function onChangeToPKind(old_selection, new_selection) {
        switch (new_selection[0]) {
            case 'P': setPoll({ ...poll, pkind: replacePkind(poll.pkind, 0, "P") });  break;
            case 'S': setPoll({ ...poll, pkind: replacePkind(poll.pkind, 0, "S") });  break;
            case 'Q': setPoll({ ...poll, pkind: replacePkind(poll.pkind, 0, "Q") });  break;
            default:  setPoll({ ...poll, pkind: replacePkind(poll.pkind, 0, "P") });  break;
        }
        if (typeof onChange === 'function') onChange();
    }
    function onChangeToPWhen(old_selection, new_selection) {
        switch (new_selection[0]) {
            case 'R': 
                let pwhen = (poll.pwhen && poll.pwhen !== '' ? dayjs(poll.pwhen) : dayjs(new Date()).add(2, 'hour').startOf('hour'));
                let pearlystt = (selE.includes('Yes') ? pwhen.add(-24, 'hour').toISOString() : '');
                let pearlyend = (selE.includes('Yes') ? pwhen.add(-10, 'minute').toISOString() : '');
                setPoll({ ...poll, pkind: replacePkind(poll.pkind, 1, "R"), pwhen: pwhen.toISOString(), pearlystt: pearlystt, pearlyend: pearlyend });  
                break;
            default:  
                setPoll({ ...poll, pkind: replacePkind(poll.pkind, 1, "R"), pwhen: '', pearlystt: '', pearlyend: '' });  
                break;
        }
        if (typeof onChange === 'function') onChange();
    }
    function onChangeToPEarly(old_selection, new_selection) {
        if (poll.pwhen && new_selection.includes('Yes')) {
            let pwhen = dayjs(poll.pwhen);
            let pearlystt = pwhen.add(-24, 'hour').toISOString();
            let pearlyend = pwhen.add(-10, 'minute').toISOString();
            setPoll({ ...poll, pearlystt: pearlystt, pearlyend: pearlyend })
        } else {
            setPoll({ ...poll, pearlystt: '', pearlyend: '' })
        }
        if (typeof onChange === 'function') onChange();
    }
    function onAcceptToDatePicker(pwhen) {
        // console.log("DateTimePicker:", pwhen, pwhen.toISOString());
        if (selE.includes('Yes')) {
            let pearlystt = pwhen.add(-24, 'hour').toISOString();
            let pearlyend = pwhen.add(-10, 'minute').toISOString();
            setPoll({ ...poll, pwhen: pwhen.toISOString(), pearlystt: pearlystt, pearlyend: pearlyend });
        } else {
            setPoll({ ...poll, pwhen: pwhen.toISOString(), pearlystt: '', pearlyend: '' });
        }
        if (typeof onChange === 'function') onChange();
    } 
        
    const disabledDate = (current) => {
        // Can not select days before today
        return current && (current < dayjs().startOf('day') || current > dayjs().add(6, 'month').startOf('day'));
    };

    return (
        <div className={"w-full grid grid-cols-[auto_1fr] justify-start items-start gap-x-3 gap-y-2 " + text_size} >

            {/* POLLER */}
            { visibility.u && !pollCreating && <>
                <span htmlFor={idTitle} className={"pt-[3px] " + text_size}>{localeMap["poll01.poller.label"]} :</span>
                <PzInput.ForText className="w-[60%]" tabIndex={-1}
                    id={idUName} name="uname" maxLength={appConfig.maxLenTitle}
                    value={poll.uname} onChangeDone={onChangeDoneToUName} disabled={false} />
            </> }

            {/* KIND */}
            { visibility.k && <>
                <span htmlFor={idType} className={text_size}>{localeMap["poll01.ptype.label"]}</span>
                <div className="grid grid-cols-[auto_auto_auto] items-start">
                    <PzInput.Radio2 value="P" selection={selK} setSelection={setSelK} onChange={onChangeToPKind} size={size} disabled={false} text={localeMap["comm.event.p"]} />
                    <PzInput.Radio2 value="S" selection={selK} setSelection={setSelK} onChange={onChangeToPKind} size={size} disabled={true}  text={localeMap["comm.event.s"]} />
                    <PzInput.Radio2 value="Q" selection={selK} setSelection={setSelK} onChange={onChangeToPKind} size={size} disabled={true}  text={localeMap["comm.event.q"]} />
                </div>
            </>}

            {/* TITLE */}
            { visibility.t && <>
                <span htmlFor={idTitle} className={"pt-[3px] " + text_size}>{localeMap["poll01.ptitle.label"]} :</span>
                <PzInput.ForText className={"border-[1px] border-gray-300 disabled:bg-gray-100 px-1 "} 
                    id={idTitle} name="title" maxLength={appConfig.maxLenTitle} autoFocus={true} tabIndex={0}
                    value={poll.ptitle} onChangeDone={onChangeDoneToPTitle} disabled={false} />
            </> }

            {/* WHEN */}
            { visibility.w && <>
                <span htmlFor={idDate} className={text_size}>{localeMap["poll01.pwhen.label"]} :</span>
                <div className="flex flex-col justify-start items-start gap-1">
                    <div className="w-full flex flex-col justify-start items-start">
                        <div className="w-full flex flex-row justify-between items-center">
                            <PzInput.Radio2 value="N" selection={selW} setSelection={setSelW} onChange={onChangeToPWhen} 
                                size={size} disabled={false} text={localeMap["poll01.pwhen.rightnow"]} />
                            { selW.includes('N') && 
                                <span className="text-slate-500">{localeMap["poll01.pwhen.rightnow.constraint"]}</span>
                            }
                        </div>
                        { wConst && selW.includes('N') && 
                            <div className="w-auto flex flex-row justify-start items-center gap-2">
                                <span className={"ml-8 text-slate-500 text-md"}>{wConst}</span> 
                                <PzTime.RemainingExp poll={poll} format='hh:mm:ss' />
                            </div>
                        }
                    </div>
                    <div className="w-full flex flex-row justify-between items-center">
                        <PzInput.Radio2 value="R" selection={selW} setSelection={setSelW} onChange={onChangeToPWhen} 
                            size={size} disabled={false} text={localeMap["poll01.pwhen.start"]} />
                        { (selW.includes('R') || selW.includes('P')) && 
                            <PzDateTimePicker 
                                format="YYYY-MM-DD HH:mm" 
                                defaultValue={poll.pwhen!=='' ? dayjs(poll.pwhen) : dayjs(new Date()).add(2, 'hour').startOf('hour')} 
                                minDate={dayjs(new Date())} maxDate={dayjs(new Date().setDate(new Date().getDate()+60))}
                                onAccept={onAcceptToDatePicker} disabled={false} />
                        }
                    </div>
                </div>
            </> }

            {/* EARLY VOTING */}
            { visibility.e && !pollCreating && selW.includes('R') && <> 
                <span htmlFor={idEarly} className={text_size}>{localeMap["poll01.pearly.label"]} :</span>
                <div className="w-full flex flex-row justify-between items-center">
                    <PzInput.Checkbox2 value="Yes" selection={selE} setSelection={setSelE} onChange={onChangeToPEarly} 
                        size={size} disabled={false} text={ localeMap[selE.includes('Yes') ? 'poll01.pearly.yes' : 'poll01.pearly.no']} />
                    { selE.includes('Yes') && 
                    <div className="flex flex-row justify-end gap-3 items-center text-sm">
                        <label className="">{utils.getTimeInUserTimezone(poll.pearlystt, "MM-DD HH:mm", utzone, false)} (-24H)</label>
                        <label className=""> ~ </label>
                        <label className="">{utils.getTimeInUserTimezone(poll.pearlyend, "MM-DD HH:mm", utzone, false)} (-10M)</label>
                    </div> 
                    }
                </div>
            </> }

            {/* LANGUAGE */}
            { visibility.l && !pollCreating && <>
                <span htmlFor={idLang} className={text_size}>{localeMap["poll01.locale.label"]} :</span>
                <label className={text_size + " font-bold pl-1"}>{appConfig.getLanguageFromLocale(poll?.locale, true)}</label>
                {/* <PzInput.ForText className={"border-[1px] border-gray-300 disabled:bg-gray-100 px-1 "} 
                    id={idLang} value={appConfig.getLanguageFromLocale(poll?.locale, true)} 
                    disabled={true}  autoFocus={false} /> */}
            </> }


        </div>
    );
}


export function ModalToEditEventKTW({poll, onClose, onSaveChanges=()=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [tmpPoll, setTmpPoll] = React.useState(structuredClone(poll));

    function onClickToSave() {
        // console.log("onClickToSave to Edit : ");
        if (typeof onSaveChanges === 'function') onSaveChanges(tmpPoll);
        onClose();
    }

    return (
        <PzBasicModal onClose={onClose} xclose={true} >
            <div className="min-w-[36rem] flex flex-col justify-start items-stretch p-5 gap-5">

                <h2>{localeMap["poller.editevent.event"]}</h2>

                <EvtEditorForKTW poll={tmpPoll} setPoll={setTmpPoll} size='lg' visibility={{u:true, k:false, t:true, w:true, e:true, l:true}} />

                <div className="w-full flex flex-row justify-end items-center">
                    {/* <PzButton.Default onClick={onClose}>
                        <h3>{localeMap["modal.button.cancel"]}</h3>
                    </PzButton.Default> */}
                    <PzButton.Default onClick={onClickToSave}>
                        <h3>{localeMap["poll02.button.save"]}</h3>
                    </PzButton.Default>
                </div>

            </div>
        </PzBasicModal>
    );
}


