import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
// import { getTimeInUserTimezone } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import EvtViewerForQstResult from "../../eventview/EvtViewerForQstResult";

export default function PzAdminQstInfo() {
    const location = useLocation();
    const [qinfo, setQInfo] = React.useState({qguid:location?.state?.qguid, qseq:location?.state?.qseq});
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const utzone = useSelector((state)=>state.ucfg.timezone);  // timezone from user settings
    // const nav_qid = location?.state?.qguid

    const fetchQstInfo = ()=>{
        if (!uauth?.ispza) return;
        appConfig.getAPI().post("/api/pza/qstinfo", {qguid:location?.state?.qguid})
        .then((response)=>{
            // console.log("PzAPI 'pza/userinfo' fetched : ", response.data);
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setQInfo(pzdata.resource);
            // console.log("QInfo:", pzdata.resource);
        })
        .catch((error)=>{
            console.log("PzAPI 'pza/qstinfo' error : ", error);
        })
        .finally(()=>{});
    };
    
    React.useEffect(() => {
        if (!uauth?.ispza) { navigate("/"); return; }
        fetchQstInfo();
    }, []);

    return (
        <PzArea.Container>

        {!uauth?.ispza ? null : (
            <PzArea.AdminBoard >
                <h3>{"PzA - Question Info"}</h3>
                <div className="grid grid-cols-[100px_1fr] gap-x-5 gap-1">
                    <h5>QSeq</h5>      {qinfo.qseq ? <p>{qinfo.qseq}</p> : <p>{''}</p>}
                    <h5>QGuid</h5>     {qinfo.qguid ? <p>{qinfo.qguid.slice(0,32)} {qinfo.qguid.slice(32)}</p> : <p>{''}</p>}
                    <h5>Type</h5>  
                    <div className="grid grid-cols-[45px_1fr_45px_1fr] gap-1">
                        <p>Kind:</p> <p>{qinfo.qkind}</p>
                        <p>Status:</p> <p>{qinfo.qstatus}</p>
                    </div>
                    <h5>Question</h5>   <p>{qinfo.question || ""}</p>
                    <h5>Options</h5>    <p className="truncate">{JSON.stringify(qinfo.qoption)}</p>
                    <h5>Plan Time</h5>  
                    <div className="grid grid-cols-[60px_1fr_60px_1fr] gap-1">
                        <p>Created:</p>  <p>{utils.getTimeInUserTimezone(qinfo.createdat, "MM-DD HH:mm:ss", utzone, true)}</p>
                        <p>Updated:</p>  <p>{utils.getTimeInUserTimezone(qinfo.updatedat, "MM-DD HH:mm:ss", utzone, true)}</p>
                    </div>
                    <h5>Run Time</h5>  
                    <div className="grid grid-cols-[60px_1fr_60px_1fr] gap-1">
                        <p>Opened:</p>  <p>{utils.getTimeInUserTimezone(qinfo.openedat, "MM-DD HH:mm:ss", utzone, true)}</p>
                        <p>Closed:</p>  <p>{utils.getTimeInUserTimezone(qinfo.closedat, "MM-DD HH:mm:ss", utzone, true)}</p>
                    </div>
                    { qinfo.answers &&
                        <>
                            <h5>Answers</h5> 
                            <EvtViewerForQstResult qst={qinfo} className="px-5" />
                        </>
                    }
                </div>
                <div className="flex flex-row justify-between">
                    <PzButton.Std text='qinfo' size='md' onClick={()=>console.log(qinfo)} />
                </div>
            </PzArea.AdminBoard>
        )}

        </PzArea.Container>
    );
}
