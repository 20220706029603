import React from "react";
import { CookiesProvider } from "react-cookie";
import { RouterProvider } from "react-router-dom";
import router from "./routes";

const App = () => {
    return (
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <RouterProvider router={router} />
        </CookiesProvider>
    );
};

export default App;
