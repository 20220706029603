import React from "react";
import { useSelector } from "react-redux";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzSymbol from "../../common/PzSymbol";
import EvtViewerForQst from "../../eventview/EvtViewerForQst";
import { ReactComponent as IconChevron } from "../../resource/icons/pz-chevron-down.svg";
import UserSplash from "../UserSplash";

export default function XQuestResults({poll, className=''}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [currQidx, setCurrQidx] = React.useState(-1);
    const [open4Detail, setOpen4Detail] = React.useState({q:false, g:false, x:true})

    if      (!poll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (poll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <div className={" w-full flex flex-col justify-start items-start gap-y-3 " + className} >

            {poll.pqstlist?.map((q,idx) =>
                <div key={idx} className="w-full">
                    <label className="w-full grid grid-cols-[auto_1fr_1fr] gap-2 cursor-pointer">
                        <input type="radio" name="selected" value={idx} checked={idx===currQidx} 
                            onChange={()=>{}}
                            onClick={()=>{setCurrQidx(idx == currQidx ? -1 : idx);}} />
                        <div className="flex flex-row justify-start items-center gap-2">
                            <PzSymbol.Text size='md' text={'Q'+q.qseq} />
                            <h5 className="font-bold">{q.question!=='' ? q.question : localeMap["poll02.question.example"]}</h5>
                        </div>
                        <div className="flex flex-row justify-start items-center gap-2">
                            <PzSymbol.Text size='md' text='R' />
                            <label className="text-sm">{utils.getQstResultSummary(q, localeMap, utils.completeMessageText)}</label>
                        </div>
                    </label>
                    {idx===currQidx &&
                    <div className="px-10 py-2 flex flex-col justify-start items-stretch gap-3 text-sm">

                        <div className="flex flex-row justify-start items-center gap-1">
                            <h5>{localeMap["poll.xresult.plabel.qna"]}</h5>
                            <IconChevron className={`w-[20px] h-[20px] cursor-pointer ${open4Detail.q ? `rotate-180` : ``}`}
                                onClick={()=>{setOpen4Detail({...open4Detail, q:!open4Detail.q})}} /> 
                        </div>
                        {open4Detail.q ? <EvtViewerForQst pqst={poll.pqstlist[currQidx]} visibility={{q:true}} size='md' className="px-5 !w-[80%]" /> : <></>}

                        <div className="flex flex-row justify-start items-center gap-1">
                            <h5>{localeMap["poll.xresult.plabel.gnc"]}</h5>
                            <IconChevron className={`w-[20px] h-[20px] cursor-pointer ${open4Detail.g ? `rotate-180` : ``}`}
                                onClick={()=>{setOpen4Detail({...open4Detail, g:!open4Detail.g})}} /> 
                        </div>
                        {open4Detail.g ? <EvtViewerForQst pqst={poll.pqstlist[currQidx]} visibility={{g:true}} size='md' className="px-5 !w-[80%]" /> : <></>}

                        <div className="flex flex-row justify-start items-center gap-1">
                            <h5>{localeMap["poll.xresult.plabel.xns"]}</h5>
                            <IconChevron className={`w-[20px] h-[20px] cursor-pointer ${open4Detail.x ? `rotate-180` : ``}`}
                                onClick={()=>{setOpen4Detail({...open4Detail, x:!open4Detail.x})}} /> 
                        </div>
                        {open4Detail.x ? <EvtViewerForQst pqst={poll.pqstlist[currQidx]} visibility={{x:true}} size='md' className="px-5" /> : <></>}
                        
                    </div>
                    }
                </div>
            )}

        </div>
    );
}
