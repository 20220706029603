import React from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import EventCard from "../../eventview/EventCard";
import PzButton from "../../common/PzButton";
import EventWatcher from "../../eventmonitor/EventWatcher";
import EntranceForVoter from "../../entrance/EntranceForVoter"
import { ReactComponent as IconQRCode } from "../../resource/icons/pz-qrcode.svg";
import UserSplash from "../UserSplash";

export default function WatchPoll() {
    const [cookies] = useCookies();
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const location = useLocation();
    const [open4EntWeb, setOpen4EntWeb] = React.useState(false);

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'S' && upoll.xrole !== 'M' && upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event"} /> );
    }  

    return (
        <PzArea.InvisibleBoardInContainer>

            <EventCard poll={upoll} viewable={false} foldable={true} />

            <PzArea.FullBoardWithTitle title={localeMap["menu.staff.watchpoll"]} >

                <div className="w-full flex flex-col justify-start items-center gap-10">

                    <EventWatcher poll={upoll} />

                </div>

            </PzArea.FullBoardWithTitle>

        </PzArea.InvisibleBoardInContainer>
    );
}
