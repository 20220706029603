import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import EventCard from "../../eventview/EventCard";
import EventRunner from "../../eventmonitor/EventRunner";
import PzButton from "../../common/PzButton"
import EntranceForTransfer from "../../entrance/EntranceForTransfer"
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";
import { ReactComponent as IconQRCode } from "../../resource/icons/pz-qrcode.svg";
import UserSplash from "../UserSplash";

export default function RunPoll() {
    const [cookies, removeCookie] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const [poll, setPoll] = React.useState(upoll);  
    const navigate = useNavigate();
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open4Transfer, setOpen4Transfer] = React.useState(false);
    const location = useLocation();
    const query = utils.useQuery();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!uauth) {
            let pguid = (upoll ? upoll.pguid : query.get("p"))
            navigate("/auth/login", {state:{referer:location.pathname, pguid:pguid}});
        }
    }, [cookies]);

    function setPoll2(new_poll) {
        setPoll(new_poll);                  // update the poll in memory
        dispatch(setUPoll(new_poll));       // update the poll in Redux storage
    }

    function simulateVoterCheckin() {
        if (!uauth || !uauth.ispza || !upoll) return;
        appConfig.getAPI().post("/api/pza/simulate/checkin", {pguid:upoll.pguid, natt:upoll.nmax*3/4})
        .then((response)=>{ console.log("PzAPI 'simulate checkin' success : ", response.data); })
        .catch((error)=>{  console.log("PzAPI 'simulate checkin' failure : ", error.response?.data); })
        .finally(()=>{ });
    }

    function simulateVoterCasting() {
        if (!uauth || !uauth.ispza || !upoll) return;
        appConfig.getAPI().post("/api/pza/simulate/casting", {pguid:upoll.pguid})
        .then((response)=>{ console.log("PzAPI 'simulate casting' success : ", response.data); })
        .catch((error)=>{ console.log("PzAPI 'simulate casting' failure : ", error.response?.data); })
        .finally(()=>{ });
    }

    const etype = (upoll ? localeMap["comm.event."+upoll.pkind[0].toLowerCase()] : undefined);
    const title = (etype ? `[${etype}] ` : '') + localeMap["menu.poller.runpoll"];

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    }  

    return (
        <PzArea.InvisibleBoardInContainer>

            <EventCard poll={upoll} viewable={false} foldable={true} />

            <PzArea.FullBoardWithTitle title={title} >

                <div className="w-full flex flex-col justify-start items-center gap-5 border-2 bg-white">

                    <EventRunner poll={poll} setPoll={setPoll2} />

                    {uauth?.ispza && 
                        <PzArea.MwBoard className="!p-2">
                            <div className="w-full flex flex-row justify-between items-center gap-2">
                                <PzButton.Std text='Simulate V-Checkin' size='sm' bold={false} onClick={simulateVoterCheckin} />
                                <PzButton.Std text='Poll'               size='sm' bold={false} onClick={()=>{console.log(upoll);}} />
                                <PzButton.Std text='xVAuth'             size='sm' bold={false} onClick={()=>{removeCookie("vauth",null);}} />
                                <PzButton.Std text='Simulate V-Casting' size='sm' bold={false} onClick={simulateVoterCasting} />
                            </div>
                        </PzArea.MwBoard>
                    }

                </div>

                <div className="w-full flex flex-row justify-between items-center mt-10">
                    <PzButton.Std Icon={IconStepPrev} text={localeMap["menu.myevents"]}        size='lg' onClick={()=>{navigate("/pages/myevents");}} />
                    <PzButton.Std Icon={IconQRCode}   text={localeMap["entrance.qrcode.for.transfer"]} size='lg' onClick={()=>{setOpen4Transfer(true);}} />
                    <PzButton.Std Icon={IconStepNext} text={localeMap["menu.poller.xresult"]} size='lg' onClick={()=>{navigate("/pages/poller/xresult");}} disabled={poll.pstatus < 'Xp'} />
                </div>

            </PzArea.FullBoardWithTitle>

            { open4Transfer && 
                <EntranceForTransfer.Dialog path={'/p/run'} onClose={()=>{setOpen4Transfer(false);}} />
            }

        </PzArea.InvisibleBoardInContainer>
    );
}

