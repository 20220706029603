import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzBasicModal from "../../common/PzBasicModal";
import PzInput from "../../common/PzInput";
import PzTable from "../../common/PzTable";
import PzButton from "../../common/PzButton";
import EvtEditorForStaff from "../../eventview/EvtEditorForStaff";


export function GetReadyStaffs({poll, setPoll, sState, setSState}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [updated, setUpdated] = React.useState(dayjs());
    const [folded, setFolded] = React.useState(sState.folded);
    if (sState.updated.isAfter(updated)) { setUpdated(sState.updated); fetchCounts(); }
    if (sState.folded != folded) setFolded(sState.folded);

    React.useEffect(() => {
        fetchCounts();
    }, []);

    function fetchCounts() {
        appConfig.getAPI().post("/api/mp/staff/counts", {pguid: poll.pguid})
        .then((response) => {
            // console.log("PzAPI 'voter/counts' success");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const counts = pzdata.resource; // {ntotal:0, nbdate:0, nphone:0, nemail:0}
            // console.log("counts:", counts);
            setSState({ ...sState, nt:counts.ntotal, nm:counts.nmanager, ns:counts.nstaff });
            if (counts.ntotal !== poll.nstaff) {
                setPoll({ ...poll, nstaff: counts.ntotal });
            }
        })
        .catch((error) => { 
            console.log("PzAPI 'staff/counts' failure (%v)", error); 
        })
        .finally(() => {});
    }

    function onClickForExample() {
        appConfig.getAPI().post("/api/mp/staff/example", {pguid: poll.pguid, nstaffs: 3})
        .then((response) => {
            // const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            // console.log("PzAPI 'staff/example' success");
            setSState({ ...sState, updated: dayjs() });
        })
        .catch((error) => { 
            console.log("PzAPI 'staff/example' failure (%v)", error); 
        })
        .finally(() => {});
    }

    function onChangeToFolded(fold) {
        setFolded(fold);
        setSState({ ...sState, folded: fold });
    }

    return (
        <div className="w-full pl-10 grid grid-cols-[auto_auto_auto_1fr] justify-start items-center gap-x-5 gap-y-1">
            <label className="text-right">{localeMap["poller.getready.staffs.nm"]} :</label>
            <label className="text-right font-bold">{sState.nm}</label>
            <label>&nbsp;</label>
            <label className="text-left opacity-50 text-sm hover:opacity-100">
                {localeMap["poller.getready.staffs.nm0"]}
            </label>

            <label className="text-right">{localeMap["poller.getready.staffs.ns"]} :</label>
            <label className="text-right font-bold">{sState.ns}</label>
            <label>&nbsp;</label>
            <label className="text-left opacity-50 text-sm hover:opacity-100">
                {localeMap["poller.getready.staffs.ns0"]}
            </label>

            <label className="text-right">{localeMap["poller.getready.staffs.list"]} :</label>
            <label>&nbsp;</label>
            <PzButton.Folder folded={folded} setFolded={onChangeToFolded} size='lg'/>
            <div className="pr-5 flex flex-row justify-end items-center gap-5">
            <PzButton.Std className={sState.nt > 0 || poll.pstatus >= 'Ax' ? 'invisible' : ''}
                    size='sm' bold={false} onClick={onClickForExample}
                    text={localeMap["poller.getready.staffs.example"]} />
            </div>
        </div>
    );
}

export function GetReadyStaffList({poll, setPoll, sState, setSState, className}) {
    const [cookies] = useCookies(['uauth']);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [pgOpts, setPgOpts] = React.useState({page:0,pageSize:10,pguid:poll?.pguid});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});
    const [open4List, setOpen4List] = React.useState(false);
    const [open4Add, setOpen4Add] = React.useState(false);
    const [open4Edit, setOpen4Edit] = React.useState(false);
    const [text4List, setText4List] = React.useState('');
    const [currStaff, setCurrStaff] = React.useState({});

    React.useEffect(() => {
        fetchStaffList(pgOpts);
    }, []);

    const fetchStaffList = (opts)=>{
        if (!uauth) return;
        appConfig.getAPI().post("/api/mp/staff/list", opts)
        .then((response) => {
            // console.log("PzApi 'stafflist' responded : ", response.data);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setPgData(pgdata);
        })
        .catch((error) => {
            console.log("PzAPI 'stafflist' error : ", error);
        })
        .finally(() => {});
    };

    function onClickToEditStaffDataset() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/staff/list", {...pgOpts, pageSize:0}) // fetch all
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            let text = "";
            pgdata.pageData.map((s)=>{
                text += `${s.uname}, ${s.uemail}, ${s.role}\n`;
                return text;
            });
            setText4List(text); // set the text for the TextArea of the entire list
            setOpen4List(true); // let the modal dialog open
            setSState({ ...sState, updated:dayjs() });
        })
        .catch((error) => {
            console.log("PzAPI 'stafflist' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    function onClickToClearStaffDataset() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/staff/clearall", {pguid:poll.pguid}) // 
        .then((response) => {
            console.log("PzAPI 'staff/clearall' success ");
            setSState({ ...sState, nt:0, nm:0, ns:0, updated:dayjs() });
            fetchStaffList(pgOpts);
        })
        .catch((error) => {
            console.log("PzAPI 'staff/clearall' failure : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    if (!poll) return (<div></div>);
    return (
        <div className={"w-full flex flex-col justify-start items-stretch gap-y-2 " + className}>
            <PzTable 
                data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchStaffList}
                rowKey="uemail" searchable={true} configurable={true} 
                columns={[
                    { title: 'Name', key: 'uname' },
                    { title: 'Email', key: 'uemail' },
                    { title: 'Role', key: 'role',
                        render: ({role})=>{return (<p>{role==='M' ? 'Manager' : 'Staff'}</p>)} },
                    { title: 'CreatedAt', key: 'createdat', datetime:'YYYY-MM-DD HH:mm', hidden:true },
                    { title: 'CheckedAt', key: 'checkedat', datetime:'YYYY-MM-DD HH:mm' },
                    ]} 
                buttons={[
                    { title: localeMap["poll04.staff.action.text"], onClick:()=>{onClickToEditStaffDataset();} },
                    { title: localeMap["poll04.staff.action.clear"], onClick:()=>{onClickToClearStaffDataset();} },
                ]} 
                onClickToAddRow={()=>{setOpen4Add(true);}} 
                onClickToEditRow={(row)=>{setOpen4Edit(true); setCurrStaff(row);}} />

            {open4List && (
                <StaffModalToEditDataset poll={poll} 
                    text4List={text4List} 
                    onClose={()=>{setOpen4List(false);}} 
                    onSuccess={()=>{fetchStaffList(pgOpts);}} />
            )}

            {open4Add && (
                <StaffModalToAdd poll={poll} 
                    onClose={()=>{setOpen4Add(false);}}  
                    onSuccess={()=>{fetchStaffList(pgOpts);}} />
            )}

            {open4Edit && (
                <StaffModalToEdit poll={poll} staff={currStaff} setStaff={setCurrStaff}
                    onClose={()=>{setOpen4Edit(false);}}  
                    onSuccess={()=>{fetchStaffList(pgOpts);}} />
            )}

        </div>
    );
}

function StaffModalToEditDataset({poll, text4List, onClose, onSuccess}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [lastText,  setLastText] = React.useState(text4List);
    const [staffText, setStaffText] = React.useState(text4List);
    const [selection, setSelection] = React.useState('');
    const [errMsgKey, setErrMsgKey] = React.useState('');

    function extractStaffMembersFromText(staff_text) {
        let staff_members = [], errkey = '', errtxt = '';
        const lines = staff_text.split('\n');
        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            if (line.trim().length === 0) continue;
            const parts = (lines[i].indexOf('\t') > 0 ? lines[i].split('\t') : lines[i].split(','));
            let v = { email:'', name:'', role:'S' };
            for (let j = 0; j < parts.length; j++) {
                const part = parts[j].trim()
                if      (part.indexOf('@') > 0) v.email = part;
                else if (part === 'M' || part === 'Manager' || part === 'True'  || part === 'true' ) v.role = 'M';
                else if (part === 'S' || part === 'Staff'   || part === 'False' || part === 'false') v.role = 'S';
                else if (part.length >= 1) v.name = part;
            }
            if      (v.email === '')    { errkey = 'no_email'; errtxt = line; break; }
            else if (v.name === '')     { errkey = 'no_name';  errtxt = line; break; }
            else staff_members.push(v);
        }
        if (errkey === '' && staff_members.length === 0) {
            errkey = 'empty_list';
            errtxt = '';
        }
        return [staff_members, errkey, errtxt];
    }
    
    function onImportStaffMembersFromText(staff_text) {
        setLastText(staff_text);
        const [staff_members, errkey, errtxt] = extractStaffMembersFromText(staff_text);
        if (errkey !== '') {
            setErrMsgKey('poll04.staff.errmsg.'+errkey);
            setSelection(errtxt);
            // console.log(`errkey: '${errkey}'`, `  errtxt: '${errtxt}'`);
        } else {
            setErrMsgKey('');
            setSelection('');
            appConfig.getAPI().post("/api/mp/staff/dataset", {pguid:poll.pguid, dataset:staff_members}) // update the dataset
            .then((response) => {
                const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
                if (typeof onSuccess === 'function') onSuccess();
            })
            .catch((error) => {
                console.log("PzAPI 'staff/dataset' error : ", error);
            })
            .finally(() => {
                if (typeof onClose === 'function') onClose();
            });
        }
    }

    if (!poll) return (<div></div>);
    return (
        <PzBasicModal onClose={onClose} >
            <div className="min-w-[600px] flex flex-col justify-start items-stretch p-5 gap-2">
                <h3>{utils.completeLocaleMapText(localeMap, "poll04.staff.title", [], poll.pkind)}</h3>
                <h5 className="text-slate-400">
                    {localeMap["poll04.staff.direction1"]} <br/>
                    {localeMap["poll04.staff.direction2"]}
                </h5>
                <PzInput.ForTextArea name="staff" className="border-[1px] px-1 resize-y" rows={10} 
                    value={staffText} onChangeDone={(text)=>{setStaffText(text);}} selection={selection}/>
                <h5 className="text-slate-400 w-full text-right">
                    &nbsp;{localeMap[errMsgKey]}
                </h5>
                <div className="w-full flex flex-row justify-end">
                    <PzButton.Default onClick={()=>{onImportStaffMembersFromText(staffText);}} disabled={staffText === lastText} >
                        <h5>{localeMap["poll04.staff.button.save"]}</h5>
                    </PzButton.Default>
                </div>
            </div>
        </PzBasicModal>
    );
}

function StaffModalToAdd({poll, onClose, onSuccess}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const initial = {pguid:poll.pguid, uname:'', uemail:'', role:'S'};
    const [staff, setStaff] = React.useState(initial);
    const okToSave = (staff.uname !== '' && utils.isValidEmail(staff.uemail));

    function onClickToSave() {
        console.log("onSave to Add : ", staff);
        appConfig.getAPI().post("/api/mp/staff/add", staff)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("Staff added : ", pzdata);
            if (typeof onSuccess === 'function') onSuccess();
        })
        .catch((error) => {
            console.log("Staff add error : ", error);
        })
        .finally(() => {
            if (typeof onClose === 'function') onClose();
        });
    }

    return (
        <PzBasicModal onClose={onClose} >
            <div className="min-w-[400px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h3>{utils.completeLocaleMapText(localeMap, "poll04.staff.action.add", [], poll.pkind)}</h3>

                <EvtEditorForStaff staff={staff} setStaff={setStaff} editable={true} editable_email={true} />

                <div className="w-full flex flex-row justify-end">
                    <PzButton.Default onClick={onClickToSave} disabled={!okToSave} >
                        <h5>{localeMap["poll04.staff.button.save"]}</h5>
                    </PzButton.Default>
                </div>

            </div>
        </PzBasicModal>
    );
}

function StaffModalToEdit({poll, staff, setStaff, onClose, onSuccess}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const okToSave = (staff.uname !== '');

    function onSave() {
        console.log("onSave to Edit : ", staff);
        const payload = { ...staff, pguid: poll.pguid }
        appConfig.getAPI().post("/api/mp/staff/edit", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("Staff edited : ", pzdata);
            if (typeof onSuccess === 'function') onSuccess();
        })
        .catch((error) => {
            console.log("Staff edit error : ", error);
        })
        .finally(() => {
            if (typeof onClose === 'function') onClose();
        });
    }
    function onDelete() {
        console.log("onDelete : ", staff);
        const payload = { pguid: poll.pguid, uemail: staff.uemail }
        appConfig.getAPI().post("/api/mp/staff/delete", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("Staff deleted : ", pzdata);
            if (typeof onSuccess === 'function') onSuccess();
        })
        .catch((error) => {
            console.log("Staff delete error : ", error);
        })
        .finally(() => {
            if (typeof onClose === 'function') onClose();
        });
    }

    return (
        <PzBasicModal onClose={onClose} >
            <div className="min-w-[400px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h3>{utils.completeLocaleMapText(localeMap, "poll04.staff.action.edit", [], poll.pkind)}</h3>

                <EvtEditorForStaff staff={staff} setStaff={setStaff} editable={true} />

                <div className="w-full flex flex-row justify-between items-center">
                    <PzButton.Default onClick={()=>{onDelete();}}>
                        <h5>{localeMap["poll04.staff.button.delete"]}</h5>
                    </PzButton.Default>
                    <PzButton.Default onClick={()=>{onSave();}} disabled={!okToSave} >
                        <h5>{localeMap["poll04.staff.button.save"]}</h5>
                    </PzButton.Default>
                </div>

            </div>
        </PzBasicModal>
    );
}
