import React from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';

import PzSymbol from "./PzSymbol";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";

const PzButton = {
    Default:    PzButtonDefault,
    Minimal:    PzButtonMinimal,
    Underlined: PzButtonUnderlined,
    ToNavigate: PzButtonToNavigate,

    Std:        PzButtonStd,
    Folder:     PzButtonFolder,
    EMAction:   PzButtonForEMAction,
    EMMinimal:  PzButtonForEMMinimal,
};

export default PzButton;

function PzButtonDefault({children, onClick=()=>{}, disabled=false, className='', gap='gap-1'}) {
    const u_bttn = ` cursor-pointer px-2 py-1 text-sm font-semibold shadow-sm text-slate-800 hover:text-blue-600 disabled:text-slate-400 disabled:opacity-50 `;
    const u_ring = ` ring-inset ring-1 hover:ring-2 rounded-md text-slate-800 hover:text-blue-600 `;
    return (
        <button 
            onClick={onClick} disabled={disabled}
            className={u_bttn + u_ring + className} >
            <div className={"flex flex-row justify-start items-center " + gap} >
                {children}
            </div>
        </button>
    )
}

function PzButtonMinimal({children, onClick=()=>{}, disabled=false, className='', gap='gap-1'}) {
    const u_bttn = " cursor-pointer text-sm font-semibold shadow-sm text-slate-800 hover:text-blue-600 disabled:text-slate-400 disabled:opacity-50 ";
    return (
        <button 
            onClick={onClick} disabled={disabled}
            className={u_bttn + className} >
            <div className={"flex flex-row justify-start items-center " + gap} >
                {children}
            </div>
        </button>
    )
}

function PzButtonUnderlined({children, onClick=()=>{}, disabled=false, className='', gap='gap-1'}) {
    const u_undr = ` underline text-blue-500 cursor-pointer font-mono disabled:opacity-50 `;
    return (
        <button 
            onClick={onClick} disabled={disabled}
            className={u_undr + className} >
            <div className={"flex flex-row justify-start items-center " + gap} >
                {children}
            </div>
        </button>
    )
}

function PzButtonToNavigate({ children, to, state, newtab=false, onClick=undefined }) {
    const navigate = useNavigate();
    const onButtonClick = ()=>{
        if (onClick) onClick();
        if (newtab) {
            const win = window.open(to, '_blank');
            if (win != null) win.focus();
        } else if (state) {
            navigate(to, { state: state });
        } else {
            navigate(to);
        }
    };
    return (
        <button className="underline text-blue-500 cursor-pointer" onClick={onButtonClick}>
            {children}
        </button>
    );
}

function PzButtonStd({Icon=null, text='', size='md', onClick=()=>{}, disabled=false, className='', bold=true, loading=false}) {
    //                          h1:  1.875   h2:  1.50  h3: 1.25    h4: 1.125   h5: 1.00    h6: 0.875
    // 5xl: 3.00    4xl: 2.25   3xl: 1.875   2xl: 1.50  xl: 1.25    lg: 1.125   md: 1.00    sm: 0.875   xs: 0.75
    let iconsize = " ", textsize = "text-md ", padsize = " ", gapsize = " ";
    if      (size === '4xl') { iconsize = "2.25rem "; textsize = "text-4xl "; padsize = "px-5 py-3 "; gapsize = "gap-3 "; }
    else if (size === '3xl') { iconsize = "1.87rem "; textsize = "text-3xl "; padsize = "px-5 py-3 "; gapsize = "gap-3 "; }
    else if (size === '2xl') { iconsize = "1.50rem "; textsize = "text-2xl "; padsize = "px-5 py-3 "; gapsize = "gap-3 "; }
    else if (size === 'xl')  { iconsize = "1.25rem "; textsize = "text-xl ";  padsize = "px-4 py-2 "; gapsize = "gap-2 "; }
    else if (size === 'lg')  { iconsize = "1.12rem "; textsize = "text-lg ";  padsize = "px-4 py-2 "; gapsize = "gap-2 "; }
    else if (size === 'md')  { iconsize = "1.00rem "; textsize = "text-md ";  padsize = "px-2 py-1 "; gapsize = "gap-2 "; }
    else if (size === 'sm')  { iconsize = "0.87rem "; textsize = "text-sm ";  padsize = "px-2 py-[0.1rem] "; gapsize = "gap-1 "; }
    else if (size === 'xs')  { iconsize = "0.75rem "; textsize = "text-xs ";  padsize = "px-1 py-[0.1rem] "; gapsize = "gap-1 "; }
    const clscolor  = " cursor-pointer text-slate-800 disabled:opacity-50 " + (disabled ? "" : " hover:text-blue-600 ");
    const clsring   = " ring-inset ring-1 rounded-md shadow-sm " + padsize  + (disabled ? "" : " hover:ring-2 ");
    const txtshape = " " + (bold ? "font-semibold " : "") + textsize;

    return (
        <button className={className+clscolor+clsring} onClick={onClick} disabled={disabled} >
            { loading ?
                <CircularProgress variant="indeterminate" size={iconsize} thickness={4}/>
            :
                <div className={"w-full flex flex-row justify-start items-center " + gapsize} >
                    { Icon && <Icon width={iconsize} height={iconsize} /> }
                    { text && <p className={"w-full text-center " + txtshape}>{text}</p> }
                </div>
            }
        </button>
    );
}

function PzButtonFolder({folded, setFolded=()=>{}, size='md', onChange=(foldednew)=>{}}) {
    //                          h1:  1.875   h2:  1.50  h3: 1.25    h4: 1.125   h5: 1.00    h6: 0.875
    // 5xl: 3.00    4xl: 2.25   3xl: 1.875   2xl: 1.50  xl: 1.25    lg: 1.125   md: 1.00    sm: 0.875   xs: 0.75
    let iconsize = "1.00rem";
    if      (size === '4xl') iconsize = "2.25rem";
    else if (size === '3xl') iconsize = "1.87rem";
    else if (size === '2xl') iconsize = "1.50rem";
    else if (size === 'xl')  iconsize = "1.25rem";
    else if (size === 'lg')  iconsize = "1.12rem";
    else if (size === 'md')  iconsize = "1.00rem";
    else if (size === 'sm')  iconsize = "0.87rem";
    else if (size === 'xs')  iconsize = "0.75rem";

    return (
        <IconChevron width={iconsize} height={iconsize} className={"cursor-pointer " + (folded ? "" : "rotate-180")}
            onClick={()=>{ setFolded(!folded); if (typeof onChange === 'function') onChange(!folded); }} /> 
    );
}

function PzButtonForEMAction({symbol='', text='', onClick=()=>{}, disabled=false, invisible=false, className='', loading=false}) {
    const clscolor = " cursor-pointer hover:text-white text-white bg-purple-800 disabled:bg-slate-300 ";
    const clsring  = " rounded-3xl shadow-sm px-5 py-3 ";
    const txtshape = " text-sm font-semibold " + (invisible || (!symbol && !text) ? "invisible " : " ");

    if (loading) {
        return ( // "h3 + py-2" : "1.2rem"
            <button className={txtshape + clscolor + clsring + className} >
                <CircularProgress variant="indeterminate" size="1.5rem" thickness={4}/>
            </button>
        );
    } else {
        return (
            <button onClick={onClick} disabled={disabled} className={txtshape + clscolor + clsring + className} >
                <div className="w-full flex flex-row justify-start items-center gap-4" >
                    &nbsp;
                    {/* { symbol && <PzSymbol.Text text={symbol} size="xl" /> } */}
                    { symbol && <h2 className="text-center">{symbol}</h2> }
                    { text   && <h2 className="text-center">{text}</h2> }
                    &nbsp;
                </div>
            </button>
        );
    }
}

function PzButtonForEMMinimal({text='', onClick=()=>{}, disabled=false, invisible=false, className=''}) {
    const clsshape = " px-5 py-3 text-sm font-semibold shadow-sm " + (invisible || !text ? "invisible " : "");
    const clscolor = " cursor-pointer text-slate-800 hover:text-blue-600 disabled:text-slate-400 disabled:opacity-50 ";

    return (
        <button onClick={onClick} disabled={disabled} className={clsshape + clscolor + className} >
            <div className="w-auto flex flex-row justify-start items-center gap-0" >
                &nbsp;
                { text && <h2 className="">{text}</h2> }
                &nbsp;
            </div>
        </button>
    );
}

