import React from "react";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal";
import PzInput from '../common/PzInput';
import PzTime from '../common/PzTime';

export default function EvtViewerForKTW({ poll, size='lg', setErr=()=>{}, onExpire=()=>{} }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const utzone = useSelector((state)=>state.ucfg.timezone);  // timezone from user settings
    const idType = React.useId();
    const idTitle = React.useId();
    const idDate = React.useId();
    const idLang = React.useId();
    const [selK, setSelK] = React.useState([ poll.pkind[0] ]);
    const text_size = 'text-' + size;
    
    function replacePkind(s, idx, c) {
        if (!s || s.length === 0 || idx >= s.length) {
            return s
        } else if (idx === s.length - 1) {
            return s.substring(0,idx) + c 
        } else {
            return s.substring(0,idx) + c + s.substring(idx+1)
        }
    }
    function onOkToDatePicker(value, dateString) {
        // setRightNow(false); 
        // setPoll({ ...poll, pwhen: value.$d.toISOString(), pkind: replacePkind(poll.pkind, 2, "B") });
    } 
        
    const disabledDate = (current) => {
        // Can not select days before today
        return current && (current < dayjs().startOf('day') || current > dayjs().add(6, 'month').startOf('day'));
    };

    return (
        <div className={"w-full grid grid-cols-[auto_1fr] justify-start items-start gap-x-4 gap-y-1 " + text_size} >

            {/* POLLER */}
            <>
                <span htmlFor={idLang} className={"text-right " + text_size}>{localeMap["poll01.poller.label"]} :</span>
                <label className="font-bold">{poll?.uname}</label>
            </>

            {/* KIND */}
            { false && <>
                <span htmlFor={idType} className={"text-right " + text_size}>{localeMap["poll01.ptype.label"]} :</span>
                <div className="grid grid-cols-[auto_auto_auto] items-start">
                    <PzInput.Radio2 value="P" selection={selK} setSelection={setSelK} size={size} disabled={false} text={localeMap["comm.event.p"]} />
                    <PzInput.Radio2 value="S" selection={selK} setSelection={setSelK} size={size} disabled={true}  text={localeMap["comm.event.s"]} />
                    <PzInput.Radio2 value="Q" selection={selK} setSelection={setSelK} size={size} disabled={true}  text={localeMap["comm.event.q"]} />
                </div>
            </>}

            {/* TITLE */}
            <>
                <span htmlFor={idTitle} className={"text-right " + text_size}>{localeMap["poll01.ptitle.label"]} :</span>
                <label className="font-bold">{poll.ptitle}</label>
            </>

            {/* WHEN */}
            <>
                <span htmlFor={idDate} className={"text-right " + text_size}>{localeMap["poll01.pwhen.label"]} :</span>
                { poll.pwhen === '' ?
                    <div className="w-full flex flex-col justify-start items-start">
                        <div className="w-full flex flex-row justify-between items-center">
                            <label className="font-bold">{localeMap["poll01.pwhen.rightnow"]}</label>
                            <span className="opacity-50">{localeMap["poll01.pwhen.rightnow.constraint"]}</span>
                        </div>
                        { poll.willBeExpiredAt &&
                        <div className="w-full flex flex-row justify-start items-center gap-2 opacity-50">
                            <span className={"text-md"}>
                                {utils.completeTimeText(localeMap["poll01.pwhen.rightnow.expire"], dayjs(poll.willBeExpiredAt), utzone)}
                            </span> 
                            (<PzTime.RemainingExp poll={poll} format='hh:mm:ss' />)
                        </div>
                        }
                    </div>
                : 
                    <div className="w-full flex flex-row justify-start items-center gap-3">
                        <label className="font-bold">{utils.getTimeInUserTimezone(poll.pwhen, "YYYY-MM-DD HH:mm", utzone, false)}</label>
                        { dayjs(poll.pwhen).isAfter(dayjs()) && 
                            <span className={" " + text_size}>( {dayjs(poll.pwhen).fromNow()} )</span>
                        }
                    </div>
                }
            </>

            {/* EARLY VOTING */}
            { !poll.pearlystt || poll.pearlystt === '' ?
                <>
                    <span htmlFor={idDate} className={"text-right " + text_size}>{localeMap["poll01.pearly.label"]} :</span>
                    <label className="">{localeMap["poll01.pearly.no"]}</label>
                </> 
            :
                <>
                    <span htmlFor={idDate} className={"text-right " + text_size}>{localeMap["poll01.pearly.label"]} :</span>
                    <div className="w-full flex flex-row justify-start items-center gap-3">
                        <label className="font-bold">{utils.getTimeInUserTimezone(poll.pearlystt, "YYYY-MM-DD HH:mm", utzone, false)} &nbsp; <span className={"font-normal " + text_size}>(-24H)</span></label>
                        <label className=""> ~ </label>
                        <label className="font-bold">{utils.getTimeInUserTimezone(poll.pearlyend, "MM-DD HH:mm", utzone, false)} &nbsp; <span className={"font-normal " + text_size}>(-10M)</span></label>
                    </div>
                </> 
            }

            {/* LANGUAGE */}
            <>
                <span htmlFor={idLang} className={"text-right " + text_size}>{localeMap["poll01.locale.label"]} :</span>
                <label className="font-bold">{appConfig.getLanguageFromLocale(poll?.locale, true)}</label>
            </>

        </div>
    );
}


export function Modal4EvtViewerForKTW({poll, onClose}) {
    const localeMap = useSelector(utils.selectLocaleMap);

    if (!poll) return (<p>invalid poll</p>);

    return (
        <PzBasicModal onClose={onClose} xclose={true} >
            <div className="min-w-[34rem] flex flex-col justify-start items-stretch p-5 gap-5">

                <h2>{localeMap["poller.editevent.event"]}</h2>

                <EvtViewerForKTW poll={poll} size='lg' />

            </div>
        </PzBasicModal>
    );
}
