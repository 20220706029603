import * as React from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function PzProgress({className, count=0, total=0, size='sm', bold=false, showlabels=true, determinate=true}) {
    const percent = (total > 0 ? Math.round(count * 100 / total) : 0);
    const determn = (determinate ? 'determinate' : 'indeterminate');
    const TEXTCLS = 'text-' + size + (bold ? ' font-bold ' : ' ');
  
    return (
        <div className={className+" "}>
            <div className="w-full flex flex-row justify-between items-center">
                { !showlabels ?
                    <label className={TEXTCLS}>&nbsp;</label>
                : 
                    <>
                    <label className={TEXTCLS}>&nbsp;</label>
                    <label className={TEXTCLS}>{count}</label>
                    <label className={TEXTCLS}>{percent}%</label>
                    </>
                }
            </div>
            <div className="w-full">
                <LinearProgress variant={determn} value={percent} />
            </div>
        </div>
        // <Box sx={{ width: '100%' }}>
        //     <LinearProgress variant="determinate" value={progress} />
        // </Box>
    );
}
