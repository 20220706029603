import * as React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
// import Stack from '@mui/material/Stack';
// import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useLocaleText } from '@mui/x-date-pickers/internals';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const CustomCalendarHeaderRoot = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
});

function CustomCalendarHeader(props) {
    const { currentMonth, onMonthChange } = props;

    const selectNextMonth = () => onMonthChange(currentMonth.add(1, 'month'), 'left');
    const selectPrevMonth = () => onMonthChange(currentMonth.subtract(1, 'month'), 'right');
    // const selectNextYear = () => onMonthChange(currentMonth.add(1, 'year'), 'left');
    // const selectPrevYear = () => onMonthChange(currentMonth.subtract(1, 'year'), 'right');

    return (
        <CustomCalendarHeaderRoot>
            {/* <Stack spacing={0} direction="row">
                <IconButton onClick={selectPrevYear} title="Previous year">
                    <KeyboardDoubleArrowLeftIcon />
                </IconButton>
                <IconButton onClick={selectPrevMonth} title="Previous month">
                    <ChevronLeft />
                </IconButton>
            </Stack> */}
            <IconButton onClick={selectPrevMonth} title="Previous month">
                <ChevronLeft />
            </IconButton>
            <Typography variant="body2">{currentMonth.format('MMMM YYYY')}</Typography>
            <IconButton onClick={selectNextMonth} title="Next month">
                <ChevronRight />
            </IconButton>
            {/* <Stack spacing={0} direction="row">
                <IconButton onClick={selectNextMonth} title="Next month">
                    <ChevronRight />
                </IconButton>
                <IconButton onClick={selectNextYear} title="Next year">
                    <KeyboardDoubleArrowRightIcon />
                </IconButton>
            </Stack> */}
        </CustomCalendarHeaderRoot>
    );
}

function CustomActionBar(props) {
    const { onSetToday, onAccept, onClear, onCancel, className } = props;
    const localeText = useLocaleText();
  
    return (
        <DialogActions className={className}>
            <Button onClick={(event) => { onSetToday(); }} >
                {localeText.todayButtonLabel}
            </Button>
            <div style={{flex: '1 0 0'}} />
            <Button onClick={(event) => { onCancel(); }} >
                {localeText.cancelButtonLabel}
            </Button>
            <Button onClick={(event) => { onAccept(); }} >
                {localeText.okButtonLabel}
            </Button>
        </DialogActions>
    );
}
  
export default function PzDateTimePicker({format, defaultValue, value, minDate, maxDate, disabled, onChange, onAccept}) {
    const text_props = { style:{paddingTop:'0', paddingRight:'4px', paddingBottom:'0px', paddingLeft:'4px', fontSize:'14px'} };
    const icon_props = { sx:{width:'20px', height:'20px'} };
    return (
        <LocalizationProvider  className="bg-slate-100 m-0 p-0" dateAdapter={AdapterDayjs}>
            <DateTimePicker 
                format={format ? format : "YYYY-MM-DD HH:mm" }
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                onAccept={onAccept}
                minDate={minDate}  maxDate={maxDate}
                disablePast={true}
                timeSteps={{ minutes: 10 }}
                slots={{ 
                    calendarHeader: CustomCalendarHeader,
                    actionBar: CustomActionBar,
                }} 
                slotProps={{ // 'standard', 'outlined', 'filled'
                    textField: { variant:'standard', size:'small', margin:'none', inputProps:text_props}, // 'standard'/'outlined'/'filled'
                    openPickerIcon: icon_props,
                    toolbar: { toolbarFormat: 'YYYY', toolbarPlaceholder: '??', hidden: true },
                    calendarHeader: { }, 
                }}
                sx={{color:'red', paddingTop:0, paddingBottom:0}}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
}
