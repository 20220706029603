import React from "react";

import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";
import PzSymbol from "../common/PzSymbol";
import EMDialogQstRounds from "./EMDialogQstRounds";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";

export default function EMComQstHeader({poll, pqst, bold=false, op50=false, className='', qstFolded=false, setQstFolded=undefined, tanswers=[] }) {
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const SIZE = (pqst.question.length > 24 ? "2xl" : "3xl");
    const fonts = (bold ? " font-bold " : " font-normal ") + (op50 ? " opacity-50 " : " ");
    const [open4QR, setOpen4QR] = React.useState(false);
    const [resultMode, setResultMode] = React.useState('T');
    // console.log("EMComQstHeader : ", "  bold:", bold, "  op50:", op50);

    function onChangeToResultMode(event) {
        let top_or_all = event.target.value;
        setResultMode(top_or_all);
        if (typeof setQstFolded === 'function') {
            setQstFolded(top_or_all === 'T' ? true : false);
        }
    }

    if (!poll) {
        return (<h3>poll is null</h3>);
    } else if (!pqst) {
        return (<h3>pqst is null</h3>);
    }

    return (
        <>
            <div className={"w-full flex flex-col justify-start items-start gap-4 " + className} >
                <div className={"w-full flex flex-row justify-between items-start "} >
                    <div className={"w-full flex flex-row justify-start items-start gap-4 "} >
                        <PzSymbol.Text text={'Q'+pqst.qseq} size='2xl'
                            className="!bg-purple-800 !text-white hover:cursor-pointer" 
                            onClick={()=>{ console.log(pqst); }} />
                        { pqst.qround > 1 &&
                            <PzSymbol.Text text={'Round'+pqst.qround} size='2xl'
                                className="!bg-purple-800 !text-white hover:cursor-pointer"
                                onClick={()=>{if (qstatus!=='Vt') setOpen4QR(true);}} />
                        }
                    </div>
                    { setQstFolded ?
                        ( pqst.qstatus <= 'Vs' ?
                            <IconChevron width={24} height={24} 
                                className={`cursor-pointer hover:text-blue-500 ${qstFolded ? `` : `rotate-180`}`} 
                                onClick={()=>{ if (typeof setQstFolded === 'function') setQstFolded(!qstFolded); }}/> 
                        : pqst.qstatus === 'Vx' &&  pqst.qkind !== 'A' ?
                            <div className="w-full flex flex-row justify-end items-center gap-4">
                                <PzInput.Radio1 value="T" checked={resultMode==="T"} onChange={onChangeToResultMode}><span>{`Top${tanswers.length}`}</span></PzInput.Radio1>
                                <PzInput.Radio1 value="A" checked={resultMode==="A"} onChange={onChangeToResultMode}><span>ALL </span></PzInput.Radio1>
                            </div>
                        :
                            <></>
                        )
                    :
                        <></>
                    }
                </div>
                { pstatus === 'Os' && qstatus === 'Vs' ? 
                    <label className={"w-full text-" + SIZE + " " + fonts}>{pqst.question}</label> 
                :
                    <></> 
                }
            </div>

            { open4QR &&
                <EMDialogQstRounds poll={poll} pqst={pqst} onClose={()=>{setOpen4QR(false);}} />
            }
        </>
    );
}

