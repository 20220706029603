import React from "react";
import { useNavigate } from "react-router-dom";

import { utils } from "../config/userConfig";
import PzArea from "../common/PzArea";

export default function EMSplash({msg='', locale='en', gohome=false, xclose=false}) {
    const localeMap = utils.selectMapByLocale(locale);
    const navigate = useNavigate();
    
    return (
        <PzArea.MwhBoardInContainer xclose={xclose} >
            <div>&nbsp;</div>
                <div className="flex flex-col justify-center items-center w-full h-full gap-10">
                    <h1 className="font-extrabold text-5xl cursor-pointer" onClick={()=>{if (gohome) navigate('/');}}>
                        Pollerz.net
                    </h1>
                    { msg === 'THANKYOU' ?
                        <h3 className="font-bold ">&nbsp;{localeMap["monitor.splash.thankyou"]}&nbsp;</h3>
                    :
                        <h3 className="font-bold ">&nbsp;{msg}&nbsp;</h3>
                    }
                </div>
            <div>&nbsp;</div>
        </PzArea.MwhBoardInContainer> 
    );
}
