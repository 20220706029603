import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";
import PzBasicModal from "../common/PzBasicModal";
import PzTable, {PztShortTooltip} from "../common/PzTable";
import EntranceForVoter from "../entrance/EntranceForVoter";
import EMWithPzServer from "./EMWithPzServer";
import EMWithSimulator from "./EMWithSimulator";
import EMComQstMap from "./EMComQstMap";
import { ReactComponent as IconQRCode } from "../resource/icons/pz-qrcode.svg";

export default function EMAreaHeader({poll, className='', asVoter=false}) {
    const pstatus = (poll ? poll.pstatus : ' ');
    const pqst = (poll?.qactive >= 0 && poll?.pqstlist ? poll?.pqstlist[poll?.qactive] : null);
    const [cookies, removeCookie] = useCookies(['vauth']);
    const [vauth, setVAuth] = React.useState(cookies && cookies.vauth ? jwtDecode(cookies.vauth) : null);
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const [open4Voters, setOpen4Voters] = React.useState(false);
    const [open4Entrance, setOpen4Entrance] = React.useState(false);

    function showVoterList() {
        if (!asVoter) {
            setOpen4Voters(true);
        }
    }

    return (
        <div className={" w-full flex flex-col justify-center items-stretch gap-2 " + className}>

            <label className="text-2xl font-bold text-center" onClick={()=>{console.log(poll);}}>{poll.ptitle}</label>

            <div className="w-full flex flex-row justify-center items-center">
            <div className="w-auto grid grid-cols-[1fr_auto_auto_auto_1fr] justify-center items-stretch gap-5">
                {/* Poller */}
                <div className="flex flex-col justify-start items-center gap-1">
                    <label className="text-md  font-bold">{utils.completeLocaleMapText(localeMap, "monitor.event.poller.label", [], poll.pkind)}</label>
                    <label className="text-2xl font-bold">{poll.uname}</label>
                </div>
                {/* Entrance */}
                { !asVoter && 
                    <>
                    <div className="w-[1px] bg-slate-400"></div>
                    <div className="flex flex-col justify-start items-center gap-1">
                        <div>&nbsp;</div>
                        <button onClick={()=>{setOpen4Entrance(true);}} className='cursor-pointer' tabIndex='-1'>
                            <IconQRCode width='1.5rem' height='1.5rem' tabIndex='-1' />
                        </button>
                    </div>
                    </>
                }
                {/* Voters */}
                <div className="w-[1px] bg-slate-400"></div>
                { pstatus <= 'Oo' ?
                    <div className="flex flex-col justify-start items-center gap-1" onClick={showVoterList} >
                        <label className="text-md  font-bold">{utils.completeLocaleMapText(localeMap, "monitor.event.eligibles.label", [], poll.pkind)}</label>
                        <label className="text-2xl font-bold">{poll.nmax}</label>
                    </div>
                :
                    <div className="flex flex-col justify-start items-center gap-1" onClick={showVoterList} >
                        <label className="text-md  font-bold">{utils.completeLocaleMapText(localeMap, "monitor.event.attendee.label", [], poll.pkind)}</label>
                        <PzButton.Minimal><label className="text-2xl font-bold">{poll.natt} / {poll.nmax}</label></PzButton.Minimal>
                    </div>
                }
                {/* Voter Checked-in */}
                { asVoter && 
                    <>
                    <div className="w-[1px] bg-slate-400"></div>
                    <div className="flex flex-col justify-start items-center gap-1">
                        <label className="text-md  font-bold">{utils.completeLocaleMapText(localeMap, "monitor.event.myname.label", [], poll.pkind)}</label>
                        <label className="text-2xl font-bold">{vauth.vname}</label>
                    </div>
                    </>
                }
            </div>
            </div>

            <EMComQstMap poll={poll} className="pt-1" invisible={pstatus < 'Os' || pstatus >= 'Oz' || !pqst} />

            {open4Voters && 
                <ModalToShowVoters poll={poll} onClose={()=>{setOpen4Voters(false);}} />
            }
            {open4Entrance && 
                <EntranceForVoter.Dialog poll={poll} onClose={()=>{setOpen4Entrance(false);}} />
            }
        </div>
    );
}

function ModalToShowVoters({ poll, onClose=()=>{} }) {
    const location = useLocation();
    const [pgOpts, setPgOpts] = React.useState({pguid:poll?.pguid,page:0,pageSize:10});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});

    React.useEffect(() => {
        fetchVoterList();
    }, []);

    function fetchVoterList() {
        const simulate = (location.pathname.startsWith("/y/"));
        if (simulate) return EMWithSimulator.fetchVoterList(poll, pgOpts, setPgData);
        else          return EMWithPzServer.fetchVoterList(poll, pgOpts, setPgData);
    }

    return (
        <PzBasicModal onClose={onClose} >

            <div className="w-[32rem] flex flex-col p-5 gap-5">

                <PzTable 
                    data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchVoterList}
                    rowKey="vid" searchable={true} configurable={true}
                    columns={[
                        { title: 'VID', key: 'vid',
                            render: ({vid})=>{ return (<PztShortTooltip value={vid} stt={-14}/>); } },
                        { title: 'Name', key: 'vname' },
                        { title: 'S', key: 'vstatus', hidden:true },
                        { title: 'BDate', key: 'vbdate', hidden:true },
                        { title: 'Phone', key: 'vphone', hidden:(poll.pstatus <= 'Oo' ? false : true) },
                        { title: 'Email', key: 'vemail', hidden:true },
                        { title: 'CheckIn', key: 'checkin', datetime: 'HH:mm:ss', hidden:false },
                        { title: 'LastVote', key: 'lastvote', datetime: 'HH:mm:ss', hidden:(poll.pstatus <= 'Oo' ? true : false) },
                        { title: 'CheckOut', key: 'checkout', datetime: 'HH:mm:ss', hidden:true },
                        { title: 'IP', key: 'voterip', hidden:true },
                        { title: 'Location', key: 'voterloc', hidden:true },
                    ]} />

            </div>
            
        </PzBasicModal>
    );

}
