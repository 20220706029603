import React from "react";

import LinearProgress from '@mui/material/LinearProgress';

import PzSymbol from "../common/PzSymbol";

const EMStatus = {
    Waiting:    EMStatusWaiting,
}

export default EMStatus;

function EMStatusWaiting({symbol, text, invisible, noprogress=false}) {

    return (
        <div className={"w-full flex flex-col justify-start items-center gap-0 opacity-50 " + (invisible ? "invisible " : "")}>
            <div className="w-full flex flex-row justify-start items-center gap-2" >
                { symbol && <PzSymbol.Text text={symbol} size="xl" /> }
                <p className="w-full text-xl">{text}</p>
            </div>
            <div className={"w-full " + (noprogress ? "invisible " : "")}>
                <LinearProgress variant={'indeterminate'} value={0} />
            </div>

        </div>
    );

}