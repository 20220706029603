const localeStrMap = {
    "alpha.noti.title": "Pollerz.net is under ALPHA TEST",
    "alpha.noti.message": "This service is being developed right now. \n During the alpha test, some features may change without warning, and we do not guarantee the preservation of your data. \n Use it at your own risk. \n\n The official service will begin on Dec 5th, 2024.",
    "alpha.feedback.fab": "Feedback",
    "alpha.feedback.title": "What do you think about this page?",
    "alpha.feedback.desc": "Please give us your opinion, so that we can improve Pollerz.net.",
    "alpha.feedback.type0": "just a comment",
    "alpha.feedback.type1": "bug report",
    "alpha.feedback.type2": "feature request",
    "alpha.feedback.newsletter": "Want to subscribe to Email newsletter?",
    "alpha.feedback.newsletter.subscribe": "Subscribe",
    "alpha.feedback.newsletter.desc": "News and blog updates are included, being sent at most once in a month.",
    "alpha.feedback.send": "Send your feedback",

    "forgotten.msg1": "Thank you for everything,\n your account has been successfully deleted.",
    "forgotten.msg2": "We hope to serve you again in the future,\n with upgraded features in a fresh relationship.",
    "notfound.request": "The requested page does not exist",
    "notfound.nav.home": "Navigate to Home page",
    "notfound.nav.login": "Navigate to log in",

    "comm.event.p": "poll",             // this will replace '{event}' in other locale strings
    "comm.event.s": "survey",           // this will replace '{event}' in other locale strings
    "comm.event.q": "quiz",             // this will replace '{event}' in other locale strings
    "comm.timing.r": "real-time",      // realtime (right-away), this will replace '{timing}' in other locale strings
    "comm.timing.p": "period-based",   // period-based,          this will replace '{timing}' in other locale strings
    "comm.voters.p": "voters",             // this will replace '{voters}' in other locale strings
    "comm.voters.s": "participants",       // this will replace '{voters}' in other locale strings
    "comm.voters.q": "participants",       // this will replace '{voters}' in other locale strings
    "comm.voter.p": "voter",             // this will replace '{voter}' in other locale strings
    "comm.voter.s": "participant",       // this will replace '{voter}' in other locale strings
    "comm.voter.q": "participant",       // this will replace '{voter}' in other locale strings
    "comm.evoter.p": "voter",               // this will replace '{evoter}' in other locale strings
    "comm.evoter.s": "participant",         // this will replace '{evoter}' in other locale strings
    "comm.evoter.q": "participant",         // this will replace '{evoter}' in other locale strings
    "comm.pkind.pr": "real-time poll",       // this will replace '{pkind}' in other locale strings
    "comm.pkind.pp": "period-based poll",    // this will replace '{pkind}' in other locale strings
    "comm.pkind.sr": "real-time survey",     // this will replace '{pkind}' in other locale strings
    "comm.pkind.sp": "period-based survey",  // this will replace '{pkind}' in other locale strings
    "comm.pkind.qr": "real-time quiz",       // this will replace '{pkind}' in other locale strings
    "comm.pkind.qp": "period-based quiz",    // this will replace '{pkind}' in other locale strings
    "comm.button.cancel": "Cancel",
    "comm.button.close": "Close",
    "comm.button.ok": "OK",
    "comm.underdev": "Under Dev",    // 

    "errmsg.network": "Please check the network and try it later",
    "errmsg.unknown": "Unknown error",

    "auth.login.title": "Welcome back",
    "auth.login.subtitle": "",
    "auth.login.input.email": "Email",
    "auth.login.input.password": "Password",
    "auth.login.button": "Log in",
    "auth.login.nav1.label": "Don't have an account yet?",
    "auth.login.nav1.button": "Sign up",
    "auth.login.nav2.label": "Forgot password?",
    "auth.login.nav2.button": "Reset",
    "auth.login.errmsg.missed": "The email or password is incorrect. ({1}/{2})",
    "auth.login.errmsg.missxx": "You have exceeded the maximum number of login attempts. Please try again in 5 minutes.",
    "auth.login.errmsg.nouser": "The account does not exist.",
    "auth.login.errmsg.server": "Failed to sign in. Please try again.",
    "auth.resetpw.step1.title": "Reset forgotten password",
    "auth.resetpw.step1.subtitle": "We will send a 6-digit verification code to your email.",
    "auth.resetpw.step1.input.email": "Email",
    "auth.resetpw.step1.button": "Send me email",
    "auth.resetpw.step2.title": "Verify and reset your password",
    "auth.resetpw.step2.subtitle": "We've sent a 6-digit verification code to '{1}'. If you don't see it, please check your spam folder and make sure your email address is spelled correctly.",
    "auth.resetpw.step2.input.vcode": "Verification code",
    "auth.resetpw.step2.input.pword1": "New password",
    "auth.resetpw.step2.input.pword2": "Confirm password",
    "auth.resetpw.step2.button": "Reset my password",
    "auth.resetpw.errmsg.notfound": "Email address cannot be found",
    "auth.signup.step1.title": "Create your account",
    "auth.signup.step1.subtitle": "And give your voters better experience on Pollerz.net.",
    "auth.signup.step1.input.uname": "Name",
    "auth.signup.step1.input.email": "Email",
    "auth.signup.step1.button": "Sign up",
    "auth.signup.step1.nav1.label": "By clicking 'Sign up', you agree to our",
    "auth.signup.step1.nav1.button": "Terms & Privacy Policy",
    "auth.signup.step1.nav2.label": "Already have an account?",
    "auth.signup.step1.nav2.button": "Log in",
    "auth.signup.step2.title": "Verify and set your password",
    "auth.signup.step2.subtitle": "We've sent a 6-digit verification code to '{1}'. If you don't see it, please check your spam folder and make sure your email address is spelled correctly.",
    "auth.signup.step2.input.vcode": "Verification code",
    "auth.signup.step2.input.pword1": "Password",
    "auth.signup.step2.input.pword2": "Confirm password",
    "auth.signup.step2.button": "Create my account",
    "auth.chpword.title": "Change password",
    "auth.chpword.subtitle": "",
    "auth.chpword.input.pword0": "Old password",
    "auth.chpword.input.pword1": "New password",
    "auth.chpword.input.pword2": "Confirm password",
    "auth.chpword.button": "Change password",
    "auth.chpword.errmsg.notfound": "Email address is invalid",
    "auth.chpword.errmsg.oldinvalid": "Old password doesn't match",
    "auth.chpword.errmsg.newinvalid": "New password is invalid",
    "auth.chemail.title": "Change email address",
    "auth.chemail.subtitle.step1": "We will send a verification code to new email address.",
    "auth.chemail.subtitle.step2": "Enter the 6-digit verification code to activate the change",
    "auth.chemail.input.oldemail": "Old email address",
    "auth.chemail.input.newemail": "New email address",
    "auth.chemail.input.vcode": "Verification code",
    "auth.chemail.button.step1": "Send me verification code",
    "auth.chemail.button.step2": "Change email address",
    "auth.chemail.errmsg.emailtaken": "Email address is already taken.",
    "auth.vcode.errmsg.notfound": "Email address cannot be found",
    "auth.vcode.errmsg.emailtaken": "Email address is already taken",
    "auth.vcode.errmsg.emailerror": "Failed to send email - Try it later",
    "auth.vcode.errmsg.nomatch": "Verification code doesn't match",
    "auth.vcode.retry.label": "Haven't still received the email?",
    "auth.vcode.retry.button": "Try again",
    "auth.errmsg.vcode.invalid": "it should be 6 digits",
    "auth.errmsg.email.invalid": "invalid email address",
    "auth.errmsg.uname.invalid": "invalid name",
    "auth.errmsg.pword.invalid": "invalid password",
    "auth.errmsg.pword.mismatch": "password don't match",
    "auth.errmsg.pword.length": "length should be between 8 and 25",
    "auth.errmsg.pword.uchar": "include at least one capital letter",
    "auth.errmsg.pword.digit": "include at least one digit letter",
    "auth.errmsg.pword.schar": "include at least one special character",
    "auth.errmsg.network": "Please check the network and try it later",
    "auth.errmsg.unknown": "Unknown error",
    "auth.extsession.title": "Extend your login session",
    "auth.extsession.direction": "Click the 'Extend' button below \n or press SPACE key to extend.",
    "auth.extsession.remaining": "logging out in {1} seconds",
    "auth.extsession.button": "Extend",
    "auth.extsession.toast": "Your log-in session was extended.",

    "home.intro.title": "The most fast-easy-secure way\nto ask many people's opinion",
    "home.intro.subtitle": "Online poll / survey / quiz for everyone,\nwithout any installation or subscription for voters",
    "home.intro.serving": "Serving {1} events and {2} voters over {3} countries",
    "home.intro.difference": "Difference from the other services",
    "home.intro.whyforyou": "Why you need Pollerz.net",
    "home.intro.whyforus": "Why and how we build Pollerz.net",
    "home.intro.tryyourself": "Try it yourself",
    "home.why.label0": "Real time",
    "home.why.label1": "Easy",
    "home.why.label2": "Secure",
    "home.why.label3": "Free",
    "home.why.label4": "Multi round",
    "home.why.label5": "Staff support",
    "home.why.desc0": "The execution of the poll (or survey) is automated and streamlined, so that it can be run real-time alongside your ongoing offline meeting. Furthermore, the voters can watch the progress and the results of the poll in real-time.",
    "home.why.desc1": "It shouldn’t be hard at all for you to ask and aggregate the opinions of other people, especially with all the technologies we now have in our hands. No installation or subscription should be required for the participants to vote.",
    "home.why.desc2": "Every vote is securely counted, and secrecy of anonymous vote is strictly protected. The voters' personal information will never be used for any purposes other than the poll itself, and it will be permanently deleted after the poll.",
    "home.why.desc3": "You, as a poller, should have many different options that can help you to ensure eligibility of the voters. With offline options, you can make your poll free by paying it with your monthly free quota (up to 100 voters for the first time, 30 afterwards).",
    "home.why.desc4": "You may have to poll a single decision with multiple voting rounds, due to tie or absence of prevailing opinion. By selecting simple predefined rules, you can easily automate the multi-round voting process as well.",
    "home.why.desc5": "You can assign staff members and give them appropriate permissions for the event you created, so that they can help you to execute the event, check eligibility of voters, and assist the voters face-to-face to vote.",
    "home.goal.boostdm": "\"Boost our collective decision making for democracy\"",
    "home.goal.support": "\"Serve people, and never try to influence or lock people in\"",
    "home.goal.staysml": "\"Stay small, light, and agile, without compromising security\"",
    "home.goal.desc.boostdm": "Anonymous voting, where each person has one vote, is fundamental to democracy. It signifies respect for others' viewpoints and a commitment to recognizing majority opinion through fair procedures, even when it differ from my own. Pollerz.net aims to support this opinion gathering process to be fast, simple, and secure, hoping to contribute to democratic decision-making process.",
    "home.goal.desc.support": "The aim of Pollerz.net is to support people's free expression of their opinions, and we oppose any attempts to influence them. Pollerz.net does not analyze user profiles or seek to create lock-in effects. Furthermore, we do not collect or utilize personal information of event participants, ensuring that all such data is deleted from the database after the final event report is compiled.",
    "home.goal.desc.staysml": "We have often witnessed tech companies shift from their initial drive for user value and morph into entities prioritizing their own scale, revenue, and profit. We do not pursue growth in this manner. Instead, we aim to remain a small, agile, and efficient organization, dedicated to consistently delivering trusted services to our users.",
    "home.try.asvoter": "Try it as a voter",
    "home.try.aspoller": "Try it as a poller",

    "home.page.features.title": "Features",
    "home.page.pricing.title": "Pricing",
    "home.page.openpoll.title": "Pollerz.net Open Poll",
    "home.page.opensurvey.title": "Pollerz.net Open Survey",
    "home.page.openquiz.title": "Pollerz.net Open Quiz",
    "home.page.openevent.direction": "Take a picture of QR-code to enter",
    
    "menu.auth.login": "Log in",
    "menu.auth.logout": "Log out",
    "menu.auth.signup": "Sign up",
    "menu.home.howto": "HowTo",
    "menu.home.pricing": "Pricing",
    "menu.myaccount" : "My Account", 
    "menu.myevents": "My Events",
    "menu.poller": "As Poller",
    "menu.poller.editevent": "Edit & Test Event",
    "menu.poller.getready": "Get Ready",
    "menu.poller.notify": "Send Notification",
    "menu.poller.simtest": "Test as a virtual event",
    "menu.poller.runpoll": "Run Event",
    "menu.poller.xresult": "Event Result",
    "menu.poller.xreport": "Final Report",
    "menu.poller.archive": "Save in archive",
    "menu.staff": "As Staff",
    "menu.staff.entrance": "Help To Check-in",
    "menu.staff.eligibility": "Check Eligibility",
    "menu.staff.votehelper": "Help To Vote",
    "menu.staff.watchpoll": "Watch Event",
    "menu.staff.xresult": "Final Result",
    "menu.xresult.monitor": "Result Monitor",
    "menu.xresult.details": "Result Details",
    "menu.voter": "As Voter",
    "menu.voter.testvote": "Vote for Testing",
    "menu.voter.vote": "Vote Myself",
    "menu.about": "About Pollerz",
    "menu.about.mission": "Mission Statement",
    "menu.about.howto": "How to Use",
    "menu.about.pricing": "Pricing Policy",
    "menu.about.privacypolicy": "Privacy Policy",
    "menu.about.help2translate": "Help to Translate",
    "menu.about.purchaseinquiry": "Purchase Inquiry",

    "modal.button.cancel": "Cancel",
    "modal.button.more": "Advanced Settings",
    "modal.button.save": "Save",

    "myaccount.title" : "My Account", 
    "myaccount.user.label" : "Account", 
    "myaccount.username.label" : "Name", 
    "myaccount.username.change" : "Change name", 
    "myaccount.userid.label" : "User ID", 
    "myaccount.userid.change" : "Change", 
    "myaccount.userpw.label" : "User Password", 
    "myaccount.userpw.change" : "Change", 
    "myaccount.env.label" : "Settings", 
    "myaccount.env.country.label" : "Country (Residence)", 
    "myaccount.env.language.label" : "Selected Language", 
    "myaccount.env.timezone.label" : "Selected Timezone", 
    "myaccount.quota.label" : "Free Quota", 
    "myaccount.quota.remain.label" : "Remaining", 
    "myaccount.quota.monthly.label" : "Monthly Max Refill", 
    "myaccount.quota.notice" : "Free quota will be refilled on the first day of each month (UTC).", 
    "myaccount.quota.nextrefill.label" : "Next Refill", 
    "myaccount.paym.label" : "Payment", 
    "myaccount.rtbf.label" : "Right to be \nForgotten", 
    "myaccount.rtbf.warning" : "Warning: These tasks are irreversable.", 
    "myaccount.rtbf.exception" : "Payment records are the only exception of the deletion, and will remain. The remaining data will be minimal, as required by the law in many countries, and it will be automatically destroyed after three years.", 
    "myaccount.rtbf.data.label" : "Delete all my events and their related data", 
    "myaccount.rtbf.data.direction" : "Please enter '{1}' below, and then click the button.", 
    "myaccount.rtbf.data.keyword" : "ALL MY EVENTS", 
    "myaccount.rtbf.data.button" : "Delete all my events", 
    "myaccount.rtbf.acct.label" : "Delete my account and forget about me", 
    "myaccount.rtbf.acct.direction" : "Please enter '{1}' below, and then click the button.", 
    "myaccount.rtbf.acct.keyword" : "EVERYTHING", 
    "myaccount.rtbf.acct.button" : "Delete my account", 

    "myevents.needtologin" : "You need to login to use this menu", 
    "myevents.planned" : "Planned Events", 
    "myevents.archived" : "Archived Events", 
    "myevents.sysevents" : "System Events",
    "myevents.none.planned" : "You have no events planned at this moment.",
    "myevents.none.archived" : "You have no events archived at this moment.",
    "myevents.none.sysevents" : "You have no system events at this moment.",
    "myevents.none.helpmsg" : "Click the button below, if you want to learn from an example.",
    "myevents.none.createx" : "Create a poll example",

    "poll.summary.label" : "Summary", 
    "poll.summary.expire.notopened" : "기한 내에 개최되지 않아, {YYYY-MM-DD HH:mm}에 삭제될 예정입니다.", 
    "poll.summary.expire.notclosed" : "기한 내에 종결되지 않아, {YYYY-MM-DD HH:mm}에 삭제될 예정입니다.", 
    "poll.summary.elg.created" : "{Event} with {1} registered {voters},", 
    "poll.summary.elg.planned" : "{Event} with {1} (Max {2}) registered {voters},", 
    "poll.summary.elg.closed"  : "{Event} held with {3}/{1} registered {voters},", 
    "poll.summary.any.created" : "{Event} that anyone can participate,", 
    "poll.summary.any.planned" : "{Event} that anyone (Max {2}) can participate,", 
    "poll.summary.any.closed"  : "{Event} held with {3}/{2} {voters},", 
    "poll.summary.status.A0" : "Now under Planning", 
    "poll.summary.status.Ac" : "Now under Planning", 
    "poll.summary.status.Ar" : "Planned and waiting for purchase", 
    "poll.summary.status.Ax" : "Ready to be run (purchased)", 
    "poll.summary.status.Oo" : "Openning (check-in)", 
    "poll.summary.status.Os" : "Started", 
    "poll.summary.status.Oz" : "Wrapping up", 
    "poll.summary.status.Xp" : "Closed", 
    "poll.card.questions.label" : "Questions", 
    "poll.card.voters.label" : "{Voters}", 
    "poll.card.voters.elg.planned" : "{1} {voters} registered, and MAX {2} can attend", 
    "poll.card.voters.elg.closed" : "{1} {voters} registered, and {2} attended", 
    "poll.card.voters.any.planned" : "No registered {voters}, and MAX {1} can attend", 
    "poll.card.voters.any.closed" : "No registered {voters}, and {1} attended", 
    "poll.card.staff.label" : "Staff", 
    "poll.card.staff.summary" : "Poller '{1}', supported by {2} staff members", 
    "poll.card.staff.myrole.p" : "My role: 'Poller'", 
    "poll.card.staff.myrole.m" : "My role: 'Manager'", 
    "poll.card.staff.myrole.s" : "My role: 'Staff'", 
    "poll.card.button.edit" : "Edit", 
    "poll.card.button.run" : "Run", 
    "poll.card.button.watch" : "Watch", 
    "poll.card.button.xresult" : "View Result", 

    "poll.create_example" : "Create example poll event", 
    "poll.create_quick" : "Create a Simple & Quick Poll", 
    "poll.create_quick.desc" : "You can select more options if you choose to '{1}'.", 
    "poll.create_quick.title" : "Quick poll {1}", 
    "poll.create_event" : "Create a New Event", 
    // "poll.create.step1" : "Set type, title, and datetime of the event.", 
    "poll.create.step1" : "Set title and datetime of the event.", 
    "poll.create.step2" : "Register the questions to be asked in the {event}.", 
    "poll.create.step3" : "Register the {voters} of the {event}. (recommended)", 
    "poll.create.step3.direction" : "Enter the list of {voters} below, and click '{1}'", 
    "poll.create.step3.extract" : "Extract list", 
    "poll.create.step3.errmsg" : "Unrecognized string was found", 
    "poll.create.step3.none" : "No {voter} being registered.", 
    "poll.create.step3.some" : "{1} {voters} being registered", 
    "poll.create.step4" : "Confirm and create the {event}.", 
    "poll.create.button.prev": "Previous Step",
    "poll.create.button.next": "Next Step",
    "poll.create.button.create": "Create {event}",
    "poll.criteria.Majority" : "Majority", 
    "poll.criteria.Most" : "Most votes", 
    "poll.criteria.Zero" : "No Vote",
    "poll.criteria.Least" : "Least votes", 
    "poll.criteria.Top2x" : "Below Top 2x rankers", 
    "poll.criteria.Top3x" : "Below Top 3x rankers", 
    "poll.criteria.Top4x" : "Below Top 4x rankers", 
    "poll.delete.title": "Delete Event",
    "poll.delete.message": "All the data of this event will be permanently deleted.",

    "poll.xresult.plabel.tnk" : "Title & Kind",
    "poll.xresult.plabel.qna" : "Question & Candidates",
    "poll.xresult.plabel.gnc" : "Goal & Criteria",
    "poll.xresult.plabel.xns" : "Result & Statistics",
    "poll.xresult.label.residual" : "Residual votes",
    "poll.xresult.label.total" : "Total votes",

    "poll01.ptype.label" : "Type", 
    "poll01.ptitle.label" : "Title", 
    "poll01.pwhen.label" : "When", 
    "poll01.pwhen.rightnow" : "Right now", 
    "poll01.pwhen.rightnow.constraint" : "(expires in an hour after creation)", 
    "poll01.pwhen.rightnow.expire" : "It will be expired at {hh:mm:ss} and deleted", 
    "poll01.pwhen.start" : "Starting at", 
    "poll01.pwhen.period" : "With period", 
    "poll01.pearly.label" : "Early voting", 
    "poll01.pearly.yes" : "Allowed", 
    "poll01.pearly.no" : "Not allowed", 
    "poll01.locale.label" : "Locale", 
    "poll01.poller.label" : "Poller", 
    
    "poll02.title.add" : "Add New Question", 
    "poll02.title.edit" : "Edit Question", 
    "poll02.qkind.label" : "Q.Type", 
    "poll02.qkind.A" : "Approval", 
    "poll02.qkind.S" : "Selection", 
    "poll02.qkind.E" : "Election", 
    "poll02.qkind.C" : "Comment", 
    "poll02.question.example" : "The #{1} question", 
    "poll02.question.label" : "Question", 
    "poll02.question.placeholder" : "Enter the exact question to be asked", 
    "poll02.answers.clabel" : "Answers", 
    "poll02.answers.slabel" : "Alternatives", 
    "poll02.answers.elabel" : "Candidates", 
    "poll02.answers.placeholder" : "Enter one candidate on each line", 
    "poll02.answers.A" : "Yes\nNo", 
    "poll02.answers.S" : "Alternative 1\nAlternative 2", 
    "poll02.answers.E" : "Candidate A\nCandidate B", 
    "poll02.vgoal.label" : "Desired Goal", 
    "poll02.vgoal.maxrounds" : "MaxRounds", 
    "poll02.xresult.label" : "Result:", 
    "poll02.xresult.A" : "Undecided/Approved/Rejected", 
    "poll02.xresult.S" : "Undecided/Selected '{1}'/Selected '{1}' (+{2})", 
    "poll02.xresult.E" : "Undecided/Elected '{1}'/Elected '{1}' (+{2})", 
    "poll02.xresult.C" : "Undecided/Collected {1} comments", 
    "poll02.wcriteria.label.A" : "Criteria", 
    "poll02.wcriteria.label.S" : "Criteria to Win", 
    "poll02.wcriteria.label.E" : "Criteria to Win", 
    "poll02.dcriteria.label" : "Criteria to Drop", 
    "poll02.button.appendnew" : "Add Next Question", 
    "poll02.button.removelast" : "Remove Last Question", 
    "poll02.button.showdetail" : "Show detail", 
    "poll02.button.save" : "Save Changes", 
    "poll02.button.delete" : "Delete Question", 

    "poll03.voter.title" : "{Voter} list", 
    "poll03.voter.direction1" : "Edit the entire list of participants, and click 'Save List' button.", 
    "poll03.voter.direction2" : "Line example : 'John Doe, 20041230, any@example.com' (Name,BDate,Phone,Email)", 
    "poll03.voter.errmsg.no_email" : "No email provided for a participant.", 
    "poll03.voter.errmsg.no_phone" : "No phone number provided for a participant.", 
    "poll03.voter.errmsg.no_name" : "No name provided for a participant.", 
    "poll03.voter.errmsg.empty_list" : "Empty list of participants.", 
    "poll03.voter.action.add" : "Add participant", 
    "poll03.voter.action.edit" : "Edit participant", 
    "poll03.voter.action.text" : "Edit entire list as text", 
    "poll03.voter.action.clear" : "Clear entire list", 
    "poll03.voter.button.save" : "Save", 
    "poll03.voter.button.delete" : "Delete", 
    "poll03.voter.label.vid" : "ID", 
    "poll03.voter.label.vname" : "Name", 
    "poll03.voter.label.vcode" : "Code", 
    "poll03.voter.label.vbdate" : "BDate", 
    "poll03.voter.label.vemail" : "Email", 
    "poll03.voter.label.vphone" : "Phone", 

    "poll04.staff.title" : "Staff member list", 
    "poll04.staff.direction1" : "Enter the list of staff members, and click 'Save List' button.", 
    "poll04.staff.direction2" : "Line example : 'John Doe, someone@example.com, S' (Name,Email,Role)", 
    "poll04.staff.errmsg.no_email" : "No email provided for a staff member.", 
    "poll04.staff.errmsg.no_name" : "No name provided for a staff member.", 
    "poll04.staff.errmsg.empty_list" : "Empty list of staff members.", 
    "poll04.staff.action.add" : "Add staff member", 
    "poll04.staff.action.edit" : "Edit staff member", 
    "poll04.staff.action.text" : "Edit entire list as text", 
    "poll04.staff.action.clear" : "Clear entire list", 
    "poll04.staff.button.save" : "Save", 
    "poll04.staff.button.delete" : "Delete", 
    "poll04.staff.label.name" : "Name", 
    "poll04.staff.label.email" : "Email", 
    "poll04.staff.label.role" : "Role", 
    "poll04.staff.label.role.manager" : "Manager", 
    "poll04.staff.label.role.staff" : "Staff", 

    "poller.editevent.event" : "Event info",
    "poller.editevent.qlist" : "Question list",
    "poller.editevent.quest" : "Question detail",

    "poller.getready.voters" : "Registered voters",
    "poller.getready.voters.sum0" : "누구나 참여할 수 있는 이벤트입니다",
    "poller.getready.voters.sum1" : "{1}명의 참가대상자가 등록되었습니다",
    "poller.getready.voters.nt" : "Registered total",
    "poller.getready.voters.nt0" : "등록된 참가대상자가 없으므로, 누구나 참여할 수 있습니다.",
    "poller.getready.voters.nt1" : "등록된 참가대상자들만 인증(체크인)을 거쳐 참여할 수 있습니다.",
    "poller.getready.voters.ne" : "With email address",
    "poller.getready.voters.np" : "With phone numbers",
    "poller.getready.voters.na" : "With email/phone",
    "poller.getready.voters.na0" : "이메일/문자를 이용한 '참가자 온라인 초대' 기능은 사용할 수 없습니다.",
    "poller.getready.voters.na1" : "{1}명의 정보가 부족하므로, '참가자 온라인 초대' 기능은 사용할 수 없습니다.",
    "poller.getready.voters.na2" : "이메일/문자를 이용한 '참가자 온라인 초대' 기능을 사용할 수 있습니다.",
    "poller.getready.voters.list" : "List of registred voters",
    "poller.getready.voters.example" : "Add 5 examples to the list",

    "poller.getready.staffs" : "Staff members",
    "poller.getready.staffs.sum0" : "스태프 지원 없이 스스로 이벤트를 진행합니다",
    "poller.getready.staffs.sum1" : "{1}명의 스태프멤버가 이벤트 진행을 지원합니다",
    "poller.getready.staffs.nm" : "등록된 매니저 수",
    "poller.getready.staffs.nm0" : "나를 대신하여 이벤트를 진행할 수 있으며, 2명 미만이 적절합니다.",
    "poller.getready.staffs.ns" : "등록된 일반 스태프 수",
    "poller.getready.staffs.ns0" : "오프라인에서 참가자들을 지원하며, 적정인원은 참가자 수에 비례합니다.",
    "poller.getready.staffs.list" : "스태프멤버 명단",
    "poller.getready.staffs.example" : "Add 3 examples to the list",

    "poller.getready.pca.title" : "Participant Control & Authentication",
    "poller.getready.pca.sum0" : "옵션 선택 및 비용 지불을 완료하여야 합니다",
    "poller.getready.pca.sum1" : "선택된 옵션에 따라, 비용 지불을 완료해야 합니다",
    "poller.getready.pca.sum2" : "이벤트 개최를 위한 모든 준비가 완료되었습니다",
    "poller.getready.pca.tbd" : "To be determined",
    "poller.getready.pca.n.label" : "참여인원수",
    "poller.getready.pca.n.min" : "최소 참여인원수",
    "poller.getready.pca.n.max" : "최대 참여인원수",
    "poller.getready.pca.n.elg" : "등록된 인원수",
    "poller.getready.pca.inv" : "Invitation",
    "poller.getready.pca.offline" : "오프라인 (QR코드를 게시 또는 배포)",
    "poller.getready.pca.online" : "온라인 (QR코드를 이메일/메시지로 전송)",
    "poller.getready.pca.nmin" : "Min. participants",
    "poller.getready.pca.nmax" : "Max. participants",
    "poller.getready.pca.nmin.desc" : "이벤트가 유효하게 성립하기 위한 최소 조건입니다",
    "poller.getready.pca.nmax.desc" : "초과하는 경우, 참가자의 입장이 제한됩니다",
    "poller.getready.pca.nmax.cost" : "(참가자 당 {1} 기본비용)",
    "poller.getready.pca.nmax.elg" : "Registered: {1}",
    "poller.getready.pca.comm.cost" : "(참가자 당 {1} 추가비용)",
    "poller.getready.pca.auth" : "Authentication",
    "poller.getready.pca.auth.qr" : "QR",
    "poller.getready.pca.auth.allow" : "Allow",
    "poller.getready.pca.auth.check" : "Check",
    "poller.getready.pca.auth.simplicity" : "Simplicity",
    "poller.getready.pca.auth.security" : "Security",
    "poller.getready.pca.auth.A" : "Anyone",
    "poller.getready.pca.auth.E" : "Registered",
    "poller.getready.pca.auth.1" : "Shared",
    "poller.getready.pca.auth.9" : "Individual",
    "poller.getready.pca.auth.__" : "None",
    "poller.getready.pca.auth.n_" : "Name",
    "poller.getready.pca.auth.nc" : "Name,Passcode",
    "poller.getready.pca.pcode" : "Personal passcode",
    "poller.getready.pca.pcode.c" : "공통 식별코드 사용",
    "poller.getready.pca.pcode.i" : "개인 {1}의 마지막 4자리 숫자/문자",
    "poller.getready.pca.pcode.r" : "개인별 임의생성 후 온라인 전송",
    "poller.getready.pca.price.label" : "Service cost",
    "poller.getready.pca.price.total" : "Total price",
    "poller.getready.pca.price.topay" : "Actual price to pay",
    "poller.getready.pca.fq.rem" : "Remaining free quota",
    "poller.getready.pca.fq.after" : "Free quota after purchase",
    "poller.getready.pca.fq.payable" : "Usability of free quota",
    "poller.getready.pca.fq.yes" : "Usable",
    "poller.getready.pca.fq.no" : "Not usable",
    "poller.getready.pca.button.edit" : "Edit Options",
    "poller.getready.pca.paid" : "결재가 완료되었습니다",

    "poller.getready.payfq.title" : "Buy with Free Quota",
    "poller.getready.payfq.remaining" : "Remaining FQ",
    "poller.getready.payfq.tobeused" : "FQ To be used",
    "poller.getready.payfq.newprice" : "Price to Pay",
    "poller.getready.payfq.button" : "Buy",

    "poller.getready.paymn.title" : "Payment",

    "poller.notify.boardtitle" : "Notification",
    "poller.xreport.boardtitle" : "Final Report",

    "staff.entrance.boardtitle" : "Poll Entrance",
    "staff.eligibility.boardtitle" : "Eligibility Check",
    "staff.votehelper.boardtitle" : "Vote Helper",
    "staff.watchpoll.boardtitle" : "Watch Poll",
    "staff.xresult.boardtitle" : "Final Result",

    "monitor.event.poller.label": "Poller",
    "monitor.event.eligibles.label": "{Voter}",
    "monitor.event.attendee.label": "{Voter}",
    "monitor.event.myname.label": "My name",
    "monitor.opening.tremain": "This event has to be started in an hour,\nor it will be deleted automatically.\nRemaining time : {1}",
    "monitor.opening.texpire": "본 이벤트는 생성 후 한 시간 내에 시작되어야 하며,\n기한을 초과하였으므로 잠시 후 자동 삭제됩니다.",
    "monitor.opening.vnotice": "This event will be started soon.\nPlease wait.",
    "monitor.opening.vexpire": "이벤트가 기한 내에 시작되지 않아\n자동 취소되었습니다.",
    "monitor.quest.selectfirst": "Select the first question to be asked",
    "monitor.quest.selectnext": "Select the next question to be asked",
    "monitor.quest.planned": "The following questions are planned to be asked",
    "monitor.quest.voting.asked": "Voting started for the question below",
    "monitor.quest.voting.now": "Please vote now",
    "monitor.quest.chosen.A": "Chosen answer",
    "monitor.quest.chosen.S": "Chosen answers so far",
    "monitor.quest.chosen.E": "Chosen candidates so far",
    "monitor.quest.goal.label" : "Goal",
    "monitor.quest.goal.A" : "Select YES or NO",
    "monitor.quest.goal.S" : "Select {1} among {2}",
    "monitor.quest.goal.E" : "Select {1} among {2}",
    "monitor.quest.goal.roundmax" : "Max round",
    "monitor.quest.goal.total" : "Total",
    "monitor.quest.goal.selected" : "Selected",
    "monitor.quest.goal.dropped" : "Dropped",
    "monitor.quest.tally.count" : "Tallying up the votes",
    "monitor.quest.tally.result" : "Vote tally result",
    "monitor.quest.result.chosen" : "Chosen",
    "monitor.quest.result.todo" : "To do",
    "monitor.quest.todo.done" : "Achieved the goal",
    "monitor.quest.todo.fail0" : "미결정 상태로 완료되었습니다",
    "monitor.quest.todo.fail1" : "일부({1}) 미결정 상태로 완료되었습니다",
    "monitor.quest.todo.A" : "Voting again in the next round",
    "monitor.quest.todo.S" : "Select {1} more in the next round",
    "monitor.quest.todo.E" : "Elect {1} more in the next round",
    "monitor.progress.checkin": "Attendee check-in progress",
    "monitor.progress.checked": "Minimum number of attendees ({1}) satisfied",
    "monitor.progress.voting": "Voting in progress",
    "monitor.progress.rresult.ends": "Round {1} : {2} selected",
    "monitor.progress.rresult.endf": "Round {1} : {2} selected, closing with {3} undecided",
    "monitor.progress.rresult.next": "Round {1} : {2} selected, next round for {3} more",
    "monitor.result.title": "Final Results",
    "monitor.result.nochosen.A": "Undecided",
    "monitor.result.nochosen.S": "No answer chosen",
    "monitor.result.nochosen.E": "No candidate chosen",
    "monitor.action.event.open" : "Open event",
    "monitor.action.event.start" : "Start event",
    "monitor.action.event.close" : "Close event",
    "monitor.action.event.final" : "Final results",
    "monitor.action.quest.close" : "Close question",
    "monitor.action.quest.vstart" : "Start voting",
    "monitor.action.quest.vstop" : "Stop voting",
    "monitor.action.quest.nextr" : "Next Round",
    "monitor.action.quest.nextq" : "Next question",
    "monitor.action.castvote" : "Vote",
    "monitor.action.checkout" : "Check out",
    "monitor.waiting.event.checkin" : "Waiting for the voters' check-in",
    "monitor.waiting.event.checkin2" : "Waiting for the other voters' check-in",
    "monitor.waiting.event.start" : "Waiting for the event to be started",
    "monitor.waiting.quest.select" : "Waiting for the next question to be selected",
    "monitor.waiting.quest.close": "Waiting for the question to be closed",
    "monitor.waiting.quest.vstart" : "Waiting for the voting to be started",
    "monitor.waiting.quest.vstop" : "Waiting for the other voters to vote",
    "monitor.splash.loading": "Loading event ...",
    "monitor.splash.badaddr": "Sorry, it's not a valid entrance.",
    "monitor.splash.checkin": "You need to check-in first.",
    "monitor.splash.closed": "The event has been closed.",
    "monitor.splash.thankyou": "Thank you for participating.",

    "viewer.button.qst.next" : "Next",
    "viewer.button.qst.prev" : "Prev",

    "entrance.qrcode.for.transfer" : "Open on another device",
    "entrance.qrcode.instruct.user" : "Click or scan this QR-code with Camera app.",
    "entrance.qrcode.instruct.voter" : "Scan the QR-code below,\nusing Camera app on your device.",

    "voter.checkin.invitation": "'{1}'\nhave invited you to the event\n\"{2}\"",
    "voter.checkin.direction.nc": "Enter your name and passcode for eligibility check",
    "voter.checkin.direction.n": "Enter your name for eligibility check",
    "voter.checkin.label.name": "Name",
    "voter.checkin.label.code": "Personal Code",
    "voter.checkin.button": "Participate",
    "voter.checkin.err.pnotfound": "The event cannot be found.",
    "voter.checkin.err.pnotopen": "The event is not open yet.",
    "voter.checkin.err.pclosed": "The event has been closed.",
    "voter.checkin.err.vnotfound": "The name is not registered for this event.",
    "voter.checkin.err.vexlimit": "Maximum number of participants ({1}) has been reached.",
    "voter.checkin.nav.label": "More about Pollerz.net?",
    "voter.checkin.nav.button": "Visit homepage",
    "voter.vcast.err.duplicate": "You have voted already.",
    "simulate.rehearsal.title": "You are opening the event,\n'{1}',\nto test with vote simulation.",
    "simulate.rehearsal.poller": "Poller",
    "simulate.rehearsal.nvoters": "Voters",
    "simulate.rehearsal.vmethod": "Vote Simulation",
    "simulate.trial.asvoter.title": "You are invited to an event,\n\"{1}\",\nfor a trial as voter.",
    "simulate.trial.aspoller.title": "You are organizing the trial event,\n\"{1}\".",
    "simulate.trial.aspoller.instr0": "Plan and prepare the event.\n(1 / 3)",
    "simulate.trial.aspoller.instr1": "Invite voters for the event.\n(2 / 3)",
    "simulate.trial.aspoller.instr2": "Open and run the event.\n(3 / 3)",
    "simulate.trial.example.name": "John Doe",
    "simulate.trial.title": "You have an event with agenda prepared,\n\"{1}\".\nNow run the event.",
    "simulate.trial.label": "Enter your name as the host of event",
    "simulate.trial.openevent": "Open the event",
    "simulate.trial.startevent": "Start the event",
    "simulate.comment": "This event is just a simulation, and will not be saved.",

};

export default localeStrMap;