
import axios from "axios";

const pzconfig = {              // this will be updated with PzServer settings, called by 'updatePzConfig()'
    criteria: {
        criteriaToWin:  ["Majority", "Most", "N > 1/2"],  // 
        criteriaToDrop: ["Zero", "Least", "N < 1/100"],  // 
    },
    locales: {
        en: { short:'English', long:'English',          default_country_num: 1 },
        es: { short:'español', long:'español (Spanish)', default_country_num: 1 },
        ko: { short:'한국어',    long:'한국어 (Korean)',    default_country_num: 82 },
    },
    pricing: {
        priceCurrency: 'USD',   // 
        pricePerVoter: 0.02,    // 
        priceForOnInv: 0.04,    // 
        formatter: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }),
    },
    pzserver: "localhost",
    ninfinity: -999,
    updated: false,
};

const pzhost = (window.location.hostname==='localhost' ? 'localhost' : window.location.hostname==='pollerz.net' ? 'pollerz.net' : "localip");
let   pzserverInUse = (window.location.hostname); 
let   baseUrlForWebAppEntrance = (pzhost==='localhost' ? "http://localhost:3000" : pzhost==='localip' ? "http://"+window.location.hostname : "https://pollerz.net"); 
let   baseUrlForEventEntrance  = (pzhost==='localhost' ? "http://localhost:80"   : pzhost==='localip' ? "http://"+window.location.hostname : "https://pollerz.net");
let   baseUrlForAPI = (pzhost==='pollerz.net' ? '' : pzhost==='localhost' ? "http://localhost:80" : "http://"+window.location.hostname+":80");
let   pzapi = axios.create({ baseURL: baseUrlForAPI, withCredentials: true });

const appConfig = {

    project: "Pollerz.net",
    version: "ALPHA",
    maxLenTitle: 20,

    // Service Configuration

    getPzServer: ()=>{return pzconfig.pzserver;},
    getAPI: ()=>{return pzapi;},
    getBaseUrlForWebAppEntrance: ()=>{return baseUrlForWebAppEntrance;},
    getBaseUrlForEventEntrance:  ()=>{return baseUrlForEventEntrance;},
    getBaseUrlForAPI:            ()=>{return baseUrlForAPI;},
    getNInfinity:                ()=>{ return pzconfig.ninfinity; },

    // Locale

    getSupportedLocales: ()=>{return Object.keys(pzconfig.locales);},
    getLanguageFromLocale: (locale, long=false)=>{
        let loc = pzconfig.locales[locale ? locale : 'en']
        return (long ? loc?.long : loc?.short);
    },
    getCountryNumberFromLocale: (locale)=>{
        let loc = pzconfig.locales[locale ? locale : 'en']
        return loc.default_country_num;
    },

    // Price
    
    getFormattedPrice: (price)=>{return pzconfig.pricing.formatter.format(price);},
    getPricePerVoter: (fmt=false)=>{let price = pzconfig.pricing.pricePerVoter; return (!fmt ? price : pzconfig.pricing.formatter.format(price));},
    getPriceToInvite: (fmt=false)=>{
        let price = pzconfig.pricing.priceForOnInv;
        return (!fmt ? price : pzconfig.pricing.formatter.format(price));
    },
    getTotalPrice: (nmax, invitation='offline', fmt=false)=>{
        const extraPrice = (invitation === 'online' || invitation === '1' ? pzconfig.pricing.priceForOnInv : 0.00);
        let price = (nmax * (pzconfig.pricing.pricePerVoter + extraPrice));
        return (!fmt ? price : pzconfig.pricing.formatter.format(price));
    },
    isFreeQuotaUsable: (nmax, qtrem, invitation)=>{ 
        return (qtrem < 0 || nmax <= qtrem) && (invitation !== 'online' || invitation === '1'); 
    },

    // Criteria List 

    getCriteriaListToWin: ()=>{ return pzconfig.criteria.criteriaToWin; },
    getCriteriaListToDrop: ()=>{ return pzconfig.criteria.criteriaToDrop; },
    
    // 
    updatePzConfig: ()=>{
        if (pzconfig.updated) return;
        pzapi.get("/api/pz/config")
        .then((response) => {
            const pzdata = response.data;     // {respcode:0, respmsg:'success', resource:'https//pollerz.net'}
            const criteria = pzdata.resource.criteria;
            const pricing  = pzdata.resource.pricing;
            pzconfig.criteria.criteriaToWin = criteria.criteria_to_win;
            pzconfig.criteria.criteriaToDrop = criteria.criteria_to_drop;
            pzconfig.criteria.updated = true;
            pzconfig.locales = pzdata.resource.locales;
            pzconfig.pricing.priceCurrency = pricing.price_currency;
            pzconfig.pricing.pricePerVoter = pricing.price_per_voter;
            pzconfig.pricing.priceForOnInv = pricing.price_for_oninv;
            pzconfig.pricing.updated = true;
            pzconfig.pzserver = pzdata.resource.pzserver;
            pzconfig.ninfinity = pzdata.resource.ninfinity;
            if (pzconfig.pzserver !== pzserverInUse) {
                if (pzconfig.pzserver === 'pollerz.net') {   // PRODUCTION server
                    baseUrlForWebAppEntrance = 'https://pollerz.net';                     // URL : 'https://pollerz.net/auth/login'
                    baseUrlForEventEntrance  = 'https://pollerz.net';                                        // URL : '/api/auth/login'
                } else {  // IP_ADDRESS of DEVELOPMENT server
                    baseUrlForWebAppEntrance = baseUrlForWebAppEntrance.replace("localhost", pzconfig.pzserver).replace(":3000", ":80");  
                    baseUrlForEventEntrance  = baseUrlForEventEntrance.replace("localhost", pzconfig.pzserver);  
                }
                pzserverInUse = pzconfig.pzserver;
                // console.log("baseUrlForWebAppEntrance", baseUrlForWebAppEntrance);
                // console.log("baseUrlForEventEntrance", baseUrlForEventEntrance);
                // console.log("baseUrlForAPI", baseUrlForAPI);
            }
            pzconfig.updated = true;
            // console.log("PzConfig updated : ", pzdata.resource);
        })
        .catch((error) => { console.log("PzConfig update failure : ", error); })
        .finally(() => {});
    },
};

appConfig.updatePzConfig();

export default appConfig;
