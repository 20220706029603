import React from 'react'
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { ReactComponent as IconChevronRight } from "../../resource/icons/pz-chevron-right.svg";
import { ReactComponent as IconVoidEmpty } from "../../resource/icons/pz-void-empty.svg";
import EvtEditorForKTW from "../../eventview/EvtEditorForKTW";
import EvtEditorForQstList from "../../eventview/EvtEditorForQstList";
import EvtEditorForVoterList from "../../eventview/EvtEditorForVoterList";
import PzButton from "../../common/PzButton";
import PzSymbol from "../../common/PzSymbol";
import PzBasicModal from "../../common/PzBasicModal";
import EventCard from "../../eventview/EventCard";

export default function ModalToCreateEvent({ onClose=()=>{}, onSuccess=(new_pguid)=>{} }) {
    const [cookies] = useCookies(['uauth']);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const ulocale = useSelector((state)=>state.ucfg.locale);
    const localeMap = useSelector(utils.selectLocaleMap);
    const yesno = [ 'BLANK', ...(localeMap["poll02.answers.A"] ? localeMap["poll02.answers.A"].split('\n') : []) ];
    const p0 = {
        ptitle:'', pwhen:'', pkind:'PRA', pstatus:'A ', 
        uname:uauth?.uname, xrole:'P', locale:ulocale, nelg:0, nmax:0, natt:0, nstaff:0,
        pqstlist:[{qseq:1, qkind:'A', question:'', qroundmax:1, answers: yesno, nanswers:2, nwanted:1, qoption:{c2win:'Most',c2drop:'Zero'}}],
        voterlist: [],
    };
    const [poll, setPoll] = React.useState(p0);
    const lastVStep = (poll.ptitle === '' ? 1 : (!poll.pqstlist || poll.pqstlist.length < 0 || poll.pqstlist[0].q === '' ? 2 : 4))
    const [currStep, setCurrStep] = React.useState(1);           // current step
    const [maxVStep, setMaxVStep] = React.useState(lastVStep);   // maximum valid step
    const [errMsg, setErrMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const steps = [
        {step:1, title:"poll.create.step1", Component: EvtEditorForKTW},
        {step:2, title:"poll.create.step2", Component: EvtEditorForQstList},
        {step:3, title:"poll.create.step4", Component: EvtEditorReviewer},
    ];

    function isGoNextDisabled() {
        if (currStep === 1) {
            if (poll.ptitle === '') return true;
        } else {
            if (poll.ptitle === '') return true;
            for (let i = 0; i < poll.pqstlist.length; i++) {
                if (poll.pqstlist[i].question === '') return true;
            }
        }
        return false;
    }
    function onClickToGoBack() { 
        // console.log(poll);
        if (currStep > 1) setCurrStep(currStep-1); 
        setErrMsg('');
    }
    function onClickToGoNext() { 
        // console.log(poll);
        if (currStep < steps.length) setCurrStep(currStep+1); 
        setErrMsg('');
    }
    
    function getPwhenString() {
        if (poll.ptitle === '') {
            return '';
        } else if (poll.pwhen === '') {
            return localeMap["poll01.pwhen.rightnow"];
        } else {
            return dayjs(poll.pwhen).format('YYYY-MM-DD HH:mm');
        }
    }

    function postCreatePoll() {
        if (poll.ptitle === '') { setErrMsg('Event title is empty'); return; }
        for (let i = 0; i < poll.pqstlist.length; i++) {
            if (poll.pqstlist[i].question === '')   { setErrMsg(`Q${i+1} question is empty`); return; }
        }
        setLoading(true);
        appConfig.getAPI().post("/api/mp/create", poll)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI 'mp/create' success : ", pzdata);
            if (typeof onSuccess === 'function') onSuccess(pzdata.resource);
            if (typeof onClose === 'function') onClose();
        })
        .catch((error) => {
            console.log("PzAPI 'mp/create' error : ", error);
            if (error.code === 'ERR_NETWORK') {
                setErrMsg(localeMap["errmsg.network"]); 
            } else if (error.response && error.response.status === 400) { // BAD_REQUEST
                const pzdata = error.response.data;     // {respcode:0, respmsg:'success', resource:{...}}
                switch (pzdata.respcode) {
                    // case 21 : // "user not found"
                    //     setErrMsg(localeMap["comm.create.errmsg.nouser"]); 
                    //     break;   
                    // case 22 : // failed to create poll
                    //     setErrMsg(localeMap["comm.create.errmsg.missxx"]);
                    //     break;   
                    default:
                        setErrMsg(pzdata.respmsg);
                        break;   
                }
            } else {
                setErrMsg(localeMap["errmsg.unknown"] + ` (${error.code})`); 
            }
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <PzBasicModal onClose={onClose} xclose={true} >

            <div className="w-[36rem] p-8 flex flex-col">

                {/* Title */}
                <div className={`flex flex-row justify-between items-baseline`}>
                    <div className="flex flex-row justify-start items-center gap-x-3">
                        { poll.ptitle !== '' && 
                            <PzSymbol.PKind pkind={poll.pkind?.substring(0,1)} tooltip={localeMap["comm.pkind."+poll.pkind?.substring(0,2).toLowerCase()]} />
                        }
                        <h2 onClick={()=>{console.log(poll);}}>
                            {poll.ptitle !== '' ? poll.ptitle : localeMap["poll.create_event"] }
                        </h2>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-x-3 pr-10" >
                        { poll.ptitle !== '' && 
                            <PzSymbol.XRole xrole={poll.xrole} tooltip={poll.xrole === 'P' ? 'Poller' : poll.xrole === 'M' ? 'Manager' : 'Staff'} />
                        }
                        <h5>{getPwhenString()}</h5>
                    </div>
                </div>
                <hr className="mt-1 mb-8" />

                {/* Step 1/2/3/4 */}
                <div className="flex flex-col justify-start items-stretch">
                    {steps.map(s =>
                        <div key={s.step} className="bg-white grid grid-cols-[1.3rem_1fr] justify-center items-stretch gap-x-2 gap-y-0.5">
                            <div className="w-5 h-5 mt-1 flex flex-shrink-0 items-center justify-center rounded-full bg-slate-600 text-white hover:cursor-pointer"
                                onClick={()=>{setCurrStep(s.step);}}>                                
                                <h5>{s.step}</h5>                                
                            </div>
                            <span className={"text-lg hover:cursor-pointer " + (currStep === s.step ? 'font-bold' : '')} onClick={()=>{setCurrStep(s.step);}}>
                                {utils.completeLocaleMapText(localeMap, s.title, [], poll.pkind)}
                            </span>

                            <div className="mb-1 flex justify-center items-stretch">
                                <h5 className="w-[0.1rem] h-full bg-slate-900">{""}</h5>
                            </div>
                            <div className={`mb-1 ${currStep === s.step ? `p-3` : `p-1.5`}`}>
                                <div className={`${currStep === s.step ? `` : `hidden`}`}>

                                    <s.Component poll={poll} setPoll={setPoll} size='md' onChange={()=>{setErrMsg('');}}/>

                                </div>
                            </div>
                        </div>
                    )}
                    {/* Error message of Poll Creation */}
                    <label className="text-sm text-right text-slate-500 px-2">
                        {errMsg}
                    </label>
                </div>

                {/* Prev/Next Buttons */}
                <hr className="mt-8 mb-3" />
                <div className="flex flex-row justify-between items-center">
                    { true && /* 'Prev' Button */
                        <PzButton.Default onClick={onClickToGoBack} className={currStep > 1 ? '' : 'invisible'}>
                            <IconChevronRight width={20} height={20} className="rotate-180"/>
                            <h5>{localeMap["poll.create.button.prev"]}</h5>
                            <IconVoidEmpty width={20} height={20}/>
                        </PzButton.Default>
                    }
                    {/* Step indicator */}
                    <h5>{currStep} / {steps.length}</h5>
                    {/* 'Next' Button */}
                    {currStep < steps.length ?
                        <PzButton.Default onClick={onClickToGoNext} disabled={isGoNextDisabled()} >
                            <IconVoidEmpty width={20} height={20}/>
                            <h5>{localeMap["poll.create.button.next"]}</h5>
                            <IconChevronRight width={20} height={20} className=""/>
                        </PzButton.Default>
                        :
                        <PzButton.Default onClick={postCreatePoll} loading={loading} disabled={isGoNextDisabled()} >
                            <IconVoidEmpty width={20} height={20}/>
                            <h5>{utils.completeLocaleMapText(localeMap, "poll.create.button.create", [], poll.pkind)}</h5>
                            <IconVoidEmpty width={20} height={20}/>
                        </PzButton.Default>
                    }
                </div>
            </div>
            
        </PzBasicModal>
    );

}

function EvtEditorReviewer({ poll }) {

    if (!poll) return (<div></div>);
    return (
        <div className="flex flex-col justify-start items-stretch gap-2">

            <EventCard key={poll.pguid} poll={poll} viewable={false} foldable={false} />

        </div>
    );
}

