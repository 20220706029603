import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { utils } from "../config/userConfig";
import { setUPoll } from "../config/upollConfig";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import { ReactComponent as IconUser } from "../resource/icons/pz-user-b1.svg";
import { ReactComponent as IconLicense } from "../resource/icons/pz-license.svg";
import { ReactComponent as IconLogOut } from "../resource/icons/pz-logout.svg";
import PzButton from "../common/PzButton";
import PzDropdown from "../common/PzDropdown";

export default function NavbarItemLoggedIn() {
    const [cookies, removeCookie] = useCookies();
    const dispatch = useDispatch();
    const localeMap = useSelector(utils.selectLocaleMap);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    let   userid = getUseridFromCookies(cookies);

    function getUseridFromCookies(cookies) {
        let uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
        let uname = (uauth?.uname ? uauth.uname : "unknown");
        return uname;
    }

    function onLogout() {
        setOpen(false);
        removeCookie("uauth", null);    // Session closed
        dispatch(setUPoll(null));       // clear the current poll selection
        navigate('/');                  // 
        // navigate(0);                 // let the current page reload
    }

    // React.useEffect(() => {
    //     userid = getUseridFromCookies(cookies);
    // }, [cookies]);

    return (
        <PzDropdown 
            open={open} setOpen={setOpen} ha={'right'}
            button={
                <PzButton.Minimal className="px-1" gap="gap-1" >
                    <IconUser width="1.2rem" height="1.2rem" />
                    <h5>{userid}</h5>
                    <IconChevron width="1.2rem" height="1.2rem" className={`${open && `rotate-180`}`} />
                </PzButton.Minimal>
            } >
            <ul className="w-full p-2 flex flex-col justify-start items-start text-center gap-0">
                <li>
                    <PzButton.Minimal className="w-full px-2 py-1" gap="gap-2" onClick={()=>{navigate("/pages/myaccount"); setOpen(false);}}>
                        <IconLicense width="1.2rem" height="1.2rem" />
                        <h5 className="w-full text-left">{localeMap["menu.myaccount"]}</h5>
                    </PzButton.Minimal>
                </li>
                <li>
                    <PzButton.Minimal className="w-full px-2 py-1" gap="gap-2" onClick={() => onLogout()} >
                        <IconLogOut width="1.2rem" height="1.2rem" />
                        <h5 className="w-full text-left">{localeMap["menu.auth.logout"]}</h5>
                    </PzButton.Minimal>
                </li>
            </ul>
        </PzDropdown>
    );
    
}
