import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import EventCard from "../../eventview/EventCard";
import UserSplash from "../UserSplash";
import { ReactComponent as PzStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as PzStepPrev } from "../../resource/icons/pz-step-prev.svg";

export default function Notification() {
    const [cookies] = useCookies();
    const navigate = useNavigate();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);

    // React.useEffect(() => {
    //   uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    //   if (!uauth) navigate("/");
    // }, [cookies]);

    const etype = (upoll ? localeMap["comm.event."+upoll.pkind[0].toLowerCase()] : undefined);
    const title = (etype ? `[${etype}] ` : '') + localeMap["menu.poller.notify"];

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <PzArea.InvisibleBoardInContainer>

            <EventCard poll={upoll} viewable={false} foldable={true} />

            <PzArea.FullBoardWithTitle title={title} >

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <h3>{localeMap["poller.getready.voters"]}</h3>
                    <p>&nbsp;</p>
                </div>

                <div className="w-full flex flex-row justify-between items-center gap-5 mt-10">
                    <PzButton.Std Icon={PzStepPrev} text={localeMap["menu.poller.getready"]} size='lg' onClick={()=>{navigate("/pages/poller/getready");}}/>
                    <PzButton.Std Icon={PzStepNext} text={localeMap["menu.poller.runpoll"]}  size='lg' onClick={()=>{navigate("/pages/poller/runpoll");}} disabled={upoll.pstauts < 'Ax'}/>
                </div>

            </PzArea.FullBoardWithTitle>

        </PzArea.InvisibleBoardInContainer>
    );
}
