import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzInput from "../../common/PzInput";
import PzTable, {PztShortNavigate} from "../../common/PzTable";

export default function PzAdminViewLogs() {
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const [nlines, setNLines] = React.useState(-40);
    const [substr, setSubstr] = React.useState('');
    const [logs, setLogs] = React.useState([]);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);

    const fetchLogs = ()=>{
        appConfig.getAPI().get(`/api/sys/status/log?n=${nlines}&s=${substr}`)
        .then((response)=>{
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setLogs(pzdata.resource);
            console.log(`PzAPI '/api/sys/status/log' returned ${pzdata.resource.length} log entries`);
        })
        .catch((error)=>{
            console.log("PzAPI '/api/sys/status/log' error : ", error);
        })
        .finally(()=>{});
    };

    React.useEffect(() => {
        if (!uauth?.ispza) { navigate("/"); return; }
        fetchLogs();
    }, []);

    return (
        <PzArea.Container>

        {!cookies?.uauth || !jwtDecode(cookies.uauth)?.ispza ? null : (
            <PzArea.AdminBoard >
                <h3>{"PzA - PzServer Logs"}</h3>

                <div className="grid grid-cols-[auto_60px_auto_1fr_auto] justify-start items-center gap-5">
                    <h5>NLines: </h5>
                    <PzInput.ForInt  value={nlines} onChangeDone={(text)=>{setNLines(Number(text));}} min={-500} max={+500} />
                    <h5>Substr: </h5>
                    <PzInput.ForText value={substr} onChangeDone={(text)=>{setSubstr(text);}} />
                    <PzButton.Std text='Search' size='lg' onClick={fetchLogs} />
                </div>

                <div>
                    { logs.map((e, idx)=>(<pre key={idx} className="font-mono text-xs">{e}</pre>))}
                </div>
            </PzArea.AdminBoard>
        )}
        
        </PzArea.Container>
    );
}
