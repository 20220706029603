import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import EventCard from "../../eventview/EventCard";
import UserSplash from "../UserSplash";

export default function VoteHelper() {
    const [cookies] = useCookies();
    const navigate = useNavigate();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);

    // React.useEffect(() => {
    //   uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    //   if (!uauth) navigate("/");
    // }, [cookies]);

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'S' && upoll.xrole !== 'M' && upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event"} /> );
    }  

    return (
        <PzArea.InvisibleBoardInContainer>

            <EventCard poll={upoll} viewable={false} foldable={true} />

            <PzArea.FullBoardWithTitle title={localeMap["menu.staff.votehelper"]} >
            </PzArea.FullBoardWithTitle>

        </PzArea.InvisibleBoardInContainer>
  );
}
