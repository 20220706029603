import React from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { ReactComponent as IconButtonClose } from "../resource/icons/pz-button-close.svg";
import PzButton from "./PzButton";

export default function PzBasicModal({ children, title='', width='', onClose=()=>{}, xclose=false }) {
    const cancelButtonRef = React.useRef(null);

    return (
        <Transition.Root show={true} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
                <Transition.Child as={React.Fragment}
                    enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100"
                    leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity select-auto" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center text-center sm:items-center">
                    <Transition.Child as={React.Fragment}
                        enter="ease-out duration-300" enterFrom="opacity-0 scale-70" enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-70" >
                        <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all">
                            { xclose &&
                                <div className="absolute right-5 top-5 bg-slate-100 text-slate-400 rounded-lg hover:bg-slate-200 hover:text-slate-800" 
                                    onClick={onClose}>
                                    <IconButtonClose width={20} height={20} />
                                </div>
                            }
                            <div className="bg-white">

                                { title !== '' &&
                                    <div className={`flex flex-row justify-between items-baseline mb-5`}>
                                        <h3>{title}</h3>
                                    </div>
                                }

                                {children}

                            </div>

                        </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
