import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import Fab from '@mui/material/Fab';

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { vutils } from "../../config/voterConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import AzInputForName from "../../auth/azcomp/AzInputForName";
import AzButtonToNavigate from "../../auth/azcomp/AzButtonToNavigate";
import EMWithPzServer from "../../eventmonitor/EMWithPzServer";
import EMWithSimulator from "../../eventmonitor/EMWithSimulator";
import EMSplash from "../../eventmonitor/EMSplash";

export default function SimCheckinAsVoter() {
    const [localeMap, setLocaleMap] = React.useState(utils.selectMapByLocale('en'));
    const [cookies, removeCookie] = useCookies(['vauth']);
    const vauth = (cookies && cookies.vauth ? jwtDecode(cookies.vauth) : null);
    const navigate = useNavigate();
    const [qparams] = useSearchParams();
    const pguid = qparams.get("p");
    const [intro, setIntro] = React.useState({pguid:pguid});
    const [vname, setVName] = React.useState({value:'', errmsg:''});
    const [dirMsg, setDirMsg] = React.useState('');
    const [errMsg, setErrMsg] = React.useState('');
    const [splashMsg, setSplashMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        // removeCookie("vauth", null);
        if (vauth && vauth.pguid === pguid) {
            navigate("/y/vote?p=" + pguid);
        } else {
            fetchIntro();
        }
    }, []);

    const fetchIntro = ()=>{
        if (pguid === 'THANKYOU') {
            setSplashMsg("THANKYOU");
            return;
        } else if (!pguid || pguid.length !== 32) {
            setSplashMsg("Sorry, the address is not a valid entrance.");
            return;
        }
        setSplashMsg('Loading intro...');
        appConfig.getAPI().post("/api/v/intro", {pguid:pguid})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const intro  = pzdata.resource; // {}
            let new_localeMap = utils.selectMapByLocale(intro.locale);
            setIntro(intro);
            setVName({value:'', errmsg:''});
            setLocaleMap(new_localeMap);
            setDirMsg(new_localeMap['voter.checkin.direction.n'])
            setSplashMsg(''); 
            if (vname.value === '') setVName({value:localeMap['simulate.trial.example.name'], errmsg:''});
        })
        .catch((error) => {
            console.log("PzAPI 'v/intro' error : ", error);
            let respmsg = error.response?.data?.respmsg;
            if (respmsg && (respmsg.includes('pguid invalid') || respmsg.includes('pguid not found'))) {
                setSplashMsg("Sorry, the address is not a valid entrance.");
            } else {
                setSplashMsg(respmsg ? respmsg : '');
            }
        })
        .finally(() => { });
    };
    
    function checkin() {
        const onSuccessToCheckin = (respval)=>{
            setTimeout(()=>{
                EMWithSimulator.addVoterToVoterList(intro, respval.vid, vname);
                navigate("/y/vote?p=" + pguid);
                setLoading(false);
            }, 1000); // wait for a second
        };
        const onErrorToCheckin = (respmsg, respval)=>{
            if (!respmsg) {
                setErrMsg("respmsg is null for onError()");
            } else if (respmsg.includes('poll not found')) {
                setErrMsg(localeMap["voter.checkin.err.pnotfound"]);
            } else if (respmsg.includes('poll not open')) {
                setErrMsg(localeMap["voter.checkin.err.pnotopen"]);
            } else if (respmsg.includes('poll closed')) {
                setErrMsg(localeMap["voter.checkin.err.pclosed"]);
            } else if (respmsg.includes('voter not found')) {
                setErrMsg(localeMap["voter.checkin.err.vnotfound"]);
            } else if (respmsg.includes('voter limit exceeded')) {
                setErrMsg(utils.completeMessageText(localeMap["voter.checkin.err.vexlimit"], [respval.nmax]));
            } else {
                setErrMsg(respmsg);
            }
            setLoading(false);
        };
        setLoading(true);
        setErrMsg('');
        setTimeout(()=>{
            EMWithPzServer.checkinAsVoter(intro, vname.value, '', onSuccessToCheckin, onErrorToCheckin);
        }, 500); // wait for a second
    };

    // function onSuccessToCheckin2(vid) {
    //     setTimeout(()=>{
    //         setLoading(false);
    //         navigate("/y/vote?p=" + pguid);
    //     }, 1000); // wait for 'vauth' to be completed, and then navigate.
    // }

    const title = utils.completeLocaleMapText(localeMap, 'voter.checkin.invitation', [intro?.poller, intro?.ptitle], intro?.pkind);
    const titles = (title ? title.split("\n") : ['']);
    // console.log("Intro: ", intro);

    if (pguid === 'THANKYOU') {
        let splash_message = localeMap["monitor.splash.thankyou"]
        return (<EMSplash msg={splash_message} gohome={true} xclose={true} />);
    } else if (!intro) {
        return (<EMSplash msg={splashMsg} locale='en' />);
    }

    return (
        <PzArea.Container className="bg-slate-100">
            <PzArea.MwhBoard className='p-20 bg-white select-none' xclose={true} >

                <div>&nbsp;</div>

                <div className="w-full flex flex-col justify-end items-start py-4 gap-10">

                    <div className="w-full flex flex-col justify-start items-center gap-1">
                        {titles.map((title, idx)=>{
                            return(<h1 key={idx}>{title}</h1>);
                        })}
                    </div>

                    <div className="w-full flex flex-col justify-start items-center gap-1">
                        <p className={`flex flex-row justify-center text-slate-500 `}>
                            &nbsp;{dirMsg}&nbsp;
                        </p>
                        <div className="w-[20rem] flex flex-col justify-start items-stretch gap-1">
                            <AzInputForName  v={vname} setV={setVName} 
                                maxLength={30} 
                                onChange={()=>{if (errMsg!=='') setErrMsg('');}}
                                placeholder={localeMap['voter.checkin.label.name']} />
                        </div>
                    </div>

                    <div className="w-full flex flex-col justify-start items-center gap-2">
                        <p className="flex flex-row justify-center text-slate-500" >
                            &nbsp;{errMsg}&nbsp;
                        </p>

                        <PzButton.Std className="w-[20rem]" text={localeMap["voter.checkin.button"]} size='2xl' 
                            onClick={()=>{checkin()}} loading={loading} disabled={vname.value.trim()==='' || loading || vauth} />
                    </div>

                    <div className="w-full flex flex-col justify-start items-center mt-10">
                        <h4 className="text-slate-500">Hosted by <em className="font-bold">Pollerz.net</em></h4>
                        {/* <AzButtonToNavigate label={localeMap["voter.checkin.nav.label"]} button={localeMap["voter.checkin.nav.button"]} destination={"/home"} /> */}
                    </div>

                </div>
                
                <div>&nbsp;</div>

                <div className="absolute bottom-5 left-0 w-full text-center" >
                    <h4 className="text-slate-400">{localeMap["simulate.comment"]}</h4>
                </div>

            </PzArea.MwhBoard> 
        </PzArea.Container>
    );

}

export function ButtonForCheckout() {
    const [cookies, removeCookie] = useCookies();
    const vauth = (cookies && cookies?.vauth ? jwtDecode(cookies.vauth) : null);
    const checkins = useSelector((state)=>state.vcfg.checkins);
    const pguid = vauth?.pguid;
    const dispatch = useDispatch();

    function onClickToCheckout() {
        removeCookie("vauth", null);
        // dispatch(vutils.delVoterCheckin(checkins, pguid));
        // dispatch(vutils.setCheckins({}));
        dispatch(vutils.setPGuid(''));
        dispatch(vutils.setVid(''));
    }

    return (
        <Fab variant="extended" size="small" onClick={onClickToCheckout}
            style={{ margin: 0, top: 'auto', right: 'auto', bottom: 20, left: 20, position: 'fixed' }} >
            <label className="font-extrabold text-xs">COut</label>
        </Fab>
    );
}
