import { createSlice } from '@reduxjs/toolkit'

export const upollConfigSlice = createSlice({
  name: 'upcfg',
  initialState: {
      uPoll: null,     // selected (user) poll
  },
  reducers: {
    setUPoll: (state, action) => {
      state.uPoll = action.payload;
    },
  }
})

export const { setUPoll } = upollConfigSlice.actions;

