import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useInterval } from "react-use";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { vutils } from "../../config/voterConfig";
import CheckinSessionHandler from "./CheckinSessionHandler";
import PzArea from "../../common/PzArea";
import EMAreaHeader from "../../eventmonitor/EMAreaHeader";
import EMAreaBodyAsVoter from "../../eventmonitor/EMAreaBodyAsVoter";
import EMAreaFooterAsVoter from "../../eventmonitor/EMAreaFooterAsVoter";
import EMWithPzServer from "../../eventmonitor/EMWithPzServer";
import EMSplash from "../../eventmonitor/EMSplash";


export default function Vote() {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies(['vauth']);
    const [vauth, setVAuth] = React.useState(cookies && cookies.vauth ? jwtDecode(cookies.vauth) : null);
    const [qparams] = useSearchParams();
    const pguid = qparams.get("p");
    const vid = qparams.get("v");
    const [poll, setPoll] = React.useState(null);
    const pqst = (poll?.qactive >= 0 && poll?.pqstlist ? poll?.pqstlist[poll?.qactive] : null);
    const [qIndex, setQIndex] = React.useState(-1);
    const [qRound, setQRound] = React.useState(-1);
    const localeMap = utils.selectMapByLocale(poll?.locale);
    // const dispatch = useDispatch();
    const saved_choices = useSelector((state)=>state.vcfg.choices);
    // const [castChoice, setCastChoice] = React.useState(null);
    // const [currChoice, setCurrChoice] = React.useState([]);
    const [voting, setVoting] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const [svcStatus, setSvcStatus] = React.useState('');
    const [selection, setSelection] = React.useState(null);

    React.useEffect(() => {
        // removeCookie("vauth", null);
        let checkedin = (vauth != null);
        if (vauth && vauth.pguid !== pguid) {
            removeCookie('vauth', null);
            setVAuth(null);
            checkedin = false;
        } 
        if (!checkedin && pguid?.length === 32) { 
            console.log("/v/vote navigate to /v/checkin");
            if (vid) navigate("/v/checkin?p=" + pguid + "&v=" + vid);
            else     navigate("/v/checkin?p=" + pguid);
        }
    }, []);
    
    useInterval(() => {
        updateEventInfo();
    }, 1000);

    function updateEventInfo() {
        if (svcStatus === 'badaddr' || svcStatus === 'checkin') return;
        EMWithPzServer.updateEventInfo(pguid, poll, setPoll2, '/api/v/event', 'V', setSvcStatus);
    }

    function setPoll2(new_poll) {
        // This function is called on poll update is SUCCESS & CHANGED 
        setPoll(new_poll);
        const qactive = (new_poll ? new_poll.qactive : null);
        const qround = (qactive >= 0 && new_poll?.pqstlist ? new_poll?.pqstlist[qactive].qround : null);
        if (qactive !== qIndex || (qactive === qIndex && qround !== qRound)) {
            // the current question & round has been changed
            setQIndex(qactive);
            setQRound(qround);
            let prev_choice = vutils.getChoice(saved_choices, qactive, qround);
            // console.log(typeof prev_choice, prev_choice);
            // setCastChoice(prev_choice);
            // setCurrChoice(prev_choice ? prev_choice : []);
            console.log(`/vote quest changed : qactive=${qactive} qround=${qround} `);
        }
    }

    // function canChoose()    { return (castChoice === null); }
    // function canCastVote()  { return (currChoice && currChoice.length > 0); }

    function castVoteAsVoter() {
        if (!poll) { console.log("poll invalid"); return; }
        if (!pqst) { console.log("pqst invalid"); return; }
        if (qRound < 1) { console.log("qRound invalid"); return; }
        const onSuccess = ()=>{
            if (qIndex < 0) { console.log("qIndex invalid"); return; }
            // update the saved 'choices' array, so that  choices[qactive][qround] = currChoice
            // dispatch(vutils.setChoices(vutils.getNewChoices(saved_choices, qIndex, qRound, currChoice)));
            // console.log("prev choice saved : ", qIndex, qRound, currChoice);
            setVoting(false);
        };
        const onError = (respmsg, respval)=>{
            if (respmsg && respmsg.includes('duplicated')) {
                setErrMsg(localeMap["voter.vcast.err.duplicate"]);
            } else {
                setErrMsg(respmsg);
            }
            setVoting(false);
        };
        setVoting(true);
        setErrMsg('');
        EMWithPzServer.castVoteAsVoter(vauth.vid, poll, setPoll, pqst, qRound, selection, onSuccess, onError);
    }

    if (pguid === 'THANKYOU') {
        return (<EMSplash msg={localeMap["monitor.splash.thankyou"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'badaddr') {
        return (<EMSplash msg={localeMap["monitor.splash.badaddr"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'checkin') {
        return (<EMSplash msg={localeMap["monitor.splash.checkin"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'closed') {
        return (<EMSplash msg={localeMap["monitor.splash.closed"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'loading') {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    } else if (svcStatus  != '') {
        return (<EMSplash msg={svcStatus} />);
    } else if (!poll) {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    }

    return (
        <PzArea.Container>
            <PzArea.MwhBoard  className="px-5 pt-10 pb-20 select-none">

                <EMAreaHeader        poll={poll} className="mt-5" asVoter={true} />

                <EMAreaBodyAsVoter   poll={poll} pqst={pqst} selection={selection} setSelection={setSelection} />

                <EMAreaFooterAsVoter poll={poll} pqst={pqst} selection={selection} setPoll={setPoll} castVote={castVoteAsVoter} />

            </PzArea.MwhBoard >

            { vauth &&    // this component will automatically terminate the session, or let user extend it
                <CheckinSessionHandler vauth={vauth} />     
            }

        </PzArea.Container>
    );

}

