import React from "react";
import { useInterval } from "react-use";
import { useSelector, useDispatch } from "react-redux";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";

export default function PublicPoll() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const timezone = useSelector((state)=>state.ucfg.timezone);
    const [counters, setCounters] = React.useState({p:1, v:1, c:1});
    const [loading, setLoading] = React.useState(false);
    const [entrance, setEntrance] = React.useState('');

    const fetchEntranceURL = ()=>{
        setLoading(true);
        appConfig.getAPI().get("/api/s/entrance/url?p=openpoll")
        .then((response)=>{
            // console.log("PzAPI 'entrance' success : ", response.data);
            setEntrance(response.data.resource);
        })
        .catch((error)=>{
            console.log("PzAPI 'entrance' error : ", error);
        })
        .finally(()=>{
            setLoading(false);
        })
    };

    React.useEffect(() => {
        fetchEntranceURL();
    }, []);

    return (
        <PzArea.Container>
            <PzArea.MwBoard>

                <div className="w-[80%] min-w-[200px] max-w-[1600px] flex flex-col justifiy-start items-center gap-4">
                    <p className="font-extrabold text-3xl">{localeMap["home.page.openpoll.title"]}</p>
                </div>

                <div className="w-[90%] flex flex-col justifiy-start items-center gap-0">
                    <h3 className="">{localeMap["home.page.openevent.direction"]}</h3>
                    <img src="http://localhost:80/api/s/entrance/qrcode?p=openpoll" alt="" className="object-contain object-center"/>
                </div>

                <div className="w-[90%] min-w-[200px] max-w-[1600px] flex flex-col justifiy-start items-center gap-0">
                    <h3 className="">URL:</h3>
                    <PzButton.ToNavigate className="text-center" to={entrance} newtab={true}>
                        {entrance}
                    </PzButton.ToNavigate>
                </div>

            </PzArea.MwBoard>
        </PzArea.Container>
    );
}

