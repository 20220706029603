const localeStrMap = {
    "alpha.noti.title": "Pollerz.net está bajo Alpha Test",
    "alpha.noti.message": "Ce service est toujours en développement. \n Lors des tests alpha, certaines fonctionnalités peuvent être modifiées sans préavis et nous ne pouvons garantir la préservation de vos données.\n Utilisez à vos propres risques. \n\n Le service officiel débutera le 5 diciembre 2024.",
    "alpha.feedback.fab": "Retroalimentación",
    "alpha.feedback.title": "¿Que piensas sobre esta página?",
    "alpha.feedback.desc": "Merci de nous donner votre avis pour améliorer Pollerz.net.",
    "alpha.feedback.type0": "un comentario",
    "alpha.feedback.type1": "informe de error",
    "alpha.feedback.type2": "solicitud de función",
    "alpha.feedback.newsletter": "¿Deseas subscribirte en Email newsletter?",
    "alpha.feedback.newsletter.subscribe": "Subscribir",
    "alpha.feedback.newsletter.desc": "Noticias y actualizaciones de los blogs están incluidos, que se envían como máximo una vez al mes.",
    "alpha.feedback.send": "Enviar Feedback",

    "forgotten.msg1": "Gracias por todo,\n Tu cuenta ha sido eliminada.",
    "forgotten.msg2": "Esperamos a ofrecer nuestro servicio en el futuro,\n con funciones actualizadas en una nueva relación.",
    "notfound.request": "La página solicitada no existe",
    "notfound.nav.home": "Navegar a la página de inicio",
    "notfound.nav.login": "Navegar para iniciar seción",

    "comm.event.p": "encuesta(poll)",             // this will replace '{event}' in other locale strings
    "comm.event.s": "encuesta(survey)",           // this will replace '{event}' in other locale strings
    "comm.event.q": "quiz",             // this will replace '{event}' in other locale strings
    "comm.timing.r": "tiempo-real",      // realtime (right-away), this will replace '{timing}' in other locale strings
    "comm.timing.p": "basado-en-periodos",   // period-based,          this will replace '{timing}' in other locale strings
    "comm.voters.p": "votantes",             // this will replace '{voters}' in other locale strings
    "comm.voters.s": "participantes",       // this will replace '{voters}' in other locale strings
    "comm.voters.q": "participantes",       // this will replace '{voters}' in other locale strings
    "comm.voter.p": "votante",             // this will replace '{voter}' in other locale strings
    "comm.voter.s": "participante",       // this will replace '{voter}' in other locale strings
    "comm.voter.q": "participante",       // this will replace '{voter}' in other locale strings
    "comm.evoter.p": "votante",               // this will replace '{evoter}' in other locale strings
    "comm.evoter.s": "participante",         // this will replace '{evoter}' in other locale strings
    "comm.evoter.q": "participante",         // this will replace '{evoter}' in other locale strings
    "comm.pkind.pr": "tiempo-real encuesta(poll)",       // this will replace '{pkind}' in other locale strings
    "comm.pkind.pp": "basado-en-periodos encuesta(poll)",    // this will replace '{pkind}' in other locale strings
    "comm.pkind.sr": "tiempo-real encuesta(survey)",     // this will replace '{pkind}' in other locale strings
    "comm.pkind.sp": "basado-en-periodos encuesta(survey)",  // this will replace '{pkind}' in other locale strings
    "comm.pkind.qr": "tiempo-real quiz",       // this will replace '{pkind}' in other locale strings
    "comm.pkind.qp": "basado-en-periodos quiz",    // this will replace '{pkind}' in other locale strings
    "comm.button.cancel": "Cancelar",
    "comm.button.close": "Close",
    "comm.button.ok": "OK",
    "comm.underdev": "En desarrollo",    // 

    "errmsg.network": "Por favor revisa la conexión y reintente más tarde.",
    "errmsg.unknown": "Error desconocido",

    "auth.login.title": "Bienvenido de nuevo",
    "auth.login.subtitle": "",
    "auth.login.input.email": "Correo electrónico",
    "auth.login.input.password": "Contraseña",
    "auth.login.button": "Iniciar sesión",
    "auth.login.nav1.label": "No tienes una cuenta?",
    "auth.login.nav1.button": "Registrar",
    "auth.login.nav2.label": "¿Olvidaste la contraseña?",
    "auth.login.nav2.button": "Reiniciar",
    "auth.login.errmsg.missed": "El correo electrónico o la contraseña son incorrectos. ({1}/{2})",
    "auth.login.errmsg.missxx": "Has excedido el número máximo de intentos de inicio de sesión. Por favor intente de nuevo en 5 minutos.",
    "auth.login.errmsg.nouser": "La cuenta no existe.",
    "auth.login.errmsg.server": "No se pudo iniciar la sesión. Por favor intente de nuevo.",
    "auth.resetpw.step1.title": "Reiniciar la contraseña olvidada",
    "auth.resetpw.step1.subtitle": "Le enviaremos un código de verificación de 6 dígitos a su correo electrónico.",
    "auth.resetpw.step1.input.email": "Correo electrónico",
    "auth.resetpw.step1.button": "Envíeme un correo electrónico",
    "auth.resetpw.step2.title": "Verificar y reiniciar contraseña",
    "auth.resetpw.step2.subtitle": "Hemos enviado un código de verificación de 6 dígitos a '{1}'. Si no lo ve, por favor revise su carpeta de spam y asegúrese de que su correo electrónico esté escrita correctamente.",
    "auth.resetpw.step2.input.vcode": "Código de verificación",
    "auth.resetpw.step2.input.pword1": "Nueva contraseña",
    "auth.resetpw.step2.input.pword2": "Confirmar constraseña",
    "auth.resetpw.step2.button": "Reiniciar contraseña",
    "auth.resetpw.errmsg.notfound": "El correo electrónico no se pudo encontrar",
    "auth.signup.step1.title": "Crear una cuenta",
    "auth.signup.step1.subtitle": "Y brinde a sus votantes una mejor experiencia con Pollerz.net.",
    "auth.signup.step1.input.uname": "Nombre",
    "auth.signup.step1.input.email": "Correo electrónico",
    "auth.signup.step1.button": "Registrar",
    "auth.signup.step1.nav1.label": "Al apretar 'Registrar', aceptarás nuestro",
    "auth.signup.step1.nav1.button": "Términos y Política de Privacidad",
    "auth.signup.step1.nav2.label": "Ya tienes una cuenta?",
    "auth.signup.step1.nav2.button": "Iniciar sesión",
    "auth.signup.step2.title": "Verifica y establece tu contraseña",
    "auth.signup.step2.subtitle": "Hemos enviado un código de verificación de 6 dígitos a'{1}'. Si no lo ve, por favor revise su carpeta de spam y asegúrese de que su correo electrónico esté escrita correctamente.",
    "auth.signup.step2.input.vcode": "Código de verificación",
    "auth.signup.step2.input.pword1": "Contraseña",
    "auth.signup.step2.input.pword2": "Confirmar contraseña",
    "auth.signup.step2.button": "Crear cuenta",
    "auth.chpword.title": "Cambiar contraseña",
    "auth.chpword.subtitle": "",
    "auth.chpword.input.pword0": "Vieja contraseña",
    "auth.chpword.input.pword1": "Nueva contraseña",
    "auth.chpword.input.pword2": "Confirmar contraseña",
    "auth.chpword.button": "Cambiar contraseña",
    "auth.chpword.errmsg.notfound": "El correo electrónico no es válido",
    "auth.chpword.errmsg.oldinvalid": "La contraseña anterior no coincide",
    "auth.chpword.errmsg.newinvalid": "La nueva contraseña no es válido",
    "auth.chemail.title": "Cambiar correo electrónico",
    "auth.chemail.subtitle.step1": "Enviaremos un código de verificación al nuevo correo electrónico.",
    "auth.chemail.subtitle.step2": "Le enviaremos un código de verificación de 6 dígitos a su correo electrónico.",
    "auth.chemail.input.oldemail": "Dirección de correo electrónico anterior",
    "auth.chemail.input.newemail": "Nueva dirección correo electrónico",
    "auth.chemail.input.vcode": "Código de verificación",
    "auth.chemail.button.step1": "Envíeme el código de verificación",
    "auth.chemail.button.step2": "Cambiar el correo electrónico",
    "auth.chemail.errmsg.emailtaken": "El correo electrónico ya está en uso.",
    "auth.vcode.errmsg.notfound": "El correo electrónico no se pudo encontrar",
    "auth.vcode.errmsg.emailtaken": "El correo electrónico ya está en uso",
    "auth.vcode.errmsg.emailerror": "No se pudo enviar el correo electrónico - Por favor intentarlo más tarde.",
    "auth.vcode.errmsg.nomatch": "El código de verificación no coincide",
    "auth.vcode.retry.label": "Aún no ha recibido el correo?",
    "auth.vcode.retry.button": "Inténte de nuevo",
    "auth.errmsg.vcode.invalid": "tiene que ser de 6 dígitos",
    "auth.errmsg.email.invalid": "correo electrónico inválido",
    "auth.errmsg.uname.invalid": "nombre inválido",
    "auth.errmsg.pword.invalid": "contraseña inválida",
    "auth.errmsg.pword.mismatch": "La contraseña no coincide",
    "auth.errmsg.pword.length": "la longitud tiene que ser entre 8 a 25",
    "auth.errmsg.pword.uchar": "incluye al menos una letra mayúscula",
    "auth.errmsg.pword.digit": "incluye al menos una letra de dígito",
    "auth.errmsg.pword.schar": "incluye al menos un letra especial",
    "auth.errmsg.network": "Por favor revisa la conexión del red y pruébalo más tarde",
    "auth.errmsg.unknown": "Error desconocido",
    "auth.extsession.title": "Extender su sesión de inicio de sesión",
    "auth.extsession.direction": "Aprete el botón de \"Extender\" abajo \n o aprete la tecla de espacio para extender.",
    "auth.extsession.remaining": "cerrar sesión en {1} segundos",
    "auth.extsession.button": "Extender",
    "auth.extsession.toast": "Su sesión de inicio de sesión ha sido extendida.",

    "home.intro.title": "La forma más rápida, fácil y segura\nde pedir la opinión de muchas personas",
    "home.intro.subtitle": "Encuesta(poll)/ encuesta(survey)/ cuestionario en línea para todos,\nsin ninguna necesidad de instalaciones o suscripciones para los votantes",
    "home.intro.serving": "Sirviendo a {1} eventos y {2} votantes en {3} países",
    "home.intro.difference": "Difference from the other services",
    "home.intro.whyforyou": "Porque necesitas Pollerz.net",
    "home.intro.whyforus": "Por qué y cómo construimos Pollerz.net",
    "home.intro.tryyourself": "Pruébalo tú mismo",
    "home.why.label0": "Tiempo real",
    "home.why.label1": "Fácil",
    "home.why.label2": "Seguro",
    "home.why.label3": "Grátis",
    "home.why.label4": "Ronda múltiple",
    "home.why.label5": "Apoyo de los empleados",
    "home.why.desc0": "La ejecución de la encuesta(poll) (o encuesta(survey)) está automatizada y optimizada., para que pueda ejecutarse en tiempo real junto con su reunión fuera de línea en curso. Además, los votantes pueden seguir el progreso y los resultados de la encuesta(poll) en tiempo real.",
    "home.why.desc1": "No debería ser nada difícil para preguntar y agregar las opiniones de otras personas, especialmente con todas las tecnologías que tenemos ahora en nuestras manos. No se debe requerir ninguna instalación o suscripción para que los participantes voten.",
    "home.why.desc2": "Cada voto se cuenta de forma segura y el votante está estrictamente protegido. La información personal de los votantes nunca se utilizará para ningún otro propósito que no sea la propia encuesta y se eliminará permanentemente después de la encuesta.",
    "home.why.desc3": "Usted, como un poller, debe tener muchas opciones diferentes que puedan ayudarle a garantizar la elegibilidad de los votantes. Con las opciones fuera de línea, puedes hacer que tu encuesta sea gratuita pagándola con tu cuota gratuita mensual (hasta 100 votantes la primera vez, 30 después).",
    "home.why.desc4": "Es posible que tenga que votar una sola decisión con múltiples rondas de votación, debido al empate o la ausencia de una opinión predominante. Al seleccionar reglas predefinidas simples, también puede automatizar fácilmente el proceso de votación de múltiples rondas.",
    "home.why.desc5": "Puede asignar los empleados y otorgarles los permisos adecuados para el evento que creó, de modo que puedan ayudarlo a ejecutar el evento, verificar la elegibilidad de los votantes y ayudar a los votantes cara a cara a votar.",
    "home.goal.boostdm": "\"Impulsar nuestra toma de decisiones colectiva para la democracia.\"",
    "home.goal.support": "\"Servir a las personas y nunca intentar influenciarlas\"",
    "home.goal.staysml": "\"Mantener a ser pequeño, liviano y ágil sin comprometer la seguridad\"",
    "home.goal.desc.boostdm": "El voto anónimo, donde cada persona tiene un voto, es fundamental a la democracia. Esto significa respeto por los puntos de vista de los demás y el compromiso de reconocer la opinión de la mayoría a través de procedimientos justos, incluso cuando difiere de la mía. Pollerz.net planea ayudar a que este proceso de recopilación de opinión sea rápido, sencillo y seguro, con la esperanza de contribuir al proceso democrático de decisiones.",
    "home.goal.desc.support": "El objetivo de Pollerz.net es apoyar la libre expresión de las opiniones de las personas y nos oponemos a cualquier intento de influir en ellas. Pollerz.net no analiza los perfiles de los usuarios ni busca crear efectos de bloqueo. Además, no recopilamos ni utilizamos información personal de los participantes del evento, asegurándonos de que todos esos datos se eliminen de la base de datos después de que el evento de voto se finalice.",
    "home.goal.desc.staysml": "Hemos visto cómo las empresas de tecnología abandonan su impulso inicial por obtener valor para el usuario y se transforman en entidades que priorizan su propia escala, ingresos y ganancias. No perseguimos el crecimiento de esta manera. En cambio, nuestro objetivo es seguir siendo una organización pequeña, ágil y eficiente, dedicada a brindar constantemente servicios confiables a nuestros usuarios.",
    "home.try.asvoter": "Try it as a voter",
    "home.try.aspoller": "Try it as a poller",

    "home.page.features.title": "Características",
    "home.page.pricing.title": "Precios",
    "home.page.openpoll.title": "Pollerz.net encuesta(poll) abierta",
    "home.page.opensurvey.title": "Pollerz.net encuesta(survey) abierta",
    "home.page.openquiz.title": "Pollerz.net quiz abierto",
    "home.page.openevent.direction": "Tome una foto del código QR para ingresar",
    
    "menu.auth.login": "Iniciar sesión",
    "menu.auth.logout": "Cerrar sesión",
    "menu.auth.signup": "Registrar",
    "menu.home.howto": "Como",
    "menu.home.pricing": "Precios",
    "menu.myaccount" : "Mi cuenta", 
    "menu.myevents": "Mis eventos",
    "menu.poller": "Como Poller",
    "menu.poller.editevent": "Edit & Test Event",
    "menu.poller.getready": "Preparar evento",
    "menu.poller.notify": "Mandar notificación",
    "menu.poller.simtest": "Test as a virtual event",
    "menu.poller.runpoll": "Ejecutar evento",
    "menu.poller.xresult": "Resultado de evento",
    "menu.poller.xreport": "Reporte final",
    "menu.poller.archive": "Save in archive",
    "menu.staff": "Menú de empleado(a)s",
    "menu.staff.entrance": "Guía para los participantes",
    "menu.staff.eligibility": "Revisar elegibilidad",
    "menu.staff.votehelper": "Ayuda para votar",
    "menu.staff.watchpoll": "Ver evento",
    "menu.staff.xresult": "Resultado final",
    "menu.xresult.monitor": "Result Monitor",
    "menu.xresult.details": "Detalles del resultado",
    "menu.voter": "Menú de votantes",
    "menu.voter.testvote": "Votar para probar",
    "menu.voter.vote": "Votar",
    "menu.about": "Sobre Pollerz",
    "menu.about.mission": "Declaración de misión empresarial",
    "menu.about.howto": "Como usar",
    "menu.about.pricing": "política de precios",
    "menu.about.privacypolicy": "política de privacidad",
    "menu.about.help2translate": "Ayuda de traducción",
    "menu.about.purchaseinquiry": "Consulta de compra",

    "modal.button.cancel": "Cancelar",
    "modal.button.more": "Configuración avanzada",
    "modal.button.save": "Guardar",

    "myaccount.title" : "Mi cuenta", 
    "myaccount.user.label" : "Mi Cuenta", 
    "myaccount.username.label" : "Nombre", 
    "myaccount.username.change" : "Cambiar cuenta", 
    "myaccount.userid.label" : "ID de usuario", 
    "myaccount.userid.change" : "Cambiar", 
    "myaccount.userpw.label" : "Contraseña del usuario", 
    "myaccount.userpw.change" : "Cambiar", 
    "myaccount.env.label" : "Configuración", 
    "myaccount.env.country.label" : "País de residencia", 
    "myaccount.env.language.label" : "Lenguaje seleccionado", 
    "myaccount.env.timezone.label" : "Zona horaria seleccionada", 
    "myaccount.quota.label" : "Cuota Gratuita", 
    "myaccount.quota.remain.label" : "Restante", 
    "myaccount.quota.monthly.label" : "Recargar la máxima mensual", 
    "myaccount.quota.notice" : "La cuota gratuita va ser recargada el primer día de cada mes (UTC).", 
    "myaccount.quota.nextrefill.label" : "Próximo recargo", 
    "myaccount.paym.label" : "Forma de pago", 
    "myaccount.rtbf.label" : "Derecho a ser \nolvidado", 
    "myaccount.rtbf.warning" : "Advertencia: estas acciones son irreversibles.", 
    "myaccount.rtbf.exception" : "Los registros de pago son la única excepción que permanecerá. Los datos restantes serán mínimos, como exige la ley en muchos países, y se destruirán automáticamente al cabo de tres años.", 
    "myaccount.rtbf.data.label" : "Eliminar todos mis eventos y sus datos relacionados", 
    "myaccount.rtbf.data.direction" : "Ingresar '{1}' a continuación, y luego haga clic en el botón de eliminar.", 
    "myaccount.rtbf.data.keyword" : "TODOS MIS EVENTOS", 
    "myaccount.rtbf.data.button" : "Eliminar todos mis eventos", 
    "myaccount.rtbf.acct.label" : "Eliminar mi cuenta y mi información de todos los registros", 
    "myaccount.rtbf.acct.direction" : "Ingresar '{1}' a continuación, y luego haga clic en el botón de eliminar.", 
    "myaccount.rtbf.acct.keyword" : "TODA LA INFORMACIÓN DE MI CUENTA", 
    "myaccount.rtbf.acct.button" : "Eliminar cuenta", 

    "myevents.needtologin" : "Necesitas iniciar sesión para usar este menú", 
    "myevents.planned" : "Eventos planeados", 
    "myevents.archived" : "Eventos archivados", 
    "myevents.sysevents" : "System Events",
    "myevents.none.planned" : "You have no events planned at this moment.",
    "myevents.none.archived" : "You have no events archived at this moment.",
    "myevents.none.sysevents" : "You have no system events at this moment.",
    "myevents.none.helpmsg" : "Click the button below, if you want to learn from an example.",
    "myevents.none.createx" : "Create a poll example",

    "poll.summary.label" : "Resumen", 
    "poll.summary.expire.notopened" : "기한 내에 개최되지 않아, {YYYY-MM-DD HH:mm}에 삭제될 예정입니다.", 
    "poll.summary.expire.notclosed" : "기한 내에 종결되지 않아, {YYYY-MM-DD HH:mm}에 삭제될 예정입니다.", 
    "poll.summary.elg.created" : "{Event} con {1} {voters} registrados,", 
    "poll.summary.elg.planned" : "{Event} con {1} (Max {2}) {voters} registrados,", 
    "poll.summary.elg.closed"  : "{Event} junto con {3}/{1} registrados {voters},", 
    "poll.summary.any.created" : "{Event} que todos pueden participar,", 
    "poll.summary.any.planned" : "{Event} que todos (Max {2}) pueden participar,", 
    "poll.summary.any.closed"  : "{Event} junto con {3}/{2} {voters},", 
    "poll.summary.status.A0" : "Ahora bajo Planificación", 
    "poll.summary.status.Ac" : "Ahora bajo Planificación", 
    "poll.summary.status.Ar" : "Planeado y esperando compra", 
    "poll.summary.status.Ax" : "Listo para ser ejecutado (comprado)", 
    "poll.summary.status.Oo" : "Abriendo (registrar)", 
    "poll.summary.status.Os" : "Abierto", 
    "poll.summary.status.Oz" : "Wrapping up", 
    "poll.summary.status.Xp" : "Cerrado", 
    "poll.card.questions.label" : "Preguntas", 
    "poll.card.voters.label" : "{Voters}", 
    "poll.card.voters.elg.planned" : "{1} {voters} registrados, y máximo {2} pueden atender", 
    "poll.card.voters.elg.closed" : "{1} {voters} registrados, y {2} atendido(s)", 
    "poll.card.voters.any.planned" : "{voters} no registrados pueden atender por máximo {1} persona(s)", 
    "poll.card.voters.any.closed" : "Entre los {voters} no registrados, {1} atendido(s)", 
    "poll.card.staff.label" : "Preguntar a los empleados", 
    "poll.card.staff.summary" : "Poller '{1}', soportado por {2} empleados", 
    "poll.card.staff.myrole.p" : "Mi rol: 'Poller'", 
    "poll.card.staff.myrole.m" : "Mi rol: 'Gerente'", 
    "poll.card.staff.myrole.s" : "Mi rol: 'Empleado'", 
    "poll.card.button.edit" : "Edit", 
    "poll.card.button.run" : "Ejecutar", 
    "poll.card.button.watch" : "Ver", 
    "poll.card.button.xresult" : "Ver resultado", 

    "poll.create_example" : "Create example poll event", 
    "poll.create_quick" : "Crear una encuesta(poll) rápida y sencilla", 
    "poll.create_quick.desc" : "Puedes escoger más opciones si escojes '{1}'.", 
    "poll.create_quick.title" : "Rápida encuesta(poll) {1}", 
    "poll.create_event" : "Crear un nuevo evento", 
    // "poll.create.step1" : "Establecer tipo, título y fecha y hora del evento.", 
    "poll.create.step1" : "Establecer título y fecha y hora del evento.", 
    "poll.create.step2" : "Registrar las preguntas que se van a usar en el {event}.", 
    "poll.create.step3" : "Registrar los {voters} del {event}. (recomendado)", 
    "poll.create.step3.direction" : "Entre la lista de {voters} a continuación, y aprete '{1}'", 
    "poll.create.step3.extract" : "Extraer lista", 
    "poll.create.step3.errmsg" : "Se encontró una cadena no reconocida", 
    "poll.create.step3.none" : "No hay {voter} registrados.", 
    "poll.create.step3.some" : "{1} {voters} están siendo registrados", 
    "poll.create.step4" : "Confirmar y crear {event}.", 
    "poll.create.button.prev": "Paso anterior",
    "poll.create.button.next": "Próximo paso",
    "poll.create.button.create": "Crear {event}",
    "poll.criteria.Majority" : "Mayoría", 
    "poll.criteria.Most" : "Mayoría", 
    "poll.criteria.Zero" : "0 votos", 
    "poll.criteria.Least" : "El menos", 
    "poll.criteria.Top2x" : "Below Top 2x rankers", 
    "poll.criteria.Top3x" : "Below Top 3x rankers", 
    "poll.criteria.Top4x" : "Below Top 4x rankers", 
    "poll.delete.title": "Delete Event",
    "poll.delete.message": "All the data of this event will be permanently deleted.",

    "poll.xresult.plabel.tnk" : "Título y tipo",
    "poll.xresult.plabel.qna" : "Pregunta y candidatos",
    "poll.xresult.plabel.gnc" : "Objetivo y criterios",
    "poll.xresult.plabel.xns" : "Resultado y estadísticas",
    "poll.xresult.label.residual" : "Votos residuales",
    "poll.xresult.label.total" : "Votos totales",

    "poll01.ptype.label" : "Tipo", 
    "poll01.ptitle.label" : "Título", 
    "poll01.pwhen.label" : "Tiempo", 
    "poll01.pwhen.rightnow" : "Ahora", 
    "poll01.pwhen.rightnow.constraint" : "(expires in an hour after creation)", 
    "poll01.pwhen.rightnow.expire" : "It will be expired at {hh:mm:ss} and deleted", 
    "poll01.pwhen.start" : "Starting at", 
    "poll01.pwhen.period" : "With period", 
    "poll01.pearly.label" : "Early voting", 
    "poll01.pearly.yes" : "Allowed", 
    "poll01.pearly.no" : "Not allowed", 
    "poll01.locale.label" : "Lenguaje", 
    "poll01.poller.label" : "Poller", 
    
    "poll02.title.add" : "Añadir nueva pregunta", 
    "poll02.title.edit" : "Editar pregunta", 
    "poll02.qkind.label" : "Tipo", 
    "poll02.qkind.A" : "Aprobación", 
    "poll02.qkind.S" : "Selección", 
    "poll02.qkind.E" : "Elección", 
    "poll02.qkind.C" : "Comentario", 
    "poll02.question.example" : "#{1} pregunta", 
    "poll02.question.label" : "Pregunta", 
    "poll02.question.placeholder" : "Ingrese la pregunta exacta que se le hará", 
    "poll02.answers.clabel" : "Respuestas", 
    "poll02.answers.slabel" : "Alternativas", 
    "poll02.answers.elabel" : "Candidatos", 
    "poll02.answers.placeholder" : "Ingrese un candidato por cada línea", 
    "poll02.answers.A" : "Sí \nNo", 
    "poll02.answers.S" : "Alternativa 1\nAlternativa 2", 
    "poll02.answers.E" : "Candidato A\nCandidato B", 
    "poll02.vgoal.label" : "Meta deseada", 
    "poll02.vgoal.maxrounds" : "MáximaRondas", 
    "poll02.xresult.label" : "Resultado", 
    "poll02.xresult.A" : "NoDecidido/Aprobado/Rechazado", 
    "poll02.xresult.S" : "NoDecidido/Seleccionado '{1}'/Seleccionado '{1}' (+{2})", 
    "poll02.xresult.E" : "NoDecidido/Elegido '{1}'/Elegido '{1}' (+{2})", 
    "poll02.xresult.C" : "NoDecidido/Coleccionado {1} commentarios", 
    "poll02.wcriteria.label.A" : "Criterio para aprobar", 
    "poll02.wcriteria.label.S" : "Criterio para seleccionar", 
    "poll02.wcriteria.label.E" : "Criterio para elegir", 
    "poll02.dcriteria.label" : "Criterio para abandonar", 
    "poll02.button.appendnew" : "Añadir próxima pregunta", 
    "poll02.button.removelast" : "Quitar la última pregunta", 
    "poll02.button.showdetail" : "Show detail", 
    "poll02.button.save" : "Guardar cambios", 
    "poll02.button.delete" : "Eliminar pregunta", 

    "poll03.voter.title" : "lista de {Voter}", 
    "poll03.voter.direction1" : "Edite la lista entera de los participantes y luego aprete \"Guardar Lista\".", 
    "poll03.voter.direction2" : "Ejempo de la línea : 'John Doe, 20041230, any@example.com' (Nombre,Cumple,N.Tel,Correo)", 
    "poll03.voter.errmsg.no_email" : "El participante necesita un correo electrónico.", 
    "poll03.voter.errmsg.no_phone" : "El participante necesita un número telefónico.", 
    "poll03.voter.errmsg.no_name" : "El participante necesita un nombre.", 
    "poll03.voter.errmsg.empty_list" : "La lista de los participantes está vacio.", 
    "poll03.voter.action.add" : "Añadir participante", 
    "poll03.voter.action.edit" : "Editar participante", 
    "poll03.voter.action.text" : "Editar la lista entera como un texto", 
    "poll03.voter.action.clear" : "Clear entire list", 
    "poll03.voter.button.save" : "Guardar", 
    "poll03.voter.button.delete" : "Eliminar", 
    "poll03.voter.label.vid" : "ID", 
    "poll03.voter.label.vname" : "Nombre", 
    "poll03.voter.label.vcode" : "Código", 
    "poll03.voter.label.vbdate" : "Cumple", 
    "poll03.voter.label.vemail" : "Correo Electrónico", 
    "poll03.voter.label.vphone" : "Número Telefónico", 

    "poll04.staff.title" : "Lista de los empleado(a)s", 
    "poll04.staff.direction1" : "Ingrese/edite la lista entera de los empleados y aprete \"Guardar Lista\".", 
    "poll04.staff.direction2" : "Ejempo de la línea : 'John Doe, someone@example.com, S' (Nombre,Correo,Rol)", 
    "poll04.staff.errmsg.no_email" : "El empleado(a) necesita un correo electrónico.", 
    "poll04.staff.errmsg.no_name" : "El empleado(a) necesita un nombre.", 
    "poll04.staff.errmsg.empty_list" : "La lista de los empleado(a)s está vacio.", 
    "poll04.staff.action.add" : "Añadir empleado(a)", 
    "poll04.staff.action.edit" : "Editar empleado(a)", 
    "poll04.staff.action.text" : "Editar la lista entera como texto", 
    "poll04.staff.action.clear" : "Clear entire list", 
    "poll04.staff.button.save" : "Guardar", 
    "poll04.staff.button.delete" : "Eliminar", 
    "poll04.staff.label.name" : "Nombre", 
    "poll04.staff.label.email" : "Correo electrónico", 
    "poll04.staff.label.role" : "Rol", 
    "poll04.staff.label.role.manager" : "Gerente", 
    "poll04.staff.label.role.staff" : "Empleado", 

    "poller.editevent.event" : "Event info",
    "poller.editevent.qlist" : "Lista de pregunta",
    "poller.editevent.quest" : "Question detail",

    "poller.getready.voters" : "votante registrados",
    "poller.getready.voters.sum0" : "누구나 참여할 수 있는 이벤트입니다",
    "poller.getready.voters.sum1" : "{1}명의 참가대상자가 등록되었습니다",
    "poller.getready.voters.nt" : "Registered total",
    "poller.getready.voters.nt0" : "등록된 참가대상자가 없으므로, 누구나 참여할 수 있습니다.",
    "poller.getready.voters.nt1" : "등록된 참가대상자들만 인증(체크인)을 거쳐 참여할 수 있습니다.",
    "poller.getready.voters.ne" : "With email address",
    "poller.getready.voters.np" : "With phone numbers",
    "poller.getready.voters.na" : "With email/phone",
    "poller.getready.voters.na0" : "이메일/문자를 이용한 '참가자 온라인 초대' 기능은 사용할 수 없습니다.",
    "poller.getready.voters.na1" : "{1}명의 정보가 부족하므로, '참가자 온라인 초대' 기능은 사용할 수 없습니다.",
    "poller.getready.voters.na2" : "이메일/문자를 이용한 '참가자 온라인 초대' 기능을 사용할 수 있습니다.",
    "poller.getready.voters.list" : "List of registred voters",
    "poller.getready.voters.example" : "Add 5 examples to the list",

    "poller.getready.staffs" : "empleados",
    "poller.getready.staffs.sum0" : "스태프 지원 없이 스스로 이벤트를 진행합니다",
    "poller.getready.staffs.sum1" : "{1}명의 스태프멤버가 이벤트 진행을 지원합니다",
    "poller.getready.staffs.nm" : "등록된 매니저 수",
    "poller.getready.staffs.nm0" : "나를 대신하여 이벤트를 진행할 수 있으며, 2명 미만이 적절합니다.",
    "poller.getready.staffs.ns" : "등록된 일반 스태프 수",
    "poller.getready.staffs.ns0" : "오프라인에서 참가자들을 지원하며, 적정인원은 참가자 수에 비례합니다.",
    "poller.getready.staffs.list" : "스태프멤버 명단",
    "poller.getready.staffs.example" : "Add 3 examples to the list",

    "poller.getready.pca.title" : "Control y autenticación de participantes",
    "poller.getready.pca.sum0" : "옵션 선택 및 비용 지불을 완료하여야 합니다",
    "poller.getready.pca.sum1" : "선택된 옵션에 따라, 비용 지불을 완료해야 합니다",
    "poller.getready.pca.sum2" : "이벤트 개최를 위한 모든 준비가 완료되었습니다",
    "poller.getready.pca.tbd" : "Por determinar",
    "poller.getready.pca.n.label" : "참여인원수",
    "poller.getready.pca.n.min" : "최소 참여인원수",
    "poller.getready.pca.n.max" : "최대 참여인원수",
    "poller.getready.pca.n.elg" : "등록된 인원수",
    "poller.getready.pca.inv" : "Forma de invitación",
    "poller.getready.pca.offline" : "오프라인 (QR코드를 게시 또는 배포)",
    "poller.getready.pca.online" : "온라인 (QR코드를 이메일/메시지로 전송)",
    "poller.getready.pca.nmin" : "Min. participants",
    "poller.getready.pca.nmax" : "Máx. participantes",
    "poller.getready.pca.nmin.desc" : "이벤트가 유효하게 성립하기 위한 최소 조건입니다",
    "poller.getready.pca.nmax.desc" : "초과하는 경우, 참가자의 입장이 제한됩니다",
    "poller.getready.pca.nmax.cost" : "(Costo es {1} por participante)",
    "poller.getready.pca.nmax.elg" : "Registrado: {1}",
    "poller.getready.pca.nmax.fq" : "Cuota libre restante: {1}",
    "poller.getready.pca.comm.cost" : "(Hay un costo adicional de {1} por participante)",
    "poller.getready.pca.auth" : "Authentication",
    "poller.getready.pca.auth.qr" : "QR",
    "poller.getready.pca.auth.allow" : "Allow",
    "poller.getready.pca.auth.check" : "Check",
    "poller.getready.pca.auth.simplicity" : "Simplicity",
    "poller.getready.pca.auth.security" : "Security",
    "poller.getready.pca.auth.A" : "Anyone",
    "poller.getready.pca.auth.E" : "Registered",
    "poller.getready.pca.auth.1" : "Shared",
    "poller.getready.pca.auth.9" : "Individual",
    "poller.getready.pca.auth.__" : "None",
    "poller.getready.pca.auth.n_" : "Name",
    "poller.getready.pca.auth.nc" : "Name,Passcode",
    "poller.getready.pca.pcode" : "Contrseña personal",
    "poller.getready.pca.pcode.c" : "Usar código de identificación",
    "poller.getready.pca.pcode.i" : "los últimos 4 dgitos de su {1}",
    "poller.getready.pca.pcode.r" : "Mandar en línea después de generar",
    "poller.getready.pca.price.label" : "Service cost",
    "poller.getready.pca.price.total" : "Precio total",
    "poller.getready.pca.price.topay" : "Price to pay",
    "poller.getready.pca.fq.rem" : "Remaining free quota",
    "poller.getready.pca.fq.after" : "지불 후 잔여 무료쿼타",
    "poller.getready.pca.fq.payable" : "Free quota",
    "poller.getready.pca.fq.yes" : "Affordable with remaining free quota",
    "poller.getready.pca.fq.no" : "Not affordable with remaining free quota",
    "poller.getready.pca.button.edit" : "Editar opciones",
    "poller.getready.pca.paid" : "결재가 완료되었습니다",

    "poller.getready.payfq.title" : "Comprar con cutoa grátis",
    "poller.getready.payfq.remaining" : "Cuota gratis restante",
    "poller.getready.payfq.tobeused" : "Cuota gratis ara ser utilizado",
    "poller.getready.payfq.newprice" : "Precio",
    "poller.getready.payfq.button" : "Comprar",

    "poller.getready.paymn.title" : "Payment",

    "poller.notify.boardtitle" : "Notificación",
    "poller.xreport.boardtitle" : "Reporte final",

    "staff.entrance.boardtitle" : "Información sobre la entrada de la encuesta(poll)",
    "staff.eligibility.boardtitle" : "Revisar eligibilidad",
    "staff.votehelper.boardtitle" : "Información de como votar",
    "staff.watchpoll.boardtitle" : "Ver encuesta(poll)",
    "staff.xresult.boardtitle" : "Resultado final",

    "monitor.event.poller.label": "Poller",
    "monitor.event.eligibles.label": "{Voter}",
    "monitor.event.attendee.label": "{Voter}",
    "monitor.event.myname.label": "My name",
    "monitor.opening.tremain": "Este evento tiene que empezar en 1a hora,\no va ser eliminada automaticamente.\nTiempo restante : {1}",
    "monitor.opening.texpire": "Este evento tiene que empezar en 1a hora,\n va ser eliminada automaticamente.",
    "monitor.opening.vnotice": "Este evento comenzará pronto.\nPor favor espere.",
    "monitor.opening.vexpire": "El evento se eliminó automáticamente \nya que no ha comenzado en una hora.",
    "monitor.quest.selectfirst": "Select the first question to be asked",
    "monitor.quest.selectnext": "Select the next question to be asked",
    "monitor.quest.planned": "The following questions are planned to be asked",
    "monitor.quest.voting.asked": "Voting started for the question below",
    "monitor.quest.voting.now": "Please vote now",
    "monitor.quest.chosen.A": "Respuesta elegida",
    "monitor.quest.chosen.S": "Respuestas elegidas hasta ahora",
    "monitor.quest.chosen.E": "Candidatos seleccionados hasta ahora",
    "monitor.quest.goal.label" : "Goal",
    "monitor.quest.goal.A" : "Select YES or NO",
    "monitor.quest.goal.S" : "Select {1} among {2}",
    "monitor.quest.goal.E" : "Select {1} among {2}",
    "monitor.quest.goal.roundmax" : "Max round",
    "monitor.quest.goal.total" : "Total",
    "monitor.quest.goal.selected" : "Selected",
    "monitor.quest.goal.dropped" : "Dropped",
    "monitor.quest.tally.count" : "Tallying up the votes",
    "monitor.quest.tally.result" : "Vote tally result",
    "monitor.quest.result.chosen" : "Chosen",
    "monitor.quest.result.todo" : "To do",
    "monitor.quest.todo.done" : "Achieved the goal",
    "monitor.quest.todo.fail0" : "미결정 상태로 완료되었습니다",
    "monitor.quest.todo.fail1" : "일부({1}) 미결정 상태로 완료되었습니다",
    "monitor.quest.todo.A" : "Voting again in {round}",
    "monitor.quest.todo.S" : "Select {1} more in {round}",
    "monitor.quest.todo.E" : "Elect {1} more in {round}",
    "monitor.progress.checkin": "Progreso del registro de asistentes",
    "monitor.progress.checked": "Minimum number of attendees ({1}) satisfied",
    "monitor.progress.voting": "Voto en progreso",
    "monitor.progress.rresult.ends": "Ronda {1} : {2} seleccionada",
    "monitor.progress.rresult.endf": "Ronda {1} : {2} seleccionada, cerrando con {3} indeciso(s)",
    "monitor.progress.rresult.next": "Ronda {1} : {2} seleccionada, con {3} revocacion(es)",
    "monitor.result.title": "Resultado final",
    "monitor.result.nochosen.A": "No decidido",
    "monitor.result.nochosen.S": "No hay una respuesta seleccionada",
    "monitor.result.nochosen.E": "No hay un candidato seleccionado",
    "monitor.action.event.open" : "Abrir {event}",
    "monitor.action.event.start" : "Empezar {event}",
    "monitor.action.event.close" : "Cerrar {event}",
    "monitor.action.event.final" : "Final results",
    "monitor.action.quest.close" : "Close question",
    "monitor.action.quest.vstart" : "Empezar a votar",
    "monitor.action.quest.vstop" : "Parar de votar",
    "monitor.action.quest.nextr" : "Abriendo ronda",
    "monitor.action.quest.nextq" : "Próxima pregunta",
    "monitor.action.castvote" : "Votar",
    "monitor.action.checkout" : "Check out",
    "monitor.waiting.event.checkin" : "Waiting for the voters' check-in",
    "monitor.waiting.event.checkin2" : "Waiting for the other voters' check-in",
    "monitor.waiting.event.start" : "Esperando para que el evento comience",
    "monitor.waiting.quest.select" : "Waiting for the next question to be selected",
    "monitor.waiting.quest.close": "Waiting for the question to be closed",
    "monitor.waiting.quest.vstart" : "Esperando para que los votos comiencen",
    "monitor.waiting.quest.vstop" : "Waiting for the other voters to vote",
    "monitor.splash.loading": "Cargando evento ...",
    "monitor.splash.badaddr": "Lo sentimos, la dirección no es una entrada válida.",
    "monitor.splash.checkin": "You need to check-in first.",
    "monitor.splash.closed": "The event has been closed.",
    "monitor.splash.thankyou": "Gracias por participar.",

    "viewer.button.qst.next" : "Próximo",
    "viewer.button.qst.prev" : "Previo",

    "entrance.qrcode.for.transfer" : "Open on another device",
    "entrance.qrcode.instruct.user" : "Click or scan this QR-code with Camera app.",
    "entrance.qrcode.instruct.voter" : "Scan the QR-code below,\nusing Camera app on your device.",

    "voter.checkin.invitation": "'{1}'\nte invitó a {event}\n\"{2}\"",
    "voter.checkin.direction.nc": "Ingrese su nombre y su contraseña para revisar eligibilidad",
    "voter.checkin.direction.n": "Ingrese su nombre para eligibilidad",
    "voter.checkin.label.name": "Nombre",
    "voter.checkin.label.code": "Código personal",
    "voter.checkin.button": "Participar",
    "voter.checkin.err.pnotfound": "El evento no se pudo encontrar.",
    "voter.checkin.err.pnotopen": "El evento aún no está abierto.",
    "voter.checkin.err.pclosed": "El evento se ha cerrado.",
    "voter.checkin.err.vnotfound": "El nombre no está registrado para este evento.",
    "voter.checkin.err.vexlimit": "Se ha alcanzado el número máximo de participantes ({1}).",
    "voter.checkin.nav.label": "Más sobre Pollerz.net?",
    "voter.checkin.nav.button": "Visitar la página de inicio",
    "voter.vcast.err.duplicate": "Ya has votado.",
    "simulate.rehearsal.title": "You are opening the event,\n'{1}',\nto test with vote simulation.",
    "simulate.rehearsal.poller": "Poller",
    "simulate.rehearsal.nvoters": "Voters",
    "simulate.rehearsal.vmethod": "Vote Simulation",
    "simulate.trial.asvoter.title": "You are invited to an event,\n\"{1}\",\nfor a trial as voter.",
    "simulate.trial.aspoller.title": "You are organizing the trial event,\n\"{1}\".",
    "simulate.trial.aspoller.instr0": "Plan and prepare the event.\n(1 / 3)",
    "simulate.trial.aspoller.instr1": "Invite voters for the event.\n(2 / 3)",
    "simulate.trial.aspoller.instr2": "Open and run the event.\n(3 / 3)",
    "simulate.trial.example.name": "John Doe",
    "simulate.trial.title": "You have an event with agenda prepared,\n\"{1}\".\nNow run the event.",
    "simulate.trial.label": "Enter your name as the host of event",
    "simulate.trial.openevent": "Open the event",
    "simulate.trial.startevent": "Start the event",
    "simulate.comment": "This event is just a simulation, and will not be saved.",

};

export default localeStrMap;