import React from "react";
import { useSelector } from "react-redux";

import { utils } from "../config/userConfig";
import PzInput          from '../common/PzInput';

export default function EvtEditorForVoter({ voter, setVoter, editable }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    console.log("voter : ", voter);

    function onChangeToVName(text) {
        const new_voter = { ...voter, vname:text };
        if (typeof setVoter === 'function') setVoter(new_voter);
    }
    function onChangeToVCode(text) {
        const new_voter = { ...voter, vcode:text };
        if (typeof setVoter === 'function') setVoter(new_voter);
    }
    function onChangeToVBDate(text) {
        const new_voter = { ...voter, vbdate:text };
        if (typeof setVoter === 'function') setVoter(new_voter);
    }
    function onChangeToVEmail(text) {
        const new_voter = { ...voter, vemail:text };
        if (typeof setVoter === 'function') setVoter(new_voter);
    }
    
    function onChangeToVPhone(text) {
        const new_voter = { ...voter, vphone:text };
        if (typeof setVoter === 'function') setVoter(new_voter);
    }
    
    return (
        <div className="w-full grid grid-cols-[auto_1fr] gap-x-3 gap-y-2 justify-start items-center text-sm">

            {voter.vid && 
                <>
                    <label>{localeMap["poll03.voter.label.vid"]}</label>
                    <PzInput.ForText value={voter.vid} disabled={true}/>
                </>
            }

            <label>{localeMap["poll03.voter.label.vname"]}</label>
            <PzInput.ForText value={voter.vname} onChangeDone={onChangeToVName} disabled={!editable}/>

            <label>{localeMap["poll03.voter.label.vcode"]}</label>
            <PzInput.ForText value={voter.vcode} onChangeDone={onChangeToVCode} disabled={!editable}/>

            <label>{localeMap["poll03.voter.label.vbdate"]}</label>
            <PzInput.ForText value={voter.vbdate} onChangeDone={onChangeToVBDate} disabled={!editable}/>

            <label>{localeMap["poll03.voter.label.vphone"]}</label>
            <PzInput.ForText value={voter.vphone} onChangeDone={onChangeToVPhone} disabled={!editable}/>

            <label>{localeMap["poll03.voter.label.vemail"]}</label>
            <PzInput.ForText value={voter.vemail} onChangeDone={onChangeToVEmail} disabled={!editable}/>

        </div>
    );
}
