import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import NavbarItemLocale from "./NavbarItemLocale";
import NavbarItemMyEvents from "./NavbarItemMyEvents";
import NavbarItemLoggedIn from "./NavbarItemLoggedIn";
import { ReactComponent as IconMenu } from "../resource/icons/pz-hamburger-menu.svg";
import PzButton from "../common/PzButton";
import PzDropdown from "../common/PzDropdown";
import PzDialog from "../common/PzDialog";

export default function Navbar({uauth, open4Sidebar, setOpen4Sidebar}) {
    const location = useLocation();
    const isAuthPage = location.pathname.startsWith("/auth/");
    const localeMap = useSelector(utils.selectLocaleMap);
    const screenSV = (window.innerWidth <= 300 && window.innerWidth < window.innerHeight); // Note that it's not updated with resize
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = React.useState(false);

    // Window Size Change
    // const handleWindowSizeChange = () => {
    //     setScreenSV(window.innerWidth <= 500 && window.innerWidth < window.innerHeight);
    // }
    // React.useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     }
    // }, []);

    return (
        <div className="w-full h-14 px-4 py-0 top-0 flex flex-row justify-between items-center">

            <div className="w-fit h-full flex flex-row items-center gap-4">
                {uauth && !open4Sidebar && 
                    <button className="w-auto h-auto cursor-pointer text-slate-600 hover:text-slate-900" onClick={()=>setOpen4Sidebar(true)} >
                        <IconMenu width="1.4rem" height="1.4rem" />
                    </button>
                }
                {!open4Sidebar && 
                    <>
                    <button className="cursor-pointer text-slate-600 hover:text-slate-900" onClick={()=>navigate("/")} >
                        <h2 className="font-extrabold">Pollerz.net</h2>
                    </button>
                    { appConfig.version==='ALPHA' && <PzDialog.Alpha /> }
                    </>
                }
            </div>

            <div className={"w-fit h-full flex flex-row items-center " + (window.innerWidth < 500 ? "gap-0" : "gap-2")}>

                <NavbarItemLocale/>

                { uauth ?
                    <>
                        <NavbarItemMyEvents />
                        <NavbarItemLoggedIn />
                    </>
                : isAuthPage ?
                    <> </>
                : screenSV ?
                    <PzDropdown 
                        open={menuOpen} setOpen={setMenuOpen} ha={'right'}
                        button={
                            <IconMenu width="1.4rem" height="1.4rem" />
                        } >
                        <ul className="p-2 flex flex-col items-start justify-center text-center gap-0">
                            <li>
                                <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/getstarted")} disabled={appConfig.version==='ALPHA'} >
                                    <h5>{localeMap["menu.home.howto"]}</h5>
                                </PzButton.Minimal>
                            </li>
                            <li>
                                <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/pricing")} disabled={appConfig.version==='ALPHA'} >
                                    <h5>{localeMap["menu.home.pricing"]}</h5>
                                </PzButton.Minimal>
                            </li>
                            <li>
                                <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/auth/login")} >
                                    <h5>{localeMap["menu.auth.login"]}</h5>
                                </PzButton.Minimal>
                            </li>
                            <li>
                                <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/auth/signup")} >
                                    <h5>{localeMap["menu.auth.signup"]}</h5>
                                </PzButton.Minimal>
                            </li>
                        </ul>
                    </PzDropdown>
                :
                    <>
                        <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/getstarted")} disabled={appConfig.version==='ALPHA'} >
                            <h5>{localeMap["menu.home.howto"]}</h5>
                        </PzButton.Minimal>
                        <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/pricing")} disabled={appConfig.version==='ALPHA'} >
                            <h5>{localeMap["menu.home.pricing"]}</h5>
                        </PzButton.Minimal>
                        <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/auth/login")} >
                            <h5>{localeMap["menu.auth.login"]}</h5>
                        </PzButton.Minimal>
                        <PzButton.Minimal className="px-2 py-1" onClick={()=>navigate("/auth/signup")} >
                            <h5>{localeMap["menu.auth.signup"]}</h5>
                        </PzButton.Minimal>
                    </>
                }

            </div>

        </div>
    );
}
