import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import PzDialog from "../../common/PzDialog";
import EntranceForRehearsal from "../../entrance/EntranceForRehearsal";
import PzButton from "../../common/PzButton";
import EventCard from "../../eventview/EventCard";
import EvtViewerForKTW from "../../eventview/EvtViewerForKTW";
import { ModalToEditEventKTW } from "../../eventview/EvtEditorForKTW";
import EvtEditorForQstTable from "../../eventview/EvtEditorForQstTable";
import UserSplash from "../UserSplash";
import { ReactComponent as IconToolEditor } from "../../resource/icons/pz-tool-editor.svg";
import { ReactComponent as IconToolTrash }  from "../../resource/icons/pz-tool-trash.svg";
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";
import { ReactComponent as IconQRCode } from "../../resource/icons/pz-qrcode.svg";

export default function EditEvent() {
    const [cookies] = useCookies(['uauth']);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const dispatch = useDispatch();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open4Test, setOpen4Test] = React.useState(false);
    const [open4KTW, setOpen4KTW] = React.useState(false);
    const [open4Deletion, setOpen4Deletion] = React.useState(false);
    const navigate = useNavigate();

    // React.useEffect(() => {
    // }, []);

    function updateEventInfo(new_poll) {
        appConfig.getAPI().post("/api/mp/update", new_poll)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const new_pguid = pzdata.resource;
            console.log("PzAPI '/api/mp/update' success : ", pzdata);
            if (new_pguid && new_pguid.length === 32 && new_pguid !== new_poll.pguid) {
                console.log("pguid changed from ", new_poll.pguid, " to ", new_pguid);
                new_poll = { ...new_poll, pguid: new_pguid }
            }
            dispatch(setUPoll(new_poll));
        })
        .catch((error) => {
            console.log("PzAPI '/api/mp/update' error : ", error);
        })
        .finally(() => { });
    }

    function onClickToDeleteEvent() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/delete", {pguid:upoll.pguid})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI '/api/mp/delete' success : ", pzdata);
            navigate("/pages/myevents");
        })
        .catch((error) => {
            console.log("PzAPI '/api/mp/delete' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    function onExpireToPWhen() {
        console.log("Expired");
        // dispatch(setUPoll(null));
        // navigate("/pages/myevents");
    }

    const etype = (upoll ? localeMap["comm.event."+upoll.pkind[0].toLowerCase()] : undefined);
    const title = (etype ? `[${etype}] ` : '') + localeMap["menu.poller.editevent"];

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <PzArea.InvisibleBoardInContainer>

            <EventCard poll={upoll} viewable={false} foldable={true} />

            <PzArea.FullBoardWithTitle title={title} >

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <h3 onClick={()=>{if (uauth.ispza) console.log(upoll);}}>
                        {localeMap["poller.editevent.event"]}
                    </h3>
                    <div className="w-full flex flex-row justify-between items-end px-10 gap-10">

                        <EvtViewerForKTW poll={upoll} size='lg' onExpire={onExpireToPWhen} />

                        <div className="flex flex-col justify-end items-center gap-4">
                            <PzButton.Std Icon={IconToolEditor} size='4xl' onClick={()=>{setOpen4KTW(true);}}      className={"!px-4 !py-2 " + upoll.pstatus < 'Ax' ? '' : 'invisible'} />
                            <PzButton.Std Icon={IconToolTrash}  size='4xl' onClick={()=>{setOpen4Deletion(true);}} className={"!px-4 !py-2 " + upoll.pstatus[0] !== 'O' ? '' : 'invisible'} />
                        </div>

                    </div>
                </div>

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <h3>{localeMap["poller.editevent.qlist"]}</h3>
                    <EvtEditorForQstTable poll={upoll} setPoll={setUPoll} className="px-10" />
                </div>

                <div className="w-full flex flex-row justify-between items-center mt-10">
                    <PzButton.Std Icon={IconStepPrev} text={localeMap["menu.myevents"]}        size='lg' onClick={()=>{navigate("/pages/myevents");}} />
                    { upoll.pstatus <= 'Ax' && 
                    <PzButton.Std text={localeMap["menu.poller.simtest"]}  size='lg' onClick={()=>{setOpen4Test(true);}} />
                    }
                    <PzButton.Std Icon={IconStepNext} text={localeMap["menu.poller.getready"]} size='lg' onClick={()=>{navigate("/pages/poller/getready");}} />
                </div>

                { open4Test &&
                    <EntranceForRehearsal.Modal poll={upoll} onClose={()=>setOpen4Test(false)} />
                }
                { open4KTW && 
                    <ModalToEditEventKTW poll={upoll} onSaveChanges={updateEventInfo} onClose={()=>{setOpen4KTW(false);}} />
                }
                { open4Deletion && 
                    <PzDialog.Simple title={localeMap["poll.delete.title"]} 
                        message={"\"" + upoll.ptitle + "\"\n"+ localeMap["poll.delete.message"]} 
                        onClose={()=>{setOpen4Deletion(false);}}
                        onButtonCancel={()=>{setOpen4Deletion(false);}}
                        onButtonOk={onClickToDeleteEvent} />
                }

            </PzArea.FullBoardWithTitle>

        </PzArea.InvisibleBoardInContainer>
    );
}
