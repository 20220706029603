import React from "react";

import { utils } from "../config/userConfig";
import PzDialog from "../common/PzDialog";
import PzSymbol from "../common/PzSymbol";

export default function EMDialogQstRounds({poll, pqst, onClose}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const rounds = get_round_results();

    function get_round_results() {
        let rounds = [];
        if (!poll || !pqst) return rounds;
        for (let r = 0; r <= pqst.qround; r++) {
            let before = (r <= 1 ? [] : utils.getChosenAnswers(pqst, 1, r-1));
            let nc4s  = (r === 0 ? pqst.nwanted : utils.countValidAnswers(pqst, r));
            let nc2s  = (r === 0 ? pqst.nwanted : pqst.nwanted - before.length);
            let goal  = utils.completeLocaleMapText(localeMap, "monitor.quest.goal." + pqst.qkind, [ nc2s, nc4s], poll.pkind);
            let chosen  = (r === 0 ? utils.getChosenAnswers(pqst, 1,pqst.qround)    : utils.getChosenAnswers(pqst, r,r));
            let dropped = (r === 0 ? utils.countDroppedAnswers(pqst, 1,pqst.qround) : utils.countDroppedAnswers(pqst, r,r));
            rounds.push( { goal:goal, chosen:chosen, dropped:dropped, last:(r===pqst.qround) } );
        }
        return rounds;
    }

    return (
        <PzDialog.Empty className="w-auto" onClose={onClose} >
            <div className="w-full flex flex-col justify-start items-start gap-5 max-h-[40rem] overflow-y-scroll">
                { pqst.qroundmax > 1 && rounds.slice(1).map((rnd,idx)=>(
                    <div key={idx} className="grid grid-cols-[5rem_1fr] justify-start items-start gap-2">
                        <div><PzSymbol.Text text={'Round'+(idx+1)} size="lg" op50={true}/></div>
                        <div className="grid grid-cols-[auto_1fr] gap-x-2 gap-y-0">
                            <label>{localeMap["monitor.quest.goal.label"]} : </label>
                            <label className="font-bold">{rnd.goal}</label> 
                            <label>{localeMap["monitor.quest.goal.selected"]} : </label>
                            <label className="font-bold">{rnd.chosen.length > 0 ? rnd.chosen.join(', ') : '-'}</label>
                            { pqst.qkind !== 'A' &&
                                <>
                                    <label>{localeMap["monitor.quest.goal.dropped"]} : </label>
                                    <label className="font-bold">{rnd.last && pqst.qstatus <= 'Vt' ? '-' : rnd.dropped}</label>
                                </>
                            }
                        </div>
                    </div>
                )) }
                <div className="grid grid-cols-[5rem_1fr] justify-start items-start gap-2">
                    <div><PzSymbol.Text text={'Result'} size="lg" op50={true} /></div>
                    <label className="font-bold">{rounds[0].chosen.length > 0 ? rounds[0].chosen.join(', ') : '-'}</label>
                </div>
            </div>

        </PzDialog.Empty>
    );
}

