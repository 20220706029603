import React from "react";

import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";
import PzSymbol from "../common/PzSymbol";
import EMDialogQstHistory from "./EMDialogQstHistory";

export default function EMComQstMap({poll, className='', invisible=false}) {
    const [open4QHist, setOpen4QHist] = React.useState(false);
    const [qSelected, setQSelected] = React.useState(null);
    const visibility = (invisible || poll.pqstlist.length <= 1 ? " invisible " : " ");
    const itemgap = (poll.pqstlist.length >= 10 ? " gap-3 " : " gap-4 ")

    function onClickToViewQHistory(q) {
        // console.log("onClickToViewQHistory() :", q);
        if (q.qstatus < 'Vx' && q.qround < 2) return;
        setQSelected(q); 
        setOpen4QHist(true);
    }

    if (!poll) {
        return (<h3>poll is null</h3>);
    }

    return (
        <>
            <div className={"w-full flex flex-row justify-center items-center gap-4 overflow-x-scroll " + visibility + itemgap + className}>
                { poll.pqstlist.map((q, qidx)=>{
                    return ( qidx === poll.qactive || (invisible && qidx == 0) ?
                        <PzSymbol.Text key={qidx} text={'Q'+q.qseq} size='md'
                            className="!bg-purple-800 !text-white hover:cursor-pointer" 
                            onClick={()=>{ onClickToViewQHistory(q); }} />
                    : q.qstatus < 'Vx' ? // not voted yet
                        <div key={qidx} className="w-[0.7rem] h-[0.7rem] border-2 border-slate-700 bg-white     hover:cursor-pointer rounded-md" 
                            onClick={()=>{}} />
                    :
                        <div key={qidx} className="w-[0.7rem] h-[0.7rem] border-2 border-slate-700 bg-slate-400 hover:cursor-pointer rounded-md" 
                            onClick={()=>{ onClickToViewQHistory(q); }} />
                    ); 
                })}
            </div>

            { open4QHist &&
                <EMDialogQstHistory poll={poll} pqst={qSelected} onClose={()=>{setOpen4QHist(false);}} />
            }
        </>
    );
}

