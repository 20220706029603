import React from "react";
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal";
import PzTable, { PztShortTooltip } from "../common/PzTable";

export default function EvtEditorForStaffModal({poll, setPoll, onClose}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [staffPgOpts, setStaffPgOpts] = React.useState({page:0,pageSize:10,pguid:poll.pguid});
    const [staffPgData, setStaffPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});

    React.useEffect(() => {
        fetchStaffList(staffPgOpts);
    }, []);

    const fetchStaffList = (opts)=>{
        if (!staffPgOpts.pguid) return;
        appConfig.getAPI().post("/api/mp/staff/list", opts)
        .then((response) => {
            // console.log("PzApi 'stafflist' responded : ", response.data);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setStaffPgData(pgdata);
        })
        .catch((error) => {
            console.log("PzAPI 'stafflist' error : ", error);
        })
        .finally(() => {});
    };

    return (
        <PzBasicModal onClose={onClose}>
            <div className="min-w-[600px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h2 className="font-bold">{localeMap["poll.card.staff.label"]}</h2>  
                
                <PzTable 
                    data={staffPgData} opts={staffPgOpts} setOpts={setStaffPgOpts} fetchData={fetchStaffList}
                    rowKey="uemail" searchable={true} configurable={false} 
                    columns={[
                        { title: 'PGuid', key: 'pguid', hidden: true,
                            render: ({pguid})=>{return (<PztShortTooltip value={pguid} stt={12} end={30}/>);} },
                        { title: 'Email', key: 'uemail' },
                        { title: 'Name', key: 'uname' },
                        { title: 'Role', key: 'role',
                            render: ({role})=>{return (<p>{role==='M' ? 'Manager' : 'Staff'}</p>)} },
                        { title: 'CheckedAt', key: 'checkedat', datetime:'YYYY-MM-DD HH:mm' },
                    ]} />

            </div>
        </PzBasicModal>
    );
}
