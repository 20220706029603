import React from "react";
import { useInterval } from "react-use";
import { useSelector, useDispatch } from "react-redux";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";

export default function Pricing() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const timezone = useSelector((state)=>state.ucfg.timezone);
    const dispatch = useDispatch();
    const [counters, setCounters] = React.useState({p:1, v:1, c:1});
    const [loading, setLoading] = React.useState(false);

    // React.useEffect(() => {
    // }, []);

    return (
        <div className="w-full py-[calc(10vh)] overflow-y-scroll flex flex-col justify-start items-center gap-10"> 

            <div className="w-[80%] min-w-[200px] max-w-[1600px] flex flex-col justifiy-start items-center gap-4">
                <p className="font-extrabold text-3xl">{localeMap["home.page.pricing.title"]}</p>
            </div>


        </div>
    );
}

