import React from "react";

import TextField from '@mui/material/TextField';

export default function PzTextField({placeholder='', onEnter=(text)=>{}}) {

    return (
        <TextField 
            size="small" 
            placeholder={placeholder} // 
            sx={{ width: 200 }}
            inputProps={{ style: { padding: 0 } }}
            InputProps={{ style: { padding: 5 }, sx: { borderRadius: 1 } }} 
            onChange={(e)=>{}}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    onEnter(e.target.value);
                }
            }}
        />
    )
}
