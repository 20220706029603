import React from "react";
import { useSelector } from "react-redux";

import { utils } from "../config/userConfig";
import PzInput          from '../common/PzInput';

export default function EvtEditorForStaff({ staff, setStaff, editable, editable_email }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    // console.log("staff : ", staff);

    function onChangeToUEmail(text) {
        const new_staff = { ...staff, uemail:text };
        if (typeof setStaff === 'function') setStaff(new_staff);
    }
    
    function onChangeToUName(text) {
        const new_staff = { ...staff, uname:text };
        if (typeof setStaff === 'function') setStaff(new_staff);
    }
    
    function onChangeToRole(text) {
        const new_staff = { ...staff, role:text };
        if (typeof setStaff === 'function') setStaff(new_staff);
    }
    
    return (
        <div className="w-full grid grid-cols-[auto_1fr] gap-x-3 gap-y-2 justify-start items-center text-sm">
            
            <label>{localeMap["poll04.staff.label.name"]}</label>
            <PzInput.ForText value={staff.uname} onChangeDone={onChangeToUName} disabled={!editable}/>

            <label>{localeMap["poll04.staff.label.email"]}</label>
            <PzInput.ForText value={staff.uemail} onChangeDone={onChangeToUEmail} disabled={editable && editable_email ? false : true}/>

            <label>{localeMap["poll04.staff.label.role"]}</label>
            <div className="grid grid-cols-2 items-start">
                <PzInput.Radio1 name="s1type" value='S' checked={staff.role==='S'} onChange={()=>{onChangeToRole('S')}} disabled={!editable}> {localeMap["poll04.staff.label.role.staff"]} </PzInput.Radio1>
                <PzInput.Radio1 name="s1type" value='M' checked={staff.role==='M'} onChange={()=>{onChangeToRole('M')}} disabled={!editable}> {localeMap["poll04.staff.label.role.manager"]} </PzInput.Radio1>
            </div>
            
        </div>
    );
}
