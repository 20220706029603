import React from 'react';
import Popover from '@mui/material/Popover';

const PzDropdown = ({children, button, id, ha, open=false, setOpen=()=>{}}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const id2 = id ? id : 'dropdown';
    const onOpen = (e) => { setAnchorEl(e.currentTarget); setOpen(true);  };
    const onClose = () => { setAnchorEl(null);            setOpen(false); };

    return (
        <div>
            <div aria-describedby={id2} className="w-auto" onClick={onOpen}>
                {button}
            </div>
            <Popover id={id2} open={open} anchorEl={anchorEl} onClose={onClose} 
                anchorOrigin={{vertical:'bottom',horizontal:(typeof ha === 'string' ? ha : 'right')}} 
                transformOrigin={{vertical:'top',horizontal:(typeof ha === 'string' ? ha : 'right')}}>
                {children}
            </Popover>
        </div>
    );
};

export default PzDropdown;


