import React from 'react';
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from '../common/PzBasicModal';
import PzTable from "../common/PzTable";

export default function EvtViewerForQstResult({pqst}) {
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [data, columns] = getResultTableDataAndColumns(pqst);
    const [open4VCastList, setOpen4VCastList] = React.useState(null);

    function getResultTableDataAndColumns(pqst) {
        let columns = [], data = [];
        if (!pqst || !pqst.answers) return [data, columns];
        // create 'columns' array
        columns.push({title:'Answer', key: 'answer', render:({answer,chosen})=>{return (<p className={chosen ? 'font-bold' : ''}>{answer}</p>)}});
        columns.push({title:'AStat', key: 'astat', hidden:true});
        for (let r = 1; r <= pqst.qroundmax; r++) {
            columns.push({
                title:`R${r}`, key:`r${r}`, align:'right', hidden:false, 
                onClick:(row,key)=>{
                    if (!uauth?.ispza || row.astat) return;
                    setOpen4VCastList({qguid: pqst.qguid, qround: r});
                },
            });
        }
        columns.push({title:'Chosen', key:'chosen', align:'center', checksign:true, hidden:false});
        // append each candidate into 'data' array
        for (let i = 1; i < pqst.answers.length; i++) { // 0-th answer is 'WAIVER'
            let a = pqst.answers[i];
            let adata = { answer: a.aname, astat:a.astat };
            for (let r = 1; r <= pqst.qroundmax; r++) {   // Round 0 is reserved
                if (a.rvcount && r < a.rvcount.length) {
                    adata[`r${r}`] = a.rvcount[r];
                } else {
                    adata[`r${r}`] = undefined;
                }
            }
            let chosen = false;
            for (let c = 0; c < pqst.chosen.length; c++) {
                if (a.aname === pqst.chosen[c].aname) { chosen = true; break; }
            }
            adata['chosen'] = chosen;
            if (chosen) {
                adata['emphasized_column'] = `r${a.rvcount.length-1}` // the last round
            }
            data.push(adata);
        }
        // append WAIVER into 'data' array
        if (pqst.answers && pqst.answers.length >= 1) {
            let a = pqst.answers[0];
            let wdata = { answer: localeMap['poll.xresult.label.residual'], astat:'', footer: true };
            for (let r = 1; r <= pqst.qroundmax; r++) {   // Round 0 is reserved
                if (a.rvcount && r < a.rvcount.length) {
                    wdata[`r${r}`] = a.rvcount[r];
                } else {
                    wdata[`r${r}`] = undefined;
                }
            }
            data.push(wdata);
        }
        // append TOTAL into 'data' array
        if (pqst.chosen && pqst.chosen.length >= 1) {
            let t = pqst.chosen[0];
            let tdata = { answer: localeMap['poll.xresult.label.total'], astat:'', footer: true };
            for (let r = 1; r <= pqst.qroundmax; r++) {   // Round 0 is reserved
                if (t.rvcount && r < t.rvcount.length) {
                    tdata[`r${r}`] = t.rvcount[r];
                } else {
                    tdata[`r${r}`] = undefined;
                }
            }
            data.push(tdata);
        }
        return [data, columns];
    }

    return (
        <>
            <PzTable data={data} columns={columns} configurable={true} />
            { open4VCastList &&
                <ModalForVCastList qguid={open4VCastList.qguid} qround={open4VCastList.qround} onClose={()=>{setOpen4VCastList(null)}}/>
            }
        </>
    );

}

function ModalForVCastList({qguid, qround, onClose, filter=undefined}) {
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const [vcastPgOpts, setVcastPgOpts] = React.useState({page:0, pageSize:10, qguid:qguid, qround:qround});
    const [vcastPgData, setVcastPgData] = React.useState({page:0, pageSize:10, pageData:[], total:0});

    React.useEffect(() => {
        fetchVcastList(vcastPgOpts);
    }, []);

    function fetchVcastList(opts) {
        if (!uauth?.ispza) return;
        appConfig.getAPI().post("/api/pza/vcastlist", opts)
        .then((response)=>{
            // console.log("PzApi 'vcastlist' responded : ", response.data);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setVcastPgData(pgdata);
        })
        .catch((error)=>{
            console.log("PzAPI 'pza/vcastlist' error : ", error);
        })
        .finally(()=>{});
    };
    

    return (
        <PzBasicModal onClose={onClose} xclose={true} >
            <div className="min-w-[36rem] flex flex-col justify-start items-stretch p-5 gap-5">

                <h2>VCast List</h2>

                <PzTable 
                    data={vcastPgData} opts={vcastPgOpts} setOpts={setVcastPgOpts} fetchData={fetchVcastList}
                    rowKey="vid" searchable={true} configurable={true} 
                    columns={[
                        { title: 'R', key: 'qround', hidden:false },
                        { title: 'VID', key: 'vid', hidden:false, onClick:(row,key)=>{console.log(row);} },
                        { title: 'Name', key: 'vname', hidden:false },
                        { title: 'Choice', key: 'choice', render:({choice})=>{return(<p>{JSON.stringify(choice)}</p>)} },
                        { title: 'Comment', key: 'comment', hidden: true },
                        { title: 'VotedAt', key: 'votedat', datetime: 'HH:mm:ss' },
                        { title: 'VoterIP', key: 'voterip', hidden:true },
                        { title: 'Location', key: 'location', hidden:false },
                        { title: 'HelpedBy', key: 'helpedby', hidden:true },
                    ]} />
            </div>
        </PzBasicModal>
    );
}


