import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from 'redux-thunk';

import { userConfigSlice } from "./userConfig";
import { upollConfigSlice } from "./upollConfig";
import { voterConfigSlice } from "./voterConfig";

const persistConfig = {
    key: "pollerzConfig",
    storage,
    timeout: null,
    whitelist: [
        userConfigSlice.name,
        upollConfigSlice.name,
        voterConfigSlice.name,
    ], // 
};

const rootReducer = combineReducers({
    [userConfigSlice.name]: userConfigSlice.reducer,
    [upollConfigSlice.name]: upollConfigSlice.reducer,
    [voterConfigSlice.name]: voterConfigSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: ()=>[thunk],
    // middleware: getDefaultMiddleware => getDefaultMiddleware({ thunk: {} }),
});

const persistor = persistStore(store);

export { store, persistor };
