import React from "react";

function AzContainer({ children }) {
    // h-[calc(100vh-56px)] 
    return (
        <div className="w-full flex flex-col justify-start items-center p-5 gap-5">  
            {children}
        </div>
    );
}

function AzBoard({ children }) {
    return (
        <div className="w-full min-w-[25rem] max-w-[30rem] bg-slate-50 flex flex-col p-5 gap-10" >
            {children}
        </div>
    );
}

function AzBoardInContainer({ children }) {
    return (
        <AzContainer>
            <AzBoard>
                {children}
            </AzBoard>
        </AzContainer>
    );
}

function AzMwhBoard({ children }) {
    return (
        <div className="w-[70%] min-w-[min(max(94vw,360px),500px)] max-w-[min(94vw,600px)] min-h-[min(max(80vh,500px),700px)] flex flex-col justify-center  bg-slate-50 p-5" >
            {children}
        </div>
    );
}

function AzMwhBoardInContainer({ children }) {
    return (
        <AzContainer>
            <AzMwhBoard>
                {children}
            </AzMwhBoard>
        </AzContainer>
    );
}

const  AzArea = {
    Container: AzContainer,
    Board: AzBoard,
    BoardInContainer: AzBoardInContainer,

    MwhBoard: AzMwhBoard,
    MwhBoardInContainer: AzMwhBoardInContainer,
};

export default AzArea;
