import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useInterval } from "react-use";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzDialog from "../../common/PzDialog";
import EMAreaHeader from "../../eventmonitor/EMAreaHeader";
import EMAreaBodyAsVoter from "../../eventmonitor/EMAreaBodyAsVoter";
import EMAreaFooterAsVoter from "../../eventmonitor/EMAreaFooterAsVoter";
import EMWithPzServer  from "../../eventmonitor/EMWithPzServer";
import EMWithSimulator from "../../eventmonitor/EMWithSimulator";
import EMSplash from "../../eventmonitor/EMSplash";


export default function SimVoter() {
    const location = useLocation();
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies(['vauth']);
    const [vauth, setVAuth] = React.useState(cookies && cookies.vauth ? jwtDecode(cookies.vauth) : null);
    const [qparams] = useSearchParams();
    const pguid = qparams.get("p");
    const [poll, setPoll] = React.useState(null);
    const pqst = (poll?.qactive >= 0 && poll?.pqstlist ? poll?.pqstlist[poll?.qactive] : null);
    const [qIndex, setQIndex] = React.useState(-1);
    const [qRound, setQRound] = React.useState(-1);
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const [svcStatus, setSvcStatus] = React.useState('');
    const [voting, setVoting] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');
    const [selection, setSelection] = React.useState(null);
    const nc2s = (pqst ? pqst.nwanted - (pqst.chosen?.length - 1) : 0);
    const [open4Warn, setOpen4Warn] = React.useState(false);

    React.useEffect(() => {
        // removeCookie("vauth", null);
        let checkedin = (vauth != null);
        if (vauth && vauth.pguid !== pguid) {
            removeCookie('vauth', null);
            setVAuth(null);
            checkedin = false;
        }
        // dispatch(vutils.setChoices([]));
        if (!checkedin && pguid?.length === 32) { 
            console.log("/y/vote navigate to /y/vcheckin");
            navigate("/y/vcheckin?p=" + pguid);
        }
    }, []);
    
    useInterval(() => {
        updateEventInfo();
    }, 1000);

    function updateEventInfo() {
        if (svcStatus === 'badaddr' || svcStatus === 'checkin') return;
        else if (!poll) EMWithPzServer.updateEventInfo(pguid, poll, setPoll2, '/api/v/event', 'V',  setSvcStatus);
        else           EMWithSimulator.updateEventInfo(pguid, poll, setPoll2, true, setSvcStatus);
    }

    function setPoll2(new_poll) {
        // This function is called on poll update is SUCCESS & CHANGED 
        setPoll(new_poll);
        if (!poll) {    // first time
            setPoll({ ...new_poll, pstatus:'Oo', nmax:60, nmax:120, xrole:'V' });
        } else {
            setPoll(new_poll);
        }
        const qactive = (new_poll ? new_poll.qactive : null);
        const qround = (qactive >= 0 && new_poll?.pqstlist ? new_poll?.pqstlist[qactive].qround : null);
        if (qactive !== qIndex || (qactive === qIndex && qround !== qRound)) {
            // the current question & round has been changed
            setQIndex(qactive);
            setQRound(qround);
            setSelection([]);
            // setCastChoice(null);
            // let prev_choice = vutils.getChoice(saved_choices, qactive, qround);
            // console.log(typeof prev_choice, prev_choice);
            // setPrevChoice(prev_choice);
            // setSelection(prev_choice ? prev_choice : []);
            // console.log(`/vote quest changed : qactive=${qactive} qround=${qround} `);
        }
    }

    function onClickToVote() {
        if (!poll) { console.log("poll invalid"); return; }
        if (!pqst) { console.log("pqst invalid"); return; }
        if (qRound < 1) { console.log("qRound invalid"); return; }
        if (selection.length < nc2s) {
            setOpen4Warn(true);
        } else {
            castVoteAsVoter();
        }
    }
    function castVoteAsVoter() {
        const simulate = (location.pathname.startsWith("/y/"));
        if (!poll) { console.log("poll invalid"); return; }
        if (!pqst) { console.log("pqst invalid"); return; }
        if (qRound < 1) { console.log("qRound invalid"); return; }
        setOpen4Warn(false);
        setVoting(true);
        setErrMsg('');
        if (simulate) EMWithSimulator.castVoteAsVoter(vauth.vid, poll, setPoll, pqst, qRound, selection, onSuccessToVote, onErrorToVote);
        else           EMWithPzServer.castVoteAsVoter(vauth.vid, poll, setPoll, pqst, qRound, selection, onSuccessToVote, onErrorToVote);
    }

    function onSuccessToVote() {
        setVoting(false);
        setErrMsg('');
    }

    function onErrorToVote(respmsg, respval) {
        setVoting(false);
        if (respmsg && respmsg.includes('duplicated')) {
            setErrMsg(localeMap["voter.vcast.err.duplicate"]);
        } else {
            setErrMsg(respmsg);
        }
    }

    if (pguid === 'THANKYOU') {
        return (<EMSplash msg={localeMap["monitor.splash.thankyou"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'badaddr') {
        return (<EMSplash msg={localeMap["monitor.splash.badaddr"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'checkin') {
        return (<EMSplash msg={localeMap["monitor.splash.checkin"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'closed') {
        return (<EMSplash msg={localeMap["monitor.splash.closed"]} gohome={true} xclose={true} />);
    } else if (svcStatus === 'loading') {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    } else if (svcStatus  != '') {
        return (<EMSplash msg={svcStatus} />);
    } else if (!poll) {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    }

    return (
        <PzArea.Container className="bg-slate-100">
            <PzArea.MwhBoard className="px-0 py-10 select-none bg-white gap-10">

                <div className="w-full h-[calc(100vh-14rem)] px-20 flex flex-col justify-start gap-10 overflow-y-scroll">
                    <EMAreaHeader poll={poll} className="pb-5" asVoter={true} />
                    <EMAreaBodyAsVoter   poll={poll} pqst={pqst} selection={selection} setSelection={setSelection} />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </div>

                {/* <div className="absolute bottom-0 left-0 w-full pt-5 pb-10 overflow-clip">
                    <div className="w-full mt-5 p-5 bg-white pz-em-shadow">
                        <EMAreaFooterAsVoter poll={poll} pqst={pqst} selection={selection} setPoll={setPoll} castVote={castVoteAsVoter} />
                    </div>
                </div> */}

                <div className="w-full absolute bottom-10 left-0">
                    <EMAreaFooterAsVoter poll={poll} pqst={pqst} selection={selection} setPoll={setPoll} castVote={castVoteAsVoter} />
                </div>
                <div className="w-full absolute bottom-5  left-0 text-center" >
                    <h5 className="text-slate-400">{localeMap["simulate.comment"]}</h5>
                </div>

            </PzArea.MwhBoard >

            { open4Warn && selection.length < nc2s &&
                <PzDialog.Simple title={
                    (utils.completeMessageText("{2}명 중 {1}명 선택", [selection.length, nc2s]))
                } message={
                    (utils.completeMessageText("{1}명만 선택하였습니다. 그대로 투표하시겠습니까?", [selection.length]))
                } 
                onClose={()=>{setOpen4Warn(false);}} onButtonOk={castVoteAsVoter} onButtonCancel={()=>{setOpen4Warn(false);}}/>
            }
        </PzArea.Container>
    );

}

