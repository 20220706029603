import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

// Authentication
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import ResetPWord from "./auth/ResetPWord";

// User pages
import Home from "./pages/home/Home";
import Features from "./pages/home/Features";
import GetStarted from "./pages/home/GetStarted";
import Mission from "./pages/home/Mission";
import Pricing from "./pages/home/Pricing";
import MyAccount from "./pages/aspoller/MyAccount";
import MyEvents from "./pages/aspoller/MyEvents";
import PublicPoll from "./pages/public/PublicPoll";
import PublicSurvey from "./pages/public/PublicSurvey";
import PublicQuiz from "./pages/public/PublicQuiz";

import EditEvent from "./pages/aspoller/EditEvent";
import GetReady from "./pages/aspoller/GetReady";
import Notification from "./pages/aspoller/Notification";
import RunPoll from "./pages/aspoller/RunPoll";
import XPollerResult from "./pages/aspoller/XPollerResult";
import XFinalReport from "./pages/aspoller/XFinalReport";

import Entrance from "./pages/asstaff/Entrance";
import Eligibility from "./pages/asstaff/Eligibility";
import VoteHelper from "./pages/asstaff/VoteHelper";
import WatchPoll from "./pages/asstaff/WatchPoll";
import XStaffResult from "./pages/asstaff/XStaffResult";

// PzAdmin pages
import PzAdminDashboard from "./pages/pza/PzAdminDashboard";
import PzAdminPubEvents from "./pages/pza/PzAdminPubEvents";
import PzAdminUserList from "./pages/pza/PzAdminUserList";
import PzAdminPollList from "./pages/pza/PzAdminPollList";
import PzAdminUserInfo from "./pages/pza/PzAdminUserInfo";
import PzAdminPollInfo from "./pages/pza/PzAdminPollInfo";
import PzAdminQstInfo from "./pages/pza/PzAdminQstInfo";
import PzAdminFeedback from "./pages/pza/PzAdminFeedback";
import PzAdminViewDesc from "./pages/pza/PzAdminViewDesc";
import PzAdminViewLogs from "./pages/pza/PzAdminViewLogs";

// Voter pages
import Checkin from "./pages/mobile/Checkin";
import Run from "./pages/mobile/Run";
import Vote from "./pages/mobile/Vote";

// Simulation pages
import SimCheckinAsVoter from "./pages/sim/SimCheckinAsVoter";
import SimCheckinAsPoller from "./pages/sim/SimCheckinAsPoller";
import SimVoter  from "./pages/sim/SimVoter";
import SimRunner from "./pages/sim/SimRunner";

import EmptyLayout from "./layouts/EmptyLayout";
import BasicLayout from "./layouts/BasicLayout";
import NotFound from "./pages/NotFound";
import UserForgotten from "./pages/UserForgotten";

const router = createBrowserRouter([
    {
        path: "/",                  // Pages for public (open) access
        element: <BasicLayout />,
        children: [
            { element: <Navigate to="/home" />, index: true },
            { path: "home", element: <Home /> },
            { path: "getstarted", element: <GetStarted /> },
            { path: "features", element: <Features /> },
            { path: "mission", element: <Mission /> },
            { path: "pricing", element: <Pricing /> },
            { path: "forgotten", element: <UserForgotten /> },
        ],
    },
    {
        path: "/auth",              // Pages for User Authentication
        element: <BasicLayout />,
        children: [
            { element: <Navigate to={"/auth/login"} />, index: true },
            { path: "login", element: <Login /> },
            { path: "signup", element: <Signup /> },
            { path: "resetpw", element: <ResetPWord /> },
        ],
    },
    {
        path: "/public",              // 
        element: <BasicLayout />,
        children: [
            { element: <Navigate to={"publicsurvey"} />, index: true },
            { path: "publicpoll", element: <PublicPoll /> },
            { path: "publicsurvey", element: <PublicSurvey /> },
            { path: "publicquiz", element: <PublicQuiz /> },
        ],
    },
    {
        path: `/pages`,             // Pages for Loged-in Users
        element: <BasicLayout />,
        children: [
            { element: <Navigate to="myevents" />, index: true },
            { path: "myevents", element: <MyEvents /> },
            { path: "myaccount", element: <MyAccount /> },
            { path: "poller/editevent", element: <EditEvent /> },
            { path: "poller/getready", element: <GetReady /> },
            { path: "poller/notify", element: <Notification /> },
            { path: "poller/runpoll", element: <RunPoll /> },
            { path: "poller/xresult", element: <XPollerResult /> },
            { path: "poller/xreport", element: <XFinalReport /> },
            { path: "staff/entrance", element: <Entrance /> },
            { path: "staff/eligibility", element: <Eligibility /> },
            { path: "staff/votehelper", element: <VoteHelper /> },
            { path: "staff/watchpoll", element: <WatchPoll /> },
            { path: "staff/xresult", element: <XStaffResult /> },
            { path: "voter/vote", element: <MyEvents /> },
        ],
    },
    {
        path: "/pages/pza",     // Pages for PzAdmin Users
        element: <BasicLayout />,
        children: [
            { element: <Navigate to={"dashboard"} />, index: true },
            { path: "dashboard", element: <PzAdminDashboard /> },
            { path: "users", element: <PzAdminUserList /> },
            { path: "polls", element: <PzAdminPollList /> },
            { path: "pubevts", element: <PzAdminPubEvents /> },
            { path: "archived", element: <PzAdminPollList /> },
            { path: "userinfo", element: <PzAdminUserInfo /> },
            { path: "pollinfo", element: <PzAdminPollInfo /> },
            { path: "qstinfo", element: <PzAdminQstInfo /> },
            { path: "feedback", element: <PzAdminFeedback /> },
            { path: "sysdesc", element: <PzAdminViewDesc /> },
            { path: "syslogs", element: <PzAdminViewLogs /> },
        ],
    },
    {
        path: "/p",                 // Mobile Pages for Poller
        element: <EmptyLayout />,
        children: [
            { element: <Navigate to={"run"} />, index: true },
            { path: "run", element: <Run /> },
        ],
    },
    {
        path: "/v",                 // Mobile Pages for Voters 
        element: <EmptyLayout />,
        children: [
            { element: <Navigate to={"vote"} />, index: true },
            { path: "checkin", element: <Checkin /> },
            { path: "vote", element: <Vote /> },
        ],
    },
    {
        path: "/y",                 // Mobile Pages for Trial (Simulation) 
        element: <EmptyLayout />,
        children: [
            { element: <Navigate to={"vote"} />, index: true },
            { path: "vote", element: <SimVoter  /> },                   // Simulated trial     as Voter
            { path: "prun", element: <SimRunner /> },                   // Simulated trial     as Poller
            { path: "rrun", element: <SimRunner /> },                   // Simulated Rehearsal as Poller
            { path: "vcheckin", element: <SimCheckinAsVoter /> },
            { path: "pcheckin", element: <SimCheckinAsPoller /> },
            { path: "rcheckin", element: <SimCheckinAsPoller /> },
        ],
    },
    {
        element: <EmptyLayout />,
        children: [
        {
            path: "*",
            index: true,
            element: <NotFound />,
        },
        ],
    },
]);

export default router;
