import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import EventCard from "../../eventview/EventCard";
import UserSplash from "../UserSplash";

export default function Entrance() {
    const [cookies] = useCookies();
    const navigate = useNavigate();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    // console.log(upoll);

    const POLL_URL_PATH = '/v/vote?p=' + upoll?.pguid;

    // React.useEffect(() => {
    //   uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    //   if (!uauth) navigate("/");
    // }, [cookies]);

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'S' && upoll.xrole !== 'M' && upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event"} /> );
    }  

    return (
        <PzArea.InvisibleBoardInContainer>

            <EventCard poll={upoll} viewable={false} foldable={true} />

            <PzArea.FullBoardWithTitle title={localeMap["menu.staff.entrance"]} >
                <div className="w-full grid grid-cols-[auto_1fr] justify-start items-start gap-4">

                    <h4>URL</h4>
                    <PzButton.ToNavigate label={POLL_URL_PATH} destination={POLL_URL_PATH} withNewTab={true} />

                    <h4>QR-code</h4>
                    <div className="w-full bg-slate-100">
                        <img src={`/api/s/entrance/qrcode?p=${upoll?.pguid}`} alt="" className="object-none object-center"/>
                    </div>
                </div> 
            </PzArea.FullBoardWithTitle>

        </PzArea.InvisibleBoardInContainer>
    );
}
