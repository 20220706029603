import React from "react";
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal";
import PzTable, { PztShortTooltip } from "../common/PzTable";

export default function EvtEditorForVoterModal({poll, setPoll, onClose}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [voterPgOpts, setVoterPgOpts] = React.useState({page:0,pageSize:10,pguid:poll?.pguid});
    const [voterPgData, setVoterPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});

    React.useEffect(() => {
        fetchVoterList(voterPgOpts);
    }, []);

    const fetchVoterList = (opts)=>{
        if (!voterPgOpts.pguid) return;
        appConfig.getAPI().post("/api/mp/voter/list", opts)
        .then((response) => {
            // console.log("PzApi 'voterlist' responded : ", response.data);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setVoterPgData(pgdata);
            console.log(pgdata);
        })
        .catch((error) => {
            console.log("PzAPI 'voterlist' error : ", error);
        })
        .finally(() => {});
    };

    return (
        <PzBasicModal onClose={onClose}>
            <div className="min-w-[600px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h2 className="font-bold">{utils.completeLocaleMapText(localeMap, "poll.card.voters.label", [], poll.pkind)}</h2>  
                
                <PzTable 
                    data={voterPgData} opts={voterPgOpts} setOpts={setVoterPgOpts} fetchData={fetchVoterList}
                    rowKey="vid" searchable={true} configurable={false} 
                    columns={[
                        { title: 'VID', key: 'vid', hidden: true,
                            render: ({vid})=>{return (<PztShortTooltip value={vid} stt={2} />);} },
                        { title: 'Name', key: 'vname' },
                        { title: 'VCode', key: 'vcode', hidden: true },
                        { title: 'BDate', key: 'vbdate' },
                        { title: 'Phone', key: 'vphone' },
                        { title: 'Email', key: 'vemail' },
                        { title: 'CheckIn', key: 'checkin', hidden: true },
                        { title: 'CheckOut', key: 'checkout', hidden: true },
                        { title: 'LastVote', key: 'lastvote', hidden: true },
                    ]} />

            </div>
        </PzBasicModal>
    );
}
