import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useInterval } from "react-use";

import { utils } from "../config/userConfig";
import PzArea from "../common/PzArea";
import EMAreaHeader from "./EMAreaHeader";
import EMAreaBodyAsPoller from "./EMAreaBodyAsPoller";
import EMAreaFooterAsPoller from "./EMAreaFooterAsPoller";
import EMWithPzServer from "./EMWithPzServer";
import EMSplash from "./EMSplash";

export default function EventWatcher({poll, setPoll=null}) {
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const pqst = (poll?.qactive >= 0 && poll.pqstlist ? poll.pqstlist[poll?.qactive] : null);
    const [qparams] = useSearchParams();
    const pguid = qparams.get("p");
    const [errMsg, setErrMsg] = React.useState('');

    useInterval(() => {
        if      (!setPoll)      return;
        EMWithPzServer.updateEventInfo(poll, setPoll, '/api/s/event', poll.xrole, setErrMsg);
    }, 1000);

    if (pguid === 'THANKYOU') {
        return (<EMSplash msg={localeMap["monitor.splash.thankyou"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'badaddr') {
        return (<EMSplash msg={localeMap["monitor.splash.badaddr"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'checkin') {
        return (<EMSplash msg={localeMap["monitor.splash.checkin"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'closed') {
        return (<EMSplash msg={localeMap["monitor.splash.closed"]} gohome={true} xclose={true} />);
    } else if (errMsg === 'loading') {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    } else if (errMsg  != '') {
        return (<EMSplash msg={errMsg} />);
    } else if (!poll) {
        return (<EMSplash msg={localeMap["monitor.splash.loading"]} />);
    }

    return (
        <PzArea.MwhBoard className="p-20 select-none">

            <EMAreaHeader poll={poll} className="" asVoter={false} />

            <EMAreaBodyAsPoller   poll={poll} pqst={pqst} setPoll={setPoll} />

            <EMAreaFooterAsPoller poll={poll} pqst={pqst} setPoll={setPoll} />

        </PzArea.MwhBoard> 
    );
}

