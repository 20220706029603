import React from "react";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzProgress from "../common/PzProgress";
import EMComFinalResults from "./EMComFinalResults";
import EMComQstList from "./EMComQstList";
import EMComQstHeader from "./EMComQstHeader";
import EMComQstAnswers from "./EMComQstAnswers";
import EMComQstFooter from "./EMComQstFooter";
import EMComQstResult from "./EMComQstResult";

export default function EMAreaBodyAsPoller({poll, pqst, setPoll, setSvcStatus=()=>{}}) {
    const pstatus = (poll ? poll.pstatus : ' ');
    const qstatus = (pqst ? pqst.qstatus : ' ');
    const localeMap = utils.selectMapByLocale(poll?.locale);
    const ranswers = (poll && pqst && pstatus[0] === 'O' ? get_round_answers() : []);
    const tanswers = (poll && pqst && pqst.qstatus === 'Vx' ? get_top_answers(ranswers) : []);
    const [qstFolded, setQstFolded] = React.useState(true);
    const first = isFirstTime();

    function isFirstTime() {
        if (!poll) return false;
        let closed_pqst = 0;
        for (let i = 0; i < poll.pqstlist.length; i++) {
            if (poll.pqstlist[i].qstatus >= 'Vx') closed_pqst++;
        }
        return (closed_pqst === 0);
    }

    function get_round_answers() {
        let answers = []; // list of selectable answers for the current round
        for (let i = 1; pqst && i < pqst.answers.length; i++) {
            let a = pqst.answers[i];  // 'aidx', 'aname', 'adesc', 'astat', and 'rvcount'
            let rcount = (qstatus >= 'Vx' && a.rvcount?.length == pqst.qround + 1 ? a.rvcount[pqst.qround] : 0);
            if (a.astat[0] === pqst.qround.toString()) answers.push({ ...a, rcount: rcount });
        }
        return answers;
    }

    function get_top_answers(ranswers) {
        let nonzeros = [];
        for (let i = 0; ranswers && i < ranswers.length; i++) {
            let a = ranswers[i]; // { aidx:1, aname:'', adesc:null, astat:'1-', rvcount:[...], rcount:99 }
            if (a.rcount > 0) nonzeros.push(a);
        }
        if (nonzeros.length === 0) return nonzeros;
        nonzeros.sort((a,b)=>{return -(a.rcount - b.rcount);})
        if (nonzeros.length > 3 && nonzeros[nonzeros.length-1].rcount < nonzeros[2].rcount / 5) {
            // if (LEAST < 3RD / 5), then remove LEAST answers from the list
            let pos = -1;
            for (let i = nonzeros.length-1; i >= 2; i--) {
                if (nonzeros[i].rcount < nonzeros[2].rcount / 5) pos = i;
                else break;  // note that 'a.rvcount' might be null (when 'qstatus' === 'Vt')
            }
            if (pos > 0) nonzeros = nonzeros.slice(0, pos);
        }
        let MAX_LIMIT = 5
        if (nonzeros.length > MAX_LIMIT) {
            // if len(NONZEROS) > MAX_LIMIT, then remove answers with smaller counts
            let pos = -1, last_rcount = nonzeros[0].rcount;
            for (let i = 0; i < nonzeros.length; i++) {
                if (i >= MAX_LIMIT && nonzeros[i].rcount < last_rcount) { pos = i; break; }
                last_rcount = nonzeros[i].rcount;
            }
            if (pos > 0) nonzeros = nonzeros.slice(0, pos);
        }
        return nonzeros;
    }

    if (!poll) {
        return (<h3>poll is null</h3>);
    }
    
    return (
        <div className="w-full">
            { pstatus == 'Oo' ?    // open for participants
                <div className="w-full pt-20 flex flex-col justify-start items-center">
                    <div className="w-full flex flex-col justify-start items-center gap-5">
                        <label className="text-2xl font-bold">
                            {localeMap["monitor.progress.checkin"]}
                        </label>
                        <PzProgress className="w-[20rem]" size='3xl' bold={true} count={poll.natt} total={poll.nmax} />
                        { poll.natt < poll.nmin ? 
                            <label className={"text-xl font-bold mt-10 "}>
                                {localeMap["monitor.waiting.event.checkin"]}
                            </label>
                        :
                            <label className={"text-xl font-bold mt-10 "}>
                                {utils.completeMessageText(localeMap["monitor.progress.checked"], [poll.nmin])}
                            </label>
                        }
                    </div>
                </div>
            : pstatus == 'Os' ?    // 
                ( !pqst ?
                    <div className="w-full flex flex-col justify-start items-stretch gap-5">
                        <label className="text-2xl font-bold">
                            {first ? localeMap["monitor.quest.selectfirst"] : localeMap["monitor.quest.selectnext"]}
                        </label>
                        <EMComQstList poll={poll} setPoll={setPoll} selectable={true} deselectable={!first} />
                    </div>
                : qstatus === 'Vs' ?
                    <div className="w-full flex flex-col justify-start items-stretch gap-5">
                        <label className="text-2xl font-bold">{localeMap["monitor.quest.voting.asked"]}</label>
                        <div className="w-full ring-slate-800 ring-inset ring-1 rounded-2xl flex flex-col justify-start items-stretch gap-4">  
                            <EMComQstHeader  poll={poll} pqst={pqst} bold={true} className="pt-4 px-4" qstFolded={qstFolded} setQstFolded={setQstFolded} />
                            { !qstFolded && 
                            <>
                                <div className="w-full h-[1px] bg-slate-200"></div>
                                <EMComQstAnswers pqst={pqst} answers={ranswers} preview={true} selectable={false} className="px-8" />
                            </>
                            }
                            <div className="w-full h-[1px] bg-slate-800"></div>
                            <EMComQstFooter  poll={poll} pqst={pqst} roundAnswers={ranswers} className="pb-4 px-4" />
                        </div>
                        <div className="w-full pt-2 flex flex-col justify-center items-center gap-1">
                            <label className="text-xl font-bold">{localeMap["monitor.progress.voting"]}</label>
                            <PzProgress className="w-[50%]" size="xl" bold={true} count={pqst.nvcast} total={poll.natt} />    
                        </div>
                    </div>
                : qstatus === 'Vt' || qstatus === 'Vx' ?
                    <div className="w-full flex flex-col justify-start items-stretch gap-5">
                        <label className="text-2xl font-bold">
                            { qstatus === 'Vt' ? localeMap["monitor.quest.tally.count"] : localeMap["monitor.quest.tally.result"]}
                        </label>
                        <div className="w-full ring-slate-800 ring-inset ring-1 rounded-2xl flex flex-col justify-start items-stretch gap-4">  
                            <EMComQstHeader poll={poll} pqst={pqst} bold={true} className="pt-4 px-4" qstFolded={qstFolded} setQstFolded={setQstFolded} tanswers={tanswers} />
                            <div className="w-full h-[1px] bg-slate-200"></div>
                            <EMComQstResult poll={poll} pqst={pqst} roundAnswers={ranswers} topAnswers={tanswers} folded={qstFolded} />
                            <div className="w-full h-[1px] bg-slate-800"></div>
                            <EMComQstFooter  poll={poll} pqst={pqst} roundAnswers={ranswers} className="pb-4 px-4" />
                        </div>
                    </div>
                : 
                    <h3>invalid status &emsp; pstatus={pstatus} &emsp; qstatus={qstatus}</h3>
                )
            : pstatus >= 'Oz' && pstatus  <= 'Xp' ?
                <div className="w-full flex flex-col justify-start items-stretch gap-5">
                    <label className="text-2xl font-bold">
                        {localeMap["monitor.result.title"]}
                    </label>
                    <EMComFinalResults poll={poll} />
                </div>
            : 
                <div>&nbsp;</div>
            }
        </div>
    );
}

