import React from "react";

import CircularProgress from '@mui/material/CircularProgress';

export default function AzButton({children, type='', onClick=()=>{}, disabled=false, className='', loading=false}) {
    const u_bttn = "cursor-pointer px-2 py-2 text-sm font-semibold shadow-sm text-slate-800 disabled:text-slate-400 hover:text-blue-600 disabled:opacity-50";
    const u_undr = "underline text-blue-500 cursor-pointer font-mono disabled:opacity-50";
    const u_ring = "ring-inset ring-1 hover:ring-2 rounded-md text-slate-800 hover:text-blue-600";
    const class_names = (type==='U' ? u_undr : type==='R' ? u_bttn + ' ' + u_ring : u_bttn);

    return (
        <button onClick={onClick} className={class_names + ' ' + className} disabled={disabled}>
            { loading ?
                <CircularProgress variant="indeterminate" size="1.2rem" thickness={4}/>
            :
                <h4 className="font-bold">{children}</h4>
            }
        </button>
    )
}
