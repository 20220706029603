import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import EventCard from "../../eventview/EventCard";
import { GetReadyVoters, GetReadyVoterList } from "./GetReadyVoter";
import { GetReadyStaffs, GetReadyStaffList } from "./GetReadyStaff";
import GetReadyPca from "./GetReadyPca";
import UserSplash from "../UserSplash";
import { ReactComponent as PzStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as PzStepPrev } from "../../resource/icons/pz-step-prev.svg";

export default function GetReady() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [cookies] = useCookies(['uauth']);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const navigate = useNavigate();
    const [vState, setVState] = React.useState({nt:0,nb:0,np:0,ne:0,na:0,updated:dayjs(),folded:true});
    const [sState, setSState] = React.useState({nt:0,nm:0,ns:0,          updated:dayjs(),folded:true});
    const [aSummary, setASummary] = React.useState('');
    const [fold4Voters, setFold4Voters] = React.useState(true);
    const [fold4Staffs, setFold4Staffs] = React.useState(true);
    if (vState.folded != fold4Voters) { setFold4Voters(vState.folded); }
    if (sState.folded != fold4Staffs) { setFold4Staffs(sState.folded); }
    const dispatch = useDispatch();

    const etype = (upoll ? localeMap["comm.event."+upoll.pkind[0].toLowerCase()] : undefined);
    const title = (etype ? `[${etype}] ` : '') + localeMap["menu.poller.getready"];
    let vSummary = utils.completeMessageText(localeMap["poller.getready.voters." + (vState.nt===0 ? "sum0" : "sum1")], [vState.nt]);
    let sSummary = utils.completeMessageText(localeMap["poller.getready.staffs." + (sState.nt===0 ? "sum0" : "sum1")], [sState.nt]);
    let ready4next = (upoll.pstatus >= 'Ax');
    // console.log("GetReady:", upoll.pstatus, ready4next);

    // console.log("GetReady : ", upoll?.pkind, upoll?.pstatus, upoll?.pstatus < 'Ax');
    // console.log("vState:", vState);

    // React.useEffect(() => {
    // }, []);

    function setUPoll2(new_poll) {
        dispatch(setUPoll(new_poll));
    }
    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <PzArea.InvisibleBoardInContainer>

            <EventCard poll={upoll} viewable={false} foldable={true} />

            <PzArea.FullBoardWithTitle title={title} >

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <div className="w-full pr-5 flex flex-row justify-between items-center">
                        <h3>{localeMap["poller.getready.voters"]}</h3>
                        <p className="text-lg">({vSummary})</p>
                    </div>
                    <GetReadyVoters poll={upoll} setPoll={setUPoll2} vState={vState} setVState={setVState} />
                    { !fold4Voters && 
                        <GetReadyVoterList poll={upoll} setPoll={setUPoll2} vState={vState} setVState={setVState} className="px-10" /> 
                    }
                </div>

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <div className="w-full pr-5 flex flex-row justify-between items-center">
                        <h3>{localeMap["poller.getready.staffs"]}</h3>
                        <p className="text-lg">({sSummary})</p>
                    </div>
                    <GetReadyStaffs poll={upoll} setPoll={setUPoll2} sState={sState} setSState={setSState} />
                    { !fold4Staffs && 
                        <GetReadyStaffList poll={upoll} setPoll={setUPoll2} sState={sState} setSState={setSState} className="px-10" /> 
                    }
                </div>

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <div className="w-full pr-5 flex flex-row justify-between items-center">
                        <h3>{localeMap["poller.getready.pca.title"]}</h3>
                        <p className="text-lg">({aSummary})</p>
                    </div>
                    <GetReadyPca poll={upoll} setPoll={setUPoll2} setSummary={setASummary}/>
                </div>

                <div className="w-full flex flex-row justify-between items-center gap-5 mt-10">
                    <PzButton.Std Icon={PzStepPrev} text={localeMap["menu.poller.editevent"]}   size='lg' onClick={()=>{navigate("/pages/poller/editevent");}}/>
                    { !upoll.pwhen ?
                        <PzButton.Std Icon={PzStepNext} text={localeMap["menu.poller.runpoll"]} size='lg' onClick={()=>{navigate("/pages/poller/runpoll");}} disabled={!ready4next}/>
                    :
                        <PzButton.Std Icon={PzStepNext} text={localeMap["menu.poller.notify"]}  size='lg' onClick={()=>{navigate("/pages/poller/notify");}} disabled={!ready4next}/>
                    }
                </div>

            </PzArea.FullBoardWithTitle>

        </PzArea.InvisibleBoardInContainer>
    );
}
