import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInterval } from "react-use";
import { useLocation } from "react-router-dom";
import { SHA256, enc } from "crypto-js";
import dayjs from "dayjs";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import { setUPoll } from "../config/upollConfig";
import AzArea from "./azcomp/AzArea";
import AzButton from "./azcomp/AzButton";
import AzButtonToNavigate from "./azcomp/AzButtonToNavigate";
import AzInputForEmail from "./azcomp/AzInputForEmail";
import AzInputForPWord from "./azcomp/AzInputForPWord";


export default function Login() {
    const navigate = useNavigate();
    const localeMap = useSelector(utils.selectLocaleMap);
    const ulocale = useSelector((state)=>state.ucfg.locale);  // user's current locale selection
    const utzone = useSelector((state)=>state.ucfg.timezone); // user's current timezone selection
    const [email, setEmail] = React.useState({value:'', errmsg:''});
    const [pword, setPword] = React.useState({value:'', errmsg:''});
    const [errMsg, setErrMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [nextTry, setNextTry] = React.useState(null);
    const location = useLocation();
    const dispatch = useDispatch();

    function postLogin() {
        setLoading(true);
        
        const pwhash = SHA256(pword.value).toString(enc.Hex);
        appConfig.getAPI().post("/api/auth/login", {email:email.value, pword64:pwhash, locale:ulocale, timezone:utzone})
        .then((response)=>{
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI 'login' success : ", pzdata);
            // navigate to default or referer page
            if (!location.state || !location.state.referer) {
                navigate("/pages/myevents");        // default page
            } else if (!location.state.pguid) {
                navigate(location.state.referer);   // referer page
            } else {
                appConfig.getAPI().post("/api/s/event", {pguid:location.state.pguid, claim:'P'})
                .then((response)=>{ dispatch(setUPoll(response.data.resource)); })
                .catch((error)=>{ })
                .finally(()=>{ navigate(location.state.referer); });
            }
        })
        .catch((error)=>{
            if (error.code === 'ERR_NETWORK') {
                setErrMsg(localeMap["auth.errmsg.network"]); 
            } else if (error.response && error.response.status === 400) { // BAD_REQUEST
                const pzdata = error.response.data;     // {respcode:0, respmsg:'success', resource:{...}}
                switch (pzdata.respcode) {
                    case 21 : // "user not found"
                        setErrMsg(localeMap["auth.login.errmsg.nouser"]); 
                        break;   
                    case 22 : // "user blocked"
                        setNextTry(dayjs(pzdata.resource.blockuntil));
                        setErrMsg(localeMap["auth.login.errmsg.missxx"]);
                        break;   
                    case 23 : // "password mismatch (...)"
                        setErrMsg(utils.completeMessageText(localeMap["auth.login.errmsg.missed"], [pzdata.resource.trial, pzdata.resource.maxtrials]));
                        break;   
                    case 24 : // "password mismatch (blocked)"
                        setNextTry(dayjs(pzdata.resource.blockuntil));
                        setErrMsg(localeMap["auth.login.errmsg.missxx"]);
                        break;   
                    default:
                        setErrMsg(pzdata.respmsg);
                        break;   
                }
            } else {
                setErrMsg(localeMap["auth.errmsg.unknown"] + ` (${error.code})` + ' : ' + JSON.stringify(error.response.data)); 
                console.log("PzAPI 'login' failure - status:", error.response?.status, ", error:", error);
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    };
    
    useInterval(() => {
        if (nextTry && dayjs().isAfter(nextTry)) {
            setErrMsg('');
            setNextTry(null);
        }
    }, 1000);

    return (
        <AzArea.MwhBoardInContainer>

            <div className="w-full flex flex-col justify-start items-center py-4 gap-4" >

                <div className="w-full flex flex-col justify-start items-center" >
                    <h1>{localeMap["auth.login.title"]}</h1>
                    <h5 className="flex flex-row justify-center text-slate-500">
                        &nbsp;{localeMap["auth.login.subtitle"]}&nbsp;
                    </h5>
                </div>

                <div className="w-[16rem] flex flex-col justify-start items-stretch gap-3">
                    <div className="flex flex-col justify-start items-stretch gap-3">
                        <AzInputForEmail v={email} setV={setEmail} 
                            onChange={()=>{if (errMsg!=='') setErrMsg('');}} 
                            placeholder={localeMap["auth.login.input.email"]}    className="w-full" autoFocus={true} />
                        <AzInputForPWord v={pword} setV={setPword} 
                            onChange={()=>{if (errMsg!=='') setErrMsg('');}} 
                            onChangeDone={(text,enter)=>{ if (enter) postLogin(); }}
                            placeholder={localeMap["auth.login.input.password"]} className="w-full" />
                    </div>
                    <h5 className="text-xs text-center text-slate-500">&nbsp;{errMsg}&nbsp;</h5>
                </div>

                <div className="w-full flex flex-col justify-start items-center gap-2">
                    <AzButton type='R' className="w-[16rem] flex justify-center" 
                        disabled={email.value === '' || email.errmsg!=='' || pword.value==='' || pword.errmsg!=='' || errMsg!=='' || loading} 
                        onClick={postLogin} loading={loading} >
                        {localeMap["auth.login.button"]}
                    </AzButton>
                    <div className="flex flex-col justify-start items-center gap-0">
                        <AzButtonToNavigate label={localeMap["auth.login.nav1.label"]} button={localeMap["auth.login.nav1.button"]} destination={"/auth/signup"} />
                        <AzButtonToNavigate label={localeMap["auth.login.nav2.label"]} button={localeMap["auth.login.nav2.button"]} destination={"/auth/resetpw"} />
                    </div>
                </div>

            </div>
            
        </AzArea.MwhBoardInContainer>
    );
}

