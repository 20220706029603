import React from "react";

import { useSelector } from "react-redux";

import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";
import PzButton from "../common/PzButton";
import EvtEditorForQst from "./EvtEditorForQst";
import { ReactComponent as IconButtonAdd } from "../resource/icons/pz-button-add.svg";
import { ReactComponent as IconButtonSub } from "../resource/icons/pz-button-sub.svg";

export default function EvtEditorForQstList({ poll, setPoll, size='lg', onChange=()=>{} }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [selQidx, setSelQidx] = React.useState([0]);
    const [detail, setDetail] = React.useState(false);
    const last_qst = (poll && poll.pqstlist && poll.pqstlist.length > 0 ? poll.pqstlist[poll.pqstlist.length-1] : undefined);
    const last_qst_ok = (last_qst && last_qst.question !== '' && last_qst.nanswers >= 2 && last_qst.answers.length >= 2);
    const text_size = 'text-' + size;

    function setCurrQst(qst) {
        if (selQidx[0] >= 0 && selQidx[0] < poll.pqstlist.length) {
            poll.pqstlist[selQidx[0]] = qst;
            if (typeof setPoll === 'function') setPoll({...poll, pqstlist: poll.pqstlist});
        }
        if (typeof onChange === 'function') onChange();
    }
    function onChangeForQst(e) {
        let new_qidx = Number(e.target.value);
        setSelQidx(new_qidx);
        if (typeof onChange === 'function') onChange();
    }

    function appendNextQuestion() {
        let new_pqstlist = [ ...poll.pqstlist ];
        const qanswers = (localeMap["poll02.answers.A"] ? localeMap["poll02.answers.A"].split('\n') : []);
        new_pqstlist.push({qseq:poll.pqstlist.length, question:'', qkind:'A', nanswers:qanswers.length, nwanted:1, qroundmax:1, question:'', answers:qanswers});
        let new_poll = { ...poll, pqstlist: new_pqstlist };
        if (typeof setPoll === 'function') setPoll(new_poll);
        let new_qidx = poll.pqstlist.length;
        if (new_qidx !== selQidx[0]) setSelQidx([new_qidx]);
        if (typeof onChange === 'function') onChange();
    }

    function removeLastQuestion() {
        if (poll.pqstlist.length <= 1) return;
        let new_pqstlist = [];
        for (let i = 0; i < poll.pqstlist.length-1; i++) {
            new_pqstlist.push(poll.pqstlist[i]);
        }
        if (typeof setPoll === 'function') setPoll({ ...poll, pqstlist: new_pqstlist });
        let new_qidx = (selQidx[0] < new_pqstlist.length ? selQidx[0] : new_pqstlist.length-1);
        if (new_qidx !== selQidx[0]) setSelQidx([new_qidx]);
        if (typeof onChange === 'function') onChange();
    }

    return (
        <div className={"flex flex-col gap-y-1 " + text_size} >
            {poll.pqstlist?.map((q,idx) =>
                <div key={idx}>
                    <div  className="w-full grid grid-cols-[1fr_1fr] gap-x-2 cursor-pointer">
                        <PzInput.Radio2 value={idx} selection={selQidx} setSelection={setSelQidx} size={size} 
                            text={q.question!=='' ? q.question : utils.completeMessageText(localeMap["poll02.question.example"], [idx+1])} />
                        <p onClick={()=>{console.log(q);}}>{utils.getQstGoalSummary(q, localeMap)}</p>
                    </div>
                    {idx === selQidx[0] &&
                    <div className="ml-5 my-3">

                        <EvtEditorForQst qst={poll.pqstlist[selQidx[0]]} setQst={setCurrQst} 
                            size={size} visibility={{k:true,q:true,g:(detail)}} narrow={true} />
                        
                    </div>
                    }
                </div>
            )}
            <div className="mt-1 flex flex-row justify-end gap-x-4 text-sm">
                {poll.pqstlist.length > 1 &&
                    <PzButton.Std Icon={IconButtonSub} text={localeMap["poll02.button.removelast"]} size='sm' bold={false} onClick={removeLastQuestion} className="!px-2 !py-[0.06rem]" />
                }
                <PzButton.Std Icon={IconButtonAdd} text={localeMap["poll02.button.appendnew"]} size='sm' bold={false} onClick={appendNextQuestion} className="!px-2 !py-[0.06rem]" disabled={!last_qst_ok} />
                <PzInput.Checkbox1 value={detail} checked={detail} onChange={()=>{setDetail(!detail)}} className="!px-1" >{localeMap["poll02.button.showdetail"]}</PzInput.Checkbox1>
            </div>
        </div>
    )
}
