import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzDialog from "../../common/PzDialog";
import EventCard from "../../eventview/EventCard";
import UserSplash from "../UserSplash";
import EvtViewerForKTW from "../../eventview/EvtViewerForKTW";
import XQuestResults from "./XQuestResults";
import { ReactComponent as IconToolArchive } from "../../resource/icons/pz-tool-archive.svg";
import { ReactComponent as IconToolTrash }  from "../../resource/icons/pz-tool-trash.svg";
import { ReactComponent as IconStepNext } from "../../resource/icons/pz-step-next.svg";
import { ReactComponent as IconStepPrev } from "../../resource/icons/pz-step-prev.svg";

export default function XPollerResult() {
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open4Deletion, setOpen4Deletion] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        // if (upoll && upoll.pqstlist && upoll.pqstlist.length >= 1 && currQidx == -1) setCurrQidx(0);
    }, []);

    function onClickToDeleteEvent() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/delete", {pguid:upoll.pguid})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI '/api/mp/delete' success : ", pzdata);
            navigate("/pages/myevents");
        })
        .catch((error) => {
            console.log("PzAPI '/api/mp/delete' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    const etype = (upoll ? localeMap["comm.event."+upoll.pkind[0].toLowerCase()] : undefined);
    const title = (etype ? `[${etype}] ` : '') + localeMap["menu.poller.xresult"];

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event."} /> );
    }  

    return (
        <PzArea.InvisibleBoardInContainer>

            <EventCard poll={upoll} viewable={false} foldable={true} />

            <PzArea.FullBoardWithTitle title={title} >

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <h3>{localeMap["poller.editevent.event"]}</h3>
                    <div className="w-full px-10 flex flex-row justify-between items-end gap-10">

                        <EvtViewerForKTW poll={upoll} size='lg' />

                        <div className="flex flex-col justify-end items-center gap-4">
                            <PzButton.Std Icon={IconToolArchive} size='2xl' onClick={()=>{}} />
                            <PzButton.Std Icon={IconToolTrash}   size='2xl' onClick={()=>{setOpen4Deletion(true);}} />
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col justify-start items-start gap-5">
                    <h3>{localeMap["menu.xresult.details"]}</h3>
                    <XQuestResults poll={upoll} className="ml-10" />
                </div>

                {/* <div className="w-full flex flex-col justify-start items-start gap-5">
                    <h3>{localeMap["menu.xresult.monitor"]}</h3>
                    <div className="w-full flex flex-col justify-start items-center">
                        <EventRunner poll={upoll} />
                    </div>
                </div> */}

                <div className="w-full flex flex-row justify-between items-center gap-5 mt-10">
                    <PzButton.Std Icon={IconStepPrev} text={localeMap["menu.myevents"]}       size='lg' onClick={()=>{navigate("/pages/myevents");}}/>
                    <PzButton.Std Icon={IconStepNext} text={localeMap["menu.poller.xreport"]} size='lg' onClick={()=>{navigate("/pages/poller/xreport");}}/>
                </div>

                { open4Deletion && 
                    <PzDialog.Simple title={localeMap["poll.delete.title"]} 
                        message={"\"" + upoll.ptitle + "\"\n"+ localeMap["poll.delete.message"]} 
                        onClose={()=>{setOpen4Deletion(false);}}
                        onButtonCancel={()=>{setOpen4Deletion(false);}}
                        onButtonOk={onClickToDeleteEvent} />
                }
            </PzArea.FullBoardWithTitle>

        </PzArea.InvisibleBoardInContainer>
    );
}
