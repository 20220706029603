import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import { setUPoll } from "../../config/upollConfig";
import { ReactComponent as IconButtonAdd } from "../../resource/icons/pz-button-add.svg";

import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import EmptyCard from "../../eventview/EmptyCard";
import EventCard from "../../eventview/EventCard";
import ModalToCreateEvent from "./ModalToCreateEvent";

export default function MyEvents() {
    const [cookies] = useCookies(['uauth']);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open4CreateQuick, setOpen4CreateQuick] = React.useState(false);  
    const [open4CreateEvent, setOpen4CreateEvent] = React.useState(false);  
    const [loading4Pln, setLoading4Pln] = React.useState(false);
    const [loading4Ach, setLoading4Ach] = React.useState(false);
    const [loading4Sys, setLoading4Sys] = React.useState(false);
    const [planned, setPlanned] = React.useState(null);
    const [archived, setArchived] = React.useState(null);
    const [sysevents, setSysEvents] = React.useState(null);
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    // console.log("MyEvents :", upoll.ptitle);

    React.useEffect(() => {
        // console.log("MyEvents init", document.body.style.zoom);
        // document.body.style.zoom = "100%";
        if (uauth) {
            fetchMyEvents('PLANNED');
        }
    }, []);

    function fetchMyEvents(target='', default_pguid='') {
        let pzapi = '';
        if      (target === 'SYSEVENTS') { pzapi = "/api/my/sysevts"; setLoading4Sys(true); }
        else if (target === 'ARCEVENTS') { pzapi = "/api/my/archived"; setLoading4Ach(true); }
        else                             { pzapi = "/api/my/planned"; setLoading4Pln(true); }
        appConfig.getAPI().post(pzapi, null)
        .then((response) => {
            // console.log("PzApi 'myevents' responded : ", response.data);
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            if (pzdata.respcode !== 0) {                // PzAPI returned failure
                console.log("PzAPI 'myevents' returned failure : ", pzdata);
            } else if (target == 'SYSEVENTS') {
                setSysEvents(pzdata.resource);
            } else if (target == 'ARCEVENTS') {
                setArchived(pzdata.resource);
            } else {
                let new_planned = pzdata.resource;
                setPlanned(new_planned);
                if (new_planned.length == 1) {
                    dispatch(setUPoll(new_planned[0]));
                } else if (default_pguid) {
                    for (let i = 0; i < pzdata.resource.length; i++) {
                        if (pzdata.resource[i].pguid === default_pguid) { 
                            dispatch(setUPoll(pzdata.resource[i]));
                            break;
                        }
                    }
                } else if (upoll && upoll.pstatus < 'Xx') { 
                    let upoll_found = false; // if there is a selected 'upoll', then update it
                    for (let i = 0; i < pzdata.resource.length; i++) {
                        if (upoll.pguid === pzdata.resource[i].pguid) { upoll_found = true; break; }
                    }
                    if (!upoll_found) dispatch(setUPoll(null)); 
                }
            }
        })
        .catch((error) => {
            console.log("PzAPI '" + pzapi + "' error : ", error);
        })
        .finally(() => {
            if      (target === 'SYSEVENTS') { setLoading4Sys(false); }
            else if (target === 'ARCEVENTS') { setLoading4Ach(false); }
            else                             { setLoading4Pln(false); }
        });
    };

    function getBoardTitle(keyward, plist) {
        return localeMap[keyward] + (plist ? ` [${plist?.length}]` : '');
    }

    function onChangeToArcEvtsFold(folded_old, folded_new) {
        if (!folded_new && (!archived || archived.length === 0)) {
            fetchMyEvents('ARCEVENTS');
        }
    }

    function onChangeToSysEvtsFold(folded_old, folded_new) {
        if (!folded_new && (!sysevents || sysevents.length === 0)) {
            fetchMyEvents('SYSEVENTS');
        }
    }

    function onSuccessToCreateEvent(created_pguid) {
        fetchMyEvents('PLANNED', created_pguid);
    }

    return (
        <PzArea.InvisibleBoardInContainer>

            { uauth?.uemail ? 
                <div className="w-full pt-5 flex flex-row justify-end gap-5">
                    <PzButton.Std Icon={IconButtonAdd} text={localeMap["poll.create_event"]} size='lg' 
                        onClick={()=>{setOpen4CreateEvent(true);}} />
                </div> 
                : 
                <div className="w-full pt-5 flex flex-row justify-center items-center gap-2">
                    <p className="text-md font-semibold text-slate-900">
                        {localeMap["myevents.needtologin"]}
                    </p>
                    <PzButton.Std text={localeMap["menu.auth.login"]} size='md' onClick={() => navigate("/auth/login",{state:{funnel:"myevents"}})} />
                </div>
            }

            <PzArea.FullBoardWithTitle title={getBoardTitle("myevents.planned", planned)} foldable={true} folded={false} >
                <div className="pl-5 flex flex-col justify-start items-start gap-5">
                { planned && planned.length > 0 ?
                    planned.map(p =>
                        <EventCard key={p.pguid} poll={p} viewable={true} foldable={false} 
                            onClickToSelect={()=>{dispatch(setUPoll(p.pguid === upoll?.pguid ? null : p));}} /> 
                    )
                : uauth ?
                    <EmptyCard onSuccessToCreateEx={fetchMyEvents} />
                :
                    <></>
                }
                </div>
            </PzArea.FullBoardWithTitle>

            <PzArea.FullBoardWithTitle title={getBoardTitle("myevents.archived", archived)} 
                foldable={true} folded={true} onChangeToFold={onChangeToArcEvtsFold} >
                <div className="pl-5 flex flex-col justify-start items-start gap-5">
                    { archived &&
                        archived.map(p =>
                            <EventCard key={p.pguid} poll={p} viewable={true} foldable={false} /> )
                    }
                    <div className="w-full text-center">
                        <PzButton.Underlined onClick={()=>{fetchMyEvents('ARCEVENTS');}}>Refresh </PzButton.Underlined>
                    </div>
                </div>
            </PzArea.FullBoardWithTitle>

            { uauth && uauth.ispza && 
            <PzArea.FullBoardWithTitle title={getBoardTitle("myevents.sysevents", sysevents)} 
                foldable={true} folded={true} onChangeToFold={onChangeToSysEvtsFold} >
                <div className="pl-5 flex flex-col justify-start items-start gap-5">
                    { sysevents &&
                        sysevents.map(p =>
                            <EventCard key={p.pguid} poll={p} viewable={true} foldable={false} 
                                onClickToSelect={()=>{dispatch(setUPoll(p.pguid === upoll?.pguid ? null : p));}} /> )
                    }
                    <div className="w-full text-center">
                        <PzButton.Underlined onClick={()=>{fetchMyEvents('SYSEVENTS');}}>Refresh </PzButton.Underlined>
                    </div>
                </div>
            </PzArea.FullBoardWithTitle>
            }

            {open4CreateEvent && 
                <ModalToCreateEvent onClose={()=>{setOpen4CreateEvent(false);}} onSuccess={onSuccessToCreateEvent} />
            }

        </PzArea.InvisibleBoardInContainer>
    );
}
