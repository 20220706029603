import React from "react";
import { useInterval } from "react-use";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzBasicModal from "../../common/PzBasicModal";
import PzButton from "../../common/PzButton";

export default function CheckinSessionHandler({vauth}) {
    const [cookies, removeCookie] = useCookies();
    const ssexp = (vauth ? vauth.exp : 0)
    const [open4ExtModal, setOpen4ExtModal] = React.useState(false);
    const [refusedToExt, setRefusedToExt] = React.useState(false);

    useInterval(() => {
        if (!vauth) return;
        const currentTime = Math.floor(new Date().getTime() / 1000); // in seconds
        if (currentTime > ssexp) {
            removeCookie("vauth", null);    // Session closed
        } else if (currentTime > ssexp-10 && !open4ExtModal && !refusedToExt) {
            setOpen4ExtModal(true);         // Session closing in 10 seconds => open modal
        }
    }, 1000);

    return (
        <>
            { open4ExtModal && 
                <ModalToExtendSession ssexp={ssexp} 
                    onClose={(extended)=>{
                        setOpen4ExtModal(false); 
                        if (extended) setRefusedToExt(false);
                        else setRefusedToExt(true);
                    }} /> 
            }
        </>
    );
}

function ModalToExtendSession({ssexp, onClose=(extended)=>{}}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [remaining, setRemaining] = React.useState();
    const [loading, setLoading] = React.useState(false)

    useInterval(() => {
        const currt = Math.floor(new Date().getTime() / 1000);
        setRemaining(ssexp - currt); // 
        if (currt > ssexp) onClose(false);
    }, 1000);


    const extendCheckinSession = () => {
        setLoading(true);
        appConfig.getAPI().get("/api/auth/token/extend")
        .then((response)=>{
            console.log("PzAPI 'extend' success");
            onClose(true);
        })
        .catch((error)=>{
            console.log("PzAPI 'extend' error : ", error);
        })
        .finally(()=>{
            setLoading(false);
        });
    };

    return (
        <PzBasicModal
            onClose={() => { onClose(false); }}
            onKeyDown={(e)=>{ if (e.key === " " || e.code === "Space") extendCheckinSession(); }} >

            <div className="flex flex-col justify-start items-center p-5 gap-3">
                <h3 className="">{localeMap["auth.extsession.title"]}</h3>
                <div className="flex flex-col justify-start items-center gap-0">
                    {localeMap["auth.extsession.direction"].split('\n').map((part,idx)=>(
                        <h5 key={idx} className="">{part.trim()}</h5>
                    ))}
                </div>
                <h5 className="">{utils.completeMessageText(localeMap["auth.extsession.remaining"], [remaining])}</h5>
                
                <PzButton.Std text={localeMap["auth.extsession.button"]} size='md' onClick={extendCheckinSession} loading={loading} />
            </div>

        </PzBasicModal>
    );
}

