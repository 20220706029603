import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInterval } from "react-use";
import dayjs from "dayjs";

import { utils } from "../config/userConfig";
import { setUPoll } from "../config/upollConfig";
import { ReactComponent as IconToolViewer } from "../resource/icons/pz-tool-viewer.svg";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import PzArea from "../common/PzArea";
import PzButton from "../common/PzButton";
import EntranceForVoter from "../entrance/EntranceForVoter";
import PzSymbol from "../common/PzSymbol";
import { Modal4EvtViewerForKTW } from "./EvtViewerForKTW";
import { Modal4EvtViewerForQst } from "./EvtViewerForQst";
import EvtEditorForVoterModal from "./EvtEditorForVoterModal";
import EvtEditorForStaffModal from "./EvtEditorForStaffModal";
import appConfig from "../config/appConfig";

export default function EventCard({ poll, viewable, foldable, onClickToSelect=undefined }) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [foldedCard, setFoldedCard] = React.useState(true);
    const utzone = useSelector((state)=>state.ucfg.timezone); // timezone from user settings
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const [currQidx, setCurrQidx] = React.useState(-1);
    const [open4PktwModal, setOpen4PktwModal] = React.useState(false);
    const [open4PqstModal, setOpen4PqstModal] = React.useState(false);
    const [open4VoterModal, setOpen4VoterModal] = React.useState(false);
    const [open4StaffModal, setOpen4StaffModal] = React.useState(false);
    const [axRemaining, setAxRemaining] = React.useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectable = (onClickToSelect !== undefined);
    onClickToSelect = (onClickToSelect ? onClickToSelect : ()=>{});
    const pollSelected = (poll?.pguid === upoll?.pguid);
    const pollCreating = (poll?.pguid === undefined);
    const ninfinity = appConfig.getNInfinity();

    viewable = viewable && pollSelected;
    // console.log("EventCard", poll.pguid, foldable, foldedCard, selectable, pollSelected);
    // console.log(axRemaining);

    function updateStatusAxButton() {
        if (!poll) return;
        else if (poll.pstatus === 'Ax') {
            let remaining = '';
            let evt = dayjs(poll.pwhen).utc(); // .format('YYYY-MM-DD HH:mm')
            let now = dayjs().utc();
            let diff_day = evt.diff(now, 'day');
            let diff_min = evt.diff(now, 'minute');
            if      (diff_day >   0) remaining = `D - ${diff_day}`;
            else if (diff_min < -60) remaining = `PASS`;
            else if (diff_min < +60) remaining = `RUN`;
            else {
                let hh = Math.floor(diff_min / 60);
                let mm = diff_min - (60 * hh);
                remaining = `D - ${hh}:${mm}`;
            }
            setAxRemaining(remaining);
        }
    }

    useInterval(() => {
        updateStatusAxButton();
    }, 5000);

    React.useEffect(() => {
        updateStatusAxButton();
    }, []);

    const describe_pwhen = (pkind, pwhen, pwhenend)=>{
        if (!pkind) {
            return '';
        } else if (pkind[1] === 'P') {
            return utils.getPeriodInUserTimezone(pwhen, pwhenend, utzone);
        } else if (pwhen === '') {
            return localeMap["poll01.pwhen.rightnow"];
        } else {
            return utils.getTimeInUserTimezone(pwhen, "YYYY-MM-DD HH:mm", utzone);
        }
    };

    function getEventSummary(poll) {
        // description
        let pdesc = '', pkind = (poll.pkind[0]==='Y' ? poll.pkind[1]+'ra' : poll.pkind);
        let nvoters = [ (poll.nelg === ninfinity ? "∞" : poll.nelg), (poll.nmax === ninfinity ? "∞" : poll.nmax), poll.natt ]
        if (poll.willBeExpiredAt) {
            let expireAt = dayjs(poll.willBeExpiredAt);
            let remaining = expireAt.diff(dayjs(), 'minute');
            if (remaining > 0 && remaining < 60) {
                if (poll.pstatus < 'O ') {
                    pdesc = utils.completeTimeText(localeMap["poll.summary.expire.notopened"], expireAt, utzone);
                    return pdesc;
                } else if (poll.pstatus < 'X ') {
                    pdesc = utils.completeTimeText(localeMap["poll.summary.expire.notclosed"], expireAt, utzone);
                    return pdesc;
                }
            }
        }
        if (poll.nelg === 0) {
            if (poll.pstatus <= 'Ac') {
                pdesc = utils.completeLocaleMapText(localeMap, "poll.summary.any.created", nvoters, pkind);
            } else if (poll.pstatus < 'X ') {
                pdesc = utils.completeLocaleMapText(localeMap, "poll.summary.any.planned", nvoters, pkind);
            } else {
                pdesc = utils.completeLocaleMapText(localeMap, "poll.summary.any.closed", nvoters, pkind);
            }
        } else {
            if (poll.pstatus <= 'Ac') {
                pdesc = utils.completeLocaleMapText(localeMap, "poll.summary.elg.created", nvoters, pkind);
            } else if (poll.pstatus < 'X ') {
                pdesc = utils.completeLocaleMapText(localeMap, "poll.summary.elg.planned", nvoters, pkind);
            } else {
                pdesc = utils.completeLocaleMapText(localeMap, "poll.summary.elg.closed", nvoters, pkind);
            }
        }
        // status
        let status = '';
        if      (poll.pstatus  <  'Ax') status = localeMap["poll.summary.status." + poll.pstatus];
        else if (poll.pstatus === 'Ax') status = localeMap["poll.summary.status." + poll.pstatus];
        else if (poll.pstatus === 'Oo') status = localeMap["poll.summary.status." + poll.pstatus];
        else if (poll.pstatus === 'Os') status = localeMap["poll.summary.status." + poll.pstatus];
        else if (poll.pstatus  >= 'X')  status = localeMap["poll.summary.status." + poll.pstatus];
        return pdesc + " " + status;
    }

    function navigateToEditEvent(poll) {
        if (poll.xrole === 'P') {
            dispatch(setUPoll(poll));
            navigate("/pages/poller/editevent");
        }
    }

    function navigateToRunEvent(poll) {
        if (poll.xrole === 'P') {
            dispatch(setUPoll(poll));
            navigate("/pages/poller/runpoll");
        }
    }

    function navigateToWatchEvent(poll) {
        dispatch(setUPoll(poll));
        navigate("/pages/staff/watchpoll");
    }

    function navigateToXResult(poll) {
        dispatch(setUPoll(poll));
        if      (poll.xrole === 'P') navigate("/pages/poller/xresult");
        else if (poll.xrole === 'M') navigate("/pages/staff/xresult");
        else if (poll.xrole === 'S') navigate("/pages/staff/xresult");
    }

    function setPoll(poll) {

    }

    if (!poll || !poll.pkind || !poll.pstatus) {
        console.log("Something wrong with the poll : ", poll);
        return (<div></div>);
    }
    
    return (
        <>
            <PzArea.EventCard >

                {/* Title */}
                <div className="w-full flex flex-row justify-between items-center" >
                    <div className="flex flex-row justify-start items-center gap-x-2 hover:cursor-pointer" onClick={onClickToSelect} >
                        {selectable &&
                            <input type="radio" name="pollSelected" checked={pollSelected===true} readOnly />
                        }
                        <PzSymbol.PKind pkind={poll.pkind.substring(0,1)} tooltip={localeMap["comm.pkind."+poll.pkind.substring(0,2).toLowerCase()]} />
                        <h4>{poll.ptitle}</h4>
                    </div>
                    <div className="flex flex-row justify-end items-center gap-x-3" >
                        <div className="flex flex-row gap-x-1">
                            <PzSymbol.XRole xrole={poll.xrole} tooltip={localeMap["poll.card.staff.myrole."+poll.xrole?.toLowerCase()]} />
                            <h5 onClick={()=>{console.log(poll);}}>
                                {describe_pwhen(poll.pkind, poll.pwhen, poll.pwhenend)}
                            </h5>
                        </div>
                        {viewable &&
                            <IconToolViewer className={`w-[1.2rem] h-[1.2rem] cursor-pointer `}
                                onClick={() => { setOpen4PktwModal(true); }} /> }
                        {foldable &&
                            <IconChevron className={`w-[1.2rem] h-[1.2rem] cursor-pointer ${foldedCard ? `` : `rotate-180`}`}
                                onClick={() => { setFoldedCard(!foldedCard); }} /> }
                    </div>
                </div>

                <div className="p-1"/>

                {/* Summary */}
                { !pollCreating &&
                <div className={`grid grid-cols-[6rem_1fr] gap-x-2 mt-1`}>
                    <h5 className="font-bold">{localeMap["poll.summary.label"]}</h5>  
                    <div  className={`grid grid-cols-[1fr_auto] justify-start items-center gap-x-3`}>
                        <p>{getEventSummary(poll)}</p>
                        { poll.pstatus < 'Ax' && poll.xrole === 'P' ? 
                            ( !foldable ?
                                <PzButton.Std text={localeMap["poll.card.button.edit"]} size='sm' bold={false} 
                                    onClick={()=>{navigateToEditEvent(poll);}} />
                            : 
                                <span></span>
                            )
                        : poll.pstatus === 'Ax' && axRemaining === 'PASS' ? 
                            <span></span>
                        : poll.pstatus === 'Ax' && axRemaining === 'RUN' ? 
                            ( !foldable ?
                                <PzButton.Std text={localeMap["poll.card.button.run"]} size='sm' bold={false} 
                                    onClick={()=>{navigateToRunEvent(poll);}} />
                            : 
                                <span></span>
                            )
                        : poll.pstatus === 'Ax' ? 
                            <PzButton.Std text={axRemaining} size='sm' bold={false} disabled={true} />
                        : poll.pstatus.substring(0,1) === 'O' && poll.xrole === 'P' ? 
                            <div className="flex flex-row justify-end items-center gap-1">
                                <EntranceForVoter.Button poll={poll} size='1.2rem'/>
                                { !foldable && 
                                    <PzButton.Std text={localeMap["poll.card.button.run"]} size='sm' bold={false} 
                                    onClick={()=>{navigateToRunEvent(poll);}} />
                                }
                            </div>
                        : poll.pstatus.substring(0,1) === 'O' && poll.xrole !== 'P' ? 
                            <div className="flex flex-row justify-end items-center gap-1">
                                <EntranceForVoter.Button poll={poll} size='1.2rem'/>
                                { !foldable && 
                                    <PzButton.Std text={localeMap["poll.card.button.watch"]} size='sm' bold={false} 
                                    onClick={()=>{navigateToWatchEvent(poll);}} />
                                }
                            </div>
                        : poll.pstatus >= 'X ' && poll.pstatus < 'Xx' ? 
                            <PzButton.Std text={localeMap["poll.card.button.xresult"]} size='sm' bold={false} 
                                onClick={()=>{navigateToXResult(poll);}} />
                        :
                            <div>&nbsp;</div>
                        }
                    </div>
                </div>
                }

                {/* Foldable Body */}
                <div className={`grid grid-cols-[6rem_1fr] ${(foldable && foldedCard) || (selectable && !pollSelected) ? `hidden` : ``} mt-1 gap-x-2 gap-y-1`}>

                    {/* Questions */}
                    <h5 className="font-bold">{localeMap["poll.card.questions.label"]}</h5> 
                    <div className="flex flex-col gap-y-1">
                    {poll.pqstlist?.map((q,idx) =>
                        <div key={idx} className={`grid ${viewable ? `grid-cols-[1fr_20px] gap-x-3 cursor-pointer` : `grid-cols-1`} ${!viewable ? `` : currQidx === idx ? `bg-slate-100` : `bg-slate-50`}`} >
                            <div className="grid grid-cols-[auto_1fr_1fr] gap-2"
                                onClick={()=>{setCurrQidx(currQidx===idx ? -1 : idx);}} >
                                <PzSymbol.Text size="sm" text={'Q'+(q.qseq)} />
                                <h5 className="truncate">{q.question}</h5>
                                { poll.pstatus.substring(0,1) <= 'O' ?
                                    <div className="flex flex-row justify-start items-center gap-2">
                                        <PzSymbol.Text text="G" tooltip="" size="sm" />
                                        <label className="text-sm">{utils.getQstGoalSummary(q, localeMap)}</label>
                                    </div>
                                : 
                                    <div className="flex flex-row justify-start items-center gap-2">
                                        <PzSymbol.Text text="R" tooltip="" size="sm" />
                                        <label className="text-sm">{utils.getQstResultSummary(q, localeMap)}</label>
                                    </div>
                                }
                            </div>
                            {viewable && currQidx === idx ?
                                <IconToolViewer className="w-[1.2rem] h-[1.2rem] cursor-pointer" 
                                    onClick={() => { setCurrQidx(idx); setOpen4PqstModal(true);}} /> 
                                :
                                <span></span>
                            }
                        </div>
                    )}
                    </div>

                    {/* Voters */}
                    { !pollCreating && <>
                    <h5 className="font-bold">{utils.completeLocaleMapText(localeMap, "poll.card.voters.label", [], poll.pkind)}</h5>  
                    <div  className={`grid ${viewable ? `grid-cols-[1fr_20px] gap-x-3 cursor-pointer` : `grid-cols-1`} ${!viewable ? `` : currQidx === 1000 ? `bg-slate-100` : `bg-slate-50`}`} >
                        <div onClick={()=>{setCurrQidx(currQidx===1000 ? -1 : 1000);}} >
                            { poll.pkind.substring(2,3) === 'E' && poll.pstatus <= 'Oz' ?
                                <p>{utils.completeLocaleMapText(localeMap, "poll.card.voters.elg.planned", [poll.nelg, poll.nmax], poll.pkind)}</p>
                            : poll.pkind.substring(2,3) === 'E' && poll.pstatus >= 'X ' ?
                                <p>{utils.completeLocaleMapText(localeMap, "poll.card.voters.elg.closed",  [poll.nelg, poll.natt], poll.pkind)}</p>
                            : poll.pkind.substring(2,3) === 'A' && poll.pstatus <= 'Oz' ?
                                <p>{utils.completeLocaleMapText(localeMap, "poll.card.voters.any.planned", [(poll.nmax < 0 ? '∞' : poll.nmax)], poll.pkind)}</p>
                            : poll.pkind.substring(2,3) === 'A' && poll.pstatus >= 'X ' ?
                                <p>{utils.completeLocaleMapText(localeMap, "poll.card.voters.any.closed",  [poll.natt], poll.pkind)}</p>
                            : 
                                <p>unknown</p>
                            }
                        </div>
                        {viewable && currQidx === 1000 && poll.nelg > 0 ?
                            <IconToolViewer className="w-[20px] h-[20px] cursor-pointer" 
                                onClick={() => { setCurrQidx(1000); setOpen4VoterModal(true); }} /> 
                            :
                            <span></span>
                        }
                    </div>
                    </>}

                    {/* Staff */}
                    { !pollCreating && <>
                    <h5 className="font-bold">{localeMap["poll.card.staff.label"]}</h5>  
                    <div  className={`grid ${viewable ? `grid-cols-[1fr_20px] gap-x-3 cursor-pointer` : `grid-cols-1`} ${!viewable ? `` : currQidx === 2000 ? `bg-slate-100` : `bg-slate-50`}`} >
                        <div className=""
                            onClick={()=>{setCurrQidx(currQidx===2000 ? -1 : 2000);}} >
                            { poll.xrole === 'P' ?
                                <p> {utils.completeMessageText(localeMap["poll.card.staff.summary"], [poll.uname, poll.nstaff])} </p>
                            :
                                <p>
                                    {utils.completeMessageText(localeMap["poll.card.staff.summary"], [poll.uname, poll.nstaff])} 
                                    &nbsp; ({localeMap["poll.card.staff.myrole."+poll.xrole]})
                                </p>
                            }
                        </div>
                        {viewable && currQidx === 2000 && poll.nstaff > 0 ?
                            <IconToolViewer className="w-[20px] h-[20px] cursor-pointer" 
                                onClick={() => { setCurrQidx(2000); setOpen4StaffModal(true); }} />
                            :
                            <span></span>
                        }
                    </div>
                    </>}

                </div> {/* Foldable Body */}

            </PzArea.EventCard> 

            { open4PktwModal &&
                <Modal4EvtViewerForKTW poll={poll} onClose={()=>{setOpen4PktwModal(false);}} />
            }
            { open4PqstModal && currQidx >= 0 && currQidx < poll.pqstlist.length &&
                <Modal4EvtViewerForQst pqst={poll.pqstlist[currQidx]} onClose={()=>{setOpen4PqstModal(false);}} />
            }
            { open4VoterModal && currQidx === 1000 && 
                <EvtEditorForVoterModal poll={poll} setPoll={poll.pstatus<='Ax' ? setPoll : undefined} onClose={()=>{setOpen4VoterModal(false);}} />
            }
            { open4StaffModal && currQidx === 2000 && 
                <EvtEditorForStaffModal poll={poll} setPoll={poll.pstatus<='Ax' ? setPoll : undefined} onClose={()=>{setOpen4StaffModal(false);}} />
            }
        </>
    );
}

