import React from "react";
import { useSelector } from "react-redux";

import { utils } from "../config/userConfig";
import PzButton from "../common/PzButton";

export default function NotFound() {
    const localeMap = useSelector(utils.selectLocaleMap);
    return (
        <div className="w-full h-full flex flex-col items-center justify-center">
            <h2 className="">404</h2>
            <h2 className="">{localeMap["notfound.request"]}</h2>
            {/* <h1 className="text-3xl font-extrabold mt-10" >Pollerz.net</h1> */}
            <div className="w-full flex flex-row items-center justify-center gap-2 mt-2">
                <PzButton.ToNavigate to="/" >
                    <h5>{localeMap["notfound.nav.home"]}</h5>
                </PzButton.ToNavigate>
                <PzButton.ToNavigate to="/auth/login" >
                    <h5>{localeMap["notfound.nav.login"]}</h5>
                </PzButton.ToNavigate> 
            </div>
        </div>
    );
}
