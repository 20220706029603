import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import PzArea from "../../common/PzArea";

export default function PzAdminViewDesc() {
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const [descLines, setDescLines] = React.useState([]);
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);

    const fetchDesc = ()=>{
        appConfig.getAPI().get(`/api/sys/status/desc`)
        .then((response)=>{
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            let desc = JSON.stringify(pzdata.resource, null, '    ');
            setDescLines(desc.split('\n'));
        })
        .catch((error)=>{
            console.log("PzAPI '/api/sys/status/desc' error : ", error);
        })
        .finally(()=>{});
    };

    React.useEffect(() => {
        if (!uauth?.ispza) { navigate("/"); return; }
        fetchDesc();
    }, []);

    return (
        <PzArea.Container>

        {!cookies?.uauth || !jwtDecode(cookies.uauth)?.ispza ? null : (
            <PzArea.AdminBoard >
                <h3>{"PzA - PzServer Description"}</h3>

                <div>
                    { descLines.map((e, idx)=>(<pre key={idx} className="font-mono text-xs">{e}</pre>))}
                </div>

            </PzArea.AdminBoard>
        )}
        
        </PzArea.Container>
    );
}
