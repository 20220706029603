import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { utils } from "../config/userConfig";
import PzArea from "../common/PzArea";
import UserSplash from "./UserSplash";

export default function UserForgotten() {
    const [qparams] = useSearchParams();
    const localeMap = utils.selectMapByLocale(qparams.get("l"));
    const navigate = useNavigate();
    const msg1_orig = localeMap["forgotten.msg1"];
    const msg2_orig = localeMap["forgotten.msg2"];
    const msg1 = (msg1_orig ? msg1_orig.split("\n") : ['']);
    const msg2 = (msg2_orig ? msg2_orig.split("\n") : ['']);

    React.useEffect(() => {
        setTimeout(()=>{navigate("/");}, 20000); // go to home, after 20 seconds
    }, []);

    function onClickToLogo() {
        navigate('/home');
    }

    return (
        <PzArea.MwhBoardInContainer >
            <div>&nbsp;</div>
                <div className="flex flex-col justify-center items-center w-full h-full gap-10">
                    <h1 className="font-extrabold text-5xl cursor-pointer" onClick={onClickToLogo}>
                        Pollerz.net
                    </h1>

                    <div className="flex flex-col justify-start items-center gap-2">
                        {msg1.map((part,idx)=>{
                            return(<h2 key={idx} className="font-bold">{part}</h2>);
                        })}
                    </div>

                    <div className="flex flex-col justify-start items-center gap-2">
                        {msg2.map((part,idx)=>{
                            return(<h4 key={idx} className="font-bold">{part}</h4>);
                        })}
                    </div>
                </div>
            <div>&nbsp;</div>
        </PzArea.MwhBoardInContainer> 
    );
}
