import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzArea from "../common/PzArea";

export default function PzBottombar({}) {
    const location = useLocation();
    const isAuthPage = location.pathname.startsWith("/auth/");
    const localeMap = useSelector(utils.selectLocaleMap);
    const screenSV = (window.innerWidth <= 300 && window.innerWidth < window.innerHeight); // Note that it's not updated with resize
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = React.useState(false);

    return (
        <PzArea.Container>
            <PzArea.FooterBoard>

                <div className="w-full grid grid-cols-[2fr_1fr_1fr_1fr] justify-start items-start gap-x-2 opacity-80">

                    <div className="w-auto h-full flex flex-col justify-start items-start gap-0">
                        <label className='text-xl font-extrabold hover:cursor-pointer' onClick={()=>{navigate('/');}}>Pollerz.net</label>
                        <label className='text-sm '>Abc</label>
                    </div>

                    <div className="w-auto h-full flex flex-col justify-start items-start gap-0">
                        <label className='text-sm font-bold'>Product</label>
                        <PzBarNavLink text={'Features'} path={'/features'} />
                        <PzBarNavLink text={'Pricing'} path={'/pricing'} />
                        <PzBarNavLink text={'What\'s new'} path={''} />
                        <PzBarNavLink text={'Roadmap'} path={''} />
                    </div>

                    <div className="w-auto h-full flex flex-col justify-start items-start gap-0">
                        <label className='text-sm font-bold'>Help</label>
                        <PzBarNavLink text={'Get started'} path={''} />
                        <PzBarNavLink text={'Help center'} path={''} />
                        <PzBarNavLink text={'Contact support'} path={''} />
                        <PzBarNavLink text={'Report Abuse'} path={''} />
                    </div>
                    
                    <div className="w-auto h-full flex flex-col justify-start items-start gap-0">
                        <label className='text-sm font-bold'>About us</label>
                        <PzBarNavLink text={'Our mission'} path={'/mission'} />
                        <PzBarNavLink text={'Privacy policy'} path={'/privacypolicy'} />
                        <PzBarNavLink text={'Business Inquiry'} path={''} />
                    </div>
                    
                </div>

            </PzArea.FooterBoard>
        </PzArea.Container>
    );
}

function PzBarNavLink({text, path}) {
    const navigate = useNavigate();
    return (
        <label className='text-sm hover:cursor-pointer' 
            onClick={()=>{if (path) navigate(path);}}>
            {text}
        </label>
    );
}
