import { createSlice } from '@reduxjs/toolkit'

export const voterConfigSlice = createSlice({
    name: 'vcfg',
    initialState: {
        pguid:  '',     //
        vid:    '',     //
        choices: [],    //
    },
    reducers: {
        setPGuid: (state, action) => {
            if (!action.payload) return;
            state.pguid = action.payload;
        },
        setVid: (state, action) => {
            if (!action.payload) return;
            state.vid = action.payload;
        },
        setChoices: (state, action) => {
            if (!action.payload) return;
            state.choices = action.payload;
        },
    }
})

function getChoice(choices, qidx, ridx) {
    if (!Array.isArray(choices)) return null;
    if (qidx >= 0 && qidx < choices.length &&
        ridx >= 0 && ridx < choices[qidx].length) return choices[qidx][ridx];
    return null;
}

function getNewChoices(choices, qidx, ridx, choice) {
    if (!Array.isArray(choices)) choices = [];
    let new_choices = JSON.parse(JSON.stringify(choices));
    for (let i = 0; i <= qidx; i++) {
        // if (new_choices.length <= i) new_choices.push([]);
        if (new_choices.length <= i) { console.log("push qidx ", i); new_choices.push([]); }
        if (i === qidx) {
            for (let j = 0; j <= ridx; j++) {
                // if (new_choices[i].length <= j) new_choices[i].push([]);
                if (new_choices[i].length <= j) { console.log("push round ", i, j); new_choices[i].push(null); }
            }
        }
    }
    new_choices[qidx][ridx] = choice;
    return JSON.parse(JSON.stringify(new_choices));  // use DEEP COPY !
    // new_choices = JSON.parse(JSON.stringify(new_choices)); // use DEEP COPY !
    // console.log("setch:", new_choices);
    // return setCheckins(new_choices);
}

// const { setPGuid, setVid } = voterConfigSlice.actions;

export const vutils = { 
    ...voterConfigSlice.actions,

    getChoice:      getChoice,
    getNewChoices:  getNewChoices,
};
