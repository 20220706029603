import React from "react";
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import { ReactComponent as IconToolViewer } from "../resource/icons/pz-tool-viewer.svg";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import PzArea from "../common/PzArea";
import PzButton from "../common/PzButton";

export default function EmptyCard({onSuccessToCreateEx}) {
    const localeMap = useSelector(utils.selectLocaleMap);

    function postCreateExampleEvent() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/createx")
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            console.log("PzAPI '/api/mp/createx' success : ", pzdata);
            if (typeof onSuccessToCreateEx === 'function') onSuccessToCreateEx();
        })
        .catch((error) => {
            console.log("PzAPI '/api/mp/createx' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    return (
        <PzArea.EventCard className="bg-slate-200">

            {/* Title */}
            <div className="w-full flex flex-col justify-start items-center gap-2" >
                <h4>{localeMap["myevents.none.planned"]}</h4>
                <span>{localeMap["myevents.none.helpmsg"]}</span>
                <PzButton.Std text={localeMap["myevents.none.createx"]} size='lg' onClick={postCreateExampleEvent} />
            </div>

        </PzArea.EventCard> 
    );
}

