import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import { setUPoll } from "../config/upollConfig";
import PzBasicModal from "../common/PzBasicModal";
import PzTable from "../common/PzTable";
import PzSymbol from "../common/PzSymbol";
import PzButton from "../common/PzButton";
import EvtEditorForQst from "./EvtEditorForQst";

export default function EvtEditorForQstTable({poll, setPoll, className, size='lg'}) {
    const [ucookies] = useCookies(['uauth']);
    const uauth = (ucookies?.uauth ? jwtDecode(ucookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [currentRow, setCurrentRow] = React.useState(-1);
    const [open4QstAdder, setOpen4QstAdder] = React.useState(false);
    const [open4QstEditor, setOpen4QstEditor] = React.useState(false);
    const text_size = 'text-' + size;

    if (!poll) return (<div></div>);
    return (
        <div className={"w-full flex flex-col justify-start items-stretch gap-y-2 " + className}>
            <PzTable 
                data={poll.pqstlist} rowKey="qseq" configurable={true} size={size} allicon={true}
                columns={[
                    { title: 'Q', key: 'qseq', 
                        render: (row)=>{return (<PzSymbol.Text text={'Q'+row.qseq} size={size} onClick={()=>{if (uauth.ispza) console.log(row);}}/>);} },
                    { title: 'Kind', key: 'qkind', hidden:true,
                        render: ({qkind})=>{return (<p className={text_size}>{localeMap["poll02.qkind."+qkind]}</p>);} },
                    { title: 'Question', key: 'question', hidden:true,
                        render: ({question})=>{return (<p className={text_size}>{question}</p>);} },
                    { title: 'nA', key: 'nanswers', hidden:true,
                        render: ({nanswers})=>{return (<p className={text_size}>{nanswers}</p>);} },
                    { title: 'Goal', key: 'nwanted',
                        render: ({nwanted,answers})=>{return (<p className={text_size}>{nwanted} / {answers.length-1}</p>);} },
                    { title: 'C2Win',  key: 'c2win',  
                        render: ({qoption})=>{return (<p className={text_size}>{utils.translateCriteria(qoption.c2win, localeMap)}</p>);} },
                    { title: 'C2Drop', key: 'c2drop', 
                        render: ({qoption})=>{return (<p className={text_size}>{utils.translateCriteria(qoption.c2drop,localeMap)}</p>);} },
                    { title: 'MR', key: 'qroundmax', align:'right',
                        render: ({qroundmax})=>{return (<p className={text_size}>{qroundmax}</p>);} },
                ]}
                onClickToAddRow={poll.pstatus < 'Oz' ? ()=>{setCurrentRow(-1); setOpen4QstAdder(true);} : undefined} 
                onClickToEditRow={poll.pstatus < 'Oz' ? (row,idx)=>{setCurrentRow(idx); setOpen4QstEditor(true);} : undefined} />
            {open4QstAdder && (
                <ModalToAddQst poll={poll} setPoll={setPoll} onClose={()=>setOpen4QstAdder(false)}/>
            )}
            {open4QstEditor && (
                <ModalToEditQst poll={poll} setPoll={setPoll} qidx={currentRow} onClose={()=>setOpen4QstEditor(false)}/>
            )}
        </div>
    );
}

function ModalToAddQst({poll, setPoll, onClose}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const newq = { qseq:poll.pqstlist.length+1, qkind:'A', question:'', answers:[], nwanted:1, qroundmax:1}
    const [qst, setQst] = React.useState(newq);
    const dispatch = useDispatch();

    function onSave() {
        const payload = { ...qst, pguid: poll.pguid }
        appConfig.getAPI().post("/api/mp/qst/add", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const new_pqstlist = pzdata.resource; 
            const new_poll = { ...poll, pqstlist: new_pqstlist }
            setPoll(new_poll);
            dispatch(setUPoll(new_poll));
        })
        .catch((error) => {
            console.log("PzAPI '/qst/add' error : ", error);
        })
        .finally(() => {
            onClose();
        });
    }
    return (
        <PzBasicModal onClose={onClose} xclose={true} >
            <div className="min-w-[500px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h2>{localeMap["poll02.title.add"]}</h2>

                <EvtEditorForQst qst={qst} setQst={setQst} qseqMax={poll.pqstlist.length+1} />

                <div className="w-full flex flex-row justify-end gap-2">
                    <PzButton.Default onClick={()=>{onSave();}}>
                        <h3>{localeMap["poll03.voter.button.save"]}</h3>
                    </PzButton.Default>
                </div>

            </div>
        </PzBasicModal>
    );
}

function ModalToEditQst({poll, setPoll, qidx, onClose}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [qst, setQst] = React.useState(poll.pqstlist[qidx]);
    const dispatch = useDispatch();
    // console.log("ModalToEditQst:", qst);

    function onSave() {
        console.log("onSave to Edit : ", qst);
        const payload = { ...qst, pguid: poll.pguid }
        appConfig.getAPI().post("/api/mp/qst/edit", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const new_pqstlist = pzdata.resource; 
            const new_poll = { ...poll, pqstlist: new_pqstlist }
            setPoll(new_poll);
            dispatch(setUPoll(new_poll));
        })
        .catch((error) => {
            console.log("PzAPI '/qst/edit' error : ", error);
        })
        .finally(() => {
            onClose();
        });
    }
    function onDelete() {
        const payload = { pguid: poll.pguid, qguid: qst.qguid }
        appConfig.getAPI().post("/api/mp/qst/delete", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const new_pqstlist = pzdata.resource; 
            setPoll({ ...poll, pqstlist: new_pqstlist });
        })
        .catch((error) => {
            console.log("PzAPI '/qst/delete' error : ", error);
        })
        .finally(() => {
            onClose();
        });
    }
    return (
        <PzBasicModal onClose={onClose} xclose={true} >
            <div className="min-w-[500px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h2>{localeMap["poll02.title.edit"]}</h2>

                <EvtEditorForQst qst={qst} setQst={setQst} qseqMax={poll.pqstlist.length+1} />

                <div className="w-full flex flex-row justify-between items-center">
                    <PzButton.Default onClick={()=>{onDelete();}}>
                        <h3>{localeMap["poll02.button.delete"]}</h3>
                    </PzButton.Default>
                    <PzButton.Default onClick={()=>{onSave();}}>
                        <h3>{localeMap["poll02.button.save"]}</h3>
                    </PzButton.Default>
                </div>

            </div>
        </PzBasicModal>
    );
}
