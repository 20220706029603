import React from "react";
import { useSelector, useDispatch } from "react-redux";

import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzDialog from "../../common/PzDialog";
import PzSpin from "../../common/PzSpin";
import TrialAsPoller from "../../entrance/TrialAsPoller";
import TrialAsVoter from "../../entrance/TrialAsVoter";

export default function Home() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const locale = useSelector((state)=>state.ucfg.locale);
    const timezone = useSelector((state)=>state.ucfg.timezone);
    const dispatch = useDispatch();
    const [counters, setCounters] = React.useState({p:1, v:1, c:1});
    const [simEvents, setSimEvents] = React.useState({poll:undefined, surv:undefined, quiz:undefined});
    const [isLoadingCounters, setLoadingCounters] = React.useState(false);
    const simpoll = (simEvents["poll_"+locale] ? simEvents["poll_"+locale] : simEvents["poll"]);
    const simsurv = (simEvents["surv_"+locale] ? simEvents["surv_"+locale] : simEvents["surv"]);
    const simquiz = (simEvents["quiz_"+locale] ? simEvents["quiz_"+locale] : simEvents["quiz"]);
    const imgsrcdif = appConfig.getBaseUrlForAPI() + (locale === 'ko' ? '/static/images/procedure_diff_ko.png' : '/static/images/procedure_diff_en.png');
    const imgsrctry = appConfig.getBaseUrlForAPI() + (locale === 'ko' ? '/static/images/procedure_trial_ko.png' : '/static/images/procedure_trial_en.png');

    const fetchCounters = ()=>{
        setLoadingCounters(true);
        appConfig.getAPI().get("/api/home/info")
        .then((response)=>{
            // console.log("PzAPI 'info' success : ", response.data);
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setCounters(pzdata.resource.counters);
            setSimEvents(pzdata.resource.simevents);
        })
        .catch((error)=>{
            console.log("PzAPI 'home/info' error : ", error);
        })
        .finally(()=>{
            setLoadingCounters(false);
        })
    };

    React.useEffect(() => {
        // document.title = 'Pollerz.net';
        if (!timezone) {
            let system_tz = dayjs.tz.guess();
            console.log("setting tz to system timezone : ", system_tz)
            dispatch(utils.setTimezone(system_tz));  // use system timezone
        }
        fetchCounters();
    }, []);

    const title_parts = localeMap["home.intro.title"].split("\n")
    const subtitle_parts = localeMap["home.intro.subtitle"].split("\n")
    const features = [
        { key: 0, label: localeMap["home.why.label0"], text: localeMap["home.why.desc0"] },
        { key: 1, label: localeMap["home.why.label1"], text: localeMap["home.why.desc1"] },
        { key: 2, label: localeMap["home.why.label2"], text: localeMap["home.why.desc2"] },
        { key: 3, label: localeMap["home.why.label3"], text: localeMap["home.why.desc3"] },
        { key: 4, label: localeMap["home.why.label4"], text: localeMap["home.why.desc4"] },
        // { key: 5, label: localeMap["home.why.label5"], text: localeMap["home.why.desc5"] },
    ];


    return (
        <PzArea.Container>
            <PzArea.HomeBoard>

                <div className="w-[90%] min-w-[32rem] flex flex-col justifiy-start items-center gap-1">
                    {title_parts.map((title, idx)=>{return (
                        <h1 key={100+idx} className="font-extrabold">{title}</h1>
                    )})}
                    { appConfig.version==='ALPHA' && <PzDialog.Alpha /> }
                </div>

                <div className="w-[90%] min-w-[32rem] flex flex-col justifiy-start items-center gap-1">
                    {subtitle_parts.map((sub, idx)=>{return (
                        <h4 key={100+idx} className="">{sub}</h4>
                    )})}
                </div>

                <div>
                    <PzSpin spinning={isLoadingCounters}>
                        <h3 className="font-bold text-slate-600 mb-2">
                            {utils.completeLocaleMapText(localeMap, "home.intro.serving", [counters.p, counters.v, counters.c])}
                        </h3>
                    </PzSpin>
                </div>

                {/* Difference */}
                <div className="w-[70%] min-w-[min(max(94vw,360px),500px)] max-w-[min(96vw,700px)] flex flex-col gap-5">
                    <h2>{localeMap["home.intro.difference"]}</h2>
                    <div className="w-auto flex flex-col justify-start items-center gap-1">
                        <h4>"1분 안에 결론을 내릴 수 있는 전자투표서비스"</h4>
                        <h4>"Online voting to make your decision in one single minute"</h4>
                        <img src={imgsrcdif} alt=""  width={600} height={600} className="object-contain object-center mt-4"/>
                    </div>
                </div>

                {/* Why for You */}
                <div className="w-[70%] min-w-[min(max(94vw,360px),500px)] max-w-[min(96vw,700px)] flex flex-col gap-5">
                    <h2>{localeMap["home.intro.whyforyou"]}</h2>
                    {features.map((f,idx) => 
                        <div key={idx} className="grid grid-cols-[4rem_1fr] gap-6">
                            <div className="flex flex-col justify-center items-center">
                                {f.label.split(" ").map((item, idx2) => (
                                    <h5 key={idx2} className="font-bold">
                                        {item}
                                    </h5>
                                ))}
                            </div>
                            <div className="bg-white ">
                                <p className="text-slate-600 mb-2 text-justify">
                                    {f.text}
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {/* Try it yourself */}
                { simpoll &&
                <div className="w-[70%] min-w-[min(max(94vw,360px),500px)] max-w-[min(96vw,700px)] flex flex-col gap-5">
                    <h2>{localeMap["home.intro.tryyourself"]}</h2>
                        <div className="w-full pl-20 flex flex-col justify-start items-start gap-5">
                            <TrialAsVoter.Button  poll={simpoll} size='xl' />
                            <TrialAsPoller.Button poll={simpoll} size='xl' />
                        </div>
                </div>
                }


            </PzArea.HomeBoard>
        </PzArea.Container>

    );
}

