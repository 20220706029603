import React from "react";
import { ReactComponent as IconClose } from "../../../resource/icons/pz-button-close.svg";

/**
 *
 * @param {Number} width
 * @param {Number} height
 * @param {Boolean} closeOnWrapperClick
 * @returns
 */

export function PzaBasicModal({
    children,
    onClose = () => {},
    onKeyDown = () => {},
    width,
    height,
    closeOnWrapperClick = true,
}) {

    // const onKeyboardEvent = (e) => {
    //   if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
    //     onKeyDown();
    //   }
    //   return;
    // };

    // React.useEffect(() => {
    //   window.addEventListener("keydown", onKeyboardEvent);
    //   return () => window.removeEventListener("keydown", onKeyboardEvent);
    // }, []);

    return (
        <div className="modal--wrapper" onClick={closeOnWrapperClick ? onClose : () => {}}>
            <div
                style={{ width, height }}
                className="modal"
                onClick={(e) => {
                    e.stopPropagation();
                }}
                >
                <button className="modal-close-button" style={{ marginBottom: 5 }} onClick={onClose}>
                    <IconClose width="15" height="15" />
                </button>

                {children}

            </div>
        </div>
    );
}
