import React from "react";
import { useSelector, useDispatch } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import { ReactComponent as IconCheck } from "../resource/icons/pz-check.svg";
import { ReactComponent as IconEmpty } from "../resource/icons/pz-void-empty.svg";
import PzButton from "../common/PzButton";
import PzDropdown from "../common/PzDropdown";

export default function NavbarItemLocale() {
    const ulocale = useSelector((state)=>state.ucfg.locale);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    // console.log(appConfig.getSupportedLocales());

    return (
        <PzDropdown 
            open={open} setOpen={setOpen} ha={'right'}
            button={
                <PzButton.Minimal className="px-1" gap="gap-1">
                    <h5>{appConfig.getLanguageFromLocale(ulocale, false)}</h5>
                    <IconChevron width="1.2rem" height="1.2rem" className={`${open && `rotate-180`}`} />
                </PzButton.Minimal>
            } >
            <ul className="p-2 flex flex-col items-start justify-start items-stretch text-left gap-0">
                {appConfig.getSupportedLocales().map((loc) => (
                    <li key={loc}>
                        <PzButton.Minimal className="w-full px-1 py-1" onClick={()=>{ dispatch(utils.setLocale(loc)); setOpen(false); }} >
                            <div className="w-full grid grid-cols-[auto_1fr] justify-start items-center gap-2">
                                {loc === ulocale ?
                                    <IconCheck width={18} height={18} className="" />
                                :
                                    <IconEmpty width={18} height={18} className="" />
                                }
                                <h5 className="w-full text-left">{appConfig.getLanguageFromLocale(loc, true)}</h5>
                            </div>
                        </PzButton.Minimal>
                    </li>
                ))}
            </ul>
        </PzDropdown>
    );
}
