import React from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzInput from "../../common/PzInput";
import PzBasicModal from "../../common/PzBasicModal";
import PzTable, { PztShortTooltip } from "../../common/PzTable";
import PzButton from "../../common/PzButton";
import EvtEditorForVoter from "../../eventview/EvtEditorForVoter";

export function GetReadyVoters({poll, setPoll, vState, setVState}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [updated, setUpdated] = React.useState(dayjs());
    const [folded, setFolded] = React.useState(vState.folded);
    if (vState.updated.isAfter(updated)) { setUpdated(vState.updated); fetchCounts(); }
    if (vState.folded != folded) setFolded(vState.folded);

    React.useEffect(() => {
        fetchCounts();
    }, []);

    function fetchCounts() {
        appConfig.getAPI().post("/api/mp/voter/counts", {pguid: poll.pguid})
        .then((response) => {
            // console.log("PzAPI 'voter/counts' success");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const counts = pzdata.resource; // {ntotal:0, nbdate:0, nphone:0, nemail:0}
            // console.log("counts:", counts);
            setVState({ ...vState, folded: true, nt:counts.ntotal, nb:counts.nbdate, np:counts.nphone, ne:counts.nemail, na:counts.nany });
            if (counts.ntotal !== poll.nelg) {
                if (typeof setPoll === 'function') setPoll({ ...poll, nelg: counts.ntotal });
            }
        })
        .catch((error) => { 
            console.log("PzAPI 'voter/counts' failure (%v)", error); 
        })
        .finally(() => {});
    }

    function onClickForExample() {
        appConfig.getAPI().post("/api/mp/voter/example", {pguid: poll.pguid, nvoters: 5})
        .then((response) => {
            // console.log("PzAPI 'voter/example' success");
            setVState({ ...vState, updated: dayjs() });
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // poll info
            if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.nelg, pkind: pgdata.pkind });
        })
        .catch((error) => { 
            console.log("PzAPI 'voter/example' failure (%v)", error); 
        })
        .finally(() => {});
    }

    function onChangeToFolded(fold) {
        setFolded(fold);
        setVState({ ...vState, folded: fold });
    }

    return (
        <div className="w-full pl-10 grid grid-cols-[auto_auto_auto_1fr] justify-start items-center gap-x-5 gap-y-1">
            <label className="text-right">{localeMap["poller.getready.voters.nt"]} :</label>
            <label className="text-right font-bold">{vState.nt}</label>
            <label className="text-right">&nbsp;</label>
            <label className="text-left text-sm opacity-50 hover:opacity-100">
                { vState.nt == 0 ? localeMap["poller.getready.voters.nt0"] : localeMap["poller.getready.voters.nt1"] }
            </label>

            <label className="text-right">{localeMap["poller.getready.voters.ne"]} :</label>
            <label className="text-right font-bold">{vState.ne}</label>
            <label className="text-right">{`${vState.nt > 0 ? (vState.ne*100/vState.nt).toFixed(0) : 0} %`}</label>
            <label className="text-left opacity-50 text-sm">&nbsp;</label>

            <label className="text-right">{localeMap["poller.getready.voters.np"]} :</label>
            <label className="text-right font-bold">{vState.np}</label>
            <label className="text-right">{`${vState.nt > 0 ? (vState.np*100/vState.nt).toFixed(0) : 0} %`}</label>
            <label className="text-left opacity-50 text-sm">&nbsp;</label>

            <label className="text-right">{localeMap["poller.getready.voters.na"]} :</label>
            <label className="text-right font-bold">{vState.na}</label>
            <label className="text-right">{`${vState.nt > 0 ? (vState.na*100/vState.nt).toFixed(0) : 0} %`}</label>
            <label className="text-left opacity-50 text-sm hover:opacity-100">
                { vState.nt == 0 ? localeMap["poller.getready.voters.na0"] 
                : vState.na < vState.nt ? utils.completeMessageText(localeMap["poller.getready.voters.na1"], [ vState.nt - vState.na ])
                : localeMap["poller.getready.voters.na2"] }
            </label>

            <label className="text-right">{localeMap["poller.getready.voters.list"]} :</label>
            <label>&nbsp;</label>
            <PzButton.Folder folded={folded} setFolded={onChangeToFolded} size='lg'/>
            <div className="pr-5 flex flex-row justify-end items-center gap-5">
                <PzButton.Std className={vState.nt > 0 || poll.pstatus >= 'Ax' ? 'invisible' : ''}
                    size='sm' bold={false} onClick={onClickForExample}
                    text={localeMap["poller.getready.voters.example"]} />
            </div>
        </div>
    );
}

export function GetReadyVoterList({poll, setPoll, vState, setVState, className}) {
    const [cookies] = useCookies(['uauth']);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const localeMap = useSelector(utils.selectLocaleMap);
    const [pgOpts, setPgOpts] = React.useState({page:0,pageSize:10,pguid:poll?.pguid});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});
    const [open4List, setOpen4List] = React.useState(false);
    const [open4Add, setOpen4Add] = React.useState(false);
    const [open4Edit, setOpen4Edit] = React.useState(false);
    const [text4List, setText4List] = React.useState('');
    const [currVoter, setCurrVoter] = React.useState({});

    React.useEffect(() => {
        fetchVoterList(pgOpts);
    }, []);

    const fetchVoterList = (opts)=>{
        if (!uauth) return;
        appConfig.getAPI().post("/api/mp/voter/list", opts)
        .then((response) => {
            console.log("PzApi 'voterlist' success 1");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            setPgData(pgdata);
            if (pgdata.total != poll.nelg) {
                let pkind = poll.pkind.substring(0,2) + (pgdata.total > 0 ? 'E' : 'A') + (poll.pkind.length > 3 ? poll.pkind.substring(3) : '');
                if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.total, pkind: pkind });
            }
        })
        .catch((error) => {
            console.log("PzAPI 'voterlist' error : ", error);
        })
        .finally(() => {});
    };

    function onClickToEditVoterDataset() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/voter/list", {...pgOpts, pageSize:0, filter:''}) // fetch all
        .then((response) => {
            console.log("PzApi 'voterlist' success 2");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:108, page:1, pageSize:10, pageData:[...]}
            const text = extractTextFromVoters(pgdata.pageData);
            setText4List(text); // set the text for the TextArea of the entire list
            setOpen4List(true); // let the modal dialog open
            setVState({ ...vState, updated:dayjs() });
            if (pgdata.total != poll.nelg || pgdata.pkind != poll.pkind) {
                if (typeof setPoll === 'function') setPoll({ ...poll, pkind:pgdata.pkind, nelg: pgdata.total });
            }
        })
        .catch((error) => {
            console.log("PzAPI 'voterlist' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    function onClickToClearVoterDataset() {
        // setLoading(true);
        appConfig.getAPI().post("/api/mp/voter/clearall", {pguid:poll.pguid}) // 
        .then((response) => {
            // console.log("PzAPI 'voter/clearall' success ");
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // poll info
            setVState({ ...vState, nt:0, nb:0, np:0, ne:0, na:0, updated:dayjs() });
            fetchVoterList(pgOpts);
            if (typeof setPoll === 'function') setPoll({ ...poll, nelg: 0, pkind: pgdata.pkind });
        })
        .catch((error) => {
            console.log("PzAPI 'voter/clearall' failure : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    }

    if (!poll) return (<div></div>);
    return (
        <div className={"w-full flex flex-col justify-start items-stretch gap-y-2 " + className}>
            <PzTable 
                data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchVoterList}
                rowKey="vid" searchable={true} configurable={true} 
                columns={[
                    { title: 'ID', key: 'vid',
                        render: ({vid})=>{return (<PztShortTooltip value={vid} stt={vid.length-14} end={vid.length}/>);} },
                    { title: 'Name', key: 'vname' },
                    { title: 'VCode', key: 'vcode', hidden:true },
                    { title: 'BDate', key: 'vbdate' },
                    { title: 'Phone', key: 'vphone' },
                    { title: 'Email', key: 'vemail' },
                ]}  
                buttons={[
                    { title: localeMap["poll03.voter.action.text"],  onClick:()=>{onClickToEditVoterDataset();} },
                    { title: localeMap["poll03.voter.action.clear"], onClick:()=>{onClickToClearVoterDataset();} },
                ]} 
                onClickToAddRow={()=>{setOpen4Add(true);}} 
                onClickToEditRow={(row)=>{setOpen4Edit(true); setCurrVoter(row);}} />

            {open4List && (
                <VoterModalToEditDataset poll={poll} setPoll={setPoll}
                    text4List={text4List} 
                    onClose={()=>{setOpen4List(false);}} 
                    onSuccess={()=>{fetchVoterList(pgOpts);}} />
            )}

            {open4Add && (
                <VoterModalToAdd poll={poll} setPoll={setPoll}
                    onClose={()=>{setOpen4Add(false);}} 
                    onSuccess={()=>{fetchVoterList(pgOpts);}} />
            )}

            {open4Edit && (
                <VoterModalToEdit poll={poll} setPoll={setPoll} voter={currVoter} setCurrVoter={setCurrVoter}
                    onClose={()=>{setOpen4Edit(false);}} 
                    onSuccess={()=>{fetchVoterList(pgOpts);}} />
            )}

        </div>
    );
}

function VoterModalToEditDataset({poll, setPoll, text4List, onClose, onSuccess}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [lastText,  setLastText] = React.useState(text4List);
    const [voterText, setVoterText] = React.useState(text4List);
    const [selection, setSelection] = React.useState('');
    const [errMsgKey, setErrMsgKey] = React.useState('');

    function onClickToImportVotersFromText(voter_text) {
        setLastText(voter_text);
        const [voters, errmsg, errtxt] = extractVotersFromText(voter_text, poll.locale);
        if (errmsg !== '') {
            setErrMsgKey('poll04.voter.errmsg.'+errmsg);
            setSelection(errtxt);
            // console.log(`errmsg: '${errmsg}'`, `  errtxt: '${errtxt}'`);
        } else {
            setErrMsgKey('');
            setSelection('');
            appConfig.getAPI().post("/api/mp/voter/dataset", {pguid:poll.pguid, dataset:voters}) // update the dataset
            .then((response) => {
                const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
                const pgdata = pzdata.resource; // poll info
                if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.nelg, pkind: pgdata.pkind });
                if (typeof onSuccess === 'function') onSuccess();
            })
            .catch((error) => {
                console.log("PzAPI 'voter/dataset' error : ", error);
            })
            .finally(() => {
                if (typeof onClose === 'function') onClose();
            });
        }
    }

    if (!poll) return (<div></div>);
    return (
        <PzBasicModal onClose={onClose} >
            <div className="min-w-[600px] flex flex-col justify-start items-stretch p-5 gap-2">
                <h3>{utils.completeLocaleMapText(localeMap, "poll03.voter.title", [], poll.pkind)}</h3>
                <h5 className="text-slate-400">
                    {localeMap["poll03.voter.direction1"]} <br/>
                    {localeMap["poll03.voter.direction2"]}
                </h5>
                <PzInput.ForTextArea name="voter" className="border-[1px] px-1 resize-y" rows={10} 
                    value={voterText} onChangeDone={(text)=>{setVoterText(text);}} selection={selection}/>
                <h5 className="text-slate-400 w-full text-right">
                    &nbsp;{localeMap[errMsgKey]}
                </h5>
                <div className="w-full flex flex-row justify-end">
                    <PzButton.Default onClick={()=>{onClickToImportVotersFromText(voterText);}} disabled={voterText === lastText} >
                        <h5>{localeMap["poll03.voter.button.save"]}</h5>
                    </PzButton.Default>
                </div>
            </div>
        </PzBasicModal>
        // <PzBasicModal onClose={onClose} >
        //     <div className="min-w-[600px] p-5 flex flex-col justify-start items-stretch gap-2">
        //         <h3>{utils.completeLocaleMapText(localeMap, "poll03.voter.title", [], poll.pkind)}</h3>
        //         <label className="text-slate-400">{utils.completeLocaleMapText(localeMap, "poll03.voter.direction", [], poll.pkind)}</label>
        //         <textarea id={idVoter} name="voters" className="border-[1px] border-gray-300 px-1 resize-y"
        //             defaultValue={text4List} rows={10} placeholder={utils.completeLocaleMapText(localeMap, "poll03.voter.placeholder", [], poll.pkind)} />
        //         <div className="w-full flex flex-row justify-end">
        //             <PzButton.Default onClick={()=>{onImportVoterListFromText('');}}>
        //                 <h5>{localeMap["poll03.voter.button.save"]}</h5>
        //             </PzButton.Default>
        //         </div>
        //     </div>
        // </PzBasicModal>
    );
}

function VoterModalToAdd({poll, setPoll, onClose, onSuccess}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const initial = {pguid:poll.pguid, vname:'', vbdate:'', vphone:'', vemail:''};
    const [voter, setVoter] = React.useState(initial);
    const okToSave = (voter.vname !== '' && (voter.vemail==='' || utils.isValidEmail(voter.vemail)));

    function onClickToSave() {
        console.log("onSave to Add : ", voter);
        appConfig.getAPI().post("/api/mp/voter/add", voter)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // poll info
            if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.nelg, pkind: pgdata.pkind });
            if (typeof onSuccess === 'function') onSuccess();
        })
        .catch((error) => {
            console.log("Staff add error : ", error);
        })
        .finally(() => {
            if (typeof onClose === 'function') onClose();
        });
    }

    return (
        <PzBasicModal onClose={onClose} >
            <div className="min-w-[400px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h3>{utils.completeLocaleMapText(localeMap, "poll03.voter.action.add", [], poll.pkind)}</h3>

                <EvtEditorForVoter voter={voter} setVoter={setVoter} editable={true} editable_email={true} />

                <div className="w-full flex flex-row justify-end">
                    <PzButton.Default onClick={onClickToSave} disabled={!okToSave} >
                        <h5>{localeMap["poll03.voter.button.save"]}</h5>
                    </PzButton.Default>
                </div>

            </div>
        </PzBasicModal>
    );
}

function VoterModalToEdit({poll, setPoll, voter, setVoter, onClose, onSuccess}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const okToSave = (voter.vname !== '' && (voter.vemail==='' || utils.isValidEmail(voter.vemail)));

    function onSave() {
        console.log("onSave to Edit : ", voter);
        const payload = { ...voter, pguid: poll.pguid }
        appConfig.getAPI().post("/api/mp/voter/edit", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // poll info
            if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.nelg, pkind: pgdata.pkind });
            if (typeof onSuccess === 'function') onSuccess();
        })
        .catch((error) => {
            console.log("Voter edit error : ", error);
        })
        .finally(() => {
            if (typeof onClose === 'function') onClose();
        });
    }
    function onDelete() {
        console.log("onDelete : ", voter);
        const payload = { pguid: poll.pguid, vid: voter.vid }
        appConfig.getAPI().post("/api/mp/voter/delete", payload)
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // poll info
            if (typeof setPoll === 'function') setPoll({ ...poll, nelg: pgdata.nelg, pkind: pgdata.pkind });
            if (typeof onSuccess === 'function') onSuccess();
        })
        .catch((error) => {
            console.log("Staff delete error : ", error);
        })
        .finally(() => {
            if (typeof onClose === 'function') onClose();
        });
    }

    return (
        <PzBasicModal onClose={onClose} >
            <div className="min-w-[400px] flex flex-col justify-start items-stretch p-5 gap-5">

                <h3>{utils.completeLocaleMapText(localeMap, "poll03.voter.action.edit", [], poll.pkind)}</h3>

                <EvtEditorForVoter voter={voter} setVoter={setVoter} editable={true} />

                <div className="w-full flex flex-row justify-between items-center">
                    <PzButton.Default onClick={()=>{onDelete();}}>
                        <h5>{localeMap["poll03.voter.button.delete"]}</h5>
                    </PzButton.Default>
                    <PzButton.Default onClick={()=>{onSave();}} disabled={!okToSave} >
                        <h5>{localeMap["poll03.voter.button.save"]}</h5>
                    </PzButton.Default>
                </div>

            </div>
        </PzBasicModal>
    );
}

export function extractTextFromVoters(voter_list, name_only=false) {
    if (name_only) {
        return voter_list.map(v=>v.vname).join(", ");
    } else {
        let text = "";
        for (let i = 0; i < voter_list.length; i++) {
            let v = voter_list[i];
            text += `${v.vname ? v.vname : ''}, ${v.vbdate ? v.vbdate : ''}, ${v.vphone ? v.vphone : ''}, ${v.vemail ? v.vemail : ''}\n`;
        }
        return text;
    }
}

export function extractVotersFromText(voter_text, locale) {
    let voters = [], errmsg = '', errtxt = '';
    const lines = voter_text.trim().split('\n');
    if (lines.length === 1 && lines[0].length === 0) {
        return [[], '', ''];
    } else if (lines.length === 1) {
        // IN CASE where all the voters are on a single line, separated by comma ","

        const parts = (lines[0].indexOf('\t') > 0 ? lines[0].split('\t') : lines[0].split(','));
        let v = { vname:'', vbdate:'', vemail:'', vphone:'' };
        for (let j = 0; j < parts.length; j++) {
            const part = parts[j].trim()
            if        (part.indexOf('@') > 0) {         // email
                const [email] = utils.parseEmailString(part);
                if (email) v.vemail = email;
            } else if (utils.isDigitOnly(part)) {       // all digits
                const [bdate] = utils.parseBDateString(part);
                if (bdate) v.vbdate = bdate;
                const [phone] = utils.parsePhoneString(part, locale);
                if (phone) v.vphone = phone;
            } else {                                    // name
                if (v.vname !== '') {
                    voters.push(v);
                    v = { vname:'', vbdate:'', vemail:'', vphone:'' };
                }
                if (part.length >= 1 && v.vname === '') {
                    v.vname = part;
                }
            }
        }
        if (v.vname !== '') voters.push(v);

    } else {
        // IN CASE where each voter is on a single line, contact info fields separated by comma ","

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            if (line.trim().length === 0) continue;
            const parts = (lines[i].indexOf('\t') > 0 ? lines[i].split('\t') : lines[i].split(','));
            let v = { vname:'', vbdate:'', vemail:'', vphone:'' };
            for (let j = 0; j < parts.length; j++) {
                const part = parts[j].trim()
                if        (part.indexOf('@') > 0) {         // email
                    const [email] = utils.parseEmailString(part);
                    if (email) v.vemail = email;
                    else break; 
                } else if (utils.isDigitOnly(part)) {       // all digits
                    const [bdate] = utils.parseBDateString(part);
                    if (bdate) { v.vbdate = bdate; continue; }
                    const [phone] = utils.parsePhoneString(part, locale);
                    if (phone) { v.vphone = phone; continue; }
                } else {                                    // name
                    if (part.length >= 1 && v.vname === '') v.vname = part;
                }
            }
            if (v.vname === '') { errmsg = 'no_name'; errtxt = line; break; }
            else voters.push(v);
        }

    }
    if (errmsg === '' && voters.length === 0) {
        errmsg = 'empty_list';
        errtxt = '';
    }
    return [voters, errmsg, errtxt];
}
