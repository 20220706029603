import React from "react";
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzInput from "../common/PzInput";
import PzSymbol from "../common/PzSymbol";
import EvtViewerForQstResult from "./EvtViewerForQstResult";
import PzSetting from "../common/PzSetting";

export default function EvtEditorForQst({ qst, setQst=undefined, setErr=undefined, 
    qseqMax=-1, className='', size='lg', visibility={k:true,t:true,q:true,g:true,x:false}, narrow=false }) {
    const idTitle = React.useId();
    const idType = React.useId();
    const idQuestion = React.useId();
    const idAnswers = React.useId();
    const idQstGoal = React.useId();
    const idWCriteria = React.useId();
    const idDCriteria = React.useId();
    const ulocale = useSelector((state)=>state.ucfg.locale);  // user's current locale selection
    const localeMap = useSelector(utils.selectLocaleMap);
    let [textAnswers, setTextAnswers] = React.useState(convertAnswersForTextArea(qst?.answers));
    const editable = (typeof setQst === 'function');
    const [selQkind, setSelQkind] = React.useState([ qst.qkind ]);
    setQst = (setQst ? setQst : ()=>{});
    setErr = (setErr ? setErr : ()=>{});
    const text_size = 'text-' + size;
    // console.log(visibility);

    React.useEffect(() => {
        setTextAnswers(convertAnswersForTextArea(qst.answers))
    }, [qst.answers]);

    function onChangeToQkind(old_selection, new_selection) {
        let qkind = new_selection[0];
        if (qkind === 'A' || qkind === 'S' || qkind === 'E' || qkind === 'C') {
            let new_qst = { ...qst, qkind:qkind };
            if (qkind !== qst.qkind) {
                let new_ans_text = '';
                switch (qkind) {
                    case 'A': new_ans_text = localeMap["poll02.answers.A"]; break;
                    case 'S': new_ans_text = localeMap["poll02.answers.S"]; break;
                    case 'E': new_ans_text = localeMap["poll02.answers.E"]; break;
                    default: break;
                }
                if (new_ans_text !== '') setTextAnswers(new_ans_text);
                new_qst.answers = [ 'BLANK', ...new_ans_text.split('\n') ];
                new_qst.nanswers = new_qst.answers.length - 1;
            }
            setQst(new_qst);
        }        
    }

    function onChangeDoneToQuestion(text) {
        text = text.trim();
        if (qst.question === text) {
            return;
        } else if (text == '') {
            setQst({ ...qst, question:'' });
        } else {
            let new_qst = { ...qst, question: text }
            setQst(new_qst);
        }
    }

    function onChangeDoneToQSeq(text) {
        let new_qst = { ...qst, qseq: Number(text) }
        setQst(new_qst);
    }

    function onChangeDoneToQRoundMax(text) {
        let new_qst = { ...qst, qroundmax: Number(text) }
        setQst(new_qst);
    }

    function onChangeDoneToNWanted(text) {
        let new_qst = { ...qst, nwanted: Number(text) }
        setQst(new_qst);
    }

    function onChangeDoneToAnswers(text) {
        if (!text) { console.log("undefined answers"); return; }
        const lines = text.split('\n');
        let new_answers = [{aidx:0, aname:'_WAIVE_'}];
        for (let i = 0; i < lines.length; i++) {
            let answer = lines[i].trim();
            if (answer.length === 0) continue;
            new_answers.push({aidx:i+1, aname:answer});
        }
        if (new_answers >= 3) {
            setQst({ ...qst, answers: new_answers, nanswers: new_answers.length-1 });
        } else {
            setErr('not enough answers');
        }
    }

    function convertAnswersForTextArea(answers) {
        let desc = [];
        for (let i = 1; i < answers?.length; i++) {
            if (typeof answers[i] === 'string') {
                desc.push(answers[i]);          // This is case when it was created on the frontend
            } else if (typeof answers[i] === 'object' && answers[i].aname !== '_WAIVE_') {
                desc.push(answers[i].aname);    // This is case when it was received from the backend
            }
        }
        return desc.join("\n");
    }

    function setCriteria2Win(c2win) {
        let new_qoption = { ...qst.qoption, c2win: c2win };
        setQst({ ...qst, qoption: new_qoption });
    }

    function setCriteria2Drop(c2drop) {
        let new_qoption = { ...qst.qoption, c2drop: c2drop };
        setQst({ ...qst, qoption: new_qoption });
    }

    if (!qst) return (<h4>Undefined 'qst'</h4>);
    return (
        <div className={" w-full grid grid-cols-[auto_1fr] gap-x-3 gap-y-1 " + text_size + className}>

            {/* Kind */}
            { visibility.k && <>  
                <span htmlFor={idType} className={text_size}>{localeMap["poll02.qkind.label"]} :</span>
                <div className="grid grid-cols-[auto_auto_auto] items-start">
                    <PzInput.Radio2 value='A' selection={selQkind} setSelection={setSelQkind} size={size} onChange={onChangeToQkind} disabled={!editable && qst.qkind!=='A'} text={localeMap["poll02.qkind.A"]} />
                    <PzInput.Radio2 value='S' selection={selQkind} setSelection={setSelQkind} size={size} onChange={onChangeToQkind} disabled={!editable && qst.qkind!=='S'} text={localeMap["poll02.qkind.S"]} />
                    <PzInput.Radio2 value='E' selection={selQkind} setSelection={setSelQkind} size={size} onChange={onChangeToQkind} disabled={!editable && qst.qkind!=='E'} text={localeMap["poll02.qkind.E"]} />
                </div>
            </>}

            {/* Question */}
            { visibility.q && <>  
                <span htmlFor={idQuestion} className={text_size}>{localeMap["poll02.question.label"]} :</span>
                <PzInput.ForText 
                    id={idQuestion} name="question" className={text_size} 
                    placeholder={localeMap["poll02.question.placeholder"]}
                    value={qst.question} disabled={!editable}
                    onChangeDone={onChangeDoneToQuestion} update={true} />
            </>}

            {/* Answers */}
            { visibility.q && <>  
                <span htmlFor={idAnswers} className={text_size} hidden={qst.qkind==='C'}>{qst.qkind==='E' ? localeMap["poll02.answers.elabel"] : localeMap["poll02.answers.clabel"]} :</span>
                <PzInput.ForTextArea id={idAnswers} name="answers" hidden={qst.qkind==='C'}
                    className={'border-[1px] border-gray-300 px-1 ' + text_size + (qst.qkind==='A' ? ' resize-none' : ' resize-y')} 
                    placeholder={localeMap["poll02.answers.placeholder"]}
                    rows={qst.nanswers >= 5 ? 5 : qst.nanswers} 
                    value={textAnswers} setValue={setTextAnswers} disabled={!editable || qst.qkind==='A'} 
                    onChangeDone={onChangeDoneToAnswers} update={true} />
            </>}

            {/* Goal */}
            { visibility.g && <>  
                <span htmlFor={idQstGoal} className={text_size}>{localeMap["poll02.vgoal.label"]} :</span>
                <div id={idQstGoal} className="flex flex-row justify-between items-center">
                    <div className={text_size}>
                        { qst.qkind==='A' ?
                            <p className={text_size}>{utils.getQstGoalSummary(qst, localeMap)}</p>
                        : qst.qkind==='S' ?
                            <div className={"flex flex-row justify-start items-center gap-1 " + text_size}>
                                <span className={text_size}>{localeMap["poll02.qkind."+qst.qkind]}</span>
                                <PzInput.ForInt value={qst.nwanted} min={1} max={qst.nanswers-1} className="w-[24px] !py-0" onChangeDone={onChangeDoneToNWanted} disabled={!editable}/>
                                <label>/</label>
                                <label>{qst.nanswers}</label>
                            </div>
                        : qst.qkind==='E' ?
                            <div className={"flex flex-row justify-start items-center gap-1 " + text_size}>
                                <span className={text_size}>{localeMap["poll02.qkind."+qst.qkind]}</span>
                                <PzInput.ForInt value={qst.nwanted} min={1} max={qst.nanswers-1} className="w-[24px] !py-0" disabled={!editable}/>
                                <label>/</label>
                                <label>{qst.nanswers}</label>
                            </div>
                        : 
                            <label>{localeMap["poll02.qkind."+qst.qkind]}</label>
                        }
                    </div>
                    <div className={`flex flex-row justify-start items-center gap-2 ${qst.qkind==='C' ? `invisible` : ``}`}>
                        <span className={text_size}>{localeMap["poll02.vgoal.maxrounds"]} :</span>
                        <PzInput.ForInt value={qst.qroundmax} min={1} max={9} className="w-[24px] !py-0" onChangeDone={onChangeDoneToQRoundMax} disabled={!editable}/>
                    </div>
                </div>
            </>}

            {/* Criteria to Win */}
            { visibility.g && qst.qkind !== 'C' &&
                <>  
                    <span htmlFor={idWCriteria} className={text_size}>{localeMap["poll02.wcriteria.label." + qst.qkind]} :</span>
                    <PzSetting.C2Win criteria={qst.qoption?.c2win} setCriteria={setCriteria2Win} locale={ulocale} size={narrow ? 'md' : size}/>
                </>
            }

            {/* Criteria to Drop */}
            { visibility.g && qst.qkind !== 'A' && qst.qkind !== 'C' && qst.qroundmax > 1 &&
                <>  
                    <span htmlFor={idDCriteria} className={text_size}>{localeMap["poll02.dcriteria.label"]} :</span>
                    <PzSetting.C2Drop criteria={qst.qoption?.c2drop} setCriteria={setCriteria2Drop} locale={ulocale} size={narrow ? 'md' : size}/>
                </>
            }
            { visibility.x && <>  {/* Results */}
                <span htmlFor={idAnswers} className={text_size}>{localeMap["poll02.xresult.label"]} :</span>
                <EvtViewerForQstResult qst={qst} />
            </>}
        </div>
    );
}

