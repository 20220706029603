import React from "react";

import { ReactComponent as IconStrength0 } from "../resource/icons/pz-strength-0.svg";
import { ReactComponent as IconStrength1 } from "../resource/icons/pz-strength-1.svg";
import { ReactComponent as IconStrength2 } from "../resource/icons/pz-strength-2.svg";
import { ReactComponent as IconStrength3 } from "../resource/icons/pz-strength-3.svg";
import { ReactComponent as IconStrength4 } from "../resource/icons/pz-strength-4.svg";
import { Icons } from "react-toastify";

export default function PzIconStrength({value, size=18, className=''}) {

    if (value === 4) {
        return (<IconStrength4 width={size} height={size} className={className} />);
    } else if (value === 3) {
        return (<IconStrength3 width={size} height={size} className={className} />);
    } else if (value === 2) {
        return (<IconStrength2 width={size} height={size} className={className} />);
    } else if (value === 1) {
        return (<IconStrength1 width={size} height={size} className={className} />);
    } else  {
        return (<IconStrength0 width={size} height={size} className={className} />);
    }
}
