import React from 'react'
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Fab from '@mui/material/Fab';

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "./PzBasicModal";
import PzButton from "./PzButton";
import PzInput from "./PzInput";

const PzDialog = {
    Empty:          PzDialogEmpty,
    Simple:         PzDialogSimple,

    Alpha:          ButtonAlpha,
    SendFeedback:   ButtonSendFeedback,
};

export default PzDialog;


function PzDialogEmpty({ children, onClose=()=>{}, className='' }) {
    const localeMap = useSelector(utils.selectLocaleMap);

    return (
        <PzBasicModal onClose={onClose}>
            <div className={" flex flex-col justify-start items-center p-5 gap-5 " + className}>
                { children }
            </div>
        </PzBasicModal>
    );
}

function PzDialogSimple({ title='', message='', className='', onClose=()=>{}, 
    onButtonOk=undefined, onButtonCancel=undefined, onButtonClose=undefined }) {
    const localeMap = useSelector(utils.selectLocaleMap);

    return (
        <PzBasicModal onClose={onClose}>
            <div className={" min-w-[300px] flex flex-col justify-start items-center p-5 gap-5 " + className}>
                { title &&
                    <h2 className=" font-extrabold">{title}</h2>
                }
                <div className="flex flex-col justify-start items-center gap-0">
                    { typeof message === 'string' ?
                        message.split('\n').map((line,idx)=>(<p key={idx} className="text-center text-lg">{line.trim()}</p>))
                    :
                        <></>
                    }
                </div>
                <div className="w-full flex flex-row justify-center items-center gap-5">
                    { onButtonClose  && <PzButton.Std text={localeMap["comm.button.close"]}  size='xl' onClick={onButtonClose} /> }
                    { onButtonCancel && <PzButton.Std text={localeMap["comm.button.cancel"]} size='xl' onClick={onButtonCancel} /> }
                    { onButtonOk     && <PzButton.Std text={localeMap["comm.button.ok"]}     size='xl' onClick={onButtonOk} /> }
                </div>
            </div>
        </PzBasicModal>
    );
}

function ButtonAlpha() {
    const localeMap = useSelector(utils.selectLocaleMap);
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <PzButton.Minimal type='' className="p-0" onClick={()=>{setOpen(true);}}>
                <label className="font-extrabold text-xs text-red-900 hover:text-red-700 hover:cursor-pointer">
                    Alpha
                </label>
            </PzButton.Minimal>

            { open &&
                <PzDialogSimple className="w-[29rem]"
                    title={localeMap["alpha.noti.title"]}
                    message={localeMap["alpha.noti.message"]}
                    onClose={()=>{setOpen(false);}} />
            }
        </>
    );
}

function ButtonSendFeedback() {
    const ulocale = useSelector((state)=>state.ucfg.locale);
    const localeMap = useSelector(utils.selectLocaleMap);
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const [ftype, setFtype] = React.useState(['C']);
    const [ftext, setFtext] = React.useState('');
    const [subsc, setSubsc] = React.useState(false);

    function onClickToOpen() {
        setFtype('C'); 
        setFtext(''); 
        setSubsc(false);
        setOpen(true);
    }

    function onChangeToFType(event) {
        switch (event.target.value) {
            case 'C': setFtype('C');  break;
            case 'B': setFtype('B');  break;
            case 'F': setFtype('F');  break;
            default:  break;
        }
    }

    function onChangeToFText(text) {
        setFtext(text.trim());
    }

    function onChangeToSubsc(event) {
        setSubsc(event.target.checked);
    }

    function onClickToSendFeedback() {
        const pathname = location.pathname;
        // console.log("Send Feedback : ", ftype, pathname, ftext, subsc, ulocale);
        appConfig.getAPI().post("/api/my/feedback", {fbtype: ftype[0], fbpath: pathname, fbtext: ftext, locale: ulocale })
        .then((response)=>{
            console.log("PzAPI 'my/feedback' success : ", response.data);
        })
        .catch((error)=>{
            console.log("PzAPI 'my/feedback' error : ", error);
        })
        .finally(()=>{
            setOpen(false);
        });
    }

    return (
        <>
            <Fab variant="extended" size="small" onClick={onClickToOpen}
                style={{ margin: 0, top: 'auto', right: 20, bottom: 20, left: 'auto', position: 'fixed' }} >
                <label className="font-extrabold text-xs">{localeMap["alpha.feedback.fab"]}</label>
            </Fab>

            { open &&
                <PzBasicModal onClose={()=>{setOpen(false);}} xclose={true} >
                    <div className="w-[30rem] flex flex-col justify-start items-stretch p-5 gap-5">
                        <h2 className="font-extrabold">{localeMap["alpha.feedback.title"]}</h2>
                        <div className="flex flex-col justify-start items-stretch gap-1">
                            <label className="text-md text-slate-500">{localeMap["alpha.feedback.desc"]}</label>
                            <div className="flex flex-row justify-between items-center">
                                <PzInput.Radio2 value='C' selection={ftype} setSelection={setFtype} size="md" ><span className="text-sm">{localeMap["alpha.feedback.type0"]}</span></PzInput.Radio2>
                                <PzInput.Radio2 value='B' selection={ftype} setSelection={setFtype} size="md" ><span className="text-sm">{localeMap["alpha.feedback.type1"]}</span></PzInput.Radio2>
                                <PzInput.Radio2 value='F' selection={ftype} setSelection={setFtype} size="md" ><span className="text-sm">{localeMap["alpha.feedback.type2"]}</span></PzInput.Radio2>
                            </div>
                            <PzInput.ForTextArea value={ftext} onChange={onChangeToFText} rows={6} className="!p-2" />
                        </div>
                        <div className="flex flex-col justify-start items-stretch gap-1">
                            <div className="flex flex-row justify-between items-center">
                                <label className="text-md">{localeMap["alpha.feedback.newsletter"]}</label>
                                <PzInput.Checkbox1 checked={subsc} onChange={onChangeToSubsc}>
                                    <span className="text-md">{localeMap["alpha.feedback.newsletter.subscribe"]}</span>
                                </PzInput.Checkbox1>
                            </div>
                            <label className="text-sm text-slate-500">{localeMap["alpha.feedback.newsletter.desc"]}</label>
                        </div>
                        <div className="flex flex-col justify-start items-end gap-0">
                            <PzButton.Std text={localeMap["alpha.feedback.send"]} size='lg' 
                                onClick={onClickToSendFeedback} disabled={ftext === ''} />
                        </div>
                    </div>
                </PzBasicModal>
            }
        </>
    );
}

