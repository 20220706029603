import React from "react";

import { ReactComponent as IconChevron } from "../resource/icons/pz-chevron-down.svg";
import { ReactComponent as IconButtonClose } from "../resource/icons/pz-button-close.svg";

const  PzArea = {
    Container: PzContainer,

    HomeBoard: PzHomeBoard,
    FooterBoard: PzFooterBoard,
    InvisibleBoard: PzInvisibleBoard,
    InvisibleBoardInContainer: PzInvisibleBoardInContainer,
    Board: PzBoard,
    BoardInContainer: PzBoardInContainer,
    FullBoardWithTitle: PzFullBoardWithTitle,
    AdminBoard: PzAdminBoard,

    MwBoard: PzMwBoard,
    MwBoardInContainer: PzMwBoardInContainer,
    MwhBoard: PzMwhBoard,
    MwhBoardInContainer: PzMwhBoardInContainer,

    EventCard: PzEventCard,

};

export default PzArea;


function PzContainer({ children, className='' }) {
    // h-[calc(100vh-56px)] 
    return (
        <div className={"w-full flex flex-col justify-start items-center " + className} >  
            {children}
        </div>
    );
}

function PzHomeBoard({ children, className='' }) {
    return (
        <div className={"w-[90%] min-w-[360px] max-w-[900px] py-10 flex flex-col justifiy-start items-center gap-10 " + className}>
            {children}
        </div>
    );
}

function PzFooterBoard({ children, className='' }) {
    return (
        <div className={"w-[90%] min-w-[360px] max-w-[900px] pt-[6rem] pb-[4rem] " + className}>
            <hr className="my-4" />
            {children}
        </div>
    );
}

function PzInvisibleBoard({ children, className='' }) {
    return (
        <div className={"w-[90%] min-w-[360px] max-w-[900px] flex flex-col gap-10 " + className} >
            {children}
        </div>
    );
}

function PzInvisibleBoardInContainer({ children }) {
    return (
        <PzContainer>
            <PzInvisibleBoard>
                {children}
            </PzInvisibleBoard>
        </PzContainer>
    );
}

function PzBoard({ children, className='' }) {
    return (
        <div className={"w-[90%] min-w-[360px] max-w-[900px] bg-slate-50 pz-card-shadow flex flex-col p-5 gap-10 " + className} >
            {children}
        </div>
    );
}

function PzBoardInContainer({ children }) {
    return (
        <PzContainer>
            <PzBoard>
                {children}
            </PzBoard>
        </PzContainer>
    );
}

function PzFullBoardWithTitle({ children, title, foldable=false, folded=false, onChangeToFold=(oldv,newv)=>{} }) {
    const [bodyFolded, setBodyFolded] = React.useState(folded);

    return (
        <div className="w-full bg-slate-50 pz-card-shadow flex flex-col px-5 py-10 gap-10" >

            {/* Title */}
            <div className="flex flex-row justify-between items-center">
                <h2>{title}</h2>
                { foldable &&
                    <IconChevron width={24} height={24} 
                        className={`cursor-pointer hover:text-blue-500 ${bodyFolded ? `` : `rotate-180`}`} 
                        onClick={() => { 
                            if (typeof onChangeToFold === 'function') onChangeToFold(bodyFolded, !bodyFolded); 
                            setBodyFolded(!bodyFolded);
                        }}/> 
                }
            </div>

            {/* Foldable Body */}
            <div className={"w-full flex flex-col gap-10 " + (bodyFolded ? 'hidden' : '')}>
                {children}
            </div>

        </div>
    );
}

function PzAdminBoard({ children, className='' }) {
    return (
        <div className={"w-[90%] min-w-[360px] bg-slate-50 pz-card-shadow flex flex-col p-5 gap-10 " + className} >
            {children}
        </div>
    );
}

function PzMwBoard({ children, className='' }) {
    return (
        <div className={"w-[70%] min-w-[min(max(94vw,360px),500px)] max-w-[min(94vw,700px)] bg-slate-50 pz-card-shadow flex flex-col justify-start items-center p-5 gap-5 " + className} >
            {children}
        </div>
    );
}

function PzMwBoardInContainer({ children }) {
    return (
        <PzContainer>
            <PzMwBoard>
                {children}
            </PzMwBoard>
        </PzContainer>
    );
}

function PzMwhBoard({ children, className='', xclose=false }) {
    const clSize = " w-[70%] min-w-[min(max(98vw,360px),500px)] max-w-[min(98vw,700px)] min-h-[min(max(94vh,500px),800px)] ";
    const clComp = " relative flex flex-col justify-between ";
    return (
        <div className={clSize + clComp + className} >
            {children}
            { xclose &&
                <div className="absolute right-5 top-5 bg-slate-100 text-slate-400 rounded-lg hover:bg-slate-200 hover:text-slate-800" onClick={()=>{window.close();}}>
                    <IconButtonClose width={20} height={20} />
                </div>
            }
        </div>
);
}

function PzMwhBoardInContainer({ children, className='', xclose=false }) {
    return (
        <PzContainer>
            <PzMwhBoard className={className} xclose={xclose} >
                {children}
            </PzMwhBoard>
        </PzContainer>
    );
}

function PzEventCard({ children, className='' }) {
    return (
        <div className={" w-full p-5 text-sm pz-card-shadow " + className} >
            {children}
        </div>
    );
}

