import React from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import EventCard from "../../eventview/EventCard";
import PzTable, {PztShortTooltip} from "../../common/PzTable";
import UserSplash from "../UserSplash";

export default function Eligibility() {
    const [cookies] = useCookies();
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    var uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const [pgOpts, setPgOpts] = React.useState({pguid:upoll?.pguid, page:0,pageSize:10});
    const [pgData, setPgData] = React.useState({page:0,pageSize:10,pageData:[],total:0});

    const fetchVoterList = (opts)=>{
        // setLoading(true);
        appConfig.getAPI().post("/api/s/voter/list", {...opts, claim: upoll.xrole})
        .then((response) => {
            const pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            const pgdata = pzdata.resource; // {total:100, page:0, pageSize:10, pageData:[...]}
            setPgData(pgdata);
        })
        .catch((error) => {
            console.log("PzAPI 'voterlist' error : ", error);
        })
        .finally(() => {
            // setLoading(false);
        });
    };

    React.useEffect(() => {
        fetchVoterList(pgOpts);
    }, []);

    if      (!upoll) {
        return (<UserSplash msg={"Event cannot be found"} />);
    } else if (upoll.xrole !== 'S' && upoll.xrole !== 'M' && upoll.xrole !== 'P') {
        return (<UserSplash msg={"Missing permission for the event"} /> );
    }  

    return (
        <PzArea.InvisibleBoardInContainer>

            <EventCard poll={upoll} viewable={false} foldable={true} />

            <PzArea.FullBoardWithTitle title={localeMap["menu.staff.eligibility"]}>

                <PzTable 
                    data={pgData} opts={pgOpts} setOpts={setPgOpts} fetchData={fetchVoterList}
                    rowKey="vid" searchable={true} configurable={true}
                    columns={[
                        { title: 'VID', key: 'vid',
                            render: ({vid})=>{ return (<PztShortTooltip value={vid} stt={vid.length-14}/>); } },
                        { title: 'Name', key: 'vname' },
                        { title: 'BDate', key: 'vbdate', hidden:true },
                        { title: 'Phone', key: 'vphone', hidden:true },
                        { title: 'Email', key: 'vemail', hidden:true },
                        { title: 'Status', key: 'vstatus' },
                        { title: 'CheckIn', key: 'checkin', datetime: 'HH:mm:ss' },
                        { title: 'LastVote', key: 'lastvote', datetime: 'HH:mm:ss' },
                        { title: 'CheckOut', key: 'checkout', datetime: 'HH:mm:ss', hidden:true },
                        { title: 'IP', key: 'voterip' },
                        { title: 'Location', key: 'voterloc' },
                    ]} />

            </PzArea.FullBoardWithTitle>

        </PzArea.InvisibleBoardInContainer>
    );
}
