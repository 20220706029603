import React from "react";
import { useSelector } from "react-redux";

import Tooltip from '@mui/material/Tooltip';

import { utils } from "../config/userConfig";

export default function PzUnderDev({className=''}) {
    const localeMap = useSelector(utils.selectLocaleMap);
    const classes = "cursor-pointer px-1 py-0 text-sm font-semibold shadow-sm text-slate-400 bg-slate-100 ring-inset ring-1 hover:ring-2 rounded-md";
    return (
        <div className="flex flex-row">
            <Tooltip className={classes}
                title={'Under Development'} placement="top" 
                slotProps={{
                    popper: {
                        modifiers: [{ name: 'offset', options: {offset: [0, -14]} }],
                    },
                }}>
                <h5>{localeMap["comm.underdev"]}</h5>
            </Tooltip>
        </div>
    )
}
