import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import appConfig from "../../config/appConfig";
import { utils } from "../../config/userConfig";
// import { getTimeInUserTimezone } from "../../config/userConfig";
import PzArea from "../../common/PzArea";
import PzButton from "../../common/PzButton";
import PzTable, {PztShortNavigate} from "../../common/PzTable";

export default function PzAdminUserInfo() {
    const location = useLocation();
    const [uinfo, setUInfo] = React.useState({uguid: location?.state?.uguid, polls:[]});
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const uauth = (cookies && cookies?.uauth ? jwtDecode(cookies.uauth) : null);
    const utzone = useSelector((state)=>state.ucfg.timezone);  // timezone from user settings

    const fetchUserInfo = ()=>{
        if (!uauth?.ispza) return;
        appConfig.getAPI().post("/api/pza/userinfo", {uguid:location?.state?.uguid, balance:true})
        .then((response)=>{
            // console.log("PzAPI 'pza/userinfo' fetched : ", response.data);
            let pzdata = response.data;   // {respcode:0, respmsg:'success', resource:{...}}
            setUInfo(pzdata.resource);
        })
        .catch((error)=>{
            console.log("PzAPI 'pza/userinfo' error : ", error);
        })
        .finally(()=>{});
    };
    
    React.useEffect(() => {
        if (!uauth?.ispza) { navigate("/"); return; }
        fetchUserInfo();
    }, []);

    return (
        <PzArea.Container>

        {!uauth?.ispza ? null : (
            <PzArea.AdminBoard >
                <h3>{"PzA - User Info"}</h3>
                <div className="grid grid-cols-[100px_1fr] gap-x-5 gap-y-1 text-sm">
                    <h5>UGuid</h5>      <p>{uinfo.uguid || ""}</p>
                    <h5>Name</h5>       <p>{uinfo.uname || ""}</p>
                    <h5>Email</h5>      <p>{uinfo.uemail || ""}</p>
                    <h5>Login (First)</h5>  
                    <p>{utils.getTimeInUserTimezone(uinfo.createdat, "YYYY-MM-DD HH:mm:ss", utzone, true)}</p>
                    <h5>Login (Last)</h5>  
                    <p>{utils.getTimeInUserTimezone(uinfo.lastlogin, "YYYY-MM-DD HH:mm:ss", utzone, true)}</p>
                    <h5>Last IP</h5> <p>{uinfo.lastip} &emsp; ({uinfo.location})</p>
                    <h5>Country</h5> <p>{uinfo.country}</p>
                    <h5>Locale</h5> <p>{uinfo.locale}</p>
                    <h5>Timezone</h5> <p>{uinfo.timezone}</p>
                    <h5>Free Quota</h5> <p>{uinfo.fq?.qtrem} / {uinfo.fq?.qtmax}</p>  
                    <h5>Polls</h5> 
                    <PzTable data={uinfo.polls} rowKey='pguid' configurable={true}
                        columns={[
                            { title: 'PGuid', key: 'pguid', 
                                render: ({pguid}) => { return (<PztShortNavigate value={pguid} stt={12} to="../pollinfo" state={{pguid}} />); } },
                            { title: 'Kind', key: 'pkind' },
                            { title: 'Status', key: 'pstatus', },
                            { title: 'When', key: 'pwhen', sorter: false, datetime:'YYYY-MM-DD HH:mm'},
                            { title: 'Title', key: 'ptitle' },
                        ]} />
                </div>
                <div className="flex flex-row justify-between">
                    <PzButton.Std text='UInfo' size='md' onClick={()=>console.log(uinfo)} />
                </div>
            </PzArea.AdminBoard>
        )}
        
        </PzArea.Container>
    );
}
