import React from 'react';
import { useSelector } from "react-redux";

import appConfig from "../config/appConfig";
import { utils } from "../config/userConfig";
import PzBasicModal from "../common/PzBasicModal"

const EntranceForTransfer = {
    Dialog:       EntranceForTransferDialog,
};

export default EntranceForTransfer;


function EntranceForTransferDialog({path, onClose=()=>{}}) {
    const upoll = useSelector((state)=>state.upcfg.uPoll);
    const localeMap = useSelector(utils.selectLocaleMap);
    const target_url = appConfig.getBaseUrlForWebAppEntrance() + path + (upoll ? "?p="+upoll.pguid : "");
    const imgsrc = appConfig.getBaseUrlForAPI() + '/api/entrance/webapp?url=' + target_url

    function onClickToQR() {
        // const url = appConfig.getBaseUrlForAPI() + POLL_URL_PATH;
        const win = window.open(target_url, '_blank');
        if (win != null) win.focus();
        if (typeof onClose === 'function') onClose();
    }

    return (
        <PzBasicModal onClose={onClose}>
            <div className="w-full min-w-[26rem] max-w-[36rem] flex flex-col justify-start items-center p-10 gap-10" >
                <div className="w-full flex flex-col justify-start items-center gap-5">
                    <h2>{localeMap["entrance.qrcode.for.transfer"]}</h2>
                    <span className="text-xl">{localeMap["entrance.qrcode.instruct.voter"]}</span>
                </div>
                <div className="w-full p-3 rounded-[2rem] ring-slate-200 ring-inset ring-1 pz-qr-shadow">
                    <img src={imgsrc} onClick={onClickToQR} alt=""  width={600} height={600} className="object-contain object-center"/>
                </div>
                <div className="w-full text-center text-slate-400">
                    <div className="text-xl">Hosted by <em className="font-bold">Pollerz.net</em></div>
                </div>
            </div>
        </PzBasicModal >
    )

}

