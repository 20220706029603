import React from "react";

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from "@mui/icons-material/Search";

export default function PzSearchField({placeholder, onEnter=(text)=>{}, autoFocus=false, tabIndex=undefined}) {
    const [searchText, setSearchText] = React.useState('');
    const [savedText, setSavedText] = React.useState('');
    const [placeHolder, setPlaceHolder] = React.useState('');
    if (!placeholder) placeholder = "'value' or 'column:value'"

    return (
        <TextField 
            size="small" 
            placeholder={placeHolder} autoFocus={autoFocus}// 
            sx={{
                width: 80,
                '&:focus-within':{
                    width:'50%',
                },
                transition: 'width 0.4s',
            }}
            inputProps={{
                style: { padding: 0 },
            }}
            InputProps={{
                sx: { borderRadius: 5 },
                style: { paddingTop: 3, paddingRight: 4, paddingBottom: 3, paddingLeft: 4 },
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }} 
            value={searchText}
            onChange={(e)=>{ setSearchText(e.target.value); }}
            onFocus={()=>{ setSearchText(savedText); setPlaceHolder(placeholder); }}
            onBlur={()=>{ setSavedText(searchText); setSearchText(''); setPlaceHolder(''); }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    // let new_text = e.target.value;  // this is the same with 'searchText'
                    onEnter(searchText);
                    // console.log(`PzSearchField onKeyDown (Enter) ${e.key}  with '${searchText}'`);
                }
            }}
            tabIndex={tabIndex}
        />
    );
}